import { createStore } from 'vuex';
import createPersistedState from "vuex-persistedstate";

import auth from './auth';
import dashboard from './dashboard';

const store = createStore({
  modules: {
    auth,
    dashboard,
  },
  plugins: [
    createPersistedState({ storage: window.sessionStorage }),
  ],
});

export default store;
