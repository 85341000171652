<template>
  <div class="congrats">
    <inline-svg
      class="congrats--icon"
      :src="require('@/assets/images/icons/check-circle.svg')"
    />
    <h1 class="congrats--title h1-regular">
      {{ title }}
    </h1>
    <p
      class="congrats--description p-medium"
      :style="{ maxWidth: maxWidth + 'px',
                marginBottom: marginBottom + 'px'
      }"
    >
      {{ description }}
    </p>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
  components: {
    InlineSvg
  },

  props: {
    title: {
      type: String,
      default: 'Congrats!'
    },
    description: {
      type: String,
      default: 'Your account was successfully created.'
    },
    maxWidth: {
      type: [String, Number],
      default: 200
    },
    marginBottom: {
      type: [String, Number],
      default: 0
    },
  }
}
</script>

<style lang="scss">
.congrats {
  display: flex;
  flex-direction: column;
  align-items: center;

  &--icon {
    width: 160px;
    height: 160px;
    margin-bottom: 13px;
  }

  &--title {
    color: #151522;
    text-align: center;
    margin-bottom: 11px;
  }

  &--description {
    font-weight: 400;
    color: #617B97;
    max-width: 200px;
    text-align: center;
  }
}

@media screen and (min-width: 1024px) {
  .congrats {
    &--description {
      max-width: 100%;
      margin-bottom: 40px;
    }
  }
}
</style>
