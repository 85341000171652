export default [
  {
    label: 'Admin',
    value: '1'
  },
  {
    label: 'Editor',
    value: '2'
  },
  {
    label: 'Viewer',
    value: '3'
  }
]
