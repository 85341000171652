<template>
  <div
    class="user-role-list label"
    :class="[{'admin': getCurrentUser && (getCurrentUser?.role === 'admin' || getCurrentUser?.role === 'owner')},
            {'edit': isOpen},
            {'disabled': user.role === 'owner'}
    ]"
    :style="[{ padding: padding + 'px' },
            { marginBottom: marginBottom + 'px' }]"
    @click="handleToggleRoleList"
  >
    {{ user.role }}
    <inline-svg
      v-if="getCurrentUser && (getCurrentUser?.role === 'admin' || getCurrentUser?.role === 'owner')"
      :src="require(`@/assets/images/icons/arrow-down.svg`)"
      class="user-role-list--icon"
    />
    <ul
      v-if="isOpen"
      class="user-role-list--modal"
      :class="{'align-left': alignLeft}"
    >
      <li
        v-for="(role, index) in roleList"
        :key="index"
        class="user-role-list--modal--item"
        :class="user.role === role.label.toLowerCase() && 'h6-sb'"
        @click="handleChangeUserRole(user.id, role)"
      >
        {{ role.label }}
        <inline-svg
          v-if="user.role === role.label.toLowerCase()"
          :src="require(`@/assets/images/icons/check.svg`)"
          class="user-role-list--modal--item--icon"
        />
      </li>
    </ul>

    <custom-error
      v-if="showError"
      :errorMessage="errorMessage"
      :handleErrorClose="handleErrorClose"
      :errorStyles="errorStyles"
    />

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import InlineSvg from 'vue-inline-svg';
import CustomError from '@/components/shared/CustomError';

export default {

  components: {
    InlineSvg,
    CustomError
  },

  props: [
    'roleList',
    'user',
    'padding',
    'marginBottom',
    'alignLeft',
    'userToEdit',
    'isOneUser'
  ],

  data: () => ({
    isShowRoleList: false,
    showError: false,
    errorMessage: 'Something went wrong. Please reload the page',
    errorStyles: 'position: fixed;'
  }),

  computed: {
    ...mapGetters([
      'getCurrentUser'
    ]),

    isOpen() {
      return ((this.isShowRoleList && this.isOneUser)
        || (this.userToEdit && this.userToEdit.id === this.user.id));
    }
  },

  methods: {
    ...mapActions([
      'changeUserRole'
    ]),

    handleErrorClose() {
      this.showError = false;
    },

    handleToggleRoleList() {
      if (this.user.role !== 'owner') {
        this.isShowRoleList = !this.isShowRoleList;
        this.$emit('update:userToEdit', this.user);
      }
    },

    async handleChangeUserRole(userId, role) {
      const data = {
        id: Number(userId),
        role: Number(role.value)
      }

      try {
        await this.changeUserRole(data);
        this.handleAfterChange();
      } catch (error) {
        console.error('Edit user role', error);
        this.showError = true;
        this.handleAfterChange();
      }
    },

    handleAfterChange() {
      this.isSended = !this.isSended;
      this.userEmail = '';
      this.userRole = '';
    }
  }
}
</script>

<style lang="scss" scoped>
.user-role-list {
  color: #151522;
  text-transform: capitalize;
  box-sizing: border-box;
  max-width: 124px;
  width: 100%;

  &.admin {
    color: #10886D;
    position: relative;
    cursor: pointer;
  }

  &.edit {
    color: #046650;
  }

  &.disabled {
    color: #000;
    cursor: default;
  }

  &--icon:deep() {
    position: absolute;
    top: 50%;
    right: 11px;
    transform: translateY(-50%);

    .disabled & {
      display: none;
    }

    path {
      stroke: #10886D;

      .edit & {
        stroke: #046650;
      }
    }
  }

  &--modal {
    position: absolute;
    top: 55px;
    right: 12px;
    box-shadow: 0px 10px 32px rgba(61, 92, 109, 0.08);
    width: 176px;
    background: #fff;
    border-radius: 5px;
    z-index: 1;

    &.align-left {
      top: 37px;
      left: 0;
      right: unset;
    }

    &--item {
      color: #151522;
      font-weight: 300;
      padding: 15px 0 14px 20px;
      box-sizing: border-box;
      border-bottom: 1px solid rgba(92, 92, 102, 0.08);
      text-transform: capitalize;
      position: relative;
      height: 50px;
      font-size: 14px;

      &.h6-sb {
        font-weight: 600;
        pointer-events: none;
      }

      &:last-child {
        border-bottom: none;
      }

      &--icon {
        position: absolute;
        top: 50%;
        right: 15px;
        width: 20px;
        height: 20px;
        transform: translateY(-50%);

        path {
          stroke: #4B617A;
        }
      }
    }
  }
}
</style>
