<template>
  <div class="dashboard-inner-page single-step">
    <div
      v-if="currentPageSubPage === 'subscription'"
      class="dashboard-inner-page--sub-step-body open"
    >
      <div class="dashboard-inner-page--sub-step-body--desktop-header">
        <div class="dashboard-inner-page--sub-step-body--desktop-header--text">
          <p class="dashboard-inner-page--sub-step-body--desktop-header--text--title text-medium">{{getTitle}}</p>
          <p class="dashboard-inner-page--sub-step-body--desktop-header--text--description h6-light">{{getDescription}}</p>
        </div>
      </div>
      <div class="dashboard-inner-page--sub-step-body--desktop-body">
        <button
          class="dashboard-inner-page--sub-step-body--button normal-btn"
          @click="handleSubscriptionModal"
        >{{ buttonText }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: [
    'currentPageSubPage',
    'handleUpdateSubscription',
    'isSubscriptionActive'
  ],

  computed: {
    ...mapGetters([
      'getPaymentInfo',
    ]),

    getTitle() {
      return this.isSubscriptionActive ? 'Active' : 'Canceled';
    },

    getDescription() {
      return this.isSubscriptionActive
              ? `You can cancel your subscription at any time. To do so, please click the appropriate cancel button below. You will continue to have access to the content of your existing projects, but you will not be able to send invites to your customers and create projects from your dashboard.`
              : 'Subscription allows you to send invites to your customers and create projects from your dashboard';
    },

    buttonText() {
      return this.isSubscriptionActive ? 'Cancel subscription' : 'Activate';
    }
  },

  mounted() {
    this.$emit('update:currentPageSubPage', 'subscription');
  },

  methods: {
    handleSubscriptionModal() {
      this.$emit('update:subscriptionModal');
    },
  }
}
</script>