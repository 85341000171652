<template>
  <div
    class="loader"
    :style="positionCenter && `margin-top: ${marginTop}px;`"
  >
    <inline-svg
      class="loader--svg"
      :src="require('@/assets/images/icons/loader.svg')"
    />
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {

  components: {
    InlineSvg
  },

  props: [
    'totalPages',
    'currentPage',
    'positionCenter',
    'isMobile'
  ],

  data: () => ({
    loader: null,
    parent: '',
    marginTop: '',
  }),

  beforeMount() {
    this.loader = new IntersectionObserver(([entry]) => {
      if (entry && entry.isIntersecting && !this.isLoading) {
        if (this.totalPages > this.currentPage) {
          this.$emit('handleScrollLoad');
        }
      }
    });
  },

  mounted() {
    this.parent = document.querySelector('.loader');
    this.loader.observe(this.$el);
    this.getMarginTop();
  },

  unmounted() {
    this.loader.disconnect();
  },

  methods: {
    getMarginTop() {
      if (this.positionCenter && this.parent && this.parent.parentNode && window.innerHeight < 2000 ) {
        if (this.isMobile) {
          this.marginTop = (window.innerHeight - this.parent.parentNode.getBoundingClientRect().top) / 2 - 87;
        } else {
          this.marginTop = (window.innerHeight - this.parent.parentNode.getBoundingClientRect().top) / 2 - 36;
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.loader {
  display: flex;
  justify-content: center;
  padding-top: 10px;

  &--svg {
    width: 36px;
    height: 36px;
    animation: spin 1s linear infinite;
  }
}
</style>
