<template>
  <div v-if="activity.activity_feed">

    <div
      v-if="activity.activity_feed.activity_type === 'edit_project'"
      class="activity-item--text activity-item"
      :class="[{'highlight': highlight},
              {'clickable': notificationPage}]"
      @click="handleActivity(notificationPage, activity.company_project.id)"
    >
      <div class="activity-item--add-block--logo-wrapper activity-logo-block">
        <div
          class="activity-logo-block-image"
          :style="{'background-image': `url('${activity.company?.file_storages.length ? activity.company?.file_storages[0].file_url : require('@/assets/images/icons/logo-placeholder.svg')}')`}">
        </div>
      </div>
      <div class="activity-item--text--wrapper activity-common-wrapper">
        <p class="h6-regular add-margin-bottom">
          <span class="h6-sb">
            {{activity.company?.name}}
          </span>
          updated
          <span class="h6-sb">
            {{setUpdatedSectionsName(activity.activity_feed.updated_project_sections)}}
          </span>
          <span v-if="notificationPage"> in the
            <span class="h6-sb">"{{activity.company_project.project.name}}" </span>
            project
          </span>
          <span v-else>
            in this project
          </span>
        </p>
        <p class="p-super-tiny activity-time">{{setDate(activity.created_at)}}</p>
      </div>
    </div>

    <div
      v-if="activity.activity_feed.activity_type === 'add_files'"
      class="activity-item--text activity-item"
      :class="[{'highlight': highlight},
              {'clickable': notificationPage}]"
      @click="handleActivity(notificationPage, activity.company_project.id)"
    >
      <div class="activity-item--add-block--logo-wrapper activity-logo-block">
        <div
          class="activity-logo-block-image"
          :style="{'background-image': `url('${activity.company?.file_storages.length ? activity.company?.file_storages[0].file_url : require('@/assets/images/icons/logo-placeholder.svg')}')`}">
        </div>
      </div>
      <div class="activity-item--text--wrapper activity-common-wrapper">
        <p class="h6-regular add-margin-bottom">
          <span class="h6-sb">
            {{activity.company?.name}}
          </span>
          <span v-if="addedImagesMessage.length > 0">
            added {{addedImagesMessage.length > 1 ? 'images' : 'image'}}
            <span class="h6-sb">
              {{addedImagesMessage.join(',')}}
            </span>
          </span>
          <span v-if="addedFilesMessage.length > 0">
            {{addedImagesMessage.length > 0 ? ' and added' : ' added'}}
            {{addedFilesMessage.length > 1 ? 'files' : 'file'}}
            <span class="h6-sb">
              {{addedFilesMessage.join(',')}}
            </span>
          </span>
          <span v-if="notificationPage"> in the
            <span class="h6-sb">"{{activity.company_project.project.name}}" </span>
            project
          </span>
          <span v-else>
            in this project
          </span>
        </p>
        <p class="p-super-tiny activity-time">{{setDate(activity.created_at)}}</p>
      </div>
    </div>

    <div
      v-if="activity.activity_feed.activity_type === 'delete_files'"
      class="activity-item--text activity-item"
      :class="[{'highlight': highlight},
              {'clickable': notificationPage}]"
      @click="handleActivity(notificationPage, activity.company_project.id)"
    >
      <div class="activity-item--add-block--logo-wrapper activity-logo-block">
        <div
          class="activity-logo-block-image"
          :style="{'background-image': `url('${activity.company?.file_storages.length ? activity.company?.file_storages[0].file_url : require('@/assets/images/icons/logo-placeholder.svg')}')`}">
        </div>
      </div>
      <div class="activity-item--text--wrapper activity-common-wrapper">
        <p class="h6-regular add-margin-bottom">
          <span class="h6-sb">
            {{activity.company?.name}}
          </span>
          <span v-if="deletedImagesMessage.length > 0">
            deleted {{deletedImagesMessage.length > 1 ? 'images' : 'image'}}
            <span class="h6-sb">
              {{deletedImagesMessage.join(',')}}
            </span>
          </span>
          <span v-if="deletedFilesMessage.length > 0">
            {{deletedImagesMessage.length > 0 ? ' and deleted' : ' deleted'}}
            {{deletedFilesMessage.length > 1 ? 'files' : 'file'}}
            <span class="h6-sb">
              {{deletedFilesMessage.join(',')}}
            </span>
          </span>
          <span v-if="notificationPage"> in the
            <span class="h6-sb">"{{activity.company_project.project.name}}" </span>
            project
          </span>
          <span v-else>
            in this project
          </span>
        </p>
        <p class="p-super-tiny activity-time">{{setDate(activity.created_at)}}</p>
      </div>
    </div>

    <div
      v-if="activity.activity_feed.activity_type === 'add_files_delete_files'"
      class="activity-item--text activity-item"
      :class="[{'highlight': highlight},
              {'clickable': notificationPage}]"
      @click="handleActivity(notificationPage, activity.company_project.id)"
    >
      <div class="activity-item--add-block--logo-wrapper activity-logo-block">
        <div
          class="activity-logo-block-image"
          :style="{'background-image': `url('${activity.company?.file_storages.length ? activity.company?.file_storages[0].file_url : require('@/assets/images/icons/logo-placeholder.svg')}')`}">
        </div>
      </div>
      <div class="activity-item--text--wrapper activity-common-wrapper">
        <p class="h6-regular add-margin-bottom">
          <span class="h6-sb">
            {{activity.company?.name}}
          </span>
          <span v-if="addedImagesMessage.length > 0">
            added {{addedImagesMessage.length > 1 ? 'images' : 'image'}}
            <span class="h6-sb">
              {{addedImagesMessage.join(',')}}
            </span>
          </span>
          <span v-if="addedFilesMessage.length > 0">
            {{addedImagesMessage.length > 0 ? ' and added' : ' added'}}
            {{addedFilesMessage.length > 1 ? 'files' : 'file'}}
            <span class="h6-sb">
              {{addedFilesMessage.join(',')}}
            </span>
          </span>
          {{addedImagesMessage.length > 0 || addedFilesMessage.length > 0 ? 'and ' : ''}}
          <span v-if="deletedImagesMessage.length > 0">
            deleted {{deletedImagesMessage.length > 1 ? 'images' : 'image'}}
            <span class="h6-sb">
              {{deletedImagesMessage.join(',')}}
            </span>
          </span>
          <span v-if="deletedFilesMessage.length > 0">
            {{deletedImagesMessage.length > 0 ? ' and deleted' : ' deleted'}}
            {{deletedFilesMessage.length > 1 ? 'files' : 'file'}}
            <span class="h6-sb">
              {{deletedFilesMessage.join(',')}}
            </span>
          </span>
          <span v-if="notificationPage"> in the
            <span class="h6-sb">"{{activity.company_project.project.name}}" </span>
            project
          </span>
          <span v-else>
            in this project
          </span>
        </p>
        <p class="p-super-tiny activity-time">{{setDate(activity.created_at)}}</p>
      </div>
    </div>

    <div
      v-if="activity.activity_feed.activity_type === 'archivated' ||
            activity.activity_feed.activity_type === 'unarchivated'"
      class="activity-item--text activity-item"
      :class="[{'highlight': highlight},
              {'clickable': notificationPage}]"
      @click="handleActivity(notificationPage, activity.company_project.id)"
    >
      <div class="activity-item--add-block--logo-wrapper activity-logo-block">
        <div
          class="activity-logo-block-image"
          :style="{'background-image': `url('${activity.company?.file_storages.length ? activity.company?.file_storages[0].file_url : require('@/assets/images/icons/logo-placeholder.svg')}')`}">
        </div>
      </div>
      <div class="activity-item--text--wrapper activity-common-wrapper">
        <p class="h6-regular add-margin-bottom">
          <span class="h6-sb">
            {{activity.company?.name}}
          </span>
          {{activity.activity_feed.archivation}}
          <span v-if="notificationPage"> the
            <span class="h6-sb">"{{activity.company_project.project.name}}" </span>
            project
          </span>
          <span v-else>
            this project
          </span>
        </p>
        <p class="p-super-tiny activity-time">{{setDate(activity.created_at)}}</p>
      </div>
    </div>

    <div
      v-if="activity.activity_feed.activity_type === 'progress_bar'"
      class="activity-item--text activity-item"
      :class="[{'highlight': highlight},
              {'clickable': notificationPage}]"
      @click="handleActivity(notificationPage, activity.company_project.id)"
    >
      <div class="activity-item--add-block--logo-wrapper activity-logo-block">
        <div
          class="activity-logo-block-image"
          :style="{'background-image': `url('${activity.company?.file_storages.length ? activity.company?.file_storages[0].file_url : require('@/assets/images/icons/logo-placeholder.svg')}')`}">
        </div>
      </div>
      <div class="activity-item--text--wrapper activity-common-wrapper">
        <p class="h6-regular add-margin-bottom">
          <span class="h6-sb">
            {{activity.company?.name}}
          </span>
          updated
          <span class="h6-sb">
            likelihood of getting the job
          </span> to
          <span class="h6-sb">
            {{activity.activity_feed.progress_bar}}%
          </span>
          <span v-if="notificationPage"> in the
            <span class="h6-sb">"{{activity.company_project.project.name}}" </span>
            project
          </span>
        </p>
        <p class="p-super-tiny activity-time">{{setDate(activity.created_at)}}</p>
      </div>
    </div>

    <div
      v-if="activity.activity_feed.activity_type === 'edit_project_info'"
      class="activity-item--text activity-item"
      :class="[{'highlight': highlight},
              {'clickable': notificationPage}]"
      @click="handleActivity(notificationPage, activity.company_project.id)"
    >
      <div class="activity-item--add-block--logo-wrapper activity-logo-block">
        <div
          class="activity-logo-block-image"
          :style="{'background-image': `url('${activity.company?.file_storages.length ? activity.company?.file_storages[0].file_url : require('@/assets/images/icons/logo-placeholder.svg')}')`}">
        </div>
      </div>
      <div class="activity-item--text--wrapper activity-common-wrapper">
        <p class="h6-regular add-margin-bottom">
          <span class="h6-sb">
            {{activity.company?.name}}
          </span>
          updated
          <span class="h6-sb">
            project details
          </span>
          <span v-if="notificationPage"> in the
            <span class="h6-sb">"{{activity.company_project.project.name}}" </span>
            project
          </span>
        </p>
        <p class="p-super-tiny activity-time">{{setDate(activity.created_at)}}</p>
      </div>
    </div>

    <div
      v-if="activity.activity_feed.activity_type === 'checklist_item'"
      class="activity-item--text activity-item"
      :class="[{'highlight': highlight},
              {'clickable': notificationPage}]"
      @click="handleActivity(notificationPage, activity.company_project.id)"
    >
      <div class="activity-item--add-block--logo-wrapper activity-logo-block">
        <div
          class="activity-logo-block-image"
          :style="{'background-image': `url('${activity.company?.file_storages.length ? activity.company?.file_storages[0].file_url : require('@/assets/images/icons/logo-placeholder.svg')}')`}">
        </div>
      </div>
      <div class="activity-item--text--wrapper activity-common-wrapper">
        <p class="h6-regular add-margin-bottom">
          <span class="h6-sb">
            {{activity.company?.name}}
          </span>
          added a note “{{activity.activity_feed.checklist_item_name}}” to
          <span class="h6-sb">
            likelihood of getting the job
          </span>
          <span v-if="notificationPage"> in the
            <span class="h6-sb">"{{activity.company_project.project.name}}" </span>
            project
          </span>
        </p>
        <p class="p-super-tiny activity-time">{{setDate(activity.created_at)}}</p>
      </div>
    </div>

    <div
      v-if="activity.activity_feed.activity_type === 'checklist_item_update_name'"
      class="activity-item--text activity-item"
      :class="[{'highlight': highlight},
              {'clickable': notificationPage}]"
      @click="handleActivity(notificationPage, activity.company_project.id)"
    >
      <div class="activity-item--add-block--logo-wrapper activity-logo-block">
        <div
          class="activity-logo-block-image"
          :style="{'background-image': `url('${activity.company?.file_storages.length ? activity.company?.file_storages[0].file_url : require('@/assets/images/icons/logo-placeholder.svg')}')`}">
        </div>
      </div>
      <div class="activity-item--text--wrapper activity-common-wrapper">
        <p class="h6-regular add-margin-bottom">
          <span class="h6-sb">
            {{activity.company?.name}}
          </span>
          updated a note in
          <span class="h6-sb">
            likelihood of getting the job
          </span>
          to “{{activity.activity_feed.checklist_item_name}}”
          <span v-if="notificationPage"> in the
            <span class="h6-sb">"{{activity.company_project.project.name}}" </span>
            project
          </span>
        </p>
        <p class="p-super-tiny activity-time">{{setDate(activity.created_at)}}</p>
      </div>
    </div>

    <div
      v-if="activity.activity_feed.activity_type === 'checklist_item_delete_name'"
      class="activity-item--text activity-item"
      :class="[{'highlight': highlight},
              {'clickable': notificationPage}]"
      @click="handleActivity(notificationPage, activity.company_project.id)"
    >
      <div class="activity-item--add-block--logo-wrapper activity-logo-block">
        <div
          class="activity-logo-block-image"
          :style="{'background-image': `url('${activity.company?.file_storages.length ? activity.company?.file_storages[0].file_url : require('@/assets/images/icons/logo-placeholder.svg')}')`}">
        </div>
      </div>
      <div class="activity-item--text--wrapper activity-common-wrapper">
        <p class="h6-regular add-margin-bottom">
          <span class="h6-sb">
            {{activity.company?.name}}
          </span>
          deleted a note “{{activity.activity_feed.checklist_item_name}}” from
          <span class="h6-sb">
            likelihood of getting the job
          </span>
           <span v-if="notificationPage"> in the
            <span class="h6-sb">"{{activity.company_project.project.name}}" </span>
            project
          </span>
        </p>
        <p class="p-super-tiny activity-time">{{setDate(activity.created_at)}}</p>
      </div>
    </div>

    <div
      v-if="activity.activity_feed.activity_type === 'status' || activity.activity_feed.activity_type === 'message_status'"
      class="activity-item--text activity-item"
      :class="[{'highlight': highlight},
              {'clickable': notificationPage}]"
      @click="handleActivity(notificationPage, activity.company_project.id)"
    >
      <div class="activity-item--add-block--logo-wrapper activity-logo-block">
        <div
          class="activity-logo-block-image"
          :style="{'background-image': `url('${activity.company?.file_storages.length ? activity.company?.file_storages[0].file_url : require('@/assets/images/icons/logo-placeholder.svg')}')`}">
        </div>
      </div>
      <div class="activity-item--text--wrapper activity-common-wrapper">
        <p class="h6-regular add-margin-bottom">
          <span class="h6-sb">
            {{activity.company?.name}}
          </span>
          changed the status of
          <span
            v-if="notificationPage"
            class="h6-sb"
          >
            "{{activity.company_project.project.name}}"
          </span>
          <span v-else>this</span>
           project to
          <span class="h6-sb">
            “{{ activity.activity_feed.project_status_name }}”
          </span>
          <span
            v-if="notificationPage
                  && activity.activity_feed.activity_type === 'message_status'
                  && activity.activity_feed.messages.length"
          >
            and left a comment
          </span>
        </p>
        <div
          v-if="activity.activity_feed.activity_type === 'message_status'
                && activity.activity_feed.messages.length
                && !notificationPage"
          class="activity-common-body add-margin-bottom"
        >
          <p class="input">{{activity.activity_feed.messages[0].message}}</p>
        </div>
        <p class="p-super-tiny activity-time">{{setDate(activity.created_at)}}</p>
      </div>
    </div>

    <div v-if="notificationPage">
      <div
        v-if="activity.activity_feed.activity_type === 'message'"
        class="activity-item--text activity-item"
        :class="[{'highlight': highlight},
                {'clickable': notificationPage}]"
        @click="handleActivity(notificationPage, activity.company_project.id)"
      >
        <div class="activity-item--add-block--logo-wrapper activity-logo-block">
          <div
            class="activity-logo-block-image"
            :style="{'background-image': `url('${activity.company?.file_storages.length ? activity.company?.file_storages[0].file_url : require('@/assets/images/icons/logo-placeholder.svg')}')`}">
          </div>
        </div>
        <div class="activity-item--text--wrapper activity-common-wrapper">
          <p class="h6-regular add-margin-bottom">
            <span class="h6-sb">
              {{activity.company?.name}}
            </span> left a comment in the
            <span class="h6-sb">"{{activity.company_project.project.name}}" </span>project
          </p>
          <p class="p-super-tiny activity-time">{{setDate(activity.created_at)}}</p>
        </div>
      </div>

      <div
        v-if="activity.activity_feed.activity_type === 'document_message'
              || activity.activity_feed.activity_type === 'image_message'
              || activity.activity_feed.activity_type === 'image'
              || activity.activity_feed.activity_type === 'document'
              || activity.activity_feed.activity_type === 'image_document_message'
              || activity.activity_feed.activity_type === 'image_document'"
        class="activity-item--text activity-item"
        :class="[{'highlight': highlight},
                {'clickable': notificationPage}]"
        @click="handleActivity(notificationPage, activity.company_project.id)"
      >
        <div class="activity-item--add-block--logo-wrapper activity-logo-block">
          <div
            class="activity-logo-block-image"
            :style="{'background-image': `url('${activity.company?.file_storages.length ? activity.company?.file_storages[0].file_url : require('@/assets/images/icons/logo-placeholder.svg')}')`}">
          </div>
        </div>
        <div class="activity-item--file-comment--wrapper activity-common-wrapper">
          <div class="activity-item--file-comment--wrapper--header activity-common-header">
            <p class="h6-sb activity-common-title">{{activity.company?.name}}</p>
              <span class="h6-regular">attached </span>
              <p
                class="activity-item--file-list h6-regular"
                :class="[{'many': activity.activity_feed.file_storages.length > 1},
                        {'comment': activity.activity_feed.activity_type === 'document_message'
                        || activity.activity_feed.activity_type === 'image_message'
                        || activity.activity_feed.activity_type === 'image_document_message'}]"
              >
                <span
                  v-for="file in activity.activity_feed.file_storages"
                  :key="file.name"
                  class="activity-item--file-list--file-wrapper h6-regular"
                >
                  <span
                    class="activity-item--file-list--file-wrapper--item"

                  >{{file.file_url.split('/').pop()}}</span>
                </span>
              </p>
              <span
                v-if="activity.activity_feed.activity_type === 'image'
                          || activity.activity_feed.activity_type === 'document'
                          || activity.activity_feed.activity_type === 'image_document'"
                class="h6-regular"
              >
                to the <span class="h6-sb">"{{activity.company_project.project.name}}" </span>project
              </span>
              <span
                v-else
                class="h6-regular"
              >
                left a comment in the <span class="h6-sb">"{{activity.company_project.project.name}}" </span>project
              </span>
          </div>
          <p class="p-super-tiny activity-time">{{setDate(activity.created_at)}}</p>
        </div>
      </div>
    </div>

    <div v-else>
      <div
        v-if="activity.activity_feed.activity_type === 'message'
              || activity.activity_feed.activity_type === 'document_message'
              || activity.activity_feed.activity_type === 'image_message'
              || activity.activity_feed.activity_type === 'image'
              || activity.activity_feed.activity_type === 'document'"
        class="activity-item--text activity-item"
        :class="highlight && 'highlight'"
      >
        <div class="activity-item--add-block--logo-wrapper activity-logo-block">
          <div
            class="activity-logo-block-image"
            :style="{'background-image': `url('${activity.company?.file_storages.length ? activity.company?.file_storages[0].file_url : require('@/assets/images/icons/logo-placeholder.svg')}')`}">
          </div>
        </div>
        <div class="activity-item--file-comment--wrapper activity-common-wrapper">
          <div class="activity-item--file-comment--wrapper--header activity-common-header">
            <p class="h6-sb activity-common-title">{{activity.company?.name}}</p>
            <p class="p-super-tiny activity-time">{{setDate(activity.created_at)}}</p>
          </div>
          <div
            v-if="activity.activity_feed"
            class="activity-common-body"
          >
            <div
              v-if="activity.activity_feed.activity_type === 'image_message'
                    || activity.activity_feed.activity_type === 'image'"
              class="activity-common-body--gallery"
              :class="activity.activity_feed.activity_type !== 'image' && 'border-bottom'"
            >
              <a
                v-for="photo in activity.activity_feed.file_storages"
                :key="photo.name"
                class="activity-common-body--gallery--item"
                :style="{'background-image': `url('${photo.file_url}')`}"
                :href="photo.file_url"
                target="blank"
              >
              </a>
            </div>
            <div
              v-if="activity.activity_feed.activity_type === 'document_message'
                    || activity.activity_feed.activity_type === 'document'"
              class="activity-files-wrapper"
            >
              <a
                v-for="file in activity.activity_feed.file_storages"
                :key="file.name"
                class="activity-files-wrapper--link"
                :href="file.file_url"
                target="blank"
              >
                <div class="activity-files-wrapper--item">
                  <inline-svg
                    class="activity-files-wrapper--item--icon"
                    :src="setFileIcons(file)"
                  />
                  <div class="activity-files-wrapper--item--file-info">
                    <p class="activity-files-wrapper--item--file-info--name">
                      {{ file.file_name.length > 30
                        ? handleFileNameTrim(file.file_name)
                        : file.file_name }}
                    </p>
                    <p class="activity-files-wrapper--item--file-info--size">
                      {{ file.file_size > 1000000
                        ? ((file.file_size/1000000).toFixed(1) + 'MB')
                        : ((file.file_size/1000).toFixed(1) + 'KB') }}
                    </p>
                  </div>
                </div>
              </a>
            </div>
            <p
              v-if="activity.activity_feed.activity_type === 'message'
                    || activity.activity_feed.activity_type === 'document_message'
                    || activity.activity_feed.activity_type === 'image_message'"
              class="input">{{activity.activity_feed.messages[0].message}}</p>
          </div>
        </div>
      </div>

      <div
        v-if="activity.activity_feed.activity_type === 'image_document'
              || activity.activity_feed.activity_type === 'image_document_message'"
        class="activity-item--file-comment activity-item"
        :class="highlight && 'highlight'"
      >
        <div class="activity-item--add-block--logo-wrapper activity-logo-block">
          <div
            class="activity-logo-block-image"
            :style="{'background-image': `url('${activity.company?.file_storages.length ? activity.company?.file_storages[0].file_url : require('@/assets/images/icons/logo-placeholder.svg')}')`}">
          </div>
        </div>
        <div class="activity-item--file-comment--wrapper activity-common-wrapper">
          <div class="activity-item--file-comment--wrapper--header activity-common-header">
            <p class="h6-sb activity-common-title">{{activity.company?.name}}</p>
            <p class="p-super-tiny activity-time">{{setDate(activity.created_at)}}</p>
          </div>
          <div class="activity-common-body">
            <div class="activity-common-body--gallery border-bottom-with-files">
              <a
                v-for="photo in handleFilesPreview(activity, 'photos')"
                :key="photo.name"
                class="activity-common-body--gallery--item"
                :style="{'background-image': `url('${photo.file_url}')`}"
                :href="photo.file_url"
                target="blank"
              >
              </a>
            </div>
            <div class="activity-files-wrapper">
              <a
                v-for="file in handleFilesPreview(activity, 'files')"
                :key="file.name"
                class="activity-files-wrapper--link"
                :href="file.file_url"
                target="blank"
              >
                <div class="activity-files-wrapper--item">
                  <inline-svg
                    class="activity-files-wrapper--item--icon"
                    :src="require(`@/assets/images/icons/files/${file.file_name.split('.').pop()}.svg`)"
                  />
                  <div class="activity-files-wrapper--item--file-info">
                    <p class="activity-files-wrapper--item--file-info--name">
                      {{ file.file_name.length > 25
                        ? handleFileNameTrim(file.file_name)
                        : file.file_name }}
                    </p>
                    <p class="activity-files-wrapper--item--file-info--size">
                      {{ file.file_size > 1000000
                        ? ((file.file_size/1000000).toFixed(1) + 'MB')
                        : ((file.file_size/1000).toFixed(1) + 'KB') }}
                    </p>
                  </div>
                </div>
              </a>
            </div>
            <p
              v-if="activity.activity_feed.activity_type === 'image_document_message'
                    && activity.activity_feed.messages.length"
              class="input"
            >
              {{activity.activity_feed.messages[0].message}}
            </p>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import moment from 'moment';

import InlineSvg from 'vue-inline-svg';

export default {

  props:[
    'activity',
    'isMobile',
    'highlight',
    'notificationPage'
  ],

  components: {
    InlineSvg
  },

  data: () => ({
    addedImagesMessage: '',
    addedFilesMessage: '',
    deletedImagesMessage: '',
    deletedFilesMessage: ''
  }),

  watch: {
    activity() {
      this.addedImagesMessage = '';
      this.addedFilesMessage = '';
      this.deletedImagesMessage = '';
      this.deletedFilesMessage = '';
      if (this.activity.activity_feed.activity_type === 'add_files' ||
          this.activity.activity_feed.activity_type === 'delete_files' ||
          this.activity.activity_feed.activity_type === 'add_files_delete_files') {
        this.addedImagesMessage = this.getImagesToDisplay(this.activity.activity_feed.added_files);
        this.addedFilesMessage = this.getFilesToDisplay(this.activity.activity_feed.added_files);
        this.deletedImagesMessage = this.getImagesToDisplay(this.activity.activity_feed.deleted_files);
        this.deletedFilesMessage = this.getFilesToDisplay(this.activity.activity_feed.deleted_files);
      }
    }
  },

  created () {
    if (this.activity.activity_feed.activity_type === 'add_files' ||
        this.activity.activity_feed.activity_type === 'delete_files' ||
        this.activity.activity_feed.activity_type === 'add_files_delete_files') {
      this.addedImagesMessage = this.getImagesToDisplay(this.activity.activity_feed.added_files);
      this.addedFilesMessage = this.getFilesToDisplay(this.activity.activity_feed.added_files);
      this.deletedImagesMessage = this.getImagesToDisplay(this.activity.activity_feed.deleted_files);
      this.deletedFilesMessage = this.getFilesToDisplay(this.activity.activity_feed.deleted_files);
    }
  },

  methods: {
    handleActivity(isNotificationPage, id) {
      if (isNotificationPage) {
        this.$router.push({ name: 'ProjectPage', params: { id }})
      }
    },

    handleFilesPreview(activity, type) {
      let photos = [];
      let files = [];
      activity.activity_feed.file_storages.filter(item => {
        if (item.file_type.includes('image')) {
          photos.push(item);
        } else {
          files.push(item);
        }
      })
      if (type === 'photos') {
        return photos;
      } else {
        return files;
      }
    },

    handleFileNameTrim(longName) {
      if (this.isMobile) {
        let result = longName.substring(0, 14) + '...' + longName.slice(-7);
        return result;
      }
      return longName;
    },

    setUpdatedSectionsName(updatedSections) {
      return updatedSections.replace(/_/g, ' ').replace('existing', 'project type');
    },

    setDate(date) {
      if (moment(date).isSame(new Date(), "day")) {
        return moment(date).startOf('minute').fromNow();
      } else if (moment(date).isSame(moment().subtract(1, "days"), "day")) {
        return moment(date).format('[Yesterday at] h:mm A');
      } else {
        return moment(date).format('D MMM [at] h:mm A');
      }
    },

    getImagesToDisplay(files) {

      if (!files) return ''

      const arrayAllFiles = files.split(',');
      return arrayAllFiles.reduce((acc, curr) => {
        if (curr.includes('.png') || curr.includes('.jpg') || curr.includes('.jpeg')) {
          return [...acc, curr]
        }

        return acc

      }, [])
    },

    getFilesToDisplay(files) {
      if (!files) return ''

      const arrayAllFiles = files.split(',');
      return arrayAllFiles.reduce((acc, curr) => {
        if (!(curr.includes('.png') || curr.includes('.jpg') || curr.includes('.jpeg'))) {
          return [...acc, curr]
        }
        return acc

      }, [])
    },

    setFileIcons(file) {
      if (file.file_name.split('.').pop() === 'csv' ||
          file.file_name.split('.').pop() === 'doc' ||
          file.file_name.split('.').pop() === 'docx' ||
          file.file_name.split('.').pop() === 'dwg' ||
          file.file_name.split('.').pop() === 'dxf' ||
          file.file_name.split('.').pop() === 'ods' ||
          file.file_name.split('.').pop() === 'odt' ||
          file.file_name.split('.').pop() === 'ots' ||
          file.file_name.split('.').pop() === 'pdf' ||
          file.file_name.split('.').pop() === 'rtf' ||
          file.file_name.split('.').pop() === 'txt' ||
          file.file_name.split('.').pop() === 'xls' ||
          file.file_name.split('.').pop() === 'xlsx' ||
          file.file_name.split('.').pop() === 'xltx') {

        return require(`@/assets/images/icons/files/${file.file_name.split('.').pop()}.svg`)
      } else {
        return "undefined"
      }
    }

  }

}
</script>

<style lang="scss">
.activity-item {
  margin-bottom: 20px;
  display: flex;

  &.clickable {
    cursor: pointer;
  }

  &--file-list {
    margin-right: 5px;

    &.comment {
      &:after {
        content: ",";
      }
    }

    &--file-wrapper {
      .many & {
        &:after {
          content: ",";
          text-decoration: none;
        }
      }

      &:last-child:after {
        content: '';
      }

      &--item {
        margin-left: 5px;
        text-decoration: underline;
      }
    }
  }

  .notifications & {
    margin-bottom: 0;
    padding: 20px 25px;
    position: relative;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      height: 1px;
      background: #F0F3FF;
      width: 100%;
      bottom: 0;
      left: 0;
    }

    &.highlight {
      background: rgba(122, 149, 180, 0.08);

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 11px;
        border-radius: 50%;
        transform: translateY(-50%);
        width: 10px;
        height: 10px;
        background: #4B617A;
      }
    }
  }

  &--add-block {
    align-items: flex-start;

    &--logo-wrapper {
      flex: none;
    }
  }

  &--text {
    &--wrapper {
      p {
        color: #151522;
        opacity: 0.9;
      }
    }
  }

  .activity-item {
    display: flex;
    margin-bottom: 20px;
  }

  .activity-logo-block {
    background: #FFFFFF;
    box-shadow: 0px 6px 20px rgba(75, 97, 122, 0.15);
    border-radius: 50%;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    flex: none;
  }

  .activity-logo-block-image {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .activity-time {
    font-family: $SFProText;
    color: #617B97;
  }

  .activity-common-wrapper {
    width: 100%;
  }

  .activity-common-header {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    flex-wrap: wrap;

    .notifications & {
      margin-bottom: 2px;
    }
  }

  .activity-common-title {
    margin-right: 12px;

    .notifications & {
      margin-right: 5px;
    }
  }

  .activity-common-body {
    padding: 10px 20px;
    border: 1px solid #CCCCD7;
    box-sizing: border-box;
    border-radius: 5px;
    width: 100%;

    &--gallery {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      box-sizing: border-box;

      &.border-bottom {
        margin-bottom: 16px;
        border-bottom: 1px solid rgba(92, 92, 102, 0.16);
      }

      &.border-bottom-with-files {
        margin-bottom: 10px;
        border-bottom: 1px solid rgba(92, 92, 102, 0.16);
      }

      &--item {
        position: relative;
        width: 121px;
        height: 121px;
        margin-bottom: 16px;
        background-size: cover;
        background-position: center;
        outline: none;
      }
    }
  }

  .add-margin-bottom {
    margin-bottom: 2px;
  }

  .activity-files-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;

    &--link {
      text-decoration: none;
    }

    &--item {
      display: flex;
      align-items: center;
      width: 100%;
      line-height: 150%;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 13px;
      }

      &--icon {
        width: 40px;
        height: 40px;
        margin-right: 8px;
      }

      &--file-info {
        color: #151522;
        font-family: $SFProText;
        padding-top: 5px;

        &--name {
          font-weight: 400;
          font-size: 12px;
          line-height: 150%;
          white-space: nowrap;
          max-width: 150px;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &--size {
          margin: 0;
          font-weight: 300;
          font-size: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .activity-item {
    .activity-common-body {
      &--gallery--item {
        width: 90px;
        height: 90px;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .activity-item {
    .notifications & {
      padding: 20px 60px;

      &:after {
        width: calc(100% - 120px);
        left: 60px;
      }

      &.highlight {
        &:before {
          left: 40px;
        }
      }
    }

    .activity-common-body {
      &--gallery--item {
        width: 140px;
        height: 140px;
      }

      &--gallery {
        justify-content: flex-start;

        &--item {
          margin-right: 10px;
        }
      }
    }

    .activity-files-wrapper {
      &--item {
        padding-bottom: 12px;
        border-bottom: 1px solid rgba(92, 92, 102, 0.16);

        &--file-info--name {
          max-width: 100%;
        }
      }
    }
  }
}
</style>
