<template>
  <div :id="`project-item-${project.id}`" class="project-item">
    <inline-svg
      v-if="getUserRole === 'contractor' && getCurrentUser &&
            (getCurrentUser?.role === 'admin' || getCurrentUser?.role === 'owner')"
      :src="require('@/assets/images/icons/menu.svg')"
      class="project-item--menu-button"
      :class="showProjectMenu && 'active'"
      @click="handleProjectMenu"
    />
    <div
      v-if="showProjectMenu"
      class="project-item--menu"
    >
      <inline-svg
        :src="require('@/assets/images/icons/plus-rotated.svg')"
        class="project-item--menu--close"
        @click="handleProjectMenu"
      />
     <p class="project-item--menu--title btn-font-large">Project actions</p>
     <p
      class="project-item--menu--item btn-font-large"
      @click="handleArchiveProject(project.id)"
      >{{ archiveText }}</p>
    </div>
    <div class="project-item--top-block">
      <div
        class="project-item--top-block--image"
        :class="!isProjectImage && 'empty-image'"
        :style="{'background-image': `url('${setProjectImage()}')`}"
      ></div>
      <div class="project-item--top-block--description">
        <div>
          <p
            class="project-item--top-block--description--project-name h5-sb"
            :style="getUserRole === 'contractor' && 'padding-right: 38px;'"
          >{{ project.project.name }}</p>
        </div>
        <div>
          <p
            v-if="getUserRole === 'contractor' && project.companies_names.customer ||
                  getUserRole === 'customer' && project.companies_names.contractor"
            class="project-item--top-block--description--contractor p-regular"
          >
            <inline-svg
              :src="require('@/assets/images/icons/suitcase.svg')"
              class="project-item--top-block--description--contractor--icon"
            />
            {{ getUserRole === 'contractor'
              ? project.companies_names.customer
              : project.companies_names.contractor }}
          </p>
          <p class="project-item--top-block--description--address p-regular">
            <inline-svg
              :src="require('@/assets/images/icons/location.svg')"
              class="project-item--top-block--description--contractor--icon"
            />
            {{ project.project.location }}
          </p>
          <p class="project-item--top-block--description--customer p-regular">
            <inline-svg
              :src="require('@/assets/images/icons/person.svg')"
              class="project-item--top-block--description--contractor--icon"
            />
            {{ project.project.contact_name }}
          </p>
        </div>
      </div>
    </div>
    <div class="project-item--bottom-block">
      <p class="project-item--bottom-block--id h6-sb">
        {{ project.project_uid ? `${project.project_uid}` : '' }}
      </p>
      <div class="project-item--bottom-block--status-wrapper">
        <div class="project-item--bottom-block--status-wrapper--status" @mouseover="setTooltipLeft" @mouseleave="showTooltip=false">
          <inline-svg
            :src="require('@/assets/images/icons/tag.svg')"
            class="project-item--bottom-block--status-wrapper--status--icon"
          />
          <span class="h6-sb project-item--bottom-block--status-wrapper--status--text">{{ setProjectStatus(this.project) }}</span>
        </div>
        <inline-svg
          :src="require('@/assets/images/icons/tooltip-arrow.svg')"
          class="project-item--bottom-block--status-wrapper--tooltip-icon"
          :class="showTooltip && 'visible'"
        />
        <div
          :id="`tooltip-${project.id}`"
          class="project-item--bottom-block--status-wrapper--tooltip-wrapper"
          :class="showTooltip && 'visible'"
        >
          <p class="project-item--bottom-block--status-wrapper--tooltip-wrapper--text h6-sb">{{ setProjectStatus(this.project) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import InlineSvg from 'vue-inline-svg';

export default {
  components: {
    InlineSvg
  },

  emits: ['update:showArchiveModal'],

  props: [
    'project',
    'setProjectStatus',
    'getUserRole',
    'isArchiveProject'
  ],

  data: () => ({
    isProjectImage: false,
    showTooltip: false,
    showProjectMenu: false
  }),

  computed: {
    ...mapGetters([
      'getCurrentUser',
    ]),

    archiveText() {
      return this.isArchiveProject === true ? 'Unarchive this project' : 'Archive this project';
    }
  },

  methods: {
    setProjectImage() {
      if (this.project.file_storages) {
        const imageProject = this.project.file_storages.find(o => o.file_type.includes("image"));
        if (imageProject) {
          const imageProjectUrl = imageProject.file_url;
          this.isProjectImage = true;
          return imageProjectUrl;
        } else {
          return require('@/assets/images/icons/project-image-placeholder.svg');
        }
      } else {
        return require('@/assets/images/icons/project-image-placeholder.svg');
      }
    },

    setTooltipLeft() {
      let target = document.querySelector(`#project-item-${this.project.id}`);
      let coords = target.getBoundingClientRect();
      let tooltipElem = document.querySelector(`#tooltip-${this.project.id}`);
      if ((window.innerWidth - coords.right) <= 60) {
        if (tooltipElem.offsetWidth >= target.offsetWidth / 1.5) {
          tooltipElem.setAttribute("style", "right: -22px;");
        } else {
          tooltipElem.setAttribute("style", "left:50%; transform: translateX(-50%);");
        }
      } else {
        tooltipElem.setAttribute("style", "left:50%; transform: translateX(-50%);");
      }
      this.showTooltip = true;
    },

    handleProjectMenu() {
      this.showProjectMenu = !this.showProjectMenu;
    },

    handleArchiveProject(projectId) {
      this.handleProjectMenu();
      this.$emit('update:showArchiveModal', projectId);
    }
  }
}
</script>

<style lang="scss">
.project-item {
  margin: 0 25px 10px;
  border-radius: 10px;
  background: #fff;
  padding-top: 22px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
  box-shadow: 0px 2px 16px rgba(21, 21, 34, 0.16);

  &--menu {
    position: absolute;
    top: 48px;
    right: 0;
    width: 270px;
    padding: 6px 0;
    box-sizing: border-box;
    background: #fff;
    box-shadow: 0px 2px 10px rgba(21, 21, 34, 0.2);
    border-radius: 6px;
    z-index: 1;
    cursor: default;

    &--title {
      color: #666670;
      padding: 10px 14px 14px;
      border-bottom: 1px solid #F0F3FF;
      margin-bottom: 7px;
    }

    &--item {
      color: #151522;
      padding: 10px 14px;
      cursor: pointer;
      transition: .3s;

      &:hover {
        background: #F8FBFE;
      }
    }

    &--close {
      width: 20px;
      height: 20px;
      padding: 5px;
      box-sizing: border-box;
      position: absolute;
      top: 18px;
      right: 14px;
      cursor: pointer;
    }
  }

  &--menu-button {
    width: 28px;
    height: 28px;
    position: absolute;
    top: 22px;
    right: 22px;
    border-radius: 4px;

    &.active,
    &:hover,
    &:active,
    &:focus {
      background: #F8FBFE;
    }
  }

  &--top-block {
    padding: 0 22px 18px;
    display: flex;

    &--image {
      margin-right: 14px;
      border-radius: 4px;
      width: 106px;
      height: 136px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      box-sizing: border-box;

      &.empty-image {
        border: 1px solid #9999A0;
        background: #E6E6EB;
        background-size: auto;
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    &--description {
      max-width: 147px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &--project-name,
      &--contractor,
      &--address,
      &--customer {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        color: #151522;

        &--icon {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
      }

      &--contractor,
      &--address,
      &--customer {
        position: relative;
        padding-left: 24px;
      }

      &--project-name {
        color: #10886D;
        margin-bottom: 11px;
        height: 54px;
        white-space: break-spaces;

      }

      &--contractor,
      &--address {
        margin-bottom: 8px;
      }
    }
  }

  &--bottom-block {
    border-top: 1px solid rgba(92, 92, 102, 0.32);
    padding: 14px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--id {
      color: #151522;
      overflow-wrap: break-word;
      max-width: 110px;
    }

    &--status-wrapper {
      position: relative;

      &--status {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #666670;
        border: 1px solid #B3B3BE;
        border-radius: 40px;
        box-sizing: border-box;
        flex: none;
        width: 182px;
        height: 38px;

        &--icon {
          margin-right: 8px;
          margin-left: 20px;
          flex: none;
          pointer-events: none;
        }

        &--text {
          white-space: nowrap;
          width: auto;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-right: 20px;
          pointer-events: none;
        }
      }

      &--tooltip-icon {
        position: absolute;
        display: none;
        top: 46px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
        opacity: 0;
        transition: opacity .2s ease;

        &.visible {
          opacity: 1;
        }
      }

      &--tooltip-wrapper {
        position: absolute;
        display: none;
        padding: 8px 16px;
        background: #fff;
        border-radius: 6px;
        box-shadow: 0px 2px 10px rgba(21, 21, 34, 0.2);
        width: max-content;
        top: 57px;
        z-index: 1;
        opacity: 0;
        transition: opacity .2s ease;
        max-width: 315px;

        &.visible {
          opacity: 1;
        }

        &--text {
          color: #151522;
          text-align: center;
        }
      }
    }
  }
}

@media screen and (max-width: 374px) {
  .project-item {
    &--top-block {
      &--image {
        width: 86px;
        flex: none;
      }

      &--description {
        max-width: 126px;
      }
    }

    &--bottom-block {
      &--id {
        max-width: 55px;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .project-item {
    width: 293px;
    margin: 0 11px 22px;

    &--top-block {
      &--image {
        flex: none;
        width: 88px;
      }
    }

    &--bottom-block {
      padding: 14px 22px;

      &--id {
        max-width: 67px;
      }

      &--status-wrapper {
        &--tooltip-icon,
        &--tooltip-wrapper {
          display: block;
        }
      }
    }
  }
}
</style>
