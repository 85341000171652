import api from '../api/api';

export default {

  state: {
    status: '',
    companyProjects: [],
    companyStatuses: [],
    inviteKey: null,
    project: {},
    projectActivities: [],
    projectActivitiesTotalPages: '',
    companyProjectsTotalPages: '',
    projectActivityNew: '',
    emailSettings: {},
    allUsers: [],
    contractors: [],
    companyProjectsAny: false,
    paymentPlans: {},
    sessionCheckoutUrl: '',
    paymentInfo: '',
    workTypes: [],
    IsTradeTypesModalVisible: false
  },

  mutations: {
    setCompanyProjects: function(state, payload) {
      state.companyProjects = payload
    },
    setCompanyProjectsTotalPages: function(state, payload) {
      state.companyProjectsTotalPages = payload
    },
    setCompanyStatuses: function(state, payload) {
      state.companyStatuses = payload
    },
    setProject: function(state, payload) {
      state.project = payload
    },
    setProjectActivities: function(state, payload) {
      state.projectActivities = payload
    },
    setProjectActivityNew: function(state, payload) {
      state.projectActivityNew = payload
    },
    setProjectActivitiesTotalPages: function(state, payload) {
      state.projectActivitiesTotalPages = payload
    },
    setInviteKey: function(state, payload) {
      state.inviteKey = payload
    },
    loadingRequest: function(state) {
      state.status = 'loading'
    },
    setNotifications: function(state, payload) {
      state.notifications = payload
    },
    setNotificationsTotalPages: function(state, payload) {
      state.notificationsTotalPages = payload
    },
    setNotificationsNew: function(state, payload) {
      state.notificationsNew = payload
    },
    setEmailSettings: function(state, payload) {
      state.emailSettings = payload
    },
    setAllUsers: function(state, payload) {
      state.allUsers = payload
    },
    setAllContractors: function(state, payload) {
      state.contractors = payload
    },
    setCompanyProjectsAny: function(state, payload) {
      state.companyProjectsAny = payload
    },
    setPaymentPlans: function(state, payload) {
      state.paymentPlans = payload
    },
    setSessionCheckoutUrl: function(state, payload) {
      state.sessionCheckoutUrl = payload
    },
    setSessionBillingUrl: function(state, payload) {
      state.sessionBillingUrl = payload
    },
    setPaymentInfo: function(state, payload) {
      state.paymentInfo = payload
    },
    setWorkTypes: function(state, payload) {
      state.workTypes = payload
    },
    setTradeTypesModal: function(state, payload) {
      state.IsTradeTypesModalVisible = payload;
    },
    resetState: function(state) {
      state.status = '';
      state.companyProjects = [];
      state.companyStatuses = [];
      state.inviteKey = null;
      state.project = {};
      state.projectActivities = [];
      state.projectActivitiesTotalPages = '';
      state.companyProjectsTotalPages = '';
      state.projectActivityNew = '';
      state.emailSettings = {};
      state.allUsers = [];
      state.contractors = [];
      state.companyProjectsAny = false;
      state.paymentPlans = {};
      state.sessionCheckoutUrl = '';
      state.paymentInfo = '';
      state.sessionBillingUrl = '';
      state.workTypes = [];
      state.IsTradeTypesModalVisible = false;
    },
    resetProject: function(state) {
      state.project = {};
    },
  },

  actions: {
    setDashboardInfo({ commit, dispatch }, payload = {page: '1', filter: 'filter[status]=not_completed&filter[archive]=unarchived', order: 'order[creation_date]=desc', search: 'search[name]='}) {
      return new Promise((resolve, reject) => {
        commit('loadingRequest')
        api.get(`/dashboard?${payload.filter}&page=${payload.page}&${payload.order}&${payload.search}`)
          .then(resp => {
            if (resp.status == 200) {
              commit('setCurrentUser', resp.data.user);
              commit('setUserCompany', resp.data.user.company);
              commit('setPaymentInfo', resp.data.user.company.contractor_subscription);
              commit('setCompanyProjects', resp.data.company_projects);
              commit('setCompanyProjectsAny', resp.data.company_projects_any);
              commit('setCompanyProjectsTotalPages', resp.data.total_pages);
              if (resp.data.user.company.contractor_id) {
                dispatch('getContractorInfoForCustomer')
              }
              dispatch('notificationsInfo');
              dispatch('loadCompanyStatuses');
              dispatch('workTypes');
              resolve(resp);
            } else {
              commit('loadingRequest', resp.data.message);
            }
          })
          .catch(error => {
            commit('setCompanyProjects', []);
            commit('setCompanyProjectsAny', false);
            commit('setUserCompany', {});
            commit('setUserCompany', {});
            commit('setCompanyProjectsTotalPages', 0);
            return reject(error);
          });
      })
    },

    notificationsInfo({ commit }, payload = '1') {
      return new Promise((resolve, reject) => {
        commit('authRequest')
        api.get(`/notifications?page=${payload}`)
          .then(resp => {
            if (resp.status == "200") {
              commit('setNotifications', resp.data.activities);
              commit('setNotificationsTotalPages', resp.data.total_pages);
              commit('setNotificationsNew', resp.data.unviewed_activities_count);
              resolve(resp);
            } else {
              commit('authRequest', resp.data.message);
            }
          })
          .catch(error => {
            return reject(error);
          });
      })
    },

    getAllContractors({ commit }, payload = { page: '1', zipCode: '', workTypesIds: ''}) {
      return new Promise((resolve, reject) => {
        commit('authRequest')
        api.get(`/get_contractors?page=${payload.page}${payload.zipCode ? payload.zipCode : ''}${payload.workTypesIds ? payload.workTypesIds : ''}`)
          .then(resp => {
            if (resp.status == "200") {
              commit('setAllContractors', resp.data);
              resolve(resp);
            } else {
              commit('authRequest', resp.data.message);
            }
          })
          .catch(error => {
            return reject(error);
          });
      })
    },

    notificationsUpdate({ commit }) {
      return new Promise((resolve, reject) => {
        commit('authRequest')
        api.put('update_notifications')
          .then(resp => {
            if (resp.status == "200") {
              commit('setNotificationsNew', 0);
              resolve(resp);
            } else {
              commit('authRequest', resp.data.message);
            }
          })
          .catch(error => {
            return reject(error);
          });
      })
    },

    companyProject({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('authRequest')
        api.post('company_projects', payload.params)
          .then(resp => {
            if (resp.status == "201") {
              resolve(resp);
            } else {
              commit('authRequest', resp.data.message);
            }
          })
          .catch(error => {
            return reject(error);
          });
      })
    },

    updateCompanyProject({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        commit('authRequest')
        api.put(`company_projects/${payload.id}`, payload.params)
          .then(resp => {
            if (resp.status == "200") {
              commit('setProjectActivityNew', resp.data.activity);
              dispatch('notificationsInfo')
              resolve(resp);
            } else {
              commit('authRequest', resp.data.message);
            }
          })
          .catch(error => {
            return reject(error);
          });
      })
    },

    setInvitationKey({ commit }) {
      return new Promise((resolve, reject) => {
        commit('loadingRequest')
        api.post('/invite_customers')
          .then(resp => {
            if (resp.status >= 200 && resp.status < 300) {
              commit('setInviteKey', resp.data.invite_key);
              resolve(resp);
            } else {
              commit('loadingRequest', resp.data.message);
            }
          })
          .catch(error => {
            commit('setInviteKey', null);
            return reject(error);
          });
      })
    },

    updateProjectId({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('authRequest')
        api.put(`company_projects/${payload.id}/add_project_id`, payload.params)
          .then(resp => {
            if (resp.status == "200") {
              resolve(resp);
            } else {
              commit('authRequest', resp.data.message);
            }
          })
          .catch(error => {
            return reject(error);
          });
      })
    },

    updateProjectStatus({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('authRequest')
        api.put(`company_projects/${payload.id}/change_status`, payload.params)
          .then(resp => {
            if (resp.status == "200") {
              commit('setProjectActivityNew', resp.data.activity);
              resolve(resp);
            } else {
              commit('authRequest', resp.data.message);
            }
          })
          .catch(error => {
            return reject(error);
          });
      })
    },

    updatePaymentsProgress({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        commit('authRequest')
        api.put(`company_projects/${payload.id}/project_checklists/${payload.checklist_id}/update_progress_bar`, payload.params)
          .then(resp => {
            if (resp.status == "200") {
              commit('setProjectActivityNew', resp.data.activity);
              dispatch('projectInfo', payload.id);
              resolve(resp);
            } else {
              commit('authRequest', resp.data.message);
            }
          })
          .catch(error => {
            return reject(error);
          });
      })
    },

    addCheckListItem({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        commit('authRequest')
        api.post(`company_projects/${payload.id}/project_checklist_items`, payload.params)
          .then(resp => {
            if (resp.status == "201") {
              resolve(resp);
              commit('setProjectActivityNew', resp.data.activity);
              dispatch('projectInfo', payload.id);
            } else {
              commit('authRequest', resp.data.message);
            }
          })
          .catch(error => {
            return reject(error);
          });
      })
    },

    completeOrUpdateCheckListItem({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        commit('authRequest')
        api.put(`company_projects/${payload.id}/project_checklist_items/${payload.item_id}`, payload.params)
          .then(resp => {
            if (resp.status == "200") {
              dispatch('projectInfo', payload.id);
              commit('setProjectActivityNew', resp.data.activity);
              resolve(resp);
            } else {
              commit('authRequest', resp.data.message);
            }
          })
          .catch(error => {
            return reject(error);
          });
      })
    },

    deleteCheckListItem({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        commit('authRequest')
        api.delete(`company_projects/${payload.id}/project_checklist_items/${payload.item_id}`,
          {
            data: payload.params
          })
          .then(resp => {
            if (resp.status == "200") {
              dispatch('projectInfo', payload.id);
              commit('setProjectActivityNew', resp.data.activity);
              resolve(resp);
            } else {
              commit('authRequest', resp.data.message);
            }
          })
          .catch(error => {
            return reject(error);
          });
      })
    },

    addActivity({commit, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        commit('authRequest')
        api.post(`/company_projects/${payload.id}/activities`, {activity: payload.params})
          .then(resp => {
            if (resp.status >= 200 && resp.status < 300) {
              commit('setProjectActivityNew', resp.data.activity);
              dispatch('notificationsInfo');
              dispatch('projectInfo', resp.data.company_project.id);
              resolve(resp);
            } else {
              commit('authRequest', resp.data.message);
            }
          })
          .catch(error => {
            return reject(error);
          });
      })
    },

    resetDashbord({commit}) {
      commit('resetState')
    },

    resetProjectInfo({commit}) {
      commit('resetProject')
    },

    projectInfo({commit, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        commit('authRequest')
        api.get(`/company_projects/${payload}`)
          .then(resp => {
            if (resp.status >= 200 && resp.status < 300) {
              commit('setProject', resp.data);
              dispatch('notificationsInfo');
              resolve(resp);
            } else {
              commit('authRequest', resp.data.message);
            }
          })
          .catch(error => {
            return reject(error);
          });
      })
    },

    projectActivitiesInfo({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('authRequest')
        api.get(`/company_projects/${payload.id}/get_activities?page=${payload.page}`)
          .then(resp => {
            if (resp.status >= 200 && resp.status < 300) {
              commit('setProjectActivities', resp.data.activities);
              commit('setProjectActivitiesTotalPages', resp.data.total_pages);
              resolve(resp);
            } else {
              commit('authRequest', resp.data.message);
            }
          })
          .catch(error => {
            return reject(error);
          });
      })
    },

    emailSettingsInfo({ commit }) {
      return new Promise((resolve, reject) => {
        commit('authRequest')
        api.get('/email_notifications')
          .then(resp => {
            if (resp.status == "200") {
              commit('setEmailSettings', resp.data.email_notifications);
              resolve(resp);
            } else {
              commit('authRequest', resp.data.message);
            }
          })
          .catch(error => {
            return reject(error);
          });
      })
    },

    updateEmailSettings({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('authRequest')
        api.put('/update_email_notifications', payload)
          .then(resp => {
            if (resp.status == "200") {
              commit('setEmailSettings', resp.data.email_notifications);
              resolve(resp);
            } else {
              commit('authRequest', resp.data.message);
            }
          })
          .catch(error => {
            return reject(error);
          });
      })
    },

    archivingProject({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('authRequest')
        api.put(`/company_projects/${payload.id}/archiving_project`, payload.params)
          .then(resp => {
            if (resp.status >= 200 && resp.status < 300) {
              resolve(resp);
            } else {
              commit('authRequest', resp.data.message);
            }
          })
          .catch(error => {
            return reject(error);
          });
      })
    },

    loadCompanyStatuses({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('loadingRequest')
        api.get(`companies/${getters.getUserCompany?.contractor_id ? getters.getUserCompany?.contractor_id : getters.getUserCompany?.id}/get_statuses`)
          .then(resp => {
            if (resp.status >= 200 && resp.status < 300) {
              commit('setCompanyStatuses', resp.data);
              resolve(resp);
            } else {
              commit('loadingRequest', resp.data.message);
            }
          })
          .catch(error => {

            return reject(error);
          });
      })
    },

    addCustomStatus({ commit, getters, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        commit('authRequest')
        api.post(`/companies/${getters.getUserCompany?.id}/add_custom_status`, payload)
          .then(resp => {
            if (resp.status >= 200 && resp.status < 300) {
              dispatch('loadCompanyStatuses');
              resolve(resp);
            } else {
              commit('authRequest', resp.data.message);
            }
          })
          .catch(error => {
            return reject(error);
          });
      })
    },

    updateCustomStatus({ commit, getters, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        commit('authRequest')
        api.put(`/companies/${getters.getUserCompany?.id}/update_custom_status`, payload)
          .then(resp => {
            if (resp.status >= 200 && resp.status < 300) {
              dispatch('loadCompanyStatuses');
              resolve(resp);
            } else {
              commit('authRequest', resp.data.message);
            }
          })
          .catch(error => {
            return reject(error);
          });
      })
    },

    deleteCustomStatus({ commit, getters, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        commit('authRequest')
        api.delete(`/companies/${getters.getUserCompany?.id}/delete_custom_status`, {
          data: payload
        })
          .then(resp => {
            if (resp.status >= 200 && resp.status < 300) {
              dispatch('loadCompanyStatuses');
              resolve(resp);
            } else {
              commit('authRequest', resp.data.message);
            }
          })
          .catch(error => {
            return reject(error);
          });
      })
    },

    allUsersInfo({commit}) {
      return new Promise((resolve, reject) => {
        commit('authRequest')
        api.get('/get_all_users')
          .then(resp => {
            if (resp.status >= 200 && resp.status < 300) {
              commit('setAllUsers', resp.data);
            } else {
              commit('authRequest', resp.data.message);
            }
          })
          .catch(error => {
            return reject(error);
          });
      })
    },

    inviteUser({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('authRequest')
        api.post('/invite_user', payload)
          .then(resp => {
            if (resp.status >= 200 && resp.status < 300) {
              resolve(resp);
            } else {
              commit('authRequest', resp.data.message);
            }
          })
          .catch(error => {
            return reject(error);
          });
      })
    },

    changeUserRole({commit, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        commit('authRequest')
        api.put('/change_user_role', payload)
          .then(resp => {
            if (resp.status >= 200 && resp.status < 300) {
              dispatch('allUsersInfo');
              resolve(resp);
            } else {
              commit('authRequest', resp.data.message);
            }
          })
          .catch(error => {
            return reject(error);
          });
      })
    },

    updateUserCompany({commit, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        commit('authRequest')
        api.put('/update_user_invitation', payload)
          .then(resp => {
            if (resp.status >= 200 && resp.status < 300) {
              dispatch('setDashboardInfo');
              resolve(resp);
            } else {
              commit('authRequest', resp.data.message);
            }
          })
          .catch(error => {
            return reject(error);
          });
      })
    },

    paymentPlans({ commit }) {
      return new Promise(resolve => {
        commit('authRequest')
        api.get('get_plans')
          .then(resp => {
            if (resp.status >= 200 && resp.status < 300) {
              commit('setPaymentPlans', resp.data);
              resolve(resp);
            } else {
              commit('authRequest', resp.data.message);
            }
          })
          .catch(error => {
            console.error('Error Loading Payment Plans', error);
            resolve([]);
          });
      })
    },

    checkoutSession({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('authRequest')
        api.get(`/get_checkout_session?plan_id=${payload.plan_id}&success_url=${payload.success_url}&cancel_url=${payload.cancel_url}${payload.free_trial ? '&free_trial=' + payload.free_trial : ''}`)
          .then(resp => {
            if (resp.status >= 200 && resp.status < 300) {
              commit('setSessionCheckoutUrl', resp.data);
              resolve(resp);
            } else {
              commit('authRequest', resp.data.message);
            }
          })
          .catch(error => {
            return reject(error);
          });
      })
    },

    billingSession({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('authRequest')
        api.get(`/get_billing_portal_session?return_url=${payload}`)
          .then(resp => {
            if (resp.status >= 200 && resp.status < 300) {
              commit('setSessionBillingUrl', resp.data.url);
              resolve(resp);
            } else {
              commit('authRequest', resp.data.message);
            }
          })
          .catch(error => {
            return reject(error);
          });
      })
    },

    workTypes({ commit }) {
      return new Promise((resolve, reject) => {
        commit('authRequest')
        api.get('/work_types')
          .then(resp => {
            if (resp.status >= 200 && resp.status < 300) {
              commit('setWorkTypes', resp.data);
              resolve(resp);
            } else {
              commit('authRequest', resp.data.message);
            }
          })
          .catch(error => {
            return reject(error);
          });
      })
    },

    hideTradeTypesModal({ commit }, payload) {
      return new Promise(() => {
        commit('setTradeTypesModal', payload);
      })
    }
  },

  getters: {
    getCompanyProjects (state) {
      return state.companyProjects
    },
    getCompanyProjectsTotalPages (state) {
      return state.companyProjectsTotalPages
    },
    getCompanyStatuses (state) {
      return state.companyStatuses
    },
    getProjectInfo (state) {
      return state.project
    },
    getProjectActivities (state) {
      return state.projectActivities
    },
    getProjectActivitiesTotalPages (state) {
      return state.projectActivitiesTotalPages
    },
    getProjectActivityNew (state) {
      return state.projectActivityNew
    },
    getInviteKey (state) {
      return state.inviteKey
    },
    getNotifications (state) {
      return state.notifications
    },
    getNotificationsTotalPages (state) {
      return state.notificationsTotalPages
    },
    getNotificationsNew (state) {
      return state.notificationsNew
    },
    getEmailSettings (state) {
      return state.emailSettings
    },
    getAllUsers (state) {
      return state.allUsers
    },
    getContractors (state) {
      return state.contractors
    },
    getCompanyProjectsAny (state) {
      return state.companyProjectsAny
    },
    getPaymentPlans (state) {
      return state.paymentPlans
    },
    getSessionCheckoutUrl (state) {
      return state.sessionCheckoutUrl
    },
    getPaymentInfo (state) {
      return state.paymentInfo
    },
    getSessionBillingUrl (state) {
      return state.sessionBillingUrl
    },
    getWorkTypes (state) {
      return state.workTypes
    },
    getIsTradeTypesModalVisible (state) {
      return state.IsTradeTypesModalVisible
    }
  }

}
