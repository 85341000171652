<template>
  <div class="sign-up">

    <custom-sidebar
      v-if="currentStep !== 8"
      :authPage="true"
    />

    <div
      class="sign-up--body"
      :class="{'congrats': currentStep === 8,
        'create-account': currentStep === 0}"
    >

      <CustomHeaderWithContractor
        v-if="(isInvitedUser && currentStep === 0 || getContractorCompanyMainInfo) && currentStep !== 8"
        :companyLogo="isInvitedUser ? getInvitedUser?.logo_url : getContractorCompanyMainInfo.contractor_logo"
        :companyName="isInvitedUser ? getInvitedUser?.company_name : getContractorCompanyMainInfo.contractor_name"
      />

      <div
        v-if="currentStep !== 8"
        class="sign-up--body--header"
      >
        <inline-svg
          v-if="showBackButton"
          :src="require('@/assets/images/icons/arrow-left.svg')"
          class="sign-up--body--header--link"
          @click="previousStep"
        />
        <h3 class="sign-up--body--header--title">Registration</h3>
      </div>

      <h2
        v-if="currentStep !== 8"
        class="sign-up--body--title h2-sb"
      >
        {{stepTitle}}
        <span
          v-if="currentStep === 3"
          class="h3-light"
        >(opt.)</span>
      </h2>

      <p
        v-if="currentStep === 0 || currentStep === 1 && userType === 'Contractor'"
        class="sign-up--body--subtitle p-medium"
      >
        {{subTitle}}
      </p>

      <ChoseUserType
        v-if="currentStep === 0"
        :userType="userType"
        :handleUserTypeChange="handleUserTypeChange"
      />

      <CreateAccount
        v-if="currentStep === 1"
        :email="email"
        :password="password"
        :confirmPassword="confirmPassword"
        :handleEmailChange="handleEmailChange"
        :handlePasswordChange="handlePasswordChange"
        :handleConfirmPasswordChange="handleConfirmPasswordChange"
        :emailError="emailError"
        :passwordError="passwordError"
        :confirmPasswordError="confirmPasswordError"
        :emailDisabled="isInvitedUser ? true : false"
        :selectedWorkTypes="selectedWorkTypes"
        :handleSelectWorkTypes="handleSelectWorkTypes"
        :userType="userType"
      />

      <AddSignUpInfo
        v-if="currentStep === 2"
        :firstName="firstName"
        :lastName="lastName"
        :jobTitle="jobTitle"
        :phoneNumber="phoneNumber"
        :handleFirstNameChange="handleFirstNameChange"
        :handleLastNameChange="handleLastNameChange"
        :handleJobTitleChange="handleJobTitleChange"
        :handlePhoneNumberChange="handlePhoneNumberChange"
        :firstNameError="firstNameError"
        :lastNameError="lastNameError"
        :jobTitleError="jobTitleError"
        :phoneNumberError="phoneNumberError"
      />

      <UploadLogo
        v-if="currentStep === 3"
        :file="file"
        :filePreview="filePreview"
        :description="description"
        :handleUploadFileChange="handleUploadFileChange"
        :handleUploadFileError="handleUploadFileError"
        :handleUploadFilePreviewChange="handleUploadFilePreviewChange"
        :deleteLogoGC="deleteLogoGC"
      />

      <AddCompanyInfo
        v-if="currentStep === 4"
        :companyName="companyName"
        :contactName="contactName"
        :companyPhoneNumber="companyPhoneNumber"
        :handleCompanyNameChange="handleCompanyNameChange"
        :handleContactNameChange="handleContactNameChange"
        :handleCompanyPhoneNumberChange="handleCompanyPhoneNumberChange"
        :companyNameError="companyNameError"
        :contactNameError="contactNameError"
        :companyPhoneNumberError="companyPhoneNumberError"
      />

      <AddCompanyContactInfoDesktop
        v-if="currentStep === 5 && !isMobile"
        :city="city"
        :state="state"
        :selectOptions="stateOptions"
        :zip="zip"
        :addressOne="addressOne"
        :addressTwo="addressTwo"
        :handleCityChange="handleCityChange"
        :handleStateChange="handleStateChange"
        :handleZipChange="handleZipChange"
        :handleAddressOneChange="handleAddressOneChange"
        :handleAddressTwoChange="handleAddressTwoChange"
        :cityError="cityError"
        :stateError="stateError"
        :zipError="zipError"
        :addressOneError="addressOneError"
      />

      <AddCompanyContactInfo
        v-if="currentStep === 5 && isMobile"
        :city="city"
        :state="state"
        :selectOptions="stateOptions"
        :zip="zip"
        :handleCityChange="handleCityChange"
        :handleStateChange="handleStateChange"
        :handleZipChange="handleZipChange"
        :cityError="cityError"
        :stateError="stateError"
        :zipError="zipError"
      />

      <AddCompanyAddressInfo
        v-if="currentStep === 6 && isMobile"
        :addressOne="addressOne"
        :addressTwo="addressTwo"
        :handleAddressOneChange="handleAddressOneChange"
        :handleAddressTwoChange="handleAddressTwoChange"
        :addressOneError="addressOneError"
      />

      <BeforeStarted
        v-if="currentStep === 7"
        :termsAgree="termsAgree"
        :notificationsAgree="notificationsAgree"
        :marketingAgree="marketingAgree"
        :handleAgreeChange="handleAgreeChange"
      />

      <CustomCongrats
        v-if="currentStep === 8"
      />

      <button
        v-if="currentStep !== 8"
        class="sign-up--body--button normal-btn"
        :class="isButtonDisabled && 'disabled'"
        @click="nextStep"
      >
        {{ nextButtonTitle }}
      </button>
      <button
        v-if="showBackButton"
        class="sign-up--body--button-back ghost-btn"
        @click="previousStep"
      >
        Back
      </button>

      <p
        class="sign-up--body--register btn-font-large"
        v-if="currentStep === 0"
      >Already have an account?
        <router-link
          class="sign-up--body--register--link text-btn"
          to="/sign-in"
        > Sign in</router-link>
      </p>

      <p
        v-if="getContractorCompanyMainInfo"
        class="sign-up--body--copyright caption-3"
      >
        {{getContractorCompanyMainInfo ? getContractorCompanyMainInfo. contractor_name : `Powered by © ${currentYear} FCA International`}}
      </p>

      <div
        v-else
        class="sign-up--body--copyright caption-3"
      >
        <a
          class="sign-up--body--copyright--link"
          href="https://finishingcontractors.org/"
          target="_blank"
        >Powered by © {{ currentYear }} FCA International</a>
        <a
          class="sign-up--body--copyright--link"
          href="https://finishingcontractors.org/contact/"
          target="_blank"
        >Contact us</a>
        <a
          class="sign-up--body--copyright--link"
          href="https://finishingcontractors.org/scope-app-terms-and-conditions"
          target="_blank"
        >Terms and Conditions</a>
      </div>

      <custom-error
        v-if="showError"
        :errorMessage="errorMessage"
        :handleErrorClose="handleErrorClose"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { validationUtils } from '@/utils/validation';

import stateOptions from '@/components/sign-up/StateOptions.js';
import InlineSvg from 'vue-inline-svg';
import CustomSidebar from '@/components/shared/CustomSidebar';
import CustomHeaderWithContractor from '@/components/shared/CustomHeaderWithContractor';
import BeforeStarted from '@/components/sign-up/BeforeStarted';
import CreateAccount from '@/components/sign-up/CreateAccount';
import AddSignUpInfo from '@/components/sign-up/AddSignUpInfo';
import UploadLogo from '@/components/sign-up/UploadLogo';
import AddCompanyInfo from '@/components/sign-up/AddCompanyInfo';
import AddCompanyContactInfo from '@/components/sign-up/AddCompanyContactInfo';
import AddCompanyAddressInfo from '@/components/sign-up/AddCompanyAddressInfo';
import CustomCongrats from '@/components/shared/CustomCongrats';
import AddCompanyContactInfoDesktop from '@/components/sign-up/AddCompanyContactInfoDesktop';
import CustomError from '@/components/shared/CustomError';
import ChoseUserType from '@/components/sign-up/ChoseUserType';

export default {

  name: 'Sign up',

  components: {
    InlineSvg,
    CustomSidebar,
    CustomHeaderWithContractor,
    BeforeStarted,
    CreateAccount,
    AddSignUpInfo,
    UploadLogo,
    AddCompanyInfo,
    AddCompanyContactInfo,
    AddCompanyAddressInfo,
    CustomCongrats,
    AddCompanyContactInfoDesktop,
    CustomError,
    ChoseUserType
  },

  data: () => ({
    currentStep: 0,
    isInvitedUser: false,
    // Before Started step values
    termsAgree: false,
    notificationsAgree: false,
    marketingAgree: false,

    // First step values
    userType: '',
    showUserType: true,

    // Second step values
    email: '',
    password: '',
    confirmPassword: '',
    emailError: null,
    passwordError: null,
    confirmPasswordError: null,
    selectedWorkTypes: [],

    // Third step values
    firstName: '',
    lastName: '',
    jobTitle: '',
    phoneNumber: '',
    firstNameError: null,
    lastNameError: null,
    jobTitleError: null,
    phoneNumberError: null,

    // Fourth step values
    file: '',
    filePreview: '',
    description: '(300x300px, up to 10MB)',
    fileError: false,
    fileType: '',
    isUploadedFileToGC: false,

    // Fifth step values
    companyName: '',
    contactName: '',
    companyPhoneNumber: '',
    companyNameError: null,
    contactNameError: null,
    companyPhoneNumberError: null,

    // Sixth step values
    city: '',
    state: '',
    stateOptions: stateOptions,
    zip: '',
    cityError: null,
    stateError: null,
    zipError: null,

    // Seventh step values
    addressOne: '',
    addressTwo: '',
    addressOneError: null,

    windowWidth: window.innerWidth,
    isMobile: (window.innerWidth < 1024),
    errorMessage: 'Something went wrong. Please reload the page',
    showError: false
  }),

  created() {
    if (this.$router.currentRoute._value.query.invite_user) {
      this.isInvitedUser = true;
      this.showUserType = false;
      this.currentStep = 1;
      this.handleUserInfo();
    }
    if (this.$route.params.inviteUser === 'true') {
      this.showUserType = false;
      this.currentStep = 1;
    }
    this.workTypes();
  },

  mounted() {
    window.addEventListener('resize', this.getWindowWidth);
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.getWindowWidth);
  },

  watch: {
    windowWidth() {
      this.getWindowWidth();
      if (this.windowWidth < 1024) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
        if (this.currentStep === 6) {
          this.currentStep = 5;
        }
      }
    }
  },

  computed: {
    ...mapGetters([
      'getGcFile',
      'getContractorCompanyMainInfo',
      'getInvitedUser'
    ]),

    getEmailErrorMessage() {
      return validationUtils.isEmailValid(this.email);
    },

    getPasswordErrorMessage() {
      return validationUtils.getPasswordErrorMessage(this.password);
    },

    getConfirmPasswordErrorMessage() {
      return validationUtils.getConfirmPasswordErrorMessage(this.password,this.confirmPassword);
    },

    getPhoneNumberErrorMessage() {
      return validationUtils.getPhoneNumberErrorMessage(this.currentStep === 2
        ? this.phoneNumber
        : this.companyPhoneNumber);
    },

    stepTitle() {
      switch(this.currentStep) {
        case 0:
          return 'Select user type';
        case 1:
          return this.getContractorCompanyMainInfo || this.isInvitedUser ? 'Welcome, let’s get connected' : 'Welcome! Let’s create \nan account';
        case 2:
          return 'Add registration info';
        case 3:
          return 'Upload company logo';
        case 4:
          return 'Add company info';
        case 5:
          if (this.isMobile) {
            return 'Add your company contact information';
          } else {
            return 'Finish up by adding your company contact information';
          }
        case 6:
          return 'Finish up by adding address info';
        case 7:
          return 'Almost done ...';
        default:
          return '';
      }
    },

    subTitle() {
      if (this.isInvitedUser) {
        return `Create an account to join ${this.getInvitedUser?.company_name}.`;
      } else if (this.userType === 'Contractor' && this.currentStep === 1) {
        return 'Create a contractor account to connect \nwith your customers.';
      } else {
        return '';
      }
    },

    nextButtonTitle() {
      if (this.isInvitedUser) {
        return this.currentStep === 2 ? 'Submit' : 'Next';
      }
      return this.currentStep === 7 ? 'Submit' : 'Next';
    },

    isButtonDisabled() {
      switch(this.currentStep) {
        case 0:
          return !this.userType;
        case 1:
          return (!this.email || !this.password || !this.confirmPassword);
        case 2:
          return (!this.firstName || !this.lastName || !this.jobTitle || !this.phoneNumber);
        case 3:
          return false;
        case 4:
          return (!this.companyName || !this.contactName || !this.companyPhoneNumber);
        case 5:
          if (this.isMobile) {
            return (!this.city || !this.zip || !this.state);
          } else {
            return (!this.city || !this.zip || !this.addressOne || !this.state);
          }
        case 6:
          return (!this.addressOne);
        case 7:
          return (!this.termsAgree || !this.notificationsAgree);
        default:
          return '';
      }
    },

    currentYear() {
      return new Date().getFullYear();
    },

    showBackButton() {
      switch(this.currentStep) {
        case 0:
          return false;
        case 1:
          return this.showUserType;
        case 8:
          return false;
        default:
          return true;
      }
    }
  },

  methods: {
    ...mapActions([
      'signUp',
      'gcFileUpload',
      'gcFileDelete',
      'getContractorCompany',
      'getInvitedUserInfo',
      'workTypes'
    ]),

    async getCompanyInfo() {
      const inviteKey = this.$router.currentRoute._value.query.invite_key;

      try {
        await this.getContractorCompany(inviteKey);
      } catch (error) {
        if (error) {
          if (error.response && error.response.status === 404) {
            this.errorMessage = 'Error occurred. Link is wrong or expired. Please, contact a contractor for assistance.';
          }
          this.showError = true;
        }
      }
    },

    async handleUserInfo() {
      const inviteKey = this.$router.currentRoute._value.query.invite_user;

      try {
        await this.getInvitedUserInfo(inviteKey);
        this.email = this.getInvitedUser.email;
      } catch (error) {
        if (error) {
          if (error.response && error.response.status === 422) {
            this.errorMessage = 'Error occurred. Link is wrong or expired. Please, contact an admin for assistance.';
          }
          this.showError = true;
        }
      }
    },

    handleErrorClose() {
      this.showError = false;
    },

    getWindowWidth() {
      this.windowWidth = window.innerWidth;
    },

    formValidation() {
      let hasError;
      switch(this.currentStep) {
        case 1: {
          const emailErrorMessage = this.getEmailErrorMessage;
          const passwordErrorMessage = this.getPasswordErrorMessage;
          const confirmPasswordErrorMessage = this.getConfirmPasswordErrorMessage;

          if (emailErrorMessage) {
            this.emailError = emailErrorMessage;
            hasError = true;
          }

          if (passwordErrorMessage) {
            this.passwordError = passwordErrorMessage;
            hasError = true;
          }

          if (confirmPasswordErrorMessage) {
            this.confirmPasswordError = confirmPasswordErrorMessage;
            hasError = true;
          }

          return !hasError;
        }
        case 2: {
          const phoneNumberErrorMessage = this.getPhoneNumberErrorMessage;

          if (phoneNumberErrorMessage) {
            this.phoneNumberError = phoneNumberErrorMessage;
            hasError = true;
          }

          return !hasError;
        }
        case 3: {
          const isFileError = this.fileError

          if (isFileError) {
            hasError = true;
          }

          return !hasError;
        }
        case 4: {
          const companyPhoneNumberErrorMessage = this.getPhoneNumberErrorMessage;

          if (companyPhoneNumberErrorMessage) {
            this.companyPhoneNumberError = companyPhoneNumberErrorMessage;
            hasError = true;
          }

          return !hasError;
        }
        case 5:
          if (this.isMobile) {
            return !!(this.city && this.zip && this.state);
          } else {
            return !!(this.city && this.zip && this.state && this.addressOne);
          }
        case 6:
          return !!(this.addressOne);
        default:
          return true;
      }
    },

    nextStep() {
      const isValid = this.formValidation();
      if (!isValid) return;

      if (this.isInvitedUser) {
        if (this.currentStep === 2) {
          this.submitForm();
        } else {
          this.currentStep++
        }
        return;
      }

      if (this.isMobile) {
        if (this.currentStep === 7) {
          this.submitForm();
        } else {
          this.currentStep++;
        }
      } else {
        if (this.currentStep === 5) {
          this.currentStep = 7;
        } else if (this.currentStep === 7) {
          this.submitForm();
        } else {
          this.currentStep++
        }
      }
    },

    previousStep() {
      if (!this.isMobile && this.currentStep === 7) {
        this.currentStep = 5;
      } else {
        this.currentStep--
      }
    },

    async getUploadingLogoLink(file) {
      const filePrefix = () => Math.random().toString(36).substr(2, 9);
      const fileName = `${filePrefix()}-${file.name}`.replace(/\s/g, '_');
      const data = {
        file_name: fileName,
        file_type: file.type
      }

      try {
        await this.gcFileUpload(data);
        this.uploadingLogoGC(this.getGcFile.url, file);
      } catch (error) {
        this.showError = true;
      }
    },

    uploadingLogoGC(gcUrl, file) {
      var xhr = new XMLHttpRequest();
      xhr.open('PUT', gcUrl, true);
      xhr.onload = () => {
        if (xhr.readyState == 4 && xhr.status == "200") {
          this.fileType = file.type;
          this.isUploadedFileToGC = true;
        }
      }
      xhr.setRequestHeader('Content-Type', file.type);
      xhr.send(file.slice());
    },

    async deleteLogoGC() {
      const data = {
        file_name: this.getGcFile.file_name
      }

      try {
        await this.gcFileDelete(data);
        this.isUploadedFileToGC = false;
      } catch (error) {
        this.showError = true;
      }
    },

    async submitForm() {
      const inviteKey = this.$router.currentRoute._value.query.invite_key;
      let selectedWorkTypesData = null;
      if (this.selectedWorkTypes.length) {
        selectedWorkTypesData = this.selectedWorkTypes.map(item => {
          return { work_type_id: item.id };
        })
      }
      const  data = {
        ...((inviteKey) ? {
          invite_key: inviteKey
        } : {}),
        user: {
          marketing_mailing: this.marketingAgree,
          email: this.email.toLowerCase(),
          password: this.password,
          password_confirmation: this.confirmPassword,
          first_name: this.firstName,
          last_name: this.lastName,
          job_title: this.jobTitle,
          phone: this.phoneNumber,
          type: this.userType === 'Contractor' ? 'Contractor' : 'Customer',
          ...((this.showUserType) ? {
            role: this.getInvitedUser?.role,
            company_id: this.getInvitedUser?.company_id
          } : {}),
          ...((this.isInvitedUser) ? {
            role: this.getInvitedUser?.role,
            company_id: this.getInvitedUser?.company_id
          } : {
            company_attributes: {
              name: this.companyName,
              address_line_1: this.addressOne,
              address_line_2: this.addressTwo,
              phone: this.companyPhoneNumber,
              email: this.email.toLowerCase(),
              city: this.city,
              state: this.state,
              zip_code: this.zip,
              contact_name: this.contactName,
              ...(selectedWorkTypesData ? { company_work_types_attributes: selectedWorkTypesData } : {}),
              ...((this.fileType !== '') ?
              {
                file_storages_attributes: [
                  {
                    file_name: this.getGcFile.file_name,
                    file_type: this.fileType,
                    uploaded: this.isUploadedFileToGC
                  }
                ]
              } : {}),
            }
          })
        }
      };

      try {
        await this.signUp(data);
        this.currentStep = 7;
        if (this.userType === 'Contractor' && !this.isInvitedUser) {
          this.$router.push({ name: "Subscription" });
        } else {
          this.$router.push({ name: "Dashboard" });
        }
      } catch (error) {
        if (error) {
          console.error('Sign Up', error);
          this.showError = true;
          if (error.response && error.response.status === 422) {
            this.errorMessage = this.isInvitedUser
              ? 'Something went wrong. Please reload the page'
              : 'Email has already been taken. Please check your email and try again.';
          }
        }
      }
    },

    handleAgreeChange(value, name) {
      this[name] = value;
    },

    handleUserTypeChange(value) {
      this.userType = value;
    },

    handleEmailChange(value) {
      if (this.emailError) this.emailError = null;
      this.email = value;
    },

    handlePasswordChange(value) {
      if (this.passwordError) this.passwordError = null;
      this.password = value;
    },

    handleSelectWorkTypes(value) {
      const isIncluded = this.selectedWorkTypes.filter(item => item.id === value.id);
      if (isIncluded.length) {
        const itemIndex = this.selectedWorkTypes.indexOf(value);
        this.selectedWorkTypes.splice(itemIndex, 1)
      } else {
        this.selectedWorkTypes.push(value);
      }
    },

    handleConfirmPasswordChange(value) {
      if (this.confirmPasswordError) this.confirmPasswordError = null;
      this.confirmPassword = value;
    },

    handleFirstNameChange(value) {
      if (this.firstNameError) this.firstNameError = null;
      this.firstName = value;
    },

    handleLastNameChange(value) {
      if (this.lastNameError) this.lastNameError = null;
      this.lastName = value;
    },

    handleJobTitleChange(value) {
      if (this.jobTitleError) this.jobTitleError = null;
      this.jobTitle = value;
    },

    handlePhoneNumberChange(value) {
      if (this.phoneNumberError) this.phoneNumberError = null;
      this.phoneNumber = value;
    },

    handleUploadFileChange(value) {
      this.file = value;
      if (this.file) {
        this.getUploadingLogoLink(this.file)
      }
    },

    handleUploadFilePreviewChange(value) {
      this.filePreview = value;
    },

    handleUploadFileError(value) {
      this.fileError = value;
    },

    handleCompanyNameChange(value) {
      if (this.companyNameError) this.companyNameError = null;
      this.companyName = value;
    },

    handleContactNameChange(value) {
      if (this.contactNameError) this.contactNameError = null;
      this.contactName = value;
    },

    handleCompanyPhoneNumberChange(value) {
      if (this.companyPhoneNumberError) this.companyPhoneNumberError = null;
      this.companyPhoneNumber = value;
    },

    handleCityChange(value) {
      if (this.cityError) this.cityError = null;
      this.city = value;
    },

    handleStateChange(value) {
      if (this.stateError) this.stateError = null;
      this.state = value;
    },

    handleZipChange(value) {
      if (this.zipError) this.zipError = null;
      this.zip = value;
    },

    handleAddressOneChange(value) {
      if (this.addressOneError) this.addressOneError = null;
      this.addressOne = value;
    },

    handleAddressTwoChange(value) {
      this.addressTwo = value;
    },

    formattedPhoneNumber(value) {
      return value.replace(/[^\d]/g, "");
    }
  },

}
</script>

<style lang="scss">
.sign-up {
  height: 100%;

  &--body {
    padding: 40px 25px 7px;
    margin: 0 auto;
    max-width: 375px;
    height: 100%;
    box-sizing: border-box;
    position: relative;

    &.congrats {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0;
    }

    &--header {
      margin-bottom: 31px;
      position: relative;

      &--link {
        width: 100%;
        max-width: 8px;
        height: 14px;
        padding: 7px 20px 7px 0;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
      }

      &--title {
        text-align: center;
        width: 100%;
        color: #151522;
        font-size: 20px;
        font-weight: 600;
        font-family: $SFProDisplay;
        line-height: 150%;
      }
    }

    &--title {
      color: #151522;
      text-align: left;
      margin-bottom: 12px;
      white-space: pre-wrap;
      line-height: 100%;
    }

    &--subtitle {
      color: #617B97;
      margin-bottom: 38px;
    }

    &--button {
      padding: 13px 0;
      display: inline-block;
      width: calc(100% - 50px);
      position: absolute;
      bottom: 100px;
      left: 50%;
      max-width: 325px;
      transform: translateX(-50%);
    }

    &--button-back {
      display: none;
    }

    &--register {
      color: #617B97;
      text-align: center;
      position: absolute;
      bottom: 58px;
      left: 0;
      width: 100%;

      &--link {
        color: #10886D;
        text-decoration: none;
      }
    }

    &--copyright {
      color: #9999A0;
      text-align: center;
      position: absolute;
      bottom: 10px;
      left: 0;
      width: 100%;
      display: flex;
      flex-direction: column;

      &--link {
        text-decoration: none;
        color: #9999A0;
      }
    }
  }
}

@media screen and (max-width: 375px),
(max-height: 811px) {
  .sign-up {
    &--body {
      &--button,
      &--register,
      &--copyright {
        position: relative;
        left: unset;
        bottom: unset;
        transform: none;
        width: 100%;
      }

      &--button {
        margin-bottom: 18px;
        margin-top: 30px;
      }

      &--register {
        margin-bottom: 7px;
      }

      &--copyright {
        margin-bottom: 36px;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .sign-up {
    display: flex;
    max-width: 100%;
    padding: 0;
    height: auto;
    position: relative;

    &--body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 160px 0 40px;
      max-width: 397px;
      width: 100%;

      &.create-account {
        position: unset;
      }

      &--header,
      &--copyright {
        display: none;
      }

      &--button {
        width: 240px;
        position: relative;
        left: unset;
        bottom: unset;
        transform: none;
        margin-bottom: 20px;
        min-height: 50px;
      }

      &--button-back {
        width: 240px;
        padding: 13px 0;
        display: inline-block;
        min-height: 50px;
      }

      &--register {
        position: absolute;
        top: 100px;
        right: 60px;
        bottom: unset;
        left: unset;
        width: 249px;
      }
    }
  }
}

@media screen and (min-width: 1024px) and (min-height: 811px) {
  .sign-up {
    display: flex;
    max-width: 100%;
    padding: 0;
    min-height: 100vh;
    position: relative;

    &--body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 160px 0 40px;
      max-width: 397px;
      width: 100%;

      &.create-account {
        position: unset;
      }

      &--header,
      &--copyright {
        display: none;
      }

      &--button {
        width: 240px;
        position: relative;
        left: unset;
        bottom: unset;
        transform: none;
        margin-bottom: 20px;
        min-height: 50px;
      }

      &--button-back {
        width: 240px;
        padding: 13px 0;
        display: inline-block;
        min-height: 50px;
      }

      &--register {
        position: absolute;
        top: 100px;
        right: 60px;
        bottom: unset;
        left: unset;
        width: 249px;
      }
    }
  }
}
</style>
