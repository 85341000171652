<template>
  <div
    class="input-uploader"
    :class="isUpdating && 'frozen'"
  >
    <label
      class="input-uploader--label"
      :id="`${id}-label`"
      :for="id"
    >
      <inline-svg
        :id="`${id}-svg`"
        :src="require('@/assets/images/icons/upload.svg')"
      />
      <input
        v-if="!disable"
        class="input-uploader--label--input"
        @change="handleChange(id)"
        :type="type"
        :name="name"
        :id="id"
        :accept="accept"
        ref="inputUpload"
      >
      <img
        :src="filePreview"
        :id="`${id}-preview`"
        class="input-uploader--label--preview"
      >
      <inline-svg
        v-if="!disable"
        :id="`${id}-reset`"
        class="input-uploader--label--reset"
        :src="require('@/assets/images/icons/delete.svg')"
        @click="handleReset"
      />
      <p
        v-if="!disable"
        class="input-uploader--label--description text-small"
      >
        {{ description }}
      </p>
    </label>
    <button
      v-if="!disable"
      class="input-uploader--button small-btn btn-font-large"
      @click="handleChange(id)"
    >
      {{ buttonText }}
    </button>

    <custom-error
      v-if="showError"
      :errorMessage="errorMessage"
      :handleErrorClose="handleErrorClose"
    />

  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
import CustomError from '@/components/shared/CustomError'

export default {

  components: {
    InlineSvg,
    CustomError
  },

  props: {
    modelValue: {
      type: [String, Number, Object],
      default: ''
    },
    filePreview: {
      type: [String, Number, Object],
      default: ''
    },
    id: {
      type: String,
      default: 'file-upload'
    },
    name: {
      type: String,
      default: 'file'
    },
    type: {
      type: String,
      default: 'file'
    },
    accept: {
      type: String,
      default: '.png, .jpg, .jpeg, image/jpeg, image/png, image/jpg'
    },
    description: {
      type: String,
      default: 'Up to 10MB'
    },
    buttonText: {
      type: String,
      default: 'Upload image'
    },
    deleteLogoGC: {
      type: Function,
      default: () => {}
    },
    isLogoDeleted: {
      type: Boolean,
      default: false
    },
    isUpdate: {
      type: Boolean,
      default: false
    },
    isUpdating: {
      type: Boolean,
      default: false
    },
    disable: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      inputUpload: '',
      preview: '',
      reset: '',
      svg: '',
      reader: new FileReader(),
      file: '',
      label: '',
      errorMessage: 'Your file needs to be 300x300px and under 10MB',
      showError: false,
    }
  },

  mounted() {
    this.label = document.querySelector(`#${this.id}-label`);
    this.preview = document.querySelector(`#${this.id}-preview`);

    if (this.preview.getAttribute('src').length > 0) {
      this.label.classList.add('show');
    }
  },

  methods: {
    handleChange(id) {
      this.$refs.inputUpload.click();

      this.inputUpload = document.querySelector(`#${id}`);
      this.preview = document.querySelector(`#${id}-preview`);
      this.label = document.querySelector(`#${this.id}-label`);

      if (this.inputUpload && this.inputUpload.files[0]) {

        if (this.inputUpload.files[0].size > 10000000) {
          this.showError = true;
          if (!this.isUpdate) {
            this.label.classList.remove('show');
            this.$emit('update:modelValue', '');
          }
          this.$emit('handleFileError', true);
        } else {
          this.label.classList.add('show');
          this.reader.onload = () => {
            this.$emit('update:filePreview', this.reader.result);
          }
          this.file = this.inputUpload.files[0];
          this.$emit('update:modelValue', this.file);
          this.reader.readAsDataURL(this.inputUpload.files[0]);
          this.showError = false;
          this.$emit('handleFileError', false);
        }

      }
    },

    handleReset(e) {
      e.preventDefault();
      this.inputUpload = document.querySelector(`#${this.id}`);

      this.showError = false;
      this.inputUpload.value = '';
      this.label.classList.remove('show');
      this.$emit('update:modelValue', this.inputUpload.files[0]);
      this.$emit('update:filePreview', '');
      if (!this.isUpdate) {
        this.deleteLogoGC();
      } else {
        this.$emit('update:isLogoDeleted', true);
      }
    },

    handleErrorClose() {
      this.showError = false;
      this.inputUpload.value = '';
    },
  },

}
</script>

<style lang="scss">
.input-uploader {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 12px;

  &.frozen {
    pointer-events: none;
  }

  &--label {
    width: 179px;
    height: 216px;
    display: block;
    border-radius: 50%;
    position: relative;
    margin-bottom: 14px;
    transition: .3s;

    &--svg {
      margin-bottom: 6px;
      cursor: pointer;

      circle {
        stroke-dashoffset: 0;
        stroke: #9999A0;
      }

      &:hover {
        circle {
          stroke: #4B617A;
        }
      }

      .show & {
        circle {
          stroke-dasharray: 560;
          animation: load .6s linear;
          stroke: transparent;
        }
      }
    }

    &--input {
      display: none;
    }

    &--preview {
      display: none;
      width: 176px;
      height: 176px;
      object-fit: cover;
      position: absolute;
      top: 2px;
      left: 2px;
      border-radius: 50%;

      .show & {
        display: block;
      }
    }

    &--reset {
      display: none;
      width: 48px;
      height: 48px;
      position: absolute;
      top: 0;
      right: 0;
      border-radius: 50%;
      cursor: pointer;

      circle {
        transition: .3s;
      }

      &:hover, &:active, &:focus {
        circle {
          fill: #F34E68;
        }
      }

      .show & {
        display: block;
      }
    }

    &--description {
      color: #151522;
      text-align: center;
    }
  }

  &--button {
    padding: 8px 29px;
  }
}

@media screen and (max-width: 375px) and (max-height: 811px),
(max-height: 811px) {
  .input-uploader {
    &--label {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 155px;

      &--svg {
        width: 110px;
        height: 110px;
      }

      &--preview {
        width: 108px;
        height: 108px;
        top: 1px;
        left: 50%;
        transform: translateX(-50%);
      }

      &--reset {
        width: 40px;
        height: 40px;
        right: 24px;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .input-uploader {
    flex-direction: row;
    padding-top: 2px;

    &--label {
      margin: 0 24px 0 0;

      &--svg {
        margin-bottom: 12px;
      }
    }

    &--button {
      margin-bottom: 38px;
    }
  }
}
</style>
