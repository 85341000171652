<template>
  <div
    class="text-area"
    :style="{ marginBottom: marginBottom + 'px' }"
  >

    <label
      class="text-area--label"
      :for="id"
    >
      {{ label }}
      <span
        v-if="optional"
        class="h6-light"
      >(opt.)</span>
    </label>

    <textarea
      :class="['text-area--text-area',
        {'err': this.errorMessage}
      ]"
      @input="handleChange"
      :name="name"
      :id="id"
      :value="modelValue"
      :placeholder="placeholder ? placeholder : setPlaceholder"
      :disabled="disabled"
      :minlength="minLength"
    >
    </textarea>

    <p v-if="errorMessage" class="text-area--error">
      {{ errorMessage }}
    </p>

  </div>
</template>

<script>
export default {

  props: {
    modelValue: {
      type: [String, Number],
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    marginBottom: {
      type: [String, Number],
      default: 18
    },
    minLength: {
      type: Number,
      default: 5
    },
    errorMessage: {
      type: String,
      default: 'Error Notification'
    },
    optional: {
      type: Boolean,
      default: false
    },
  },

  computed: {
    setPlaceholder() {
      return `Enter ${this.label.toLowerCase().split('*').join('')}`
    },
  },

  methods: {
    handleChange(e) {
      this.$emit('update:modelValue', e.target.value)
    },
  },

}
</script>

<style lang="scss">
.text-area {
  display: flex;
  flex-direction: column;
  max-width: 325px;
  width: 100%;
  margin: 0 auto;
  position: relative;

  &--label {
    font-size: 16px;
    font-weight: 600;
    color: #151522;
    margin: 0 0 13px 0;
    text-align: left;
    font-family: $SFProText;
    line-height: 150%;
  }

  &--text-area {
    color: #151522;
    border: 1px solid #CCCCD7;
    box-sizing: border-box;
    font-size: 16px;
    border-radius: 5px;
    line-height: 150%;
    padding: 14px 20px 13px;
    width: 100%;
    height: 140px;
    font-family: $SFProText;
    font-weight: 300;
    box-shadow: none;
    transition: box-shadow .3s ease;
    resize: none;

    &.err {
      border: 1px solid #E83C56;
      color: #E83C56;

      &::placeholder {
        color: #E83C56;
      }
    }

    &:hover, &:focus {
      box-shadow: 0px 4px 8px rgba(21, 21, 34, 0.06);
      cursor: pointer;
    }

    &::placeholder {
      color: #B3B3BE;
    }

    &[disabled] {
      background: #B3B3BE;
      border: 1px solid #B3B3BE;
      color: #666670;

      &::placeholder {
        color: #666670;
      }

      &:hover {
        cursor: not-allowed;
        box-shadow: none;
      }
    }
  }

  &--error {
    font-size: 12px;
    color: #E83C56;
    text-align: right;
    font-family: $SFProText;
    font-weight: 500;
    line-height: 150%;
    position: absolute;
    bottom: -22px;
    right: 0;
  }
}

@media screen and (min-width: 1024px) {
  .text-area {
    max-width: 100%;

    &--text-area {
      font-size: 14px;
    }
  }
}
</style>
