<template>
<div class="roles-info">
  <div class="roles-info--header">
    <p class="roles-info--header--item h6-regular">Actions</p>
    <p class="roles-info--header--item h6-regular">Admin</p>
    <p class="roles-info--header--item h6-regular">Editor</p>
    <p class="roles-info--header--item h6-regular">Viewer</p>
  </div>
  <div class="roles-info--row">
    <p class="roles-info--row--item label">Invite users</p>
    <inline-svg
      :src="require(`@/assets/images/icons/check.svg`)"
      class="roles-info--row--item--icon"
    />
    <inline-svg
      :src="require(`@/assets/images/icons/cross-2.svg`)"
      class="roles-info--row--item--icon"
    />
    <inline-svg
      :src="require(`@/assets/images/icons/cross-2.svg`)"
      class="roles-info--row--item--icon"
    />
  </div>
  <div class="roles-info--row">
    <p class="roles-info--row--item label">Manage users</p>
    <inline-svg
      :src="require(`@/assets/images/icons/check.svg`)"
      class="roles-info--row--item--icon"
    />
    <inline-svg
      :src="require(`@/assets/images/icons/cross-2.svg`)"
      class="roles-info--row--item--icon"
    />
    <inline-svg
      :src="require(`@/assets/images/icons/cross-2.svg`)"
      class="roles-info--row--item--icon"
    />
  </div>
  <div
    v-if="getUserRole === 'contractor'"
    class="roles-info--row"
  >
    <p class="roles-info--row--item label">Invite customers</p>
    <inline-svg
      :src="require(`@/assets/images/icons/check.svg`)"
      class="roles-info--row--item--icon"
    />
    <inline-svg
      :src="require(`@/assets/images/icons/check.svg`)"
      class="roles-info--row--item--icon"
    />
    <inline-svg
      :src="require(`@/assets/images/icons/cross-2.svg`)"
      class="roles-info--row--item--icon"
    />
  </div>
  <div
    v-if="getUserRole === 'contractor'"
    class="roles-info--row"
  >
    <p class="roles-info--row--item label">
      Edit<br v-if="isMobile"> project ID</p>
    <inline-svg
      :src="require(`@/assets/images/icons/check.svg`)"
      class="roles-info--row--item--icon"
    />
    <inline-svg
      :src="require(`@/assets/images/icons/check.svg`)"
      class="roles-info--row--item--icon"
    />
    <inline-svg
      :src="require(`@/assets/images/icons/cross-2.svg`)"
      class="roles-info--row--item--icon"
    />
  </div>
  <div
    v-else
    class="roles-info--row"
  >
    <p class="roles-info--row--item label">Request quotes</p>
    <inline-svg
      :src="require(`@/assets/images/icons/check.svg`)"
      class="roles-info--row--item--icon"
    />
    <inline-svg
      :src="require(`@/assets/images/icons/check.svg`)"
      class="roles-info--row--item--icon"
    />
    <inline-svg
      :src="require(`@/assets/images/icons/cross-2.svg`)"
      class="roles-info--row--item--icon"
    />
  </div>
  <div class="roles-info--row">
    <p class="roles-info--row--item label">Update project status</p>
    <inline-svg
      :src="require(`@/assets/images/icons/check.svg`)"
      class="roles-info--row--item--icon"
    />
    <inline-svg
      :src="require(`@/assets/images/icons/check.svg`)"
      class="roles-info--row--item--icon"
    />
    <inline-svg
      :src="require(`@/assets/images/icons/cross-2.svg`)"
      class="roles-info--row--item--icon"
    />
  </div>
  <div
    v-if="getUserRole === 'contractor'"
    class="roles-info--row"
  >
    <p class="roles-info--row--item label">Add new project status</p>
    <inline-svg
      :src="require(`@/assets/images/icons/check.svg`)"
      class="roles-info--row--item--icon"
    />
    <inline-svg
      :src="require(`@/assets/images/icons/check.svg`)"
      class="roles-info--row--item--icon"
    />
    <inline-svg
      :src="require(`@/assets/images/icons/cross-2.svg`)"
      class="roles-info--row--item--icon"
    />
  </div>
  <div class="roles-info--row">
    <p class="roles-info--row--item label">Update project details</p>
    <inline-svg
      :src="require(`@/assets/images/icons/check.svg`)"
      class="roles-info--row--item--icon"
    />
    <inline-svg
      :src="require(`@/assets/images/icons/check.svg`)"
      class="roles-info--row--item--icon"
    />
    <inline-svg
      :src="require(`@/assets/images/icons/cross-2.svg`)"
      class="roles-info--row--item--icon"
    />
  </div>
  <div
    v-if="getUserRole === 'contractor'"
    class="roles-info--row"
  >
    <p class="roles-info--row--item label">Update payment progress</p>
    <inline-svg
      :src="require(`@/assets/images/icons/check.svg`)"
      class="roles-info--row--item--icon"
    />
    <inline-svg
      :src="require(`@/assets/images/icons/check.svg`)"
      class="roles-info--row--item--icon"
    />
    <inline-svg
      :src="require(`@/assets/images/icons/cross-2.svg`)"
      class="roles-info--row--item--icon"
    />
  </div>
  <div class="roles-info--row">
    <p class="roles-info--row--item label">Change company information</p>
    <inline-svg
      :src="require(`@/assets/images/icons/check.svg`)"
      class="roles-info--row--item--icon"
    />
    <inline-svg
      :src="require(`@/assets/images/icons/cross-2.svg`)"
      class="roles-info--row--item--icon"
    />
    <inline-svg
      :src="require(`@/assets/images/icons/cross-2.svg`)"
      class="roles-info--row--item--icon"
    />
  </div>
  <div
    v-if="getUserRole === 'contractor'"
    class="roles-info--row"
  >
    <p class="roles-info--row--item label">Archive projects</p>
    <inline-svg
      :src="require(`@/assets/images/icons/check.svg`)"
      class="roles-info--row--item--icon"
    />
    <inline-svg
      :src="require(`@/assets/images/icons/cross-2.svg`)"
      class="roles-info--row--item--icon"
    />
    <inline-svg
      :src="require(`@/assets/images/icons/cross-2.svg`)"
      class="roles-info--row--item--icon"
    />
  </div>
  <div class="roles-info--row">
    <p class="roles-info--row--item label">Write in activity, add attachment</p>
    <inline-svg
      :src="require(`@/assets/images/icons/check.svg`)"
      class="roles-info--row--item--icon"
    />
    <inline-svg
      :src="require(`@/assets/images/icons/check.svg`)"
      class="roles-info--row--item--icon"
    />
    <inline-svg
      :src="require(`@/assets/images/icons/check.svg`)"
      class="roles-info--row--item--icon"
    />
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex';

import InlineSvg from 'vue-inline-svg';

import dashboardHelpers from '@/mixins/dashboardHelpers';

export default {
  mixins: [dashboardHelpers],

  components: {
    InlineSvg
  },

  props: [
    'isMobile'
  ],

  computed: {
    ...mapGetters([
      'getUserCompany',
      'getCurrentUser'
    ])
  }
}
</script>

<style lang="scss" scoped>
.roles-info {
  overflow: hidden;
  
  &--header {
    border-top: 1px solid rgba(92, 92, 102, 0.4);
    border-bottom: 1px solid rgba(92, 92, 102, 0.4);
    display: flex;

    &--item {
      color: #151522;
      padding: 22px 20px 19px;
      box-sizing: border-box;
      max-width: 77px;
      width: 100%;

      &:first-child {
        box-shadow: 120px 0px 80px rgba(21, 21, 34, 0.04);
        max-width: 140px;
        width: 100%;
      }
    }
  }

  &--row {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(92, 92, 102, 0.4);

    &--item {
      color: #151522;
      padding: 20px;
      box-sizing: border-box;
      width: 100%;

      &:first-child {
        max-width: 140px;
        box-shadow: 120px 0px 80px rgba(21, 21, 34, 0.04);
      }

      &--icon {
        max-width: 77px;
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .roles-info {
    &--header--item,
    &--row--item {
      padding: 20px 10px;

      &:first-child {
        min-width: 110px;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .roles-info {
    border: 1px solid rgba(92, 92, 102, 0.4);
    box-sizing: border-box;
    border-radius: 8px;

    &--header {
      border: none;

      &--item {
        max-width: 85px;
        margin-right: 10vw;

        &:first-child {
          box-shadow: none;
          max-width: 100%;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &--row {
      border-top: 1px solid rgba(92, 92, 102, 0.4);
      border-bottom: none;

      &--item {
        margin-right: 5vw;

        &:first-child {
          box-shadow: none;
          max-width: 100%;
        }

        &--icon {
          margin-right: 10vw;
          max-width: 85px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
</style>
