<template>
  <div class="customer-quote">

    <InviteKeyAlreadyUsed
      v-if="isInviteKeyAlreadyUsed"
    />

    <custom-sidebar
      v-if="currentStep !== 7 && !isInviteKeyAlreadyUsed"
    />

    <div
      v-if="!isInviteKeyAlreadyUsed"
      class="customer-quote--body"
      :class="[{'congrats': currentStep === 7},
      {'prompt-apps': currentStep === 8}]"
    >

      <div class="customer-quote--body--wrapper">

        <CustomHeaderWithContractor
          v-if="getContractorCompanyMainInfo && currentStep !== 7 && currentStep !== 8"
          :companyLogo="getContractorCompanyMainInfo.contractor_logo"
          :companyName="getContractorCompanyMainInfo.contractor_name"
        />

        <div
          v-if="currentStep !== 0 && currentStep !== 7"
          class="customer-quote--body--header"
        >
          <inline-svg
            v-if="currentStep !== 0 && currentStep !== 8"
            :src="require('@/assets/images/icons/arrow-left.svg')"
            class="customer-quote--body--header--link"
            @click="previousStep"
          />
          <h3 class="customer-quote--body--header--title">{{ stepTitle }}</h3>
        </div>

        <h2
          v-if="currentStep !== 0 && currentStep !== 7 && currentStep !== 8"
          class="customer-quote--body--title h2-sb"
        >
          {{ stepSubTitle }}
          <span
            v-if="currentStep === 4 || currentStep === 5"
            class="h3-light"
          >(opt.)</span>
        </h2>

        <RequestQuote
          v-if="currentStep === 0"
          :quote="quote"
          :handleQuoteChange="handleQuoteChange"
          :contractorCompany="getContractorCompanyMainInfo"
        />

        <div
          class="customer-quote--body--step-wrapper"
          :class="[{'summary': currentStep === 6},
          {'upload': currentStep === 4 || currentStep === 5}]"
          :style="setStylesForStepWrapper"
        >

          <BasicInfo
            v-if="currentStep === 1"
            :projectName="projectName"
            :contactName="contactName"
            :contactEmail="contactEmail"
            :startDate="startDate"
            :projectType="projectType"
            :projectTypeOptions="projectTypeOptions"
            :handleProjectNameChange="handleProjectNameChange"
            :handleContactNameChange="handleContactNameChange"
            :handleContactEmailChange="handleContactEmailChange"
            :handleStartDateChange="handleStartDateChange"
            :handleProjectTypeChange="handleProjectTypeChange"
            :projectNameError="projectNameError"
            :contactNameError="contactNameError"
            :contactEmailError="contactEmailError"
            :startDateError="startDateError"
            :projectTypeError="projectTypeError"
          />

          <Requirements
            v-if="currentStep === 2"
            :timeRequirements="timeRequirements"
            :blackoutDays="blackoutDays"
            :specialRequirements="specialRequirements"
            :handleTimeRequirementsChange="handleTimeRequirementsChange"
            :handleBlackoutDaysChange="handleBlackoutDaysChange"
            :handleSpecialRequirementsChange="handleSpecialRequirementsChange"
          />

          <SiteInfo
            v-if="currentStep === 3"
            :location="location"
            :safetyRequirements="safetyRequirements"
            :siteVisit="siteVisit"
            :siteVisitOptions="siteVisitOptions"
            :accessibility="accessibility"
            :locationError="locationError"
            :siteVisitError="siteVisitError"
            :handleLocationChange="handleLocationChange"
            :handleSafetyRequirementsChange="handleSafetyRequirementsChange"
            :handleSiteVisitChange="handleSiteVisitChange"
            :handleAccessibilityChange="handleAccessibilityChange"
          />

          <Summary
            v-if="currentStep === 6"
            :projectName="projectName"
            :contactName="contactName"
            :contactEmail="contactEmail"
            :startDate="startDate"
            :projectType="projectType"
            :timeRequirements="timeRequirements"
            :blackoutDays="blackoutDays"
            :specialRequirements="specialRequirements"
            :location="location"
            :safetyRequirements="safetyRequirements"
            :siteVisit="siteVisit"
            :siteVisitOptions="siteVisitOptions"
            :accessibility="accessibility"
            :photos="photos"
            :photosPreview="photosPreview"
            :files="files"
            :filesPreview="filesPreview"
            :handleEditStep="handleEditStep"
          />

        </div>

        <CustomPhotosUploader
          :class="{'hidden': currentStep !== 4}"
          :photos="photos"
          @update:photos="handleUploadPhotosChange"
          :photosPreview="photosPreview"
          @update:photosPreview="handleUploadPhotosPreviewChange"
          :photosIds="photosIds"
          :deleteFile="deleteFile"
          unregisteredUser
        />

        <CustomFilesUploader
          :class="{'hidden': currentStep !== 5}"
          :modelValue="files"
          @update:modelValue="handleUploadFilesChange"
          :filesPreview="filesPreview"
          @update:filesPreview="handleUploadFilesPreviewChange"
          :filesIds="filesIds"
          :deleteFile="deleteFile"
          unregisteredUser
        />

        <CustomCongrats
          v-if="currentStep === 7"
          description="Your quote was successfully sent"
          maxWidth="325"
        />

        <PromtApps
          v-if="currentStep === 8"
        />

        <div
          v-if="currentStep === 7"
          class="customer-quote--body--buttons-wrapper"
        >
          <p class="customer-quote--body--buttons-wrapper--text p-medium">Would you like to track the progress?</p>
          <button
            class="customer-quote--body--buttons-wrapper--button-top normal-btn"
            @click="handleCreateAccount"
          >
            Yes, create an account
          </button>
          <button
            class="customer-quote--body--buttons-wrapper--button-bottom ghost-btn"
            @click="nextStep"
          >
            No, thank you
          </button>
        </div>

        <button
          v-if="currentStep !== 7"
          class="customer-quote--body--button"
          :class="[{'disabled': isButtonDisabled},
                  {'normal-btn': currentStep !== 7 && currentStep !== 8},
                  {'ghost-btn': currentStep === 8}]"
          @click="nextStep"
        >
          {{ nextButtonTitle }}
        </button>
        <button
          v-if="currentStep !== 0 && currentStep !== 7 && currentStep !== 8"
          class="customer-quote--body--button-back ghost-btn"
          @click="previousStep"
        >
          Back
        </button>

        <p class="customer-quote--body--copyright caption-3">{{getContractorCompanyMainInfo ? getContractorCompanyMainInfo.contractor_name : ''}}</p>

      </div>

      <custom-error
        v-if="showError"
        :errorMessage="errorMessage"
        :handleErrorClose="handleErrorClose"
        :errorStyles="errorStyles()"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { validationUtils } from '@/utils/validation';

import commonHelpers from '@/mixins/commonHelpers';
import quoteHelpers from '@/mixins/quoteHelpers';

import InlineSvg from 'vue-inline-svg';
import CustomSidebar from '@/components/shared/CustomSidebar';
import CustomHeaderWithContractor from '@/components/shared/CustomHeaderWithContractor';
import RequestQuote from '@/components/quote-flow/RequestQuote';
import BasicInfo from '@/components/quote-flow/BasicInfo';
import Requirements from '@/components/quote-flow/Requirements';
import SiteInfo from '@/components/quote-flow/SiteInfo';
import CustomPhotosUploader from '@/components/shared/CustomPhotosUploader';
import CustomFilesUploader from '@/components/shared/CustomFilesUploader';
import Summary from '@/components/quote-flow/Summary';
import CustomCongrats from '@/components/shared/CustomCongrats';
import PromtApps from '@/components/quote-flow/PromtApps';
import InviteKeyAlreadyUsed from '@/components/quote-flow/InviteKeyAlreadyUsed';
import CustomError from '@/components/shared/CustomError';

export default {

  name: 'Customer quote',

  mixins: [commonHelpers,quoteHelpers],

  components: {
    InlineSvg,
    CustomSidebar,
    CustomHeaderWithContractor,
    RequestQuote,
    BasicInfo,
    Requirements,
    SiteInfo,
    CustomPhotosUploader,
    CustomFilesUploader,
    Summary,
    CustomCongrats,
    CustomError,
    PromtApps,
    InviteKeyAlreadyUsed
  },

  data: () => ({
    currentStep: 0,
    stepEdit: false,
    isInviteKeyAlreadyUsed: false,

    //First step values
    quote: 'Without account',

    //Second step values
    projectName: '',
    contactName: '',
    contactEmail: '',
    startDate: '',
    projectType: null,
    projectTypeOptions: [
      {
        value: false,
        text: 'New сonstruction',
      },
      {
        value: true,
        text: 'Existing сonstruction',
      },
    ],
    projectNameError: null,
    contactNameError: null,
    contactEmailError: null,
    startDateError: null,
    projectTypeError: null,

    //Third step values
    timeRequirements: '',
    blackoutDays: '',
    specialRequirements: '',

    //Fourth step values
    location: '',
    safetyRequirements: '',
    locationError: '',
    siteVisit: null,
    siteVisitOptions: [
      {
        value: true,
        text: 'Yes',
      },
      {
        value: false,
        text: 'No',
      },
    ],
    siteVisitError: null,
    accessibility: '',

    //Fifth step values
    photos: [],
    photosPreview: [],
    photosIds: [],

    //Sixth step values
    files: [],
    filesPreview: [],
    filesIds: [],

    windowWidth: window.innerWidth,
    isMobile: (window.innerWidth < 1024),
    errorMessage: 'Something went wrong. Please reload the page',
    showError: false,
    isContractorCompany: false,
    projectId: null,
    gcloudUrl: null,
    signUpLink: '',

    // eight step value
    createAccount: false
  }),

  created() {
    this.getCompanyInfo();
  },

  mounted() {
    window.addEventListener('resize', this.getWindowWidth);
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.getWindowWidth);
  },

  computed: {
    ...mapGetters([
      'getContractorCompanyMainInfo'
    ]),

    setStylesForStepWrapper() {
      let marginBottom;
      if (this.currentStep == 2 || this.currentStep == 3) {
        marginBottom = 'margin-bottom: 60px;'
      } else if (this.currentStep == 1 || this.currentStep == 6) {
        marginBottom = 'margin-bottom: 48px;';
      }

      return marginBottom;
    },

    getEmailErrorMessage() {
      return validationUtils.isEmailValid(this.contactEmail);
    },

    stepTitle() {
      switch(this.currentStep) {
        case 0:
          return '';
        case 1:
          return 'Basic info';
        case 2:
          return 'Requirements';
        case 3:
          return 'Site';
        case 4:
          return 'Photos';
        case 5:
          return 'Files';
        case 6:
          return 'Summary';
        case 8:
          return 'For the best experience, download the SCOPE app.';
        default:
          return '';
      }
    },

    stepSubTitle() {
      switch(this.currentStep) {
        case 0:
          return '';
        case 1:
          return 'Let’s add the basic info';
        case 2:
          return 'Add requirements';
        case 3:
          return 'Add site information';
        case 4:
          return 'Add photos';
        case 5:
          return 'Add files';
        case 6:
          return 'Check all information';
        default:
          return '';
      }
    },

    nextButtonTitle() {
      if (this.stepEdit) {
        return 'Update';
      } else if (this.currentStep === 6) {
        return 'Submit';
      } else if (this.currentStep === 8) {
        if (this.createAccount) {
          return 'Go to Sign Up';
        } else {
          return 'Close';
        }
      } else {
        return 'Next';
      }
    },

    isButtonDisabled() {
      switch(this.currentStep) {
        case 0:
          return (!this.quote
           || !this.isContractorCompany);
        case 1:
          return (!this.projectName
            || !this.contactName
            || !this.contactEmail
            || !this.startDate
            || this.projectType === null);
        case 2:
          return false;
        case 3:
          return (!this.location
            || this.siteVisit === null)
        default:
          return false;
      }
    },
  },

  methods: {
    ...mapActions([
      'getContractorCompany',
      'companyProject'
    ]),

    formValidation() {
      let hasError;
      switch(this.currentStep) {
        case 0: {
          return true;
        }
        case 1: {
          const contactEmailErrorMessage = this.getEmailErrorMessage;

          if (contactEmailErrorMessage) {
            this.contactEmailError = contactEmailErrorMessage;
            hasError = true;
          }

          return !hasError;
        }
        default:
          return true;
      }
    },

    nextStep() {
      const isValid = this.formValidation();
      if (!isValid) return;

      if (this.currentStep === 8) {
        if (this.createAccount) {
          this.$router.push({
            name: 'SignUp',
            params: {inviteUser: true},
            query: { invite_key: this.$router.currentRoute._value.query.invite_key }
          });
        } else {
          window.open('https://finishingcontractors.org',"_self");
          return
        }
      }

      if (this.currentStep === 6) {
        this.submitForm();
      } else {
        if (this.currentStep === 0 && this.quote === 'Using my account') {
          this.$router.push({
            name: 'SignUp',
            query: { invite_key: this.$router.currentRoute._value.query.invite_key },
            params: { inviteUser: true }})
        } else {
          this.currentStep++
        }
      }

      if (this.stepEdit) {
        this.currentStep = 6;
        this.stepEdit = false;
      }

      window.scrollTo(0,0);
    },

    previousStep() {
      if (this.stepEdit) {
        this.currentStep = 6;
        this.stepEdit = false;
      } else {
        this.currentStep--;
      }
    },

    handleQuoteChange(value) {
      this.quote = value;
    },

    handleUploadPhotosChange(value) {
      this.photos = value;
      if (value) {
        setTimeout(() => {
          this.uploadFiles(this.photos, "photo");
        }, 500);
      }
    },

    handleUploadFilesChange(value) {
      this.files = value;
      if (value) {
        setTimeout(() => {
          this.uploadFiles(this.files, "file");
        }, 500);
      }
    },

    handleEditStep(value) {
      this.currentStep = Number(value);
      this.stepEdit = true;
    },

    handleCreateAccount() {
      this.createAccount = true;
      this.nextStep();
    },

    errorStyles() {
      if(!this.isMobile) {
        let sidebarElement = document.querySelector(".sidebar");
        let sidebarWidth = sidebarElement.offsetWidth;
        let mainPartWidth = this.windowWidth - sidebarWidth;
        let percentPosition = mainPartWidth*100/this.windowWidth/2;
        return `position: fixed; left: calc(${sidebarWidth}px + ${percentPosition}%);`;
      } else {
        return "position: fixed; left: 50%;";
      }
    },

    async getCompanyInfo() {
      const inviteKey = this.$router.currentRoute._value.query.invite_key;

      try {
        await this.getContractorCompany(inviteKey);
        this.isContractorCompany = true;
      } catch (error) {
        console.error('Customer Quote', error);
        if (error) {
          if (error.response.data.message === 'Sorry! Somebody has already been requested a quote by this invite.') {
            this.isInviteKeyAlreadyUsed = true;
          }
          if (error.response && error.response.status === 404) {
            this.errorMessage = 'Error occurred. Link is wrong or expired. Please, contact a contractor for assistance.';
          }
          this.showError = true;
        }
      }
    },
  }
}
</script>

<style lang="scss">
.customer-quote {
  display: flex;
  justify-content: center;

  &--body {
    display: flex;
    align-items: flex-start;
    padding: 52px 25px 36px;
    max-width: 375px;
    width: 100%;
    box-sizing: border-box;
    position: relative;

    &.congrats {
      padding: 40px 25px;
      height: 100vh;
      justify-content: flex-end;
      margin: auto;

      .congrats {
        margin-bottom: 136px;
      }
    }

    &.prompt-apps {
      align-items: center;
      min-height: 100vh;
    }

    &--wrapper {
      width: 100%;
    }

    &--header {
      margin-bottom: 31px;
      position: relative;

      &--link {
        width: 100%;
        max-width: 8px;
        height: 14px;
        padding: 7px 20px 7px 0;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
      }

      &--title {
        text-align: center;
        width: 100%;
        color: #151522;
        font-size: 20px;
        font-weight: 600;
        font-family: $SFProDisplay;
        line-height: 150%;

        .prompt-apps & {
          font-size: 28px;
        }
      }
    }

    &--title {
      color: #151522;
      text-align: left;
      margin-bottom: 38px;
      white-space: pre-wrap;
    }

    &--step-wrapper {
      box-sizing: border-box;

      &.upload {
        margin-bottom: 2px;
      }
    }

    &--button {
      padding: 13px 0;
      display: inline-block;
      width: 100%;
      max-width: 325px;
      margin-bottom: 49px;
    }

    &--button-back {
      display: none;
    }

    &--copyright {
      color: #9999A0;
      text-align: center;
      width: 100%;
    }

    &--buttons-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 325px;
      margin-bottom: 20px;

      &--text {
        font-weight: 400;
        color: #151522;
        text-align: center;
        margin-bottom: 20px;
      }

      &--button-top,
      &--button-bottom {
        padding: 13px 0;
        display: inline-block;
        width: 100%;
      }

      &--button-top {
        margin-bottom: 16px;
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .customer-quote {
    &--body {
      padding: 40px 15px 7px 15px;
    }
  }
}

@media screen and (max-width: 375px) and (max-height: 811px),
(max-height: 811px) {
  .customer-quote {
    &--body {
      &.congrats {
        .congrats {
          margin-bottom: 25px;
        }
      }

      &.prompt-apps {
        align-items: flex-end;
      }

      &--button,
      &--copyright {
        position: relative;
        left: unset;
        bottom: unset;
        transform: none;
        width: 100%;
      }

      &--button {
        margin-bottom: 18px;
        margin-top: 30px;
      }

      &--copyright {
        margin-bottom: 36px;
        max-width: 325px;
        display: inline-block;

        .congrats & {
          text-align: center;
          position: absolute;
          bottom: 36px;
          left: 0;
          max-width: 100%;
          margin-bottom: 0;
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .customer-quote {
    height: 100vh;
    position: relative;

    &--body {
      display: flex;
      justify-content: center;
      overflow-y: overlay;
      overflow-x: hidden;
      max-width: 100%;
      padding: 160px 6px 80px 0;
      margin-right: 2px;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: #5C5C66;
        border-radius: 10px;
        height: 44px;
      }

      &.congrats {
        justify-content: center;
        align-items: center;

        .congrats {
          transform: none;
          position: relative;
          top: unset;
          margin-bottom: 0px;
        }
      }

      &.prompt-apps {
        align-items: center;
        min-height: unset;
      }

      &--wrapper {
        max-width: 397px;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }

      &--step-wrapper {
        margin-bottom: 60px;

        .prompt-apps & {
          margin-bottom: 9px;
        }
      }

      &--button {
        width: 240px;
        position: relative;
        left: unset;
        bottom: unset;
        transform: none;
        margin-bottom: 20px;
      }

      &--button-back {
        width: 240px;
        padding: 13px 0;
        display: inline-block;
      }

      &--header,
      &--copyright {
        display: none;
      }

      &--header {
        .prompt-apps & {
          margin-bottom: 22px;
          display: block;
        }
      }

      &--header--title {
        .prompt-apps & {
          text-align: left;
          margin-bottom: 0;
        }
      }

      &--buttons-wrapper {
        position: relative;
        bottom: unset;
        left: unset;
        transform: none;
        margin: 0 auto;

        &--button-top,
        &--button-bottom {
          max-width: 240px;
          width: 100%;
        }
      }
    }
  }
}
</style>
