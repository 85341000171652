<template>
  <input
    @input="handleChange"
    :value="value"
    :id="id"
    :checked="value"
    class="custom-checkbox"
    type="checkbox"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    id: {
      type: String,
      default: null,
    },
  },

  methods: {
    handleChange(e) {
      const { checked } = e.target;
      this.$emit('update:modelValue', checked);
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-checkbox {
  appearance: none;
  width: 24px;
  height: 24px;
  border: 1px solid #CCCCD7;
  box-shadow: 0px 10px 32px rgba(61, 92, 109, 0.08);
  border-radius: 4px;
  outline: 0;
  box-sizing: border-box;
  flex: none;
  margin-right: 10px;
  flex: none;
  cursor: pointer;

  &:checked {
    background: url(~@/assets/images/icons/check-icon.svg) no-repeat;
    background-size: contain;
    border: 1px solid #4B617A;
    background-color: #4B617A;
  }
}
</style>
