<template>
  <div class="modal-wrapper">
    <div class="modal-wrapper--modal">
      <h6 class="modal-wrapper--modal--message h6-sb"
        :style="messageMargin ? `margin-bottom: ${messageMargin}px` : ''"
      >
        {{ message }}
      </h6>
      <p
        v-if="notice"
        class="modal-wrapper--modal--notice text-tiny"
      >
        {{ notice }}
      </p>
      <div class="modal-wrapper--modal--controls">
        <p
          class="modal-wrapper--modal--controls--button btn-font-large"
          @click="handleCancel"
        >{{ cancelText }}</p>
        <p
          class="modal-wrapper--modal--controls--button btn-font-large"
          @click="handleConfirm"
        >{{ confirmText }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: ''
    },
    notice: {
      type: String,
      default: ''
    },
    handleCancel: {
      type: Function,
      default: () => {}
    },
    handleConfirm: {
      type: Function,
      default: () => {}
    },
    confirmText: {
      type: String,
      default: 'Yes'
    },
    cancelText: {
      type: String,
      default: 'No'
    },
    messageMargin: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(21,21,34,0.5);
  width: 100%;
  height: 100%;
  z-index: 4;

  &--modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background: #fff;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    width: 230px;

    &--message {
      color: #151522;
      text-align: center;
      margin-bottom: 4px;
      overflow-wrap: anywhere;
      padding: 0 10px;
    }

    &--notice {
      color: #617B97;
      text-align: center;
      margin-bottom: 14px;
    }

    &--controls {
      border-top: 1px solid rgba(92, 92, 102, 0.16);
      display: flex;

      &--button {
        width: 50%;
        color: #E83C56;
        text-align: center;
        padding: 8px 0;
        cursor: pointer;

        &:first-child {
          color: #4B617A;
          border-right: 1px solid rgba(92, 92, 102, 0.16);
        }
      }
    }
  }
}
</style>
