<template>
  <div class="sign-in">

    <custom-sidebar
      :authPage="true"
    />

    <div class="sign-in--body">
      <div class="sign-in--body--header">
        <div class="sign-in--body--header--logo logo-shadow"></div>
        <h3 class="sign-in--body--header--title h3-sb">SCOPE</h3>
      </div>
      <h2 class="sign-in--body--title h2-sb">Sign in</h2>
      <h5 class="sign-in--body--description h5-light">Enter your email and password for signing in.</h5>
      <custom-input
        :modelValue="email"
        @update:modelValue="handleEmailChange"
        label="Email"
        id="email"
        type="email"
        name="email"
        :errorMessage="emailError"
      />
      <custom-input
        :modelValue="password"
        @update:modelValue="handlePasswordChange"
        label="Password"
        id="password"
        type="password"
        name="password"
        marginBottom="14"
        :errorMessage="passwordError"
        icon="secured-with-show-option"
      />

      <div class="sign-in--body--help">
        <router-link
          to="/forgot-password"
          class="sign-in--body--help--link text-btn"
        >
          Forgot password?
        </router-link>
      </div>

      <button
        class="sign-in--body--button normal-btn"
        :class="(!email || !password) && 'disabled'"
        @click="handleLogin"
      >
        Sign in
      </button>

      <p class="sign-in--body--register btn-font-large">No account?
        <router-link
          class="sign-in--body--register--link text-btn"
          to="/sign-up"
        >
          Create one
        </router-link>
      </p>
      <div class="sign-in--body--copyright caption-3">
        <a
          class="sign-in--body--copyright--link"
          href="https://finishingcontractors.org/"
          target="_blank"
        >Powered by © {{ currentYear }} FCA International</a>
        <a
          class="sign-in--body--copyright--link"
          href="https://finishingcontractors.org/contact/"
          target="_blank"
        >Contact us</a>
        <a
          class="sign-in--body--copyright--link"
          href="https://finishingcontractors.org/scope-app-terms-and-conditions"
          target="_blank"
        >Terms and Conditions</a>
      </div>

      <custom-error
        v-if="showError"
        :errorMessage="errorMessage"
        :handleErrorClose="handleErrorClose"
      />
    </div>

  </div>
</template>

<script>
import { mapActions } from 'vuex';

import { validationUtils } from '@/utils/validation';

import CustomSidebar from '@/components/shared/CustomSidebar';
import CustomInput from '@/components/shared/CustomInput';
import CustomError from '@/components/shared/CustomError';

export default {

  name: 'Sign in',

  components: {
    CustomSidebar,
    CustomInput,
    CustomError
  },

  data: () => ({
    email: '',
    password: '',
    emailError: null,
    passwordError: null,
    disabledButton: true,
    errorMessage: 'Something went wrong. Please reload the page',
    showError: false
  }),

  computed: {
    getEmailErrorMessage() {
      return validationUtils.isEmailValid(this.email);
    },

    getPasswordErrorMessage() {
      return validationUtils.getPasswordErrorMessage(this.password);
    },

    currentYear() {
      return new Date().getFullYear();
    }
  },

  methods: {
    ...mapActions([
      'signIn'
    ]),

    handleErrorClose() {
      this.showError = false;
    },

    handleEmailChange(value) {
      if (this.emailError) this.emailError = null;
      this.email = value;
    },

    handlePasswordChange(value) {
      if (this.passwordError) this.passwordError = null;
      this.password = value;
    },

    formValidation() {
      let hasError;

      const emailErrorMessage = this.getEmailErrorMessage;
      const passwordErrorMessage = this.getPasswordErrorMessage;

      if (emailErrorMessage) {
        this.emailError = emailErrorMessage;
        hasError = true;
      }

      if (passwordErrorMessage) {
        this.passwordError = passwordErrorMessage;
        hasError = true;
      }

      return !hasError
    },

    async handleLogin() {
      const isValid = this.formValidation();
      if (!isValid) return;

      const data = {
        email: this.email.toLowerCase(),
        password: this.password,
      };

      try {
        await this.signIn(data);
        this.$router.push({ name: "Dashboard" });
      } catch (error) {
        if (error) {
          this.showError = true;
          if (error.response && error.response.status === 401) {
            this.errorMessage = 'We couldn’t find an account matching the email and password you entered. Please check your email and password and try again.';
          }
        }
      }
    },
  }
}
</script>

<style lang="scss">
.sign-in {
  height: 100%;
  position: relative;

  &--body {
    padding: 40px 25px 7px;
    margin: 0 auto;
    max-width: 375px;
    height: 100%;
    box-sizing: border-box;

    &--header {
      display: flex;
      margin-bottom: 50px;
      align-items: center;

      &--logo {
        width: 100%;
        max-width: 60px;
        height: 60px;
        background: url(../assets/images/scope-logo-dashboard.svg) no-repeat;
        background-position: center;
        border-radius: 12px;
        margin: 0px 16px 0 0;
      }

      &--title {
        text-align: left;
        color: #151522;
      }
    }

    &--title {
      color: #151522;
      text-align: left;
      margin-bottom: 12px;
    }

    &--description {
      color: #4B617A;
      text-align: left;
      margin-bottom: 38px;
    }

    &--help {
      margin-bottom: 78px;
      display: inline-block;
      text-align: right;
      width: 100%;

      &--link {
        text-decoration: none;
      }
    }

    &--button {
      padding: 13px 0;
      display: inline-block;
      width: calc(100% - 50px);
      position: absolute;
      bottom: 100px;
      left: 50%;
      max-width: 325px;
      transform: translateX(-50%);
    }

    &--register {
      color: #4B617A;
      text-align: center;
      position: absolute;
      bottom: 58px;
      left: 0;
      width: 100%;

      &--link {
        color: #10886D;
        text-decoration: none;
      }
    }

    &--copyright {
      color: #9999A0;
      text-align: center;
      position: absolute;
      bottom: 10px;
      left: 0;
      width: 100%;
      display: flex;
      flex-direction: column;

      &--link {
        text-decoration: none;
        color: #9999A0;
      }
    }
  }
}

@media screen and (max-width: 375px) and (max-height: 811px),
(max-height: 811px) {
  .sign-in {
    &--body {
      &--button,
      &--register,
      &--copyright {
        position: relative;
        left: unset;
        bottom: unset;
        transform: none;
        width: 100%;
      }

      &--button {
        margin-bottom: 18px;
      }

      &--register {
        margin-bottom: 7px;
      }

      &--copyright {
        margin-bottom: 36px;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .sign-in {
    display: flex;
    max-width: 100%;
    padding: 0;
    position: relative;

    &--body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 100px 0;
      max-width: 397px;
      width: 100%;
      position: relative;

      &--header {
        display: none;
      }

      &--title {
        margin-bottom: 12px;
      }

      &--help {
        margin-bottom: 56px;
      }

      &--button {
        width: 240px;
        position: relative;
        left: unset;
        bottom: unset;
        transform: none;
      }

      &--register {
        position: fixed;
        top: 100px;
        right: 60px;
        bottom: unset;
        left: unset;
        width: 182px;
      }

      &--copyright {
        display: none;
      }
    }
  }
}
</style>
