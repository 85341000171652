<template>
  <div class="edit-status">
    <div class="edit-status--wrapper">
      <div class="edit-status--wrapper--header">
        <p class="h2-sb">Project status</p>
        <inline-svg
          :src="require('@/assets/images/icons/plus-rotated.svg')"
          class="edit-status--wrapper--header--close"
          @click="closeModals"
        />
      </div>

      <div class="edit-status--wrapper--select">
        <CustomSelect
          :modelValue="projectStatus"
          @update:modelValue="handleProjectStatusChange"
          label="Project status"
          id="status"
          name="status"
          :option="projectStatus ? projectStatus : setDefaultStatus(getProjectInfo.project.status)"
          placeholder="Choose status"
          :selectOptions="setOptions"
          :errorMessage="null"
        />

        <!-- TODO hide control to add new project status -->
        <!-- <button
          v-if="getUserRole === 'contractor'"
          class="edit-status--wrapper--select--add-btn text-btn"
          @click="handleAddNewStatus('project-statuses')"
        >
          <inline-svg
            :src="require('@/assets/images/icons/plus.svg')"
            class="edit-status--wrapper--select--add-btn--icon"
            stroke="#10886D"
          />
          Add new status
        </button> -->
      </div>

      <custom-text-area
        :modelValue="projectStatusComment"
        @update:modelValue="handleProjectStatusCommentChange"
        label="Comment"
        id="status-comment"
        name="status-comment"
        placeholder="Write a comment"
        errorMessage=""
        :marginBottom="30"
        optional
      />

      <button
        class="edit-status--wrapper--button normal-btn"
        @click="handleUpdateStatus"
      >Update</button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import dashboardHelpers from '@/mixins/dashboardHelpers';

import CustomSelect from '@/components/shared/CustomSelect';
import CustomTextArea from '@/components/shared/CustomTextArea';
import InlineSvg from 'vue-inline-svg';

export default {

  components: {
    CustomSelect,
    CustomTextArea,
    InlineSvg
  },

  mixins: [dashboardHelpers],

  props: [
    'closeModals',
    'handleAddNewStatus'
  ],

  data: () => ({
    projectStatus: '',
    projectStatusComment: '',
  }),

  computed: {
    ...mapGetters([
      'getProjectInfo',
      'getUserCompany',
      'getCurrentUser'
    ]),

    setOptions() {
      return this.getProjectInfo.project_statuses.map(option => ({
        value: option.id.toString(),
        label: option.name
      }))
    }
  },

  methods: {
    ...mapActions([
      'updateProjectStatus',
    ]),

    setDefaultStatus(status) {
      const selectedStatus = this.setOptions.find(item => item.value === status.toString());
      return selectedStatus.value;
    },

    handleProjectStatusChange(value) {
      this.projectStatus = value;
    },

    handleProjectStatusCommentChange(value) {
      this.projectStatusComment = value;
    },

    async handleUpdateStatus() {
      let activityStatus = ((this.projectStatus !== '' ? this.projectStatus : this.getProjectInfo.project.status) && this.projectStatusComment)
        ? "message_status"
        : "status";

      const data = {
        id: this.getProjectInfo.id,
        params: {
          status_id: this.projectStatus !== '' ? parseInt(this.projectStatus) : this.getProjectInfo.project.status,
          activity: {
            activity_type: activityStatus,
            ...((this.projectStatusComment !== '') ?
            {
              messages_attributes: [
                {
                  message: this.projectStatusComment
                }
              ]
            } : {}),
          }
        }
      }

      try {
        await this.updateProjectStatus(data);
        this.projectStatusComment = '';
        this.closeModals();
      } catch (error) {
        if (error) {
          console.error('Edit Status', error);
          this.showError = true;
          if (error.response && error.response.status === 401) {
            this.errorMessage = 'We couldn’t find an account matching the email and password you entered. Please check your email and password and try again.';
          }
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.edit-status {
  margin: 0 auto;

  &--wrapper {
    padding: 0;
    position: relative;

    &--header {
      display: none;
    }

    &--button {
      padding: 13px 0;
      max-width: 325px;
      width: 100%;
    }

    &--select {
      position: relative;

      &--add-btn {
        display: flex;
        align-items: center;
        border: 0px;
        background: #fff;
        padding: 0;
        position: absolute;
        top: 0;
        right: 0;

        &--icon {
          width: 12px;
          height: 12px;
          margin-right: 10px;
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .edit-status {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(21,21,34,0.5);
    width: 100%;
    max-width: 100%;
    height: 100%;
    z-index: 3;

    &--wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      background: #fff;
      border-radius: 26px;
      display: flex;
      flex-direction: column;
      width: 477px;
      padding: 40px 40px 8px;
      box-sizing: border-box;

      &--header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 26px;

        p {
          color: #151522;
        }

        &--close {
          cursor: pointer;
        }
      }

      &--button {
        max-width: 240px;
        margin-bottom: 32px;
      }
    }
  }
}
</style>
