<template>
  <div
    class="dashboard"
    :class="[{'menu-open': showMenu},
            {'search-active': search.length && currentPage === 'projects'},
            {'request-quote': currentPage === 'request-quote-form'},
            {'congrats-quote': quoteCurrentStep === 6},
            {'notifications': currentPage === 'notifications'},
            {'filter-open': currentPage === 'filter'},
            {'dashboard-mobile': isMobile}]"
    @click="handleHideMenu"
  >
    <div
      v-if="quoteCurrentStep !== 6"
      class="dashboard--header"
      :class="[{'search-active': search.length && currentPage === 'projects'},
              {'inner-page': currentPage !== 'projects'},
              {'centered': getCurrentUser && getCurrentUser?.role === 'viewer'}]"
    >
      <div
        class="dashboard--header--logo"
        :style="{'background-image': `url('${setLogoImage()}')`}"
        v-if="currentPage === 'projects' || currentPage === 'notifications'"
        @click="handleToggleMenu"
      ></div>

      <h2 class="dashboard--header--title h4-sb">{{ setTitle }}</h2>

      <inline-svg
        v-if="currentPage !== 'notifications' && !isHiddenFromViewer"
        :src="require(`@/assets/images/icons/${currentPage === 'projects'
          ? 'plus'
            : currentPage === 'filter'
              ? 'plus-rotated'
              : 'arrow-left'
        }.svg`)"
        class="dashboard--header--button"
        :class="(getUserRole === 'contractor' && currentPage === 'projects') && 'hidden'"
        @click="handleNavigation"
      />

      <button
        v-if="getUserRole === 'contractor' && currentPage === 'projects' && !isHiddenFromViewer"
        class="dashboard--header--button invite-user-btn normal-btn"
        @click="handleNavigation"
      >
        <inline-svg
          class="invite-user-btn--icon"
          :src="require(`@/assets/images/icons/user-icon.svg`)"
        />
      </button>

      <button
        v-if="currentPage === 'filter'"
        class="dashboard--header--reset text-btn"
        @click="handleFilterReset"
      >Reset</button>
    </div>

    <DesktopMenu
      v-if="quoteCurrentStep !== 6"
      :currentPage="currentPage"
      :handleNavigation="handleNavigation"
      :setTitle="setTitle"
      :handleToggleMenu="handleToggleMenu"
      :currentTab="currentTab"
      :handleTabChange="handleTabChange"
      :tabsItems="tabsItems"
      :handleRequestModal="handleRequestModal"
      :showMenu="showMenu"
      :handleOpenInnerPage="handleOpenInnerPage"
    />

    <Menu
      v-if="isMobile"
      :showMenu="showMenu"
      :currentPage="currentPage"
      :handleNavigation="handleNavigation"
      :setTitle="setTitle"
      :handleToggleMenu="handleToggleMenu"
      :currentTab="currentTab"
      :handleTabChange="handleTabChange"
      :tabsItems="tabsItems"
      :handleRequestModal="handleRequestModal"
      :setLogoImage="setLogoImage"
      :handleLogoutModal="handleLogoutModal"
      :handleOpenInnerPage="handleOpenInnerPage"
      :userRole="getUserRole"
    />

    <CustomModal
      v-if="showLogoutModal"
      :message="getLogoutMessage"
      :handleCancel="handleLogoutModal"
      :handleConfirm="logout"
      notice="Are you sure you want to log out?"
    />

    <div
      v-if="isMobile"
      class="dashboard--body"
    >
      <div
        class="dashboard--body--project-list"
        v-if="currentPage === 'projects'"
      >
        <div
          class="dashboard--body--controls"
          :class="{'search-active': search.length}"
        >
          <Search
            :search="search"
            @update:search="handleSearchChange"
            @update:filterOpen="handleToggleFilter"
            :marginBottom="currentPage === 'projects' && getUserRole === 'contractor' && getCurrentUser && getCurrentUser?.role !== 'viewer' ? 18 : 9"
            :searchError="searchError"
            :isFiltered="isFiltered"
          />

          <button
            v-if="currentPage === 'projects' && getUserRole === 'contractor' && getCurrentUser && getCurrentUser?.role !== 'viewer'"
            class="dashboard--body--controls--btn btn-create-project ghost-btn"
            @click="handleOpenInnerPage('request-quote-form')"
          >
            <inline-svg
              :src="require('@/assets/images/icons/plus.svg')"
              class="dashboard--body--controls--btn--icon"
            />
            Create new project
          </button>

          <Tabs
            :modelValue="currentTab"
            marginBottom="25"
            @update:modelValue="handleTabChange"
            :tabsItems="tabsItems"
          />
        </div>

        <div
          class="dashboard--body--project-list--wrapper"
        >
          <div
            v-if="getCompanyProjects && getCompanyProjects.length == 0"
            class="dashboard--body--project-list--wrapper--empty"
          >
            <div
              v-if="getUserRole === 'contractor'
                  && getCurrentUser
                  && getCurrentUser?.role !== 'viewer'
                  && !getCompanyProjectsAny"
              @click="handleNavigation"
              class="dashboard--body--project-list--wrapper--empty--invite-wrapper"
            >
              <p class="dashboard--body--project-list--wrapper--empty--invite-wrapper--text btn-font-large">
                <inline-svg
                  :src="require('@/assets/images/icons/plus.svg')"
                  class="dashboard--body--project-list--wrapper--empty--invite-wrapper--text--icon"
                />
                Invite customer
              </p>
              <span class="h6-light">You have no customers yet, would you like to add one?</span>
            </div>
            <p class="h5-light" style="color: #81878C;">No projects</p>
          </div>
          <div
            v-for="project in projects"
            :key="project.id"
          >
            <ProjectItem
              :project="project"
              :setProjectStatus="setProjectStatus"
              :getUserRole="getUserRole"
              :isArchiveProject="currentTab === 'archive' ? true : false"
              @update:showArchiveModal="handleArchiveModal"
              @click="handleProjectDetail($event, project.id)"
            />
          </div>
          <CustomLoader
            v-if="isLoading
              && getCompanyProjectsTotalPages !== 0
              && getCompanyProjectsTotalPages !== loadedPage"
            :totalPages="getCompanyProjectsTotalPages"
            :currentPage="loadedPage"
            @handleScrollLoad="handleScrollLoad"
            :positionCenter="loadedPage === 1"
            :isMobile="isMobile"
          />
        </div>
      </div>

      <FilterStep
        v-if="currentPage === 'filter'"
        :sortBy="sortBy"
        :handleSortingChange="handleSortingChange"
        :sortOptions="sortOptions"
        :customer="customer"
        :handleCustomerChange="handleCustomerChange"
        :customerOptions="customerOptions"
        :city="city"
        :handleCityChange="handleCityChange"
        :cityOptions="cityOptions"
        :state="state"
        :handleStateChange="handleStateChange"
        :stateOptions="stateOptions"
        :handleFilterReset="handleFilterReset"
        :isMobile="isMobile"
        :handleFilter="handleFilter"
      />

      <InviteCustomerStep
        v-if="currentPage === 'invite-customer'"
      />

      <RequestQuoteModal
        v-if="currentPage === 'request-quote'"
        :handleOpenInnerPage="handleOpenInnerPage"
      />

    </div>

    <div
      v-else
      class="dashboard--desktop-body"
      :class="[{'project-details': currentPage === 'project-detail'},
              {'quote-details': currentPage === 'request-quote-form'},
              {'notifications': currentPage === 'notifications'}]"
    >
      <div
        v-if="getCompanyProjects
            && getCompanyProjects.length > 0
            && currentPage === 'projects'"
        class="dashboard--desktop-body--filters"
      >
        <Search
          :search="search"
          @update:search="handleSearchChange"
          @update:filterOpen="handleToggleFilter"
          marginBottom="40"
          :searchError="searchError"
          :handleFilterModal="handleFilterModal"
          :isFiltered="isFiltered"
        />

        <FilterStep
          v-if="showFilterModal"
          :sortBy="sortBy"
          :handleSortingChange="handleSortingChange"
          :sortOptions="sortOptions"
          :customer="customer"
          :handleCustomerChange="handleCustomerChange"
          :customerOptions="customerOptions"
          :city="city"
          :handleCityChange="handleCityChange"
          :cityOptions="cityOptions"
          :state="state"
          :handleStateChange="handleStateChange"
          :stateOptions="stateOptions"
          :handleFilterReset="handleFilterReset"
          :isMobile="isMobile"
          :handleFilter="handleFilter"
        />
      </div>

      <div v-if="currentPage === 'projects'">
        <div class="dashboard--desktop-body--projects-wrapper">
          <div
            v-if="!isLoading && getCompanyProjects && getCompanyProjects.length == 0"
            class="dashboard--desktop-body--projects-wrapper--empty"
          >
            <div
              v-if="getUserRole === 'contractor'
                  && getCurrentUser
                  && getCurrentUser?.role !== 'viewer'
                  && !getCompanyProjectsAny"
              @click="handleRequestModal"
              class="dashboard--body--project-list--wrapper--empty--invite-wrapper"
            >
              <p class="dashboard--body--project-list--wrapper--empty--invite-wrapper--text btn-font-large">
                <inline-svg
                  :src="require('@/assets/images/icons/plus.svg')"
                  class="dashboard--body--project-list--wrapper--empty--invite-wrapper--text--icon"
                />
                Invite customer
              </p>
              <span class="h6-light">You have no customers yet, would you like to add one?</span>
            </div>
            <p class="h5-light" style="color: #81878C;">No projects</p>
          </div>
          <div
            v-for="project in projects"
            :key="project.id"
          >
            <ProjectItem
              :project="project"
              :setProjectStatus="setProjectStatus"
              :getUserRole="getUserRole"
              :isArchiveProject="currentTab === 'archive' ? true : false"
              @update:showArchiveModal="handleArchiveModal"
              @click="handleProjectDetail($event, project.id)"
            />
          </div>
        </div>
        <CustomLoader
          v-if="isLoading
              && getCompanyProjectsTotalPages !== 0
              && getCompanyProjectsTotalPages !== loadedPage"
          :totalPages="getCompanyProjectsTotalPages"
          :currentPage="loadedPage"
          @handleScrollLoad="handleScrollLoad"
          :positionCenter="loadedPage === 1"
          :isMobile="isMobile"
        />
      </div>

      <InviteCustomerStep
        v-if="showRequestModal && getUserRole === 'contractor'"
        :handleRequestModal="handleRequestModal"
      />

      <RequestQuoteModal
        v-if="showRequestModal && getUserRole === 'customer'"
        :handleRequestModal="handleRequestModal"
        :handleOpenInnerPage="handleOpenInnerPage"
      />
    </div>

    <MyAccount
      v-if="currentPage === 'my-account'"
      :currentUser="getCurrentUser"
      :currentPageSubPage="currentPageSubPage"
      @update:currentPageSubPage="handleCurrentPageSubPageChange"
      :subStep="subStep"
      :showSubStep="showSubStep"
      @update:subStep="handleSubStep"
      :email="email"
      :handleEmailChange="handleEmailChange"
      :emailError="emailError"
      :firstName="firstName"
      :handleFirstNameChange="handleFirstNameChange"
      :firstNameError="firstNameError"
      :lastName="lastName"
      :lastNameError="lastNameError"
      :handleLastNameChange="handleLastNameChange"
      :jobTitle="jobTitle"
      :handleJobTitleChange="handleJobTitleChange"
      :jobTitleError="jobTitleError"
      :phoneNumber="phoneNumber"
      :handlePhoneNumberChange="handlePhoneNumberChange"
      :phoneNumberError="phoneNumberError"
      :handleUpdateContractorInfo="handleUpdateContractorInfo"
      :isMobile="isMobile"
      :oldPassword="oldPassword"
      :handleOldPasswordChange="handleOldPasswordChange"
      :oldPasswordError="oldPasswordError"
      :password="password"
      :handlePasswordChange="handlePasswordChange"
      :passwordError="passwordError"
      :passwordConfirmation="passwordConfirmation"
      :handlePasswordConfirmationChange="handlePasswordConfirmationChange"
      :passwordConfirmationError="passwordConfirmationError"
      :isUpdating="isUpdating"
    />

    <Company
      v-if="currentPage === 'company'"
      :contractorCompany="getCurrentUser?.company"
      :currentPageSubPage="currentPageSubPage"
      @update:currentPageSubPage="handleCurrentPageSubPageChange"
      :subStep="subStep"
      :showSubStep="showSubStep"
      @update:subStep="handleSubStep"
      :isMobile="isMobile"
      :handleUpdateContractorInfo="handleUpdateContractorInfo"
      :file="file"
      :filePreview="filePreview ? filePreview : filePreviewOld"
      :description="description"
      :handleUploadFileChange="handleUploadFileChange"
      :handleUploadFileError="handleUploadFileError"
      :handleUploadFilePreviewChange="handleUploadFilePreviewChange"
      :handleIsLogoDeleted="handleIsLogoDeleted"
      :isLogoDeleted="isLogoDeleted"
      :companyName="companyName"
      :handleCompanyNameChange="handleCompanyNameChange"
      :companyNameError="companyNameError"
      :contactName="contactName"
      :handleContactNameChange="handleContactNameChange"
      :contactNameError="contactNameError"
      :companyPhoneNumber="companyPhoneNumber"
      :handleCompanyPhoneNumberChange="handleCompanyPhoneNumberChange"
      :companyPhoneNumberError="companyPhoneNumberError"
      :companyCity="companyCity"
      :handleCompanyCityChange="handleCompanyCityChange"
      :companyCityError="companyCityError"
      :companyZip="companyZip"
      :handleCompanyZipChange="handleCompanyZipChange"
      :companyZipError="companyZipError"
      :addressOne="addressOne"
      :handleAddressOneChange="handleAddressOneChange"
      :addressOneError="addressOneError"
      :addressTwo="addressTwo"
      :handleAddressTwoChange="handleAddressTwoChange"
      :companyState="companyState"
      :selectOptions="companyStateOptions"
      :handleCompanyStateChange="handleCompanyStateChange"
      :companyStateError="companyStateError"
      :isUpdating="isUpdating"
      :tradeTypesOptions="getWorkTypes"
      :selectedTradeTypes="selectedTradeTypes"
      :handleTradeTypeChange="handleTradeTypeChange"
      :isCompanyTypeChanged="isCompanyTypeChanged"
    />

    <!-- TODO hide page with adding new project statuses -->
    <!-- <ProjectSettings
      v-if="currentPage === 'project-settings'"
      :currentUser="getCurrentUser"
      :currentPageSubPage="currentPageSubPage"
      @update:currentPageSubPage="handleCurrentPageSubPageChange"
      :subStep="subStep"
      :showSubStep="showSubStep"
      @update:subStep="handleSubStep"
      :isMobile="isMobile"
    /> -->

    <EmailSettings
      v-if="currentPage === 'email-settings'"
      :currentPageSubPage="currentPageSubPage"
      @update:currentPageSubPage="handleCurrentPageSubPageChange"
      :subStep="subStep"
      :showSubStep="showSubStep"
      @update:subStep="handleSubStep"
      :isMobile="isMobile"
    />

    <Subscription
      v-if="currentPage === 'subscription'"
      :currentPageSubPage="currentPageSubPage"
      :isSubscriptionActive="isSubscriptionActive"
      @update:currentPageSubPage="handleCurrentPageSubPageChange"
      @update:subscriptionModal="handleHideSubscriptionModal"
    />

    <SubscriptionModal
      v-if="showSubscriptionModal"
      :isSubscriptionActive="isSubscriptionActive"
      :isSubscriptionTrialExpired="isSubscriptionTrialExpired"
      :isContractorOwner="isContractorOwner"
      :handleHideSubscriptionModal="handleHideSubscriptionModal"
      :handleCancelSubscriptionModal="handleCancelSubscriptionModal"
      :handleActivateSubscriptionModal="handleActivateSubscriptionModal"
    />

    <TradeTypesModal
      v-if="showTradeTypeModal"
      :handleHideTradeTypesModal="handleHideTradeTypesModal"
      :handleAddTradeTypesModal="handleAddTradeTypesModal"
    />

    <Help
      v-if="currentPage === 'help'"
      :currentPageSubPage="currentPageSubPage"
      @update:currentPageSubPage="handleCurrentPageSubPageChange"
    />

    <RequestQuoteForm
      v-if="currentPage === 'request-quote-form'"
      :companyName="getContractorCompanyMainInfo ? getContractorCompanyMainInfo.contractor_name : getUserCompany?.name"
      :isMobile="isMobile"
      :quoteCurrentStep="quoteCurrentStep"
      @update:quoteCurrentStep="handleQuoteStep"
      :contractorForQuote="contractorForQuote"
    />

    <Notifications
      v-if="currentPage === 'notifications'"
      :setLogoImage="setLogoImage"
      :isMobile="isMobile"
    />

    <p
      v-if="isMobile && currentPage !== 'request-quote-form'"
      class="dashboard--copyright caption-3"
    >
      Powered by FCA
    </p>

    <Footer
      v-if="currentPage !== 'filter'
            && quoteCurrentStep !== 6
            && !(isMobile && currentPage === 'request-quote-form')"
      :currentPage="currentPage"
      :handleFooterLinks="handleFooterLinks"
    />

    <CustomModal
      v-if="showArchiveModal"
      :message="archiveModalMessage"
      messageMargin="14"
      :handleCancel="handleArchiveModal"
      :handleConfirm="handleArchiveProject"
    />

    <InvitationModal
      v-if="getCurrentUser && getCurrentUser?.encrypted_invite"
      :user="getCurrentUser"
      :handleCancel="handleCancellUpdateUserCompany"
      :handleConfirm="handleConfirmlUpdateUserCompany"
    />

    <custom-error
      v-if="showError"
      :errorMessage="errorMessage"
      :handleErrorClose="handleErrorClose"
      :errorStyles="errorStyles()"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { nextTick } from 'vue';

import { validationUtils } from '@/utils/validation';

import dashboardHelpers from '@/mixins/dashboardHelpers';
import commonHelpers from '@/mixins/commonHelpers';

import companyStateOptions from '@/components/sign-up/StateOptions.js';
import InlineSvg from 'vue-inline-svg';
import Search from '@/components/dashboard/Search';
import Tabs from '@/components/dashboard/Tabs';
import ProjectItem from '@/components/dashboard/ProjectItem';
import FilterStep from '@/components/dashboard/FilterStep';
import InviteCustomerStep from '@/components/dashboard/InviteCustomerStep';
import DesktopMenu from '@/components/dashboard/DesktopMenu';
import CustomError from '@/components/shared/CustomError';
import MyAccount from '@/components/dashboard/MyAccount';
import Help from '@/components/dashboard/Help';
import Subscription from '@/components/dashboard/Subscription';
import Company from '@/components/dashboard/Company';
import EmailSettings from '@/components/dashboard/EmailSettings';
// TODO hide page with adding new project statuses
//import ProjectSettings from '@/components/dashboard/ProjectSettings';
import RequestQuoteModal from '@/components/dashboard/RequestQuoteModal';
import RequestQuoteForm from '@/components/dashboard/RequestQuoteForm';
import Notifications from '@/components/dashboard/Notifications';
import Menu from '@/components/dashboard/Menu';
import Footer from '@/components/dashboard/Footer';
import CustomLoader from '@/components/shared/CustomLoader';
import CustomModal from '@/components/shared/CustomModal';
import InvitationModal from '@/components/dashboard/InvitationModal';
import SubscriptionModal from '@/components/dashboard/SubscriptionModal';
import TradeTypesModal from '@/components/dashboard/TradeTypesModal';

export default {
  name: 'Dashboard',

  mixins: [dashboardHelpers, commonHelpers],

  components: {
    InlineSvg,
    Search,
    Tabs,
    ProjectItem,
    FilterStep,
    InviteCustomerStep,
    DesktopMenu,
    Menu,
    CustomError,
    MyAccount,
    Company,
    EmailSettings,
    Subscription,
    Help,
    RequestQuoteModal,
    RequestQuoteForm,
    Notifications,
    Footer,
    CustomLoader,
    CustomModal,
    // TODO hide page with adding new project statuses
    //ProjectSettings,
    InvitationModal,
    SubscriptionModal,
    TradeTypesModal
  },

  data: () => ({
    currentPage: 'projects',
    quoteCurrentStep: 0,
    currentPageSubPage: '',
    subStep: '',
    showSubStep: '',
    showMenu: false,
    showLogoutModal: false,
    showRequestModal: false,
    contractorForQuote: null,
    showFilterModal: false,
    search: '',
    searchFilters: {
      sortBy: '',
      customer: '',
      state: '',
      city: '',
    },
    searchError: null,
    currentTab: 'in-progress',
    windowWidth: window.innerWidth,
    isMobile: (window.innerWidth < 1024),
    showError: false,
    errorMessage: 'Something went wrong. Please reload the page',
    isUpdating: false,
    isFiltered: false,
    showArchiveModal: false,
    projectIdToArchive: '',
    updateUserCompanylMessage: 'Change you current company?',
    showSubscriptionModal: false,

    // loader data
    projects: [],
    isLoading: true,
    loadedPage: 0,
    isSearching: false,

    // filter data
    sortBy: '',
    sortOptions: [
      {
        label: 'A to Z',
        value: 'letters-asc'
      },
      {
        label: 'Z to A',
        value: 'letters-desс'
      },
      {
        label: 'Oldest to Newest',
        value: 'creation-date-asc'
      },
      {
        label: 'Newest to Oldest',
        value: 'creation-date-desc'
      },
    ],
    customer: '',
    state: '',
    city: '',

    // TODO - fetch data from backend
    stateOptions: [
      {
        label: 'Alabama',
        value: 'AL'
      },
      {
        label: 'Alaska',
        value: 'AK'
      },
      {
        label: 'Arizona',
        value: 'AZ'
      },
      {
        label: 'Arkansas',
        value: 'AR'
      }
    ],
    cityOptions: [
      { label: 'New York', value: 'New York' },
      { label: 'Los Angeles', value: 'Los Angeles' },
      { label: 'Chicago', value: 'Chicago' },
      { label: 'Phoenix', value: 'Phoenix' },
      { label: 'Houston', value: 'Houston' }
    ],

    // my-account data
    email: '',
    firstName: '',
    lastName: '',
    jobTitle: '',
    phoneNumber: '',
    emailError: null,
    firstNameError: null,
    lastNameError: null,
    jobTitleError: null,
    phoneNumberError: null,
    oldPassword: '',
    password: '',
    passwordConfirmation: '',
    oldPasswordError: null,
    passwordError: null,
    passwordConfirmationError: null,

    // company data
    file: '',
    filePreview: '',
    description: '(300x300px, up to 10MB)',
    fileError: false,
    fileType: '',
    fileName: '',
    isLogoDeleted: false,
    companyName: '',
    companyNameError: null,
    contactName: '',
    contactNameError: null,
    companyPhoneNumber: '',
    companyPhoneNumberError: null,
    companyCity: '',
    companyCityError: null,
    companyZip: '',
    companyZipError: null,
    addressOne: '',
    addressOneError: null,
    addressTwo: '',
    companyState: '',
    companyStateError: null,
    companyStateOptions: companyStateOptions,
    selectedTradeTypes: [],
    isCompanyTypeChanged: false
  }),

  created() {
    this.handleTabChange('in-progress');
    this.paymentPlans();
    if (this.getUserRole === 'contractor'
        && this.getCurrentUser?.company
        && this.getCurrentUser?.company?.company_work_types
        && !this.getCurrentUser?.company?.company_work_types.length
        && this.getIsTradeTypesModalVisible !== 'hide') {
      this.hideTradeTypesModal(true);
    }
  },

  mounted() {
    window.addEventListener('resize', this.getWindowWidth);
    if (this.$route.query.page) {
      this.handleOpenInnerPage(this.$route.query.page);
      if (this.$route.query.page === 'project-statuses') {
        this.currentPage = 'project-settings';
        nextTick(() => {
          this.currentTab = 'project-settings';
          this.showSubStep = 'project-statuses';
          this.currentPageSubPage = 'project-statuses';
        })
      }
      this.$router.replace({'query': null});
    }
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.getWindowWidth);
  },

  watch: {
    getCompanyProjects() {
      this.isLoading = false;
      this.handleUpdateCompanyProjects();
    },

    getWorkTypes() {
      if (this.getUserCompany?.company_work_types) {
        this.selectedTradeTypes = this.getWorkTypes.filter(o1 => this.getUserCompany?.company_work_types.some(o2 => o1.id === o2.work_type_id));
      }
    }
  },

  computed: {
    ...mapGetters([
      'getCompanyProjects',
      'getUserCompany',
      'getCurrentUser',
      'getGcFile',
      'getContractorCompanyMainInfo',
      'getCompanyProjectsTotalPages',
      'getCompanyProjectsAny',
      'getPaymentInfo',
      'getSessionBillingUrl',
      'getPaymentPlans',
      'getSessionCheckoutUrl',
      'getWorkTypes',
      'getIsTradeTypesModalVisible'
    ]),

    showTradeTypeModal() {
      return this.getIsTradeTypesModalVisible !== 'hide'
            && this.getUserRole === 'contractor'
            && this.getCurrentUser?.company
            && this.getCurrentUser?.company.company_work_types
            && !this.getCurrentUser?.company.company_work_types.length;
    },

    filePreviewOld() {
      const logoPreview = this.getUserCompany
        ? this.getUserCompany?.file_storages
        : '';
      if (logoPreview.length) {
        return logoPreview[0].file_url;
      }
      return '';
    },

    customerOptions() {
      const data = this.getCompanyProjects.map(item => {
        return {
          label: item.project.contact_name,
          value: item.project.contact_name
        }
      });
      return data;
    },

    setTitle() {
      if (this.currentPage === 'projects') {
        return 'Projects';
      } else if (this.currentPage === 'filter') {
        return 'Filters & Sorting';
      } else if (this.currentPage === 'invite-customer') {
        return 'Invite customer';
      } else if (this.currentPage === 'request-quote'
                || this.currentPage === 'request-quote-form') {
          if (this.getUserRole === 'contractor') {
            return 'New Project';
          } else {
            return 'Request quote';
          }
      } else if (this.currentPage === 'my-account') {
        if (this.isMobile) {
          if (this.currentPageSubPage === 'personal-information') {
            return 'Personal information';
          } else if (this.currentPageSubPage === 'security') {
            return 'Security';
          }
          return 'My account';
        } else {
          return 'Settings';
        }
      } else if (this.currentPage === 'company') {
        if (this.isMobile) {
          if (this.currentPageSubPage === 'company-logotype') {
            return 'Company logotype';
          } else if (this.currentPageSubPage === 'company-information') {
            return 'Company information';
          } else if (this.currentPageSubPage === 'company-location') {
            return 'Company location';
          } else if (this.currentPageSubPage === 'company-type') {
            return 'Trade Type';
          }
          return 'Company';
        } else {
          return 'Settings';
        }
      } else if (this.currentPage === 'email-settings') {
        if (this.isMobile) {
          if (this.currentPageSubPage === 'project-activity') {
            return 'Project activity';
          } else if (this.currentPageSubPage === 'account-activity') {
            return 'Account activity';
          }
          return 'Email notifications';
        } else {
          return 'Settings';
        }
      } else if (this.currentPage === 'project-settings') {
        if (this.isMobile) {
          if (this.currentPageSubPage === 'project-statuses') {
            return 'Project statuses';
          }
          return 'Project';
        } else {
          return 'Settings';
        }
      } else if (this.currentPage === 'notifications') {
        return 'Notifications';
      } else if (this.currentPage === 'subscription') {
        if (this.isMobile) {
          return 'Subscription';
        } else {
          return 'Settings';
        }
      } else if (this.currentPage === 'help') {
        if (this.isMobile) {
          return 'Help';
        } else {
          return 'Settings';
        }
      } else {
        return '';
      }
    },

    tabsItems() {
      if (this.currentPage === 'projects') {
        return {
          inProgress: {
            className: 'in-progress',
            tabName: 'In progress'
          },
          completed: {
            className: 'completed',
            tabName: 'Completed'
          },
          archive: {
            className: 'archive',
            tabName: 'Archive'
          }
        };
      } else if (this.currentPage === 'my-account'
        || this.currentPage === 'company'
        || this.currentPage === 'project-settings'
        || this.currentPage === 'subscription'
        || this.currentPage === 'help'
        || this.currentPage === 'email-settings') {
        if (this.getUserRole === 'contractor'
            && this.getCurrentUser && this.getCurrentUser?.role !== 'viewer') {
          return {
            myAccount: {
              className: 'my-account',
              tabName: 'My account'
            },
            company: {
              className: 'company',
              tabName: 'Company'
            },
            emailSettings: {
              className: 'email-settings',
              tabName: 'Email notifications'
            },
            subscription: {
              className: 'subscription',
              tabName: 'Subscription'
            },
            help: {
              className: 'help',
              tabName: 'Help'
            },
            // TODO hide page with adding new project statuses
            // projectSettings: {
            //   className: 'project-settings',
            //   tabName: 'Project'
            // }
          };
        } else {
          return {
            myAccount: {
              className: 'my-account',
              tabName: 'My account'
            },
            company: {
              className: 'company',
              tabName: 'Company'
            },
            emailSettings: {
              className: 'email-settings',
              tabName: 'Email notifications'
            },
            help: {
              className: 'help',
              tabName: 'Help'
            },
          };
        }
      } else {
        return {};
      }
    },

    isHiddenFromViewer() {
      return this.getCurrentUser && this.getCurrentUser?.role === 'viewer' && this.currentPage === 'projects';
    },

    getEmailErrorMessage() {
      if (this.email) {
        return validationUtils.isEmailValid(this.email);
      }
      return false;
    },

    getPhoneNumberErrorMessage() {
      return validationUtils.getPhoneNumberErrorMessage(this.currentPageSubPage === 'personal-information'
        ? this.phoneNumber
        : this.companyPhoneNumber);
    },

    getOldPasswordErrorMessage() {
      return validationUtils.getPasswordErrorMessage(this.oldPassword);
    },

    getPasswordErrorMessage() {
      return validationUtils.getPasswordErrorMessage(this.password);
    },

    getConfirmPasswordErrorMessage() {
      return validationUtils.getConfirmPasswordErrorMessage(this.password, this.passwordConfirmation);
    },

    getInProgressProjects() {
      if (this.getCompanyProjects.length) {
        return this.getCompanyProjects.filter(item => item.project.status !== 'completed');
      }
      return '';
    },

    getCompletedProjects() {
      return this.getCompanyProjects.filter(item => item.project.status === 'completed');
    },

    archiveModalMessage() {
      return this.currentTab === 'archive'
        ? 'Are you sure you want to unarchive this project?'
        : 'Are you sure you want to archive this project?';
    },

    isSubscriptionActive() {
      return this.getPaymentInfo && this.getPaymentInfo.status === 'trialing'
            || this.getPaymentInfo && this.getPaymentInfo.status === 'active';
    },

    isSubscriptionTrialExpired() {
      return this.getPaymentInfo && this.getPaymentInfo.trial_expired;
    },

    isContractorOwner() {
      return this.getUserRole === 'contractor' && this.getCurrentUser?.role === 'owner';
    }
  },

  methods: {
    ...mapActions([
      'logout',
      'setDashboardInfo',
      'updateUserInfo',
      'updateUserPassword',
      'gcFileUpload',
      'logoDelete',
      'gcLogoUpdate',
      'gcLogoUpdateUploadedStatus',
      'notificationsUpdate',
      'resetProjectInfo',
      'archivingProject',
      'updateUserCompany',
      'billingSession',
      'paymentPlans',
      'checkoutSession',
      'hideTradeTypesModal'
    ]),

    // common helpers
    handleRequestModal() {
      if (!this.isSubscriptionActive && this.getUserRole === 'contractor'
          || this.getUserRole === 'contractor' && this.isSubscriptionTrialExpired) {
        this.showSubscriptionModal = !this.showSubscriptionModal;
      } else {
        this.showRequestModal = !this.showRequestModal;
      }
    },

    handleArchiveModal(projectId) {
      this.showArchiveModal = !this.showArchiveModal;
      if (projectId) {
        this.projectIdToArchive = projectId;
      }
    },

    handleErrorClose() {
      this.showError = false;
    },

    clearEmptyData(obj) {
      return Object.entries(obj).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {})
    },

    // navigation, tabs, menu helpers
    handleNavigation() {
      if (this.currentPage === 'projects') {
        if (this.getUserRole === 'contractor') {
          if (!this.isSubscriptionActive) {
            this.showSubscriptionModal = !this.showSubscriptionModal;
          } else {
            this.currentPage = 'invite-customer';
          }
        } else {
          this.currentPage = 'request-quote';
        }
      } else if (this.currentPage === 'my-account') {
        if (this.currentPageSubPage === 'my-account') {
          this.currentPage = 'projects';
          this.currentTab = 'in-progress';
        } else {
          this.currentPageSubPage = 'my-account';
        }
      } else if (this.currentPage === 'company') {
        if (this.currentPageSubPage === 'company') {
          this.currentPage = 'projects';
          this.currentTab = 'in-progress';
        } else {
          this.currentPageSubPage = 'company';
        }
      } else if (this.currentPage === 'project-settings') {
        if (this.currentPageSubPage === 'project-settings') {
          this.currentPage = 'projects';
          this.currentTab = 'in-progress';
        } else {
          this.currentPageSubPage = 'project-settings';
          }
      } else if (this.currentPage === 'email-settings') {
        if (this.currentPageSubPage === 'email-settings') {
          this.currentPage = 'projects';
          this.currentTab = 'in-progress';
        } else {
          this.currentPageSubPage = 'email-settings';
        }
      } else if (this.currentPage === 'request-quote-form') {
        if (!this.isMobile) {
          this.currentPage = 'projects';
          this.currentTab = 'in-progress';
          this.quoteCurrentStep = 0;
        } else {
          if (this.quoteCurrentStep === 0) {
            if (this.getUserRole === 'contractor') {
              this.currentPage = 'projects';
              this.currentTab = 'in-progress';
            } else {
              this.currentPage = 'request-quote';
            }
          } else {
            this.quoteCurrentStep--;
          }
        }
      } else {
        this.isFiltersChanged();
        this.currentPage = 'projects';
        this.currentTab = 'in-progress';
      }
    },

    handleQuoteStep(value) {
      this.quoteCurrentStep = value;
    },

    handleHideMenu(e) {
      if (e.target === document.querySelector('.dashboard')) {
        this.showMenu = false;
      }
    },

    handleToggleMenu() {
      this.showMenu = !this.showMenu;
    },

    handleHideTradeTypesModal() {
      this.hideTradeTypesModal('hide');
    },

    handleAddTradeTypesModal() {
      this.hideTradeTypesModal('hide');
      this.currentPage = 'company';
      setTimeout(() => {
        this.handleCurrentPageSubPageChange('company-type');
      }, 100);
      this.handleSubStep('company-type');
    },

    handleOpenInnerPage(pageName, contractorId = null) {
      this.showMenu = false;
      this.showRequestModal = false;
      if (pageName === 'projects') {
        this.currentPage = pageName;
        this.currentTab = 'in-progress';
      } else if (pageName !== 'request-quote-form') {
        this.currentPage = pageName;
        this.currentTab = pageName;
      }
      if ((pageName === 'request-quote-form' && this.getCurrentUser?.type !== 'Contractor')
          ||
          (pageName === 'request-quote-form' && this.isSubscriptionActive)) {
        this.currentPage = pageName;
        this.quoteCurrentStep = 0;
        this.contractorForQuote = contractorId;
        this.resetProjectInfo();
      } else if (pageName === 'request-quote-form'
        && !this.isSubscriptionActive
        && this.getCurrentUser?.type === 'Contractor') {
        this.showSubscriptionModal = !this.showSubscriptionModal;
      }
    },

    handleFooterLinks(linkName) {
      if (this.currentPage === 'notifications') {
        this.notificationsUpdate();
        this.handleTabChange('in-progress');
      }
      if (linkName === 'notifications') {
        this.currentPage = 'notifications';
      } else if (linkName === 'projects') {
        this.currentPage = 'projects';
        this.currentTab = 'in-progress';
      } else {
        this.$router.push({ name: 'Users' });
      }
    },

    handleCurrentPageSubPageChange(value) {
      this.currentPageSubPage = value;
    },

    handleHideSubscriptionModal() {
      this.showSubscriptionModal = !this.showSubscriptionModal;
    },

    async handleCancelSubscriptionModal() {
      this.showSubscriptionModal = !this.showSubscriptionModal;
      await this.getBillingUrl();
      const sessionBillingUrl = this.getSessionBillingUrl;
      window.open(`${sessionBillingUrl}`,"_self");
    },

    async handleActivateSubscriptionModal() {
      await this.getBillingUrl();
      const paymentInfoData = this.getPaymentPlans.find(el => el.lookup_key === 'contractors_with_free_trial');
      const paymentInfo = { ...paymentInfoData };
      const data = {
        plan_id: paymentInfo.plan_id,
        success_url: encodeURIComponent(`${window.location.origin}/subscription?paid=true`),
        cancel_url: encodeURIComponent(`${window.location.origin}/dashboard`),
      };

      await this.getCheckoutUrl(data);
      const sessionCheckoutUrl = { ...this.getSessionCheckoutUrl };
      window.open(`${sessionCheckoutUrl.url}`,"_self");
    },

    async getCheckoutUrl(data) {
      try {
        const sessionCheckoutUrl = await this.checkoutSession(data);
        return sessionCheckoutUrl;
      } catch (error) {
        if (error) {
          console.error('Dashboard', error);
          this.showError = true;
          if (error.response && error.response.status === 422) {
            this.errorMessage = 'Something went wrong. Please reload the page';
          }
        }
      }
    },

    handleSubStep(subStepName) {
      if (this.isMobile) {
        this.currentPageSubPage = subStepName;
      } else {
        if (this.showSubStep === subStepName) {
          this.showSubStep = '';
        } else {
          this.showSubStep = subStepName;
        }
        this.currentPageSubPage = subStepName;
      }
    },

    async handleTabChange(value) {
      if (value === 'completed'
        || value === 'in-progress'
        || value === 'archive') {
        this.handleBeforeFilter();
        this.currentTab = value;

        const data = {
          page: '1',
          filter: this.getProjectsByTabs(this.currentTab),
          order: this.getSortByOrder(this.sortBy),
          search: `search[name]=${this.search}`
        }

        try {
          await this.setDashboardInfo(data)
          if (this.getCompanyProjectsTotalPages > this.loadedPage) {
            this.isLoading = true;
          } else {
            this.isLoading = false;
          }
        } catch (error) {
          console.error('Dashboard 2', error);
          this.showError = true;
        }
        return;
      }
      this.currentPage = value;
      this.currentTab = value;
    },

    handleProjectDetail(e, value) {
      if (e.target.closest('.project-item--menu-button')
        || e.target.closest('.project-item--menu')) {
        return;
      } else {
        e.target.closest(`#project-item-${value}`).style.border = '1px solid #10886D';
        this.currentPage = 'project-detail';
        this.$router.push({ name: 'ProjectPage', params: {id: value} });
      }
    },

    async handleArchiveProject() {
      const data = {
        id: this.projectIdToArchive,
        params: {
          archived: this.currentTab === 'archive' ? false : true,
          activity: {
            activity_type: this.currentTab === 'archive' ? 'unarchivated' : 'archivated'
          }
        }
      }

      try {
        await this.archivingProject(data);
        this.handleTabChange(this.currentTab);
        this.showArchiveModal = !this.showArchiveModal;
      } catch (error) {
        console.error('Dashboard 3', error);
        this.showError = true;
      }
    },

    async handleConfirmlUpdateUserCompany() {
      try {
        await this.updateUserCompany({
          join_to_another_company: true
        });
        this.projects = [];
      } catch (error) {
        console.error('Dashboard 4', error);
        this.showError = true;
      }
    },

    async handleCancellUpdateUserCompany() {
      try {
        await this.updateUserCompany({
          join_to_another_company: false
        });
        this.projects = [];
      } catch (error) {
        console.error('Dashboard 5', error);
        this.showError = true;
      }
    },

    // pagination filter and search helpers
    async handleScrollLoad() {
      if (this.isSearching) return;
      if (this.isLoading) {
        this.isLoading = false;
        this.loadedPage++;

        const data = {
          page: this.isSearching ? (this.loadedPage + 1).toString() : this.loadedPage.toString(),
          filter: this.getProjectsByTabs(this.currentTab),
          order: this.getSortByOrder(this.sortBy),
          search: `search[name]=${this.search}`
        }

        try {
          await this.setDashboardInfo(data);
        } catch (error) {
          console.error('Dashboard 6', error);
          this.showError = true;
        }
      }
    },

    handleUpdateCompanyProjects() {
      this.projects.push(...this.getCompanyProjects);
      if (this.getCompanyProjectsTotalPages > this.loadedPage) {
        this.isLoading = true;
      } else {
        this.isLoading = false;
      }
    },

    handleFilterModal() {
      this.showFilterModal = !this.showFilterModal;
      if (this.showFilterModal) {
        this.isFiltersChanged();
      }
    },

    async handleFilter() {
      this.handleBeforeFilter();
      this.isFiltered = true;
      this.searchFilters.sortBy = this.sortBy.length ? this.sortBy : '';
      this.searchFilters.customer = this.customer.length ? this.customer : '';
      this.searchFilters.state = this.state.length ? this.state : '';
      this.searchFilters.city = this.city.length ? this.city : '';

      const data = {
        page: '1',
        filter: this.getProjectsByTabs(this.currentTab),
        order: this.getSortByOrder(this.sortBy),
        search: `search[name]=${this.search}`
      };

      try {
        await this.setDashboardInfo(data);
        this.handleAfterFilter();
      } catch (error) {
        console.error('Dashboard 7', error);
        this.showError = true;
      }
    },

    handleToggleFilter(value) {
      this.currentPage = value;
    },

    isFiltersChanged() {
      if (this.searchFilters.sortBy !== this.sortBy) {
        this.sortBy = this.searchFilters.sortBy;
      }
      if (this.searchFilters.state !== this.state) {
        this.state = this.searchFilters.state;
      }
      if (this.searchFilters.city !== this.city) {
        this.city = this.searchFilters.city;
      }
      if (this.searchFilters.customer !== this.customer) {
        this.customer = this.searchFilters.customer;
      }
    },

    handleSortingChange(value) {
      this.sortBy = value;
    },

    handleCustomerChange(value) {
      this.customer = value;
    },

    handleStateChange(value) {
      this.state = value;
    },

    handleCityChange(value) {
      this.city = value;
    },

    getSortByOrder(value) {
      if (this.searchFilters.sortBy.length) {
        let order = value === 'letters-asc'
        ? 'order[letters]=asc'
        : value === 'letters-desс'
          ? 'order[letters]=desc'
          : value === 'creation-date-asc'
            ? 'order[creation_date]=asc'
            : 'order[creation_date]=desc';
        return order;
      } else {
        this.isFiltered = false;
        return 'order[creation_date]=desc';
      }
    },

    getProjectsByTabs(value) {
      let tab = value === 'completed'
      ? 'filter[status]=completed&filter[archive]=unarchived'
      : value === 'in-progress'
        ? 'filter[status]=not_completed&filter[archive]=unarchived'
        : value === 'archive'
          ? 'filter[archive]=archived&filter[status]=all'
          : 'filter[status]=not_completed&filter[archive]=unarchived';
      return tab;
    },

    handleFilterReset() {
      this.sortBy = '';
      this.customer = '';
      this.state = '';
      this.city = '';
    },

    handleBeforeFilter() {
      this.isLoading = false;
      this.loadedPage = 1;
      this.projects.length = 0;
    },

    handleAfterFilter() {
      if (this.isMobile) {
        this.currentPage = 'projects';
      } else {
        this.showFilterModal = false;
      }

      if (this.getCompanyProjectsTotalPages > this.loadedPage) {
        this.isLoading = true;
      } else {
        this.isLoading = false;
      }
    },

    async handleSearchChange(value) {
      this.isSearching = true;
      this.handleBeforeFilter();
      this.search = value;

      const data = {
        page: '1',
        filter: this.getProjectsByTabs(this.currentTab),
        order: this.getSortByOrder(this.sortBy),
        search: `search[name]=${this.search}`
      }

      try {
        await this.setDashboardInfo(data);
        this.handleAfterFilter();
        this.isSearching = false;
      } catch (error) {
        console.error('Dashboard 8', error);
        this.showError = true;
      }
    },

    // my-account helpers
    async handleUpdatePersonalInformation () {
      const phoneNumberChanged = this.getCurrentUser?.phone === validationUtils.getFormattedPhoneNumber(this.phoneNumber)
        ? ''
        : validationUtils.getFormattedPhoneNumber(this.phoneNumber);

      const userOld = {
        email: this.email.toLowerCase(),
        first_name: this.firstName,
        last_name: this.lastName,
        job_title: this.jobTitle,
        phone: phoneNumberChanged,
      }

      const user = this.clearEmptyData(userOld);

      try {
        await this.updateUserInfo({ user });
        setTimeout(() => {
          this.handleAfterUpdate('personal-information');
        }, 1000);
      } catch (error) {
        console.error('Dashboard 9', error);
        this.showError = true;
      }
    },

    async handleUpdateSecurity() {
      const data = {
        old_password: this.oldPassword,
        password: this.password,
        password_confirmation: this.passwordConfirmation,
      }

      try {
        await this.updateUserPassword(data);
        setTimeout(() => {
          this.handleAfterUpdate('security');
        }, 1000);
      } catch (error) {
        console.error('Dashboard 10', error);
        this.showError = true;
        if (error.response && error.response.status === 422) {
          this.errorMessage = 'Password is incorrect. Please try again.';
        }
      }
    },

    // company helpers
    async handleUpdateLogo() {
      const data = {
        file_name: this.fileName,
        file_type: this.fileType
      }

      try {
        await this.gcLogoUpdate(data);
        setTimeout(() => {
          this.handleAfterUpdate('company-logotype');
        }, 1000);
      } catch (error) {
        console.error('Dashboard 11', error);
        this.showError = true;
      }
    },

    async getUploadingLogoLink(file) {
      const filePrefix = () => Math.random().toString(36).substr(2, 9);
      const fileName = `${filePrefix()}-${file.name}`.replace(/\s/g, '_');
      const data = {
        file_name: fileName,
        file_type: file.type
      }

      this.fileName = fileName;

      try {
        await this.gcFileUpload(data);
        this.uploadingLogoGC(this.getGcFile.url, file);
      } catch (error) {
        console.error('Dashboard 12', error);
        this.showError = true;
      }
    },

    uploadingLogoGC(gcUrl, file) {
      var xhr = new XMLHttpRequest();
      xhr.open('PUT', gcUrl, true);
      xhr.onload = () => {
        if (xhr.readyState == 4 && xhr.status == "200") {
          this.fileType = file.type;
          if (this.getUserCompany?.file_storages.length) {
            this.gcLogoUpdateUploadedStatus({id: this.getUserCompany?.file_storages[0].id})
          }
        }
      }
      xhr.setRequestHeader('Content-Type', file.type);
      xhr.send(file.slice());
    },

    async deleteLogo() {
      try {
        await this.logoDelete();
        this.setDashboardInfo();
        setTimeout(() => {
          this.handleAfterUpdate('company-logotype');
        }, 1000);
      } catch (error) {
        console.error('Dashboard 13', error);
        this.showError = true;
      }
    },

    async handleUpdateCompanyInformation() {
      const companyPhoneNumberChanged = this.getCurrentUser?.company.phone === validationUtils.getFormattedPhoneNumber(this.companyPhoneNumber)
        ? ''
        : validationUtils.getFormattedPhoneNumber(this.companyPhoneNumber);

      const userCompanyOld = {
        name: this.companyName,
        contact_name: this.contactName,
        phone: companyPhoneNumberChanged,
      }

      const userCompany = this.clearEmptyData(userCompanyOld);

      try {
        await this.updateUserInfo({ company: userCompany });
        setTimeout(() => {
          this.handleAfterUpdate('company-information');
        }, 1000);
      } catch (error) {
        console.error('Dashboard 14', error);
        this.showError = true;
      }
    },

    async handleUpdateCompanyLocation() {
      const userCompanyLocationOld = {
        city: this.companyCity,
        state: this.companyState,
        zip_code: this.companyZip,
        address_line_1: this.addressOne,
        address_line_2: this.addressTwo,
      }

      const userCompanyLocation = this.clearEmptyData(userCompanyLocationOld);

      try {
        await this.updateUserInfo({ company: userCompanyLocation });
        setTimeout(() => {
          this.handleAfterUpdate('company-location');
        }, 1000);
      } catch (error) {
        console.error('Dashboard 15', error);
        this.showError = true;
      }
    },

    async handleUpdateCompanyType() {
      const companyWorkTypeIds = this.selectedTradeTypes.map(item => {
          return item.id;
      });
      try {
        await this.updateUserInfo(JSON.stringify({ company_work_type_ids: companyWorkTypeIds }));
        setTimeout(() => {
          this.handleAfterUpdate('company-type');
        }, 1000);
      } catch (error) {
        console.error('Dashboard 16', error);
        this.showError = true;
      }
    },

    async getBillingUrl() {
      const data = encodeURIComponent(`${window.location.origin}/dashboard`);

      try {
        const sessionBillingUrl = await this.billingSession(data);
        return sessionBillingUrl;
      } catch (error) {
        console.log('Dashboard 17 ',error)
        if (error) {
          console.error('Dashboard 2', error);
          this.showError = true;
          if (error.response && error.response.status === 422) {
            this.errorMessage = 'Something went wrong. Please reload the page';
          }
        }
      }
    },

    // update heplers
    formValidation() {
      let hasError;
      switch(this.currentPage) {
        case 'my-account': {
          if (this.currentPageSubPage === 'personal-information') {
            const emailErrorMessage = this.getEmailErrorMessage;
            const phoneNumberErrorMessage = this.getPhoneNumberErrorMessage;

            if (emailErrorMessage) {
              this.emailError = emailErrorMessage;
              hasError = true;
            }

            if (phoneNumberErrorMessage) {
              this.phoneNumberError = phoneNumberErrorMessage;
              hasError = true;
            }

            return !hasError;
          } else if (this.currentPageSubPage === 'security') {
            const oldPasswordErrorMessage = this.getOldPasswordErrorMessage;
            const passwordErrorMessage = this.getPasswordErrorMessage;
            const passwordConfirmationErrorMessage = this.getConfirmPasswordErrorMessage;

            if (oldPasswordErrorMessage) {
              this.oldPasswordError = oldPasswordErrorMessage;
              hasError = true;
            }

            if (passwordErrorMessage) {
              this.passwordError = passwordErrorMessage;
              hasError = true;
            }

            if (passwordConfirmationErrorMessage) {
              this.passwordConfirmationError = passwordConfirmationErrorMessage;
              hasError = true;
            }

            return !hasError;
          }
          return true;
        }
        case 'company': {
          if (this.currentPageSubPage === 'company-information') {
            const companyPhoneNumberErrorMessage = this.getPhoneNumberErrorMessage;

            if (companyPhoneNumberErrorMessage) {
              this.companyPhoneNumberError = companyPhoneNumberErrorMessage;
              hasError = true;
            }

            return !hasError;
          }
          return true;
        }
        default:
          return true;
      }
    },

    handleUpdateContractorInfo() {
      const isValid = this.formValidation();
      if (!isValid) return;

      this.isUpdating = true;
      if (this.currentPageSubPage === 'personal-information') {
        this.handleUpdatePersonalInformation();
      } else if (this.currentPageSubPage === 'security') {
        this.handleUpdateSecurity();
      } else if (this.currentPageSubPage === 'company-logotype') {
        if (!this.isLogoDeleted) {
          this.handleUpdateLogo();
        } else {
          this.deleteLogo();
        }
      } else if (this.currentPageSubPage === 'company-information') {
        this.handleUpdateCompanyInformation();
      } else if (this.currentPageSubPage === 'company-location') {
        this.handleUpdateCompanyLocation();
      } else if (this.currentPageSubPage === 'company-type') {
        this.handleUpdateCompanyType();
      }
    },

    handleAfterUpdate(page) {
      if (page === 'security') {
        this.oldPassword = '';
        this.password = '';
        this.passwordConfirmation = '';
      } else if (page === 'personal-information') {
        this.email = '';
        this.firstName = '';
        this.lastName = '';
        this.jobTitle = '';
      } else if (page === 'company-information') {
        this.companyName = '';
        this.contactName = '';
      } else if (page === 'company-location') {
        this.companyCity = '';
        this.companyState = '';
        this.companyZip = '';
        this.addressOne = '';
        this.addressTwo = '';
      } else if (page === 'company-logotype') {
        this.isLogoDeleted = false;
        this.file = '';
      } else if (page === 'company-type') {
        this.isCompanyTypeChanged = false;
      }
      this.isUpdating = false;
    },

    // variables helpers
    handleEmailChange(value) {
      if (this.emailError) this.emailError = null;
      this.email = value;
    },

    handleFirstNameChange(value) {
      if (this.firstNameError) this.firstNameError = null;
      this.firstName = value;
    },

    handleLastNameChange(value) {
      if (this.lastNameError) this.lastNameError = null;
      this.lastName = value;
    },

    handleJobTitleChange(value) {
      if (this.jobTitleError) this.jobTitleError = null;
      this.jobTitle = value;
    },

    handlePhoneNumberChange(value) {
      if (this.phoneNumberError) this.phoneNumberError = null;
      this.phoneNumber = value;
    },

    handleOldPasswordChange(value) {
      if (this.oldPasswordError) this.oldPasswordError = null;
      this.oldPassword = value;
    },

    handlePasswordChange(value) {
      if (this.passwordError) this.passwordError = null;
      this.password = value;
    },

    handlePasswordConfirmationChange(value) {
      if (this.passwordConfirmationError) this.passwordConfirmationError = null;
      this.passwordConfirmation = value;
    },

    handleUploadFileChange(value) {
      this.file = value;
      if (this.file) {
        this.isLogoDeleted = false;
        this.getUploadingLogoLink(this.file)
      }
    },

    handleUploadFilePreviewChange(value) {
      this.filePreview = value;
    },

    handleIsLogoDeleted(value) {
      this.isLogoDeleted = value;
    },

    handleUploadFileError(value) {
      this.fileError = value;
    },

    handleCompanyNameChange(value) {
      if (this.companyNameError) this.companyNameError = null;
      this.companyName = value;
    },

    handleContactNameChange(value) {
      if (this.contactNameError) this.contactNameError = null;
      this.contactName = value;
    },

    handleCompanyPhoneNumberChange(value) {
      if (this.companyPhoneNumberError) this.companyPhoneNumberError = null;
      this.companyPhoneNumber = value;
    },

    handleCompanyCityChange(value) {
      if (this.companyCityError) this.companyCityError = null;
      this.companyCity = value;
    },

    handleCompanyStateChange(value) {
      if (this.companyStateError) this.companyStateError = null;
      this.companyState = value;
    },

    handleTradeTypeChange(value) {
      const isIncluded = this.selectedTradeTypes.filter(item => item.id === value.id);
      this.isCompanyTypeChanged = true;
      if (isIncluded.length) {
        const itemIndex = this.selectedTradeTypes.findIndex(item => item.id === value.id);
        this.selectedTradeTypes.splice(itemIndex, 1)
      } else {
        this.selectedTradeTypes.push(value);
      }
    },

    handleCompanyZipChange(value) {
      if (this.companyZipError) this.companyZipError = null;
      this.companyZip = value;
    },

    handleAddressOneChange(value) {
      if (this.addressOneError) this.addressOneError = null;
      this.addressOne = value;
    },

    handleAddressTwoChange(value) {
      this.addressTwo = value;
    },
  }
}
</script>

<style lang="scss">
.dashboard {
  margin: 0 auto;
  position: relative;
  padding-bottom: 100px;
  box-sizing: border-box;
  min-height: 100%;

  &.dashboard-mobile {
    padding-bottom: 115px;
  }

  &.filter-open {
    padding-bottom: 41px;
  }

  &.request-quote {
    padding-bottom: 30px;
  }

  .error {
    position: fixed;
  }

  &.menu-open {
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      background: rgba(21,21,34,0.5);
    }
  }

  &--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 25px 8px;
    border-bottom: 1px solid #CCCCD7;
    margin-bottom: 20px;
    background: #F8FBFE;
    transition: .2s;
    height: 60px;
    position: fixed;
    top: 0;
    left: 0px;
    box-sizing: border-box;
    z-index: 1;
    width: 100%;

    &.inner-page,
    &.centered {
      justify-content: center;
    }

    .notifications & {
      margin-bottom: 0;
    }

    &.search-active {
      height: 0;
      overflow: hidden;
      margin: 0;
      padding: 0 25px;
    }

    &--logo {
      width: 28px;
      height: 28px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 50%;
      background-color: #fff;

      .notifications &,
      .centered & {
        position: absolute;
        left: 26px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &--title {
      color: #151522;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 30px;
    }

    &--button {
      width: 16px;
      height: 16px;

      .inner-page & {
        position: absolute;
        left: 27px;
        top: 50%;
        transform: translateY(-50%);
      }

      &.invite-user-btn {
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;

        &--icon {
          width: 20px;
          height: 20px;
        }
      }

      &.hidden {
        display: none;
      }
    }

    &--reset {
      background: none;
      border: none;
      padding: 0;

      .inner-page & {
        position: absolute;
        right: 22px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &--body {
    padding-top: 80px;

    .search-active & {
      padding-top: 0;
    }

    .notifications & {
      padding-top: 60px;
    }

    &--controls {
      margin: 0 25px 40px;
      transition: .2s;

      &.search-active {
        margin: 0 0 40px;
        background: #F8FBFE;
        border-bottom: 1px solid #BFC3C8;
        padding: 20px 25px 0;
        box-sizing: border-box;
      }

      &--btn {
        width: 100%;
        max-width: 325px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto 13px;

        &--icon {
          margin-right: 6px;
          stroke: #151522;
        }
      }
    }

    &--project-list {
      &--wrapper {
        max-width: 375px;
        margin: 0 auto;
        padding-bottom: 50px;

        &--empty {
          width: 100%;
          height: calc(100vh - 445px);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;

          &--invite-wrapper {
            background: #FFFFFF;
            box-shadow: 0px 2px 16px rgba(21, 21, 34, 0.16);
            border-radius: 10px;
            width: calc(100% - 50px);
            max-width: 325px;
            height: 242px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 100px;
            padding: 0 23px;
            box-sizing: border-box;
            text-align: center;
            cursor: pointer;

            &--text {
              display: flex;
              align-items: center;
              color: #10886D;
              margin-bottom: 12px;

              &--icon {
                stroke: #10886D;
                margin-right: 6px;
              }
            }
          }
        }
      }
    }
  }

  &--copyright {
    color: #9999A0;
    text-align: center;
    width: 100%;
    position: absolute;
    left: 50%;
    bottom: 100px;
    transform: translateX(-50%);

    .filter-open & {
      bottom: 25px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .dashboard {
    max-width: 100%;
    margin-left: 82px;

    &.congrats-quote {
      margin-left: 0;
    }

    &.menu-open {
      &:before {
        content: "";
        width: calc(100% + 82px);
        height: 100vh;
        position: absolute;
        top: 0;
        left: -82px;
        z-index: 3;
        background: rgba(21,21,34,0);
      }
    }

    &--header {
      display: none;
    }

    &--desktop-body {
      padding-top: 233px;

      &.project-details,
      &.quote-details {
        padding-top: 167px;
      }

      .notifications & {
        padding-top: 146px;
      }

      &--filters {
        position: relative;
      }

      &--projects-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: 0 49px;

        &--empty {
          width: 100%;
          height: calc(100vh - 300px);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
</style>
