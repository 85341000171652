<template>
  <div
    class="error"
    :style="errorStyles">
    <inline-svg
      class="error--alert"
      :src="require('@/assets/images/icons/alert-triangle.svg')"
    />
    <p class="error--text">{{ errorMessage }}</p>
    <inline-svg
      class="error--close"
      :src="require('@/assets/images/icons/cross.svg')"
      @click="handleError"
    />
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {

  components: {
    InlineSvg
  },

  props: [
    'errorMessage',
    'handleErrorClose',
    'errorStyles'
  ],

  methods: {
    handleError() {
      this.handleErrorClose();
    },
  },

}
</script>

<style lang="scss">
.error {
  background: #FF4F6A;
  box-shadow: 0px 2px 16px rgba(21, 21, 34, 0.06);
  border-radius: 10px;
  padding: 11px 20px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 30px;
  left: 50%;
  width: 100%;
  max-width: 324px;
  box-sizing: border-box;
  transform: translateX(-50%);
  z-index: 6;

  &--alert {
    margin-right: 7px;
    flex: none;
  }

  &--text {
    font-family: $SFProText;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
  }

  &--close {
    position: absolute;
    top: -8px;
    right: -8px;
    cursor: pointer;
  }
}

@media screen and (max-width: 360px) {
  .error {
    max-width: 90%;
  }
}

@media screen and (min-width: 1024px) {
  .error {
    width: 100%;
    max-width: 415px;

    &--text {
      font-size: 14px;
    }
  }
}
</style>
