<template>
  <div class="customer-request-quote">
    <div class="customer-request-quote--wrapper">
      <div class="customer-request-quote--wrapper--header">
        <p class="h2-sb">Request quote</p>
        <inline-svg
          :src="require('@/assets/images/icons/plus-rotated.svg')"
          class="customer-request-quote--wrapper--header--close"
          @click="handleRequestModal"
        />
      </div>

      <div class="customer-request-quote--wrapper--item options">
        <p class="customer-request-quote--wrapper--item--text h6-light">What type of work are you looking for?</p>
        <div class="customer-request-quote--wrapper--item--options">
          <div
            v-for="type in getWorkTypes"
            class="customer-request-quote--wrapper--item--options--item text-small"
            :class="{'selected': isIncluded(type)}"
            :key="type.id"
            :value="type.id"
            @click="handleTypeChange"
          >
            {{ type.name }}
          </div>
        </div>
      </div>

      <div class="customer-request-quote--wrapper--item">
        <p class="customer-request-quote--wrapper--item--text h6-light">Where are you located?</p>
        <CustomInput
          :modelValue="zipCode"
          @update:modelValue="handleZipChange"
          id="zip-code"
          type="text"
          name="zip-code"
          placeholder="Zip Code"
          marginBottom="0"
          :errorMessage="null"
        />
      </div>

      <div class="customer-request-quote--wrapper--item">
        <p class="customer-request-quote--wrapper--item--text h6-light">Choose contractors company where you want to make a quote request</p>
        <ContractorMultySelect
          :modelValue="selectedContractors"
          @update:modelValue="handleSelectContractor"
          id="request-quote"
          name="request-quote"
          placeholder="Choose available contractors"
          :errorMessage="null"
          marginBottom='0'
          :zipCode="zipCode"
          :selectedWorkTypes="selectedWorkTypes"
        />
      </div>

      <button
        class="customer-request-quote--wrapper--button normal-btn"
        :class="isbuttonDisabled && 'disabled'"
        @click="handleOpenInnerPage('request-quote-form', selectedContractors)"
      >Request quote</button>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import InlineSvg from 'vue-inline-svg';
import ContractorMultySelect from '@/components/dashboard/ContractorMultySelect';
import CustomInput from '@/components/shared/CustomInput';

export default {
  components: {
    InlineSvg,
    ContractorMultySelect,
    CustomInput
  },

  props: [
    'handleRequestModal',
    'handleOpenInnerPage'
  ],

  data: () => ({
    selectedContractors: [],
    zipCode: '',
    selectedWorkTypes: []
  }),

  computed: {
    ...mapGetters([
      'getWorkTypes',
    ]),

    isbuttonDisabled() {
      return this.selectedContractors.length === 0
    }
  },

  methods: {
    handleSelectContractor(contractor) {
      if (this.selectedContractors.length && this.selectedContractors[0].contractor_id === contractor.contractor_id) {
        this.selectedContractors.length = 0;
      } else {
        this.selectedContractors[0] = contractor;
      }
      // logic for two additional contractors
      // const isIncluded = this.selectedContractors.filter(item => item.contractor_id === contractor.contractor_id);
      // if (isIncluded.length) {
      //   let itemIndex = this.selectedContractors.indexOf(contractor);
      //   this.selectedContractors.splice(itemIndex, 1)
      // } else if (this.selectedContractors.length < 2) {
      //   this.selectedContractors.push(contractor);
      //   const uniqueArray = this.selectedContractors.filter((item, index) => {
      //     const _item = JSON.stringify(item);
      //     return index === this.selectedContractors.findIndex(obj => {
      //       return JSON.stringify(obj) === _item;
      //     });
      //   });
      //   this.selectedContractors = uniqueArray;
      // }
    },

    handleZipChange(value) {
      this.zipCode = value;
    },

    handleTypeChange(e) {
      const selected = this.getWorkTypes.find(item => 
        item.id === Number(e.target.getAttribute('value'))
      );

      const isIncluded = this.selectedWorkTypes.filter(item => item.id === selected.id);
      if (isIncluded.length) {
        let itemIndex = this.selectedWorkTypes.indexOf(selected);
        this.selectedWorkTypes.splice(itemIndex, 1);
      } else {
        this.selectedWorkTypes.push(selected);
      }
    },

    isIncluded(option) {
      let match = this.selectedWorkTypes.filter(item => item.id === option.id);
      return match.length;
    },
  }
}
</script>

<style lang="scss">
.customer-request-quote {
  max-width: 375px;
  margin: 0 auto;

  &--wrapper {
    padding: 0 25px;

    &--header {
      display: none;
    }

    &--item {
      margin-bottom: 20px;

      &.options {
        margin-bottom: 10px;
      }

      &--text {
        margin-bottom: 12px;
        color: #4B617A;
      }

      &--options {
        display: flex;
        flex-wrap: wrap;

        &--item {
          background: #fff;
          border: 1px solid #E6E6EB;
          border-radius: 5px;
          padding: 5px 10px;
          font-size: 14px;
          color: #151522;
          box-sizing: border-box;
          cursor: pointer;
          margin: 0 10px 10px 0;

          &.selected {
            background: #E6E6EB;
          }
        }
      }
    }

    &--button {
      padding: 13px 0;
      max-width: 325px;
      width: 100%;
    }
  }
}

@media screen and (min-width: 1024px) {
  .customer-request-quote {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(21,21,34,0.5);
    width: 100%;
    max-width: 100%;
    height: 100%;
    z-index: 3;

    &--wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      background: #fff;
      border-radius: 26px;
      display: flex;
      flex-direction: column;
      width: 477px;
      padding: 40px;
      box-sizing: border-box;

      &--header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;

        p {
          color: #151522;
        }

        &--close {
          cursor: pointer;
          margin-right: 10px;
        }
      }

      &--item {
        margin-bottom: 20px;
      }

      &--button {
        max-width: 240px;
      }
    }
  }
}
</style>
