<template>
  <div class="invite-customer">
    <div class="invite-customer--wrapper">
      <div class="invite-customer--wrapper--header">
        <p class="h2-sb">Invite customer</p>
        <inline-svg
          :src="require('@/assets/images/icons/plus-rotated.svg')"
          class="invite-customer--wrapper--header--close"
          @click="handleRequestModal"
        />
      </div>

      <div class="invite-customer--wrapper--description">
        <p class="invite-customer--wrapper--description--text">Copy, Paste and Send an existing customer or prospect your unique link so that they can easily 'request a quote'. Any inbound quote requests will show up in your projects dashboard. </p>
      </div>

      <div class="invite-customer--wrapper--item">
        <p class="invite-customer--wrapper--item--title label">Request quote link</p>
        <div class="invite-customer--wrapper--item--input-wrapper">
          <CustomInput
            :modelValue="requestQuoteLink"
            id="request-quote-link"
            name="request-quote-link"
            placeholder="Generated Link"
            marginBottom="0"
            :errorMessage="null"
          />
          <div
            class="invite-customer--wrapper--item--input-wrapper--copy"
            :class="[{'active': requestQuoteLink.length},
                    {'copied': isCopied}]"
          >
            <inline-svg
              :src="require('@/assets/images/icons/copy.svg')"
              class="invite-customer--wrapper--item--input-wrapper--copy--icon"
              :class="!requestQuoteLink.length && 'disabled'"
              @click="handleCopy($event)"
            />
            <p class="invite-customer--wrapper--item--input-wrapper--copy--notice h6-regular"
            >{{isCopied ? 'Copied!' : ''}}</p>
          </div>
        </div>
        <p class="invite-customer--wrapper--item--description">This link allows to request the  quote only once</p>
        <button
          class="invite-customer--wrapper--item--button normal-btn"
          :class="isLinkGenerated && 'disabled'"
          @click="handleGenerate($event)"
        >{{isLinkGenerated ? 'Generated!' : 'Generate Link'}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import CustomInput from '@/components/shared/CustomInput';
import InlineSvg from 'vue-inline-svg';

export default {
  components: {
    CustomInput,
    InlineSvg
  },

  props: [
    'handleRequestModal'
  ],

  data: () => ({
    isCopied: false,
    isLinkGenerated: false,
    requestQuoteLink: ''
  }),

  watch: {
    getInviteKey() {
      this.setInvitationLinks();
    },
  },

  computed: {
    ...mapGetters([
      'getInviteKey'
    ]),
  },

  methods: {
    ...mapActions([
      'setInvitationKey'
    ]),

    async handleGenerate(event) {
      await this.setInvitationKey()
      this.setInvitationLinks();
      const input = event.target.closest('.invite-customer--wrapper--item').querySelector('input');
      input.value = this.requestQuoteLink;
      this.isLinkGenerated = true;
      if (this.isCopied) {
        this.isCopied = false;
      }
      setTimeout(() => {
        this.isLinkGenerated = false;
      }, 3000);
    },

    handleCopy(event) {
      const copy = event.target.closest('.invite-customer--wrapper--item').querySelector('input');
      copy.select();
      document.execCommand("copy");
      this.isCopied = true;
    },

    setInvitationLinks() {
      this.requestQuoteLink = window.location.origin + '/customer-quote?invite_key=' + this.getInviteKey;
    },
  }
}
</script>

<style lang="scss">
.invite-customer {
  max-width: 375px;
  margin: 0 auto;

  &--wrapper {
    padding: 5px 25px 0;

    &--header {
      display: none;
    }

    &--description {
      margin-bottom: 24px;

      &--text {
        color: #4B617A;
        font-family: $SFProText;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 150%;
        margin-right: 0px;
      }
    }

    &--item {
      margin-bottom: 50px;

      &--title {
        margin-bottom: 13px;
        color: #151522;
      }

      &--input-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        &--copy {
          position: relative;
          margin-left: 20px;
          margin-right: 20px;

          &--icon {
            width: 24px;
            height: 24px;
            cursor: pointer;

            &.disabled {
              pointer-events: none;
            }

            path {
              .copied & {
                stroke: #10886D;
              }

              .active & {
                stroke: #151522;
              }
            }
          }

          &--notice {
            color: #10886D;
            position: absolute;
            left: -8px;
            bottom: -25px;
          }
        }
      }

      &--button {
        padding: 13px 0;
        max-width: 325px;
        width: 100%;
      }

      &--description {
        color: #4B617A;
        font-family: $SFProText;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 150%;
        margin-bottom: 20px;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .invite-customer {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(21,21,34,0.5);
    width: 100%;
    max-width: 100%;
    height: 100%;
    z-index: 3;

    &--wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      background: #fff;
      border-radius: 26px;
      display: flex;
      flex-direction: column;
      width: 477px;
      padding: 40px 40px 8px;
      box-sizing: border-box;

      &--header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 6px;

        p {
          color: #151522;
        }

        &--close {
          cursor: pointer;
        }
      }

      &--description {
        &--text {
          margin-right: 10px;
          font-size: 14px;
        }
      }

      &--item {
        margin-bottom: 32px;

        &--input-wrapper--copy {
          margin-right: 0;
        }

        &--button {
          max-width: 240px;
        }

         &--description {
           font-size: 14px;
         }
      }
    }
  }
}
</style>
