import { mapActions } from 'vuex';
import moment from 'moment';
export default {
  methods: {
    ...mapActions([
      'notificationsInfo'
    ]),

    handleErrorClose() {
      this.showError = false;
    },

    setUpdatedFiledsForSave(intialValue, value, fieldName, dataName) {
      const currentField = this.fieldsChanged.find(field => field.fieldName === fieldName);

      if (!currentField) {
        this.fieldsChanged.push(
          {
            fieldName,
            dataName,
            intialValue
          }
        )
      } else if (currentField.intialValue === value) {
        const updatedFieldsChanged = this.fieldsChanged.filter(field => field.fieldName === fieldName);
        this.fieldsChanged = updatedFieldsChanged;
      }
    },

    handleProjectNameChange(value) {
      if (this.isUpdateQuoteDetails) {
        this.$emit('update:isProjectDetailsChanged');
        this.isBasicInfoChanged = true;
        this.setUpdatedFiledsForSave(this.getProjectInfo.project.name, value, 'name', 'projectName');
      }
      if (this.projectNameError) this.projectNameError = null;
      this.projectName = value;
    },

    handleContactNameChange(value) {
      if (this.isUpdateQuoteDetails) {
        this.$emit('update:isProjectDetailsChanged');
        this.isBasicInfoChanged = true;
        this.setUpdatedFiledsForSave(this.getProjectInfo.project.contact_name, value, 'contact_name', 'contactName');
      }
      if (this.contactNameError) this.contactNameError = null;
      this.contactName = value;
    },

    handleContactEmailChange(value) {
      if (this.isUpdateQuoteDetails) {
        this.$emit('update:isProjectDetailsChanged');
        this.isBasicInfoChanged = true;
        this.setUpdatedFiledsForSave(this.getProjectInfo.project.email, value, 'email', 'contactEmail');
      }
      if (this.contactEmailError) this.contactEmailError = null;
      this.contactEmail = value;
    },

    handleStartDateChange(value) {
      if (this.isUpdateQuoteDetails) {
        this.$emit('update:isProjectDetailsChanged');
        this.isBasicInfoChanged = true;
        this.setUpdatedFiledsForSave(this.getProjectInfo.project.start_date, value, 'start_date', 'startDate');
      }
      if (this.startDateError) this.startDateError = null;
      this.startDate = value;
    },

    handleProjectTypeChange(value) {
      if (this.isUpdateQuoteDetails) {
        this.$emit('update:isProjectDetailsChanged');
        this.isBasicInfoChanged = true;
        this.setUpdatedFiledsForSave(this.getProjectInfo.project.existing, value, 'existing', 'projectType');
      }
      if (this.projectTypeError) this.projectTypeError = null;
      this.projectType = value;
    },

    handleTimeRequirementsChange(value) {
      if (this.isUpdateQuoteDetails) {
        this.$emit('update:isProjectDetailsChanged');
        this.isRequirementsChanged = true;
        this.setUpdatedFiledsForSave(this.getProjectInfo.project.time_requirements, value, 'time_requirements', 'timeRequirements');
      }
      this.timeRequirements = value;
    },

    handleBlackoutDaysChange(value) {
      if (this.isUpdateQuoteDetails) {
        this.$emit('update:isProjectDetailsChanged');
        this.isRequirementsChanged = true;
        this.setUpdatedFiledsForSave(this.getProjectInfo.project.blackout_days, value, 'blackout_days', 'blackoutDays');
      }
      this.blackoutDays = value;
    },

    handleSpecialRequirementsChange(value) {
      if (this.isUpdateQuoteDetails) {
        this.$emit('update:isProjectDetailsChanged');
        this.isRequirementsChanged = true;
        this.setUpdatedFiledsForSave(this.getProjectInfo.project.special_requirements, value, 'special_requirements', 'specialRequirements');
      }
      this.specialRequirements = value;
    },

    handleLocationChange(value) {
      if (this.isUpdateQuoteDetails) {
        this.$emit('update:isProjectDetailsChanged');
        this.isSiteChanged = true;
        this.setUpdatedFiledsForSave(this.getProjectInfo.project.location, value, 'location', 'location');
      }
      if (this.locationError) this.locationError = null;
      this.location = value;
    },

    handleSafetyRequirementsChange(value) {
      if (this.isUpdateQuoteDetails) {
        this.$emit('update:isProjectDetailsChanged');
        this.isSiteChanged = true;
        this.setUpdatedFiledsForSave(this.getProjectInfo.project.safety_requiremets, value, 'safety_requiremets', 'safetyRequirements');
      }
      this.safetyRequirements = value;
    },

    handleSiteVisitChange(value) {
      if (this.isUpdateQuoteDetails) {
        this.$emit('update:isProjectDetailsChanged');
        this.isSiteChanged = true;
        this.setUpdatedFiledsForSave(this.getProjectInfo.project.site_visit, value, 'site_visit', 'siteVisit');
      }
      if (this.siteVisitError) this.siteVisitError = null;
      this.siteVisit = value;
    },

    handleAccessibilityChange(value) {
      if (this.isUpdateQuoteDetails) {
        this.$emit('update:isProjectDetailsChanged');
        this.isSiteChanged = true;
        this.setUpdatedFiledsForSave(this.getProjectInfo.project.accessibility, value, 'accessibility', 'accessibility');
      }
      this.accessibility = value;
    },

    handleUploadPhotosPreviewChange(value) {
      if (this.isUpdateQuoteDetails) {
        this.$emit('update:isProjectDetailsChanged');
        this.isPhotosChanged = true;
      }
      this.photosPreview.push(value);
    },

    handleUploadFilesPreviewChange(value) {
      if (this.isUpdateQuoteDetails) {
        this.$emit('update:isProjectDetailsChanged');
        this.isFilesChanged = true;
      }
      this.filesPreview.push(value);
    },

    setDateDaysData(days) {
      if (days) {
        const daysArray = days.split(',');
        let formattedDays = [];
        daysArray.forEach((item) => {
          const newItem = moment(item,'MM/DD/YYYY').format('DD-MM-YYYY');
          formattedDays.push(newItem);
        });
        return formattedDays.join(', ');
      } else {
        return days;
      }
    },

    async submitForm(userRole = '') {
      let additionalContractorIds = [];
      if (this.contractorForQuote && this.contractorForQuote.length > 1) {
        this.contractorForQuote.forEach((item, index) => {
          if (index < 1) return;
          additionalContractorIds.push(item.contractor_id);
        })
      }
      const data = {
        params: {
          invite_key: this.$router.currentRoute._value.query.invite_key,
          ...(additionalContractorIds.length ? { additional_contractor_ids: additionalContractorIds } : {}),
          company_project: {
            ...((this.projectId !== null) ? {id: this.projectId} : {}),
            ...(this.getContractorCompanyMainInfo
              ? { contractor_id: this.getContractorCompanyMainInfo.contractor_id,
                  contractor_contact_id: this.getContractorCompanyMainInfo.contractor_contact_id }
              : {}),
            ...(this.contractorForQuote && this.contractorForQuote.length ? { contractor_id: this.contractorForQuote[0].contractor_id } : {}),
            ...((this.getCurrentUser && this.getCurrentUser?.type === 'contractor')
              ? { contractor_id: this.getCurrentUser?.company_id }
              : {}),
            project_attributes: {
              name: this.projectName,
              start_date: this.setDateDaysData(this.startDate),
              contact_name: this.contactName,
              email: this.contactEmail,
              time_requirements: this.timeRequirements,
              special_requirements: this.specialRequirements,
              location: this.location,
              safety_requiremets: this.safetyRequirements,
              blackout_days: this.setDateDaysData(this.blackoutDays),
              accessibility: this.accessibility,
              site_visit: this.siteVisit,
              existing: this.projectType,
            }
          }
        }
      }

      try {
        await this.companyProject(data);
        if (userRole === 'customer') {
          this.$emit('update:quoteCurrentStep', 6);
        } else {
          this.currentStep++;
        }
      } catch (error) {
        if (error) {
          console.error('quote helper', error);
          this.showError = true;
          if (error.response && error.response.status === 401) {
            this.errorMessage = 'We couldn’t find an account matching the email and password you entered. Please check your email and password and try again.';
          }
        }
      }
    },

    async uploadFiles(uploadingFiles, fileType, userRole = '') {
      const files = uploadingFiles;

      const filePrefix = () => Math.random().toString(36).substr(2, 9);

      const getUploadedData = (file, isFirstUpload) => {
        const fileName = `${filePrefix()}-${file.name}`.replace(/\s/g, '_');
        const urlParams = new URLSearchParams(window.location.search);
        const inviteKey = urlParams.get('invite_key');
        const data = {
          ...((inviteKey && inviteKey.length) ? {invite_key: inviteKey} : {}),
          company_project: {
            ...((!isFirstUpload || this.projectId !== null) ? {id: this.projectId} : {}),
            ...((this.getProjectInfo && this.getProjectInfo.id) ? {id: this.getProjectInfo.id} : {}),
            ...((userRole !== 'customer') ? {contractor_id: this.getContractorCompanyMainInfo.contractor_id} : {}),
            file_storages_attributes: [
              {
                file_name: fileName,
                file_type: file.type,
                file_size: file.size.toString()
              }
            ],
          },
          ...((this.getProjectInfo && this.getProjectInfo.id)
            ? { activity: {
                  activity_type: 'add_files',
                  file_session: this.updatingQouteDetailsSession
                }}
            : {}),
        }
        return {data, fileName}
      }

      const getSecureUrl = (isFirstUpload, file) => new Promise((resolve, reject) => {
        const {data, fileName} = getUploadedData(file, isFirstUpload)
        const xmlhttp = new XMLHttpRequest();
        xmlhttp.open("PUT", `${process.env.VUE_APP_STAGING_API_URL}gc_upload_link`);
        xmlhttp.setRequestHeader("Content-Type", "application/json");
        if (userRole === 'customer') {
          xmlhttp.setRequestHeader("Authorization", 'Bearer ' + this.getToken);
        }
        xmlhttp.onload = () => {
          if (xmlhttp.readyState == 4 && xmlhttp.status == "200") {
            const response = JSON.parse(xmlhttp.response);

            if (isFirstUpload) {
              this.projectId = response.id;
            }

            resolve({response, fileName});
          } else {
            reject()
            this.showError = true;
            this.errorMessage = 'An error occurred during upload!';
          }
        }
        xmlhttp.send([JSON.stringify(data)]);
      })

      const uploadToStorage = (response, file, fileName) => new Promise((resolve) => {
        const uploadedFile = response.file_storages.find(o => o.file_name === fileName);
        const uploadedFileId = uploadedFile.id;

        var xhr = new XMLHttpRequest();
        xhr.open('PUT', response.url, true);
        xhr.onload = () => {
            if (xhr.readyState == 4 && xhr.status == "200") {
              var data = {
                id: uploadedFileId
              }

              var req = new XMLHttpRequest();
              req.open("PUT", `${process.env.VUE_APP_STAGING_API_URL}update_uploaded_status`, true);
              req.setRequestHeader("Content-Type", "application/json");
              req.onload = () => {
                if (req.readyState == 4 && req.status == "200") {
                  if (this.getProjectInfo && this.getProjectInfo.id) {
                    this.notificationsInfo();
                  }
                  const response = JSON.parse(req.response);
                  resolve(response)
                } else {
                  this.showError = true;
                  this.errorMessage = 'An error occurred during upload!';
                }
              }
              req.send([JSON.stringify(data)]);

            } else {
              this.showError = true;
              this.errorMessage = 'An error occurred during upload!';
            }
        };
        xhr.setRequestHeader('Content-Type', file.type);
        xhr.send(file.slice());
      })

      const firstUpload = () => {
        return getSecureUrl(true, files[0]).then(({response, fileName}) => {
            uploadToStorage(response, files[0], fileName).then(response => {
              if(fileType == "photo") {
                this.photosIds.push({id: response.id, name: response.file_name});
              } else if (fileType == "file") {
                this.filesIds.push({id: response.id, name: response.file_name});
              }
            })
          })
        }

        firstUpload().then(() => {
          for (let i = 1; i < files.length; i++) {
            getSecureUrl(false, files[i]).then(({response, fileName}) => {
              uploadToStorage(response, files[i], fileName).then(response => {
                if(fileType == "photo") {
                  this.photosIds.push({id: response.id, name: response.file_name});
                } else if (fileType == "file") {
                  this.filesIds.push({id: response.id, name: response.file_name});
                }
              })
            })
          }
      })
    },

    deleteFile(itemIndex, fileType, unregisteredUser = '') {
      var data;
      if (fileType == "photo") {
        data = {
          ...((this.getProjectInfo && this.getProjectInfo.id)
            ? { activity: {
                  activity_type: 'delete_files',
                  file_session: this.updatingQouteDetailsSession
                }}
            : {}),
          id: Array.from(this.photosIds)[itemIndex].id
            ? Array.from(this.photosIds)[itemIndex].id
            : Array.from(this.photosIds)[itemIndex]
        }
      } else if (fileType == "file") {
        data = {
          ...((this.getProjectInfo && this.getProjectInfo.id)
            ? { activity: {
                  activity_type: 'delete_files',
                  file_session: this.updatingQouteDetailsSession
                }}
            : {}),
          id: Array.from(this.filesIds)[itemIndex].id
            ? Array.from(this.filesIds)[itemIndex].id
            : Array.from(this.filesIds)[itemIndex]
        }
      }

      var xhr = new XMLHttpRequest();
      if (unregisteredUser === 'unregistered-user') {
        xhr.open("DELETE", `${process.env.VUE_APP_STAGING_API_URL}delete_file_from_quote`, true);
      } else {
        xhr.open("DELETE", `${process.env.VUE_APP_STAGING_API_URL}delete_file`, true);
        xhr.setRequestHeader("Authorization", 'Bearer ' + this.getToken);
      }
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.onload = () => {
        if (xhr.readyState == 4 && xhr.status == "200") {
          if (fileType == "photo") {
            this.photos.splice(itemIndex, 1);
            this.photosPreview.splice(itemIndex, 1);
            this.photosIds.splice(itemIndex, 1);
          } else if (fileType == "file") {
            this.files.splice(itemIndex, 1);
            this.filesIds.splice(itemIndex, 1);
            this.filesPreview.splice(itemIndex, 1);
          }
          if (this.getProjectInfo && this.getProjectInfo.id) {
            this.notificationsInfo();
          }
        } else {
          this.showError = true;
          this.errorMessage = 'An error occurred during deleting!';
        }
      }
      xhr.send([JSON.stringify(data)]);
      if (fileType == 'photo') {
        this.isPhotosChanged = true;
      } else if (fileType == 'file') {
        this.isFilesChanged = true;
      }
    },

  }
};
