<template>
  <div class="project-gallery">
    <div
      v-if="showSlider && photosDetails.photos"
      class="project-gallery--slider"
    >
      <div class="project-gallery--slider--header">
        <inline-svg
          :src="require('@/assets/images/icons/cross-white-small.svg')"
          class="project-gallery--slider--header--close"
          @click="handleCloseSlider"
        />

        <p class="project-gallery--slider--header--text h5-regular">
          {{ sliderCounter }}
        </p>

        <div class="project-gallery--slider--header--controls">
          <div
            class="project-gallery--slider--header--controls--show-btn"
            @click="handleShowGallery"
          >
            <inline-svg
              :src="require('@/assets/images/icons/gallery-all.svg')"
              class="project-gallery--slider--header--controls--show-btn--icon"
            />
            <p class="project-gallery--slider--header--controls--show-btn--text btn-font-large">Show all photos</p>
          </div>
          <div
            class="project-gallery--slider--header--controls--btn"
            @click="handleDownload"
          >
            <inline-svg
              :src="require('@/assets/images/icons/download-cloud.svg')"
              class="project-gallery--slider--header--controls--btn--icon"
            />
          </div>
          <div
            class="project-gallery--slider--header--controls--btn"
            :class="isCopied && 'copied'"
            @click="handleShare"
          >
            <inline-svg
              :src="require('@/assets/images/icons/share.svg')"
              class="project-gallery--slider--header--controls--btn--icon"
            />
            <p
              class="project-gallery--slider--header--controls--btn--notice btn-font-medium"
            >Copied</p>
          </div>
        </div>
      </div>

      <splide
        class="project-gallery--slider--list"
        :options="sliderOptions"
        @splide:moved="handlePagination"
      >
        <splide-slide
          v-for="(photo, index) in photosDetails.photos"
          :key="photo.id"
          class="project-gallery--slider--list--image"
          :style="{'background-image': `url('${photo.file_url}')`}"
          @click="handleGalleryClick(index)"
        ></splide-slide>
      </splide>
      <div
        v-if="+imageIndex === 0 && !isMobile"
        class="project-gallery--slider--list--dissable left"
      ></div>
      <div
        v-if="+imageIndex +1 === photosDetails.photos.length && !isMobile"
        class="project-gallery--slider--list--dissable right"
      ></div>
      <div class="project-gallery--slider--navigation">
        <inline-svg
          :src="require('@/assets/images/icons/arrow-left-slider.svg')"
          class="project-gallery--slider--navigation--arrow left"
          :class="+imageIndex === 0 && 'disable'"
        />
        <p class="project-gallery--slider--navigation--text h5-regular">
          {{ sliderCounter }}
        </p>
        <inline-svg
          :src="require('@/assets/images/icons/arrow-right-slider.svg')"
          class="project-gallery--slider--navigation--arrow right"
          :class="+imageIndex +1 === photosDetails.photos.length && 'disable'"
        />
      </div>

    </div>

    <div
      v-else
      class="project-gallery--list"
    >
      <div class="project-gallery--list--header">
        <router-link
          :to="`/dashboard/project/${projectId}`"
          class="project-gallery--list--header--back-btn"
        >
          <inline-svg
            :src="require('@/assets/images/icons/arrow-left.svg')"
            class="project-gallery--list--header--back-btn--icon"
          />
        </router-link>

        <h3 class="project-gallery--list--header--title h4-sb">Gallery</h3>
      </div>

      <div class="project-gallery--list--body">
        <div
          v-for="(photo, index) in photosDetails.photos"
          :key="photo.id"
          class="project-gallery--list--body--image"
          :style="{'background-image': `url('${photo.file_url}')`}"
          @click="handleGalleryClick(index)"
        ></div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

import commonHelpers from '@/mixins/commonHelpers';

import InlineSvg from 'vue-inline-svg';

export default {
  name: 'ProjectGallery',

  mixins: [commonHelpers],

  components: {
    InlineSvg,
    Splide,
    SplideSlide,
  },

  data: () => ({
    projectId: '',
    photosPreview: [],
    photosDetails: [],
    imageIndex: '',
    showSlider: false,
    showGalleryClicked: false,
    windowWidth: window.innerWidth,
    isMobile: (window.innerWidth < 1024),
    isCopied: false
  }),

  beforeMount() {
    this.projectId = this.$route.params.id;
    this.imageIndex = this.$route.params.index;
    this.showSlider = this.$route.params.showSlider === 'true' ? true : false;
  },

  mounted() {
    this.setPhotosDetails();
    window.addEventListener('resize', this.getWindowWidth);
    window.scrollTo(0,0);
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.getWindowWidth);
  },

  computed: {
    ...mapGetters([
      'getProjectInfo'
    ]),

    sliderOptions() {
      return {
        type: this.sliderType,
        perPage: 1,
        gap: 0,
        padding: 0,
        height: '70vh',
        start: this.imageIndex,
        pagination: false,
        arrows: true,
        classes: {
          arrows: 'splide__arrows project-gallery--slider--list--navigation',
          arrow : 'splide__arrow project-gallery--slider--list--navigation--arrow',
          prev  : 'splide__arrow--prev project-gallery--slider--list--navigation--arrow prev',
          next  : 'splide__arrow--next project-gallery--slider--list--navigation--arrow next',
        }
      }
    },

    sliderCounter() {
      return `${+this.imageIndex + 1}/${this.photosDetails.photos.length}`;
    },

    sliderType() {
      return this.isMobile ? 'slide' : 'fade';
    }
  },

  watch: {
    getProjectInfo() {
      this.setPhotosDetails();
    }
  },

  methods: {
    setPhotosDetails() {
      const photoTypes = '.png, .jpg, .jpeg';

      if (this.getProjectInfo.file_storages && this.getProjectInfo.file_storages.length) {
        this.photosPreview = this.getProjectInfo.file_storages.filter(file => {
          if (photoTypes.includes(file.file_name.split('.').pop())) {
            return file;
          }
        })

        if (this.photosPreview.length) {
          this.photosDetails = {
            title: 'Photos',
            photos: this.photosPreview
          }
        }
      }
    },

    handleGalleryClick(index) {
      this.imageIndex = index;
      this.showSlider = true;
    },

    handleShowGallery() {
      this.showGalleryClicked = true;
      this.showSlider = false;
    },

    handleCloseSlider() {
      if (this.$route.params.showSlider === 'true' && !this.showGalleryClicked) {
        this.$router.push({ name: 'ProjectPage', params: {id: this.projectId} });
      } else {
        this.handleShowGallery();
      }
    },

    handlePagination(newIndex) {
      this.imageIndex = newIndex._i;
    },

    handleShare() {
      const url = this.photosDetails.photos[this.imageIndex].file_url;
      if (navigator.canShare) {
        navigator.share({
          text: url,
        })
        .catch((error) => console.log('Sharing failed', error));
      } else {
        const input = document.createElement("input");
        input.value = url;
        document.body.appendChild(input);
        input.select();
        document.execCommand("copy");
        document.body.removeChild(input);
        this.isCopied = true;
        setTimeout(() => {
          this.isCopied = false;
        }, 3000);
      }
    },

    async handleDownload() {
      const url = this.photosDetails.photos[this.imageIndex].file_url;
      const a = document.createElement("a");
      a.href = await this.toDataURL(url);
      a.download = this.photosDetails.photos[this.imageIndex].file_name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    async toDataURL(url) {
      const response = await fetch(url);
      const blob = await response.blob();
      return URL.createObjectURL(blob);
    },
  }
}
</script>

<style lang="scss">
.project-gallery {
  &--list {
    margin-top: 50px;

    &--header {
      position: relative;
      margin-bottom: 32px;

      &--back-btn {
        position: absolute;
        top: 50%;
        left: 18px;
        transform: translateY(-50%);
        width: 28px;
        height: 28px;

        &--icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
        }
      }

      &--title {
        color: #151522;
        text-align: center;
      }
    }

    &--body {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      &--image {
        position: relative;
        width: calc(50% - 4.5px);
        height: calc(50% - 4.5px);
        margin-bottom: 9px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        cursor: pointer;

        &:after {
          content: "";
          display: block;
          padding-bottom: 100%;
        }
      }
    }
  }

  &--slider {
    padding: 45px 0 38px;
    box-sizing: border-box;
    background: #151522;
    min-height: 100vh;

    &--list {
      margin-bottom: 50px;

      &--image {
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
      
      &--navigation {
        position: absolute;
        bottom: -80px;
        max-width: 220px;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
        height: 32px;

        &--arrow {
          background: none;

          svg {
            display: none;
          }

          &.prev {
            left: 0;
          }

          &.next {
            right: 0;
          }
        }
      }
    }

    &--header {
      margin: 0 auto 40px;
      max-width: 325px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &--text {
        display: none;
      }

      &--close {
        width: 28px;
        height: 28px;
        cursor: pointer;
      }

      &--controls {
        display: flex;
        align-items: center;

        &--btn {
          border: 1px solid #fff;
          box-sizing: border-box;
          border-radius: 5px;
          padding: 7px;
          width: 36px;
          height: 36px;
          cursor: pointer;
          margin-left: 10px;
          position: relative;

          &.copied {
            border: 1px solid #21aa8a;
          }

          &--notice {
            position: absolute;
            top: 54px;
            right: 0px;
            background: #fff;
            border: 1px solid #E5E5E5;
            box-sizing: border-box;
            border-radius: 4px;
            padding: 5px 10px;
            color: #000;
            font-size: 14px;
            display: none;

            .copied & {
              display: block;
            }
          }
        }

        &--show-btn {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: 1px solid #fff;
          box-sizing: border-box;
          border-radius: 5px;
          padding: 9px 20px;
          height: 42px;
          cursor: pointer;

          &--icon {
            width: 20px;
            height: 20px;
            margin-right: 6px;
          }

          &--text {
            color: #fff;
          }
        }
      }
    }

    &--navigation {
      max-width: 195px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &--arrow {
        stroke: #fff;

        &.disable {
          pointer-events: none;
          opacity: .5;
        }
      }

      &--text {
        color: #fff;
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .project-gallery--slider--header {
    margin: 0 auto 40px;
    max-width: 90%;
  }
}

@media screen and (min-width: 1024px) {
  .project-gallery {
    &--list {
      margin-top: 0;
      overflow: hidden;

      &--header {
        &--back-btn {
          top: 56px;
          left: 50px;
          width: 32px;
          height: 32px;
        }

        &--title {
          display: none;
        }
      }

      &--body {
        margin: 11% auto;
        max-width: 70%;

        &--image {
          width: calc(50% - 10px);
          height: calc(50% - 10px);
          margin-bottom: 20px;
        }
      }
    }

    &--slider {
      padding: 45px 0;

      &--header {
        flex-direction: row-reverse;
        max-width: 92%;
        position: relative;
        margin: 0 auto 9.5vh;

        &--controls {
          &--show-btn {
            padding: 9px 27px;
          }

          &--btn {
            width: 42px;
            height: 42px;
            padding: 10px;

            &--notice {
              right: -78px;
            }
          }
        }

        &--text {
          display: inline;
          color: #fff;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
        }
      }

      &--list {
        margin-bottom: 0;

        .splide__track {
          margin: 0 12%;
        }

        &--dissable {
          width: 40px;
          height: 40px;
          position: fixed;
          top: calc(50% + 50px);
          transform: translateY(-50%);
          z-index: 1;

          &.left {
            left: 4%
          }

          &.right {
            right: 4%
          }
        }

        &--navigation {
          position: fixed;
          bottom: unset;
          top: calc(50% + 50px);
          max-width: 100%;
          height: 40px;
          left: unset;
          transform: translateY(-50%);
          z-index: 1;

          &--arrow {
            opacity: 0;
            width: 40px;
            height: 40px;

            &:hover {
              background: rgba(255, 255, 255, 0.24);
            }

            &:active,
            &:focus {
              background: rgba(255, 255, 255, 0.4);
            }

            &.prev {
              left: 4%;
            }

            &.next {
              right: 4%;
            }
          }
        }
      }

      &--navigation {
        position: absolute;
        top: calc(50% + 50px);
        transform: translateY(-50%);
        width: 100%;
        max-width: 100%;

        &--arrow {
          border: 1px solid #FFFFFF;
          opacity: 1;
          width: 40px;
          height: 40px;
          transition: .3s;
          border-radius: 50%;
          padding: 14px;
          box-sizing: border-box;
          cursor: pointer;
          position: absolute;

          path {
            stroke: #fff;
            stroke-width: 2px;
          }

          &.disable {
            pointer-events: none;
            opacity: .5;
            cursor: default;
          }

          &.left {
            left: 4%;
          }

          &.right {
            right: 4%;
          }
        }

        &--text {
          display: none;
        }
      }
    }
  }
}
</style>
