export const validationUtils = {

  isEmailValid(email) {
    const isEmpty = !email && 'Field is required';
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i;
    const isInvalid = Boolean(!email.match(emailRegex)) && 'Invalid Email';
    return isEmpty || isInvalid;
  },

  getPasswordErrorMessage(password) {
    const isEmpty = !password && 'Field is required';
    const isShort = password.trim().length < 8 && 'Should be more than 8 characters';
    const isLong = password.trim().length > 22 && 'Should be less than 22 characters';

    return isEmpty || isShort || isLong;
  },

  getConfirmPasswordErrorMessage(password, confirmPassword) {
    const noMatch = password !== confirmPassword && 'Passwords do not match';
    return noMatch;
  },

  getPhoneNumberErrorMessage(phoneNumber) {
    const isEmpty = !phoneNumber && 'Field is required';

    return isEmpty;
  },

  isProjectIdValid(projectId) {
    const isLong = projectId.length > 10 && 'Should be less than 10 characters';
    return isLong;
  },

  getFormattedPhoneNumber(phoneNumber) {
    return `1${phoneNumber.replace(/[^\d]/g, "")}`;
  },
}
