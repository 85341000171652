<template>
  <div class="textarea-wrapper">
    <textarea
      :value="modelValue"
      @input="handleChange"
      @focus="handleFocus"
      :name="name"
      :id="id"
      :placeholder="placeholder"
      :minlength="minLength"
      class="textarea-wrapper--textarea"
    >
    </textarea>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [String, Number],
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    minLength: {
      type: Number,
      default: 5
    },
  },

  methods: {
    handleChange(event) {
      this.$emit('update:modelValue', event.target.value);
      event.target.style.height = "21px";
      event.target.style.height = `${event.target.scrollHeight}px`;
    },

    handleFocus(event) {
      event.target.style.height = `${event.target.scrollHeight}px`;
    }
  },
}
</script>

<style lang="scss" scoped>
.textarea-wrapper {
  &--textarea {
    color: #151522;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 150%;
    width: 100%;
    height: 21px;
    font-family: $SFProText;
    font-weight: 300;
    box-shadow: none;
    border: none;
    resize: none;
    overflow: auto;
    padding: 0;
    margin-bottom: 16px;
  }
}
</style>
