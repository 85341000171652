<template>
  <div class="request-quote">

    <div class="request-quote--body">
      <div class="request-quote--body--header">
        <div
          class="request-quote--body--header--logo logo-shadow"
        >
          <div
            class="request-quote--body--header--logo--image"
            :style="contractorCompany && `background-image: url('${contractorCompany.contractor_logo}')`"
          >
          </div>
        </div>
        <h3 class="request-quote--body--header--title h3-sb">{{ contractorCompany ? contractorCompany.contractor_name : '' }}</h3>
      </div>
      <h2 class="request-quote--body--title h2-sb">How would you like to request the quote?</h2>
      <h5 class="request-quote--body--description h5-light">You received an invitation to request a quote from {{ contractorCompany ? contractorCompany.contractor_name : '' }}.</h5>

      <custom-choosing-button
        :modelValue="quote"
        @update:modelValue="handleQuoteChange"
        id="use-account"
        icon="phone.svg"
        title="Create an account"
        description="With an account you will be able to receive updates, check status, and quickly make changes."
        @click="handleQuoteChange('Using my account')"
      />

      <custom-choosing-button
        :modelValue="quote"
        @update:modelValue="handleQuoteChange"
        id="without-account"
        icon="cloud.svg"
        title="Without account"
        description="All updates will be sent directly via email. Any changes to a request will require you to create an account."
        @click="handleQuoteChange('Without account')"
      />

    </div>

  </div>
</template>

<script>
import CustomChoosingButton from '@/components/shared/CustomChoosingButton';

export default {

  components: {
    CustomChoosingButton,
  },

  props: [
    'quote',
    'handleQuoteChange',
    'contractorCompany',
  ]
}
</script>

<style lang="scss">
.request-quote {
  position: relative;

  &--body {
    margin: 0 auto;
    max-width: 375px;
    box-sizing: border-box;
    margin-bottom: 44px;

    &--header {
      display: flex;
      margin-top: 41px;
      margin-bottom: 53px;
      align-items: center;

      &--logo {
        width: 100%;
        max-width: 60px;
        height: 60px;
        border-radius: 12px;
        margin: 0px 16px 0 0;
        flex: 0 0 auto;
        background-color: #fff;
        position: relative;

        &--image {
          width: 46px;
          height: 46px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;
          top: 7px;
          left: 7px;
        }
      }

      &--title {
        text-align: left;
        color: #151522;
        word-break: break-all;
      }
    }

    &--title {
      color: #151522;
      text-align: left;
      margin-bottom: 12px;
    }

    &--description {
      color: #4B617A;
      text-align: left;
      margin-bottom: 34px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .request-quote {

    &--body {
      max-width: 397px;
      margin-bottom: 0px;

      &--header {
        display: none;
      }

      &--title,
      &--description {
        max-width: 315px;
      }

      &--description {
        margin-bottom: 40px;
      }
    }
  }
}
</style>
