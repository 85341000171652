<template>
  <div
    class="input-item"
    :style="{ marginBottom: marginBottom + 'px' }"
  >
    <label
      v-if="label"
      class="input-item--label"
      :for="id"
    >
      {{ label }}
      <span
        v-if="optional"
        class="h6-light"
      >(opt.)</span>
    </label>
    <div :class="['input-item--input-wrapper', {'disabled': this.disabled === true}]">
      <input
        :class="['input-item--input-wrapper--input',
          /* {'input-item-phone': this.type === 'tel'}, */
          {'err': this.errorMessage}
        ]"
        :style="inputHeight && `height: ${inputHeight}px`"
        @input="handleChange"
        @keyup.enter="onEnter"
        :type="setInputType"
        :name="name"
        :id="id"
        :value="modelValue"
        :placeholder="placeholder ? placeholder : inputPlaceholder"
        :disabled="disabled"
        v-maska="addMaskInput"
        :minlength="minLength"
        @keydown="handleEmailValidation"
      >

      <!-- <div
        v-if="this.type === 'tel'"
        class="input-item--input-wrapper--elements"
      >
        <inline-svg
          :src="require('@/assets/images/icons/flag.svg')"
          class="input-item--input-wrapper--elements--svg"
        />
        <span class="input-item--input-wrapper--elements--number">+1</span>
      </div> -->

      <div class="input-item--input-wrapper--icon-wrapper">
        <inline-svg
          @click="togglePassword"
          v-if="icon === 'secured-with-show-option'"
          :src="showPassword ? require('@/assets/images/icons/eye-cross-icon.svg') : require('@/assets/images/icons/eye-icon.svg')"
          class="input-item--input-wrapper--icon-wrapper--icon"
        />
      </div>

    </div>
    <p v-if="errorMessage" class="input-item--input-wrapper--error">
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
import { maska } from 'maska'
import InlineSvg from 'vue-inline-svg'

export default {

  directives: {
    maska
  },

  components: {
    InlineSvg
  },

  props: {
    modelValue: {
      type: [String, Number],
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String
    },
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    confirmPassword: {
      type: Boolean,
      default: false
    },
    marginBottom: {
      type: [String, Number],
      default: 18
    },
    minLength: {
      type: Number,
      default: 5
    },
    errorMessage: {
      type: String,
      default: 'Error Notification'
    },
    optional: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
    },
    onEnter: {
      type: Function,
    },
    inputHeight: {
      type: String
    }
  },

  data: () => ({
    showPassword: false
  }),

  computed: {
    inputPlaceholder() {
      if (this.type === 'tel') {
        return '(xxx) xxx xxxx'.toUpperCase().split('*').join('')
      }
      if (this.type === 'password' && this.confirmPassword === true) {
        return `Enter password again`
      }
      return `Enter ${this.label.toLowerCase().split('*').join('')}`
    },

    addMaskInput() {
      if (this.type === 'tel') {
        return ''
      } else {
        return false
      }
    },

    setInputType() {
      if (this.type === 'password') {
        return this.showPassword ? 'text' : 'password';
      } else {
        return this.type || 'text'
      }
    }
  },

  methods: {
    handleChange(e) {
      if (e.target.type === 'email') {
        this.$emit('update:modelValue', e.target.value.replace(/\s/g, ''))
      } else if(e.target.type === 'tel') {
        e.target.value = e.target.value.replace(/[^0-9+]/g, '');
        this.$emit('update:modelValue', e.target.value.replace(/[^0-9+]/g, ''))
      } else {
         this.$emit('update:modelValue', e.target.value)
       }
    },

    handleEmailValidation(e) {
      if (this.setInputType === 'email' && e.keyCode === 32) {
        e.preventDefault();
      }
    },

    togglePassword() {
      if (this.modelValue !== '' ) {
        this.showPassword = !this.showPassword;
      }
    }
  },

}
</script>

<style lang="scss">
.input-item {
  display: flex;
  flex-direction: column;
  max-width: 325px;
  width: 100%;
  margin: 0 auto;
  position: relative;

  &--label {
    font-size: 16px;
    font-weight: 600;
    color: #151522;
    margin: 0 0 13px 0;
    text-align: left;
    font-family: $SFProText;
    line-height: 150%;
  }

  &--input-wrapper {
    position: relative;

    &--input {
      color: #151522;
      border: 1px solid #CCCCD7;
      box-sizing: border-box;
      font-size: 16px;
      border-radius: 5px;
      line-height: 150%;
      padding: 14px 20px 13px;
      width: 100%;
      font-family: $SFProText;
      font-weight: 300;
      box-shadow: none;
      transition: box-shadow .3s ease;

      &.input-item-phone {
        padding: 14px 20px 13px 88px;
      }

      &.err {
        border: 1px solid #E83C56;
        color: #E83C56;

        &::placeholder {
          color: #E83C56;
        }
      }

      &:hover, &:focus {
        box-shadow: 0px 4px 8px rgba(21, 21, 34, 0.06);
        cursor: pointer;
      }

      &::placeholder {
        color: #B3B3BE;
      }

      &[disabled] {
        background: #B3B3BE;
        border: 1px solid #B3B3BE;
        color: #666670;

        &::placeholder {
          color: #666670;
        }

        &:hover {
          cursor: not-allowed;
          box-shadow: none;
        }
      }
    }

    &--error {
      font-size: 12px;
      color: #E83C56;
      text-align: right;
      font-family: $SFProText;
      font-weight: 500;
      line-height: 150%;
      position: absolute;
      bottom: -22px;
      right: 0;
    }

    &--elements {
      display: inline-flex;
      align-items: center;
      left: 20px;
      top: 50%;
      position: absolute;
      transform: translate(0, -50%);
      pointer-events: none;

      &--svg {
        width: 28px;
        height: 28px;

        .disabled & {
          opacity: .5;
        }
      }

      &--number {
        margin-left: 11px;
        font-family: $SFProText;
        font-weight: 300;
        font-size: 16px;
        color: #151522;

        .disabled & {
          color: #666670;
        }
      }
    }

    &--icon-wrapper {
      position: absolute;
      width: 22px;
      height: 22px;
      top: 14px;
      right: 13px;
      display: flex;
      justify-content: center;
      align-items: center;

      &--icon {
        cursor: pointer;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .input-item {
    max-width: 100%;

    &--input-wrapper {
      &--input,
      &--elements--number {
        font-size: 14px;
      }
    }
  }
}
</style>
