<template>
  <div class="summary">
    <div class="summary--body">
      <CustomSummaryCollapse
        :summary="basicInfoDetails"
        :step="startFromZero ? 0 : 1"
        @update:currentStep="handleEditStep"
      />
      <CustomSummaryCollapse
        v-if="timeRequirements || blackoutDays || specialRequirements"
        :summary="requirementsDetails"
        :step="startFromZero ? 1 : 2"
        @update:currentStep="handleEditStep"
      />
      <CustomSummaryCollapse
        :summary="siteDetails"
        :step="startFromZero ? 2 : 3"
        @update:currentStep="handleEditStep"
      />
      <CustomSummaryCollapse
        v-if="photosPreview.length > 0"
        :summary="photosDetails"
        :step="startFromZero ? 3 : 4"
        @update:currentStep="handleEditStep"
      />
      <CustomSummaryCollapse
        v-if="filesPreview.length > 0"
        :summary="filesDetails"
        :step="startFromZero ? 4 : 5"
        @update:currentStep="handleEditStep"
      />
    </div>
  </div>
</template>

<script>
import CustomSummaryCollapse from '@/components/shared/CustomSummaryCollapse';

export default {

  components: {
    CustomSummaryCollapse
  },

  props: [
    'projectName',
    'contactName',
    'contactEmail',
    'startDate',
    'projectType',
    'timeRequirements',
    'blackoutDays',
    'specialRequirements',
    'location',
    'safetyRequirements',
    'siteVisit',
    'accessibility',
    'photos',
    'photosPreview',
    'files',
    'filesPreview',
    'handleEditStep',
    'startFromZero',
  ],

  data: () => ({
    basicInfoDetails: {},
    requirementsDetails: {},
    siteDetails: {},
    photosDetails: {},
    filesDetails: {},
  }),

  mounted() {
    this.setBasicInfoDetails();
    this.setRequirementsDetails();
    this.setSiteDetails();
    this.setPhotosDetails();
    this.setFilesDetails();
  },

  methods: {
    setBasicInfoDetails() {
      this.basicInfoDetails = {
        title: 'Basic info',
        details: [
          {
            title: 'Project name',
            text: this.projectName
          },
          {
            title: 'Contact name',
            text: this.contactName
          },
          {
            title: 'Contact email',
            text: this.contactEmail
          },
          {
            title: 'Start date',
            text: this.startDate
          },
          {
            title: 'Project type',
            text: this.projectType
          }
        ]
      }
    },

    setRequirementsDetails() {
      const details = [
        {
          title: 'Shift/time requirements',
          text: this.timeRequirements
        },
        {
          title: 'Blackout days',
          text: this.blackoutDays
        },
        {
          title: 'Special requirements',
          text: this.specialRequirements
        }
      ].filter(d => d.text !== '')

      this.requirementsDetails = {
        title: 'Requirements',
        details
      }
    },

    setSiteDetails () {
      const details = [
        {
          title: 'Location',
          text: this.location
        },
        {
          title: 'Safety requirements',
          text: this.safetyRequirements
        },
        {
          title: 'Site visit',
          text: this.siteVisit
        },
        {
          title: 'Accessibility',
          text: this.accessibility
        }
      ].filter(d => d.text !== '')

      this.siteDetails = {
        title: 'Site',
        details
      }
    },

    setPhotosDetails() {
      this.photosDetails = {
        title: 'Photos',
        photos: this.photosPreview
      }
    },

    setFilesDetails() {
      this.filesDetails = {
        title: 'Files',
        files: this.filesPreview
      }
    },
  }
}
</script>

<style lang="scss">
.summary {
  position: relative;
  min-height: 399px;

  &--body {
    border-top: 1px solid rgba(92, 92, 102, 0.32);
  }
}
</style>
