<template>
  <div class="subscription-modal">
    <div class="subscription-modal--content">
      <inline-svg
        :src="require('@/assets/images/icons/plus-rotated.svg')"
        class="subscription-modal--content--close"
        @click="handleHideSubscriptionModal"
      />
      <h6 class="subscription-modal--content--title h2-sb">{{getTitle}}</h6>
      <p class="subscription-modal--content--description h6-regular">{{getDescription}}</p>
      <div class="subscription-modal--content--controls">
        <p
          class="subscription-modal--content--controls--button ghost-btn"
          @click="isSubscriptionActive ? handleCancelSubscriptionModal() : handleHideSubscriptionModal()"
        >{{getCancelButtonText}}</p>
        <p
          v-if="isContractorOwner"
          class="subscription-modal--content--controls--button normal-btn"
          @click="isSubscriptionActive ? handleHideSubscriptionModal() : handleActivateSubscriptionModal()"
        >{{getConfirmButtonText}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
  components: {
    InlineSvg
  },

  props: {
    isSubscriptionActive: {
      type: Boolean,
      default: false
    },
    isSubscriptionTrialExpired: {
      type: Boolean,
      default: false
    },
    isContractorOwner: {
      type: Boolean,
      default: false
    },
    handleHideSubscriptionModal: {
      type: Function,
      default: () => {}
    },
    handleCancelSubscriptionModal: {
      type: Function,
      default: () => {}
    },
    handleActivateSubscriptionModal: {
      type: Function,
      default: () => {}
    },
  },

  computed: {
    getTitle() {
      return this.isSubscriptionTrialExpired
              ? 'Your free trial has expired'
              : this.isSubscriptionActive
                ? 'Cancel Subscribtion'
                : 'Activate subscription';
    },

    getDescription() {
      return this.isSubscriptionTrialExpired
              ? `Thank you for signing up for SCOPE! We hope you have enjoyed the free trial. To continue using SCOPE , subscribe now to be able to send invites to your customers and create projects from your dashboard. ${this.isContractorOwner ? '' : 'Contact the company owner to purchase the subscription.'}`
              : this.isSubscriptionActive
                ? 'By canceling subscription you will not be able to send invites to your customers and create projects from your dashboard”'
                : `Please activate your subscription to be able to send invites to your customers and create projects from your dashboard. ${this.isContractorOwner ? '' : 'Contact the company owner to purchase the subscription.'}`;
    },

    getCancelButtonText() {
      return !this.isContractorOwner
             ? 'Back'
             : this.isSubscriptionTrialExpired
               ? 'No, thanks'
               : this.isSubscriptionActive
                 ? 'Cancel anyway'
                 : 'Close';
    },

    getConfirmButtonText() {
      return this.isSubscriptionTrialExpired
              ? 'Subscribe now'
              : this.isSubscriptionActive
                ? 'Back'
                : 'Activate subscription';
    },
  }
}
</script>

<style lang="scss" scoped>
.subscription-modal {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(21,21,34,0.5);
  width: 100%;
  height: 100%;
  z-index: 4;

  &--content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background: #fff;
    border-radius: 26px;
    display: flex;
    flex-direction: column;
    padding: 40px;
    max-width: 477px;
    width: 90%;
    box-sizing: border-box;

    &--close {
      position: absolute;
      top: 25px;
      right: 25px;
      cursor: pointer;
    }

    &--title {
      color: #151522;
      text-align: left;
      margin-bottom: 6px;
    }

    &--description {
      color: #617B97;
      text-align: left;
      margin-bottom: 13px;
      font-size: 18px;

      span {
        font-weight: 600;
      }

      &:last-child {
        margin-bottom: 20px;
      }
    }

    &--controls {
      padding-top: 14px;
      display: flex;
      justify-content: space-between;

      &--button {
        width: calc(50% - 6px);
        padding: 13px 0;
        cursor: pointer;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .subscription-modal--content--close {
    top: 50px;
    right: 50px;
  }
}
</style>
