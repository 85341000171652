<template>
  <div
    class="subscription"
    :class="{'congrats': currentStep === 1}"
  >

    <custom-sidebar
      v-if="currentStep !== 1"
      :authPage="true"
    />

    <div
      v-if="currentStep === 0"
      class="subscription--body"
    >
      <h2 class="subscription--body--title h2-sb">Subscription</h2>

      <p class="subscription--body--subtitle p-medium">
        Unlock Unlimited Access - and start Streamlining the Contractor Owner Project Experience
      </p>

      <button
        class="subscription--body--button normal-btn"
        @click="handleSubscribe('subscribe')"
      >
        $99 / Month
      </button>
      <!-- Temporary hide -->
      <!-- <button
        class="subscription--body--button ghost-btn"
        @click="handleSubscribe('trial')"
      >
        30 Day Free Trial
      </button> -->

      <p class="subscription--body--copyright caption-3">
        {{`Powered by © ${currentYear} FCA International`}}
      </p>
    </div>

    <div
      v-else
      class="subscription--congrats"
    >
      <CustomCongrats
        :title="isPaymentSuccessfull ? 'Thanks for your payment!' : 'Congrats!'"
        :description="isPaymentSuccessfull ? 'Now you can send invites to your customers and create projects from your dashboard.' : 'Your account was successfully created'"
        maxWidth="375"
        marginBottom="40"
      />

      <button
        class="subscription--congrats--button normal-btn"
        @click="handleGoToDashboard"
      >
        Go to dashboard
      </button>
    </div>

    <custom-error
      v-if="showError"
      :errorMessage="errorMessage"
      :handleErrorClose="handleErrorClose"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import CustomSidebar from '@/components/shared/CustomSidebar';
import CustomCongrats from '@/components/shared/CustomCongrats';
import CustomError from '@/components/shared/CustomError';

export default {

  name: 'Subscription',

  components: {
    CustomSidebar,
    CustomError,
    CustomCongrats
  },

  data: () => ({
    currentStep: 0,
    errorMessage: 'Something went wrong. Please reload the page',
    showError: false,
    paymentPlanInfo: {},
    isPaymentSuccessfull: false
  }),

  created() {
    this.paymentPlans();
    if (this.$router.currentRoute._value.query.paid) {
      this.isPaymentSuccessfull = true;
      this.currentStep = 1;
    }
    if (this.$router.currentRoute._value.query.trial) {
      this.currentStep = 1;
    }
  },

  computed: {
    ...mapGetters([
      'getPaymentPlans',
      'getSessionCheckoutUrl'
    ]),

    currentYear() {
      return new Date().getFullYear();
    },
  },

  methods: {
    ...mapActions([
      'paymentPlans',
      'checkoutSession'
    ]),

    async handleSubscribe(type) {
      this.paymentPlanInfo = this.getPaymentPlans.find(el => el.lookup_key === 'contractors_with_free_trial');
      const paymentInfo = { ...this.paymentPlanInfo };

      const data = {
        plan_id: paymentInfo.plan_id,
        success_url: type !== 'trial'
          ? encodeURIComponent(`${window.location.origin}/subscription?paid=true`)
          : encodeURIComponent(`${window.location.origin}/subscription?trial=true`),
        cancel_url: encodeURIComponent(`${window.location.origin}/dashboard`),
        ...((type === 'trial')
          ? { free_trial: true }
          : {}),
      };

      await this.getCheckoutUrl(data);
      const sessionCheckoutUrl = { ...this.getSessionCheckoutUrl };
      window.open(`${sessionCheckoutUrl.url}`,"_self");
    },

    async getCheckoutUrl(data) {
      try {
        const sessionCheckoutUrl = await this.checkoutSession(data);
        return sessionCheckoutUrl;
      } catch (error) {
        if (error) {
          console.error('Subscriptions', error);
          this.showError = true;
          if (error.response && error.response.status === 422) {
            this.errorMessage = 'Something went wrong. Please reload the page';
          }
        }
      }
    },

    handleGoToDashboard() {
      this.$router.push({ name: "Dashboard" });
    },

    handleErrorClose() {
      this.showError = false;
    },
  },

}
</script>

<style lang="scss">
.subscription {
  height: 100%;

  &--congrats {
    display: flex;
    flex-direction: column;
    align-items: center;

    &--button {
      padding: 13px 0;
      display: inline-block;
      width: calc(100% - 50px);
      position: relative;
      max-width: 325px;
      width: 100%;
    }
  }

  &.congrats {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
  }

  &--body {
    padding: 113px 25px 7px;
    margin: 0 auto;
    max-width: 375px;
    box-sizing: border-box;
    position: relative;
    height: 100%;

    &--title {
      color: #151522;
      text-align: left;
      margin-bottom: 12px;
      white-space: pre-wrap;
      line-height: 100%;
    }

    &--subtitle {
      color: #4B617A;
      margin-bottom: 60px;
      max-width: 325px;
    }

    &--button {
      padding: 13px 0;
      display: inline-block;
      width: calc(100% - 50px);
      position: relative;
      max-width: 325px;
      margin-bottom: 20px;
      width: 100%;
    }

    &--copyright {
      color: #9999A0;
      text-align: center;
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}

@media screen and (min-width: 375px) and
(min-height: 811px) {
  .subscription {
    &--body {
      height: 100%;

      &--copyright {
        position: absolute;
        bottom: 36px;
        left: 50%;
        transform: translateX(-50%);
      }

      &--button {
        position: absolute;
        bottom: 170px;
        left: 50%;
        transform: translateX(-50%);

        &.ghost-btn {
          bottom: 100px;
        }
      }

      &--copyright {
        margin-bottom: 36px;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .subscription {
    display: flex;
    max-width: 100%;
    padding: 0;
    height: 100%;
    position: relative;

    &--congrats {
      &--button {
        max-width: 240px;
      }
    }

    &--body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 40px 0 40px;
      max-width: 397px;
      width: 100%;
      position: unset;

      &--copyright {
        display: none;
      }

      &--button {
        width: 240px;
        position: relative;
        left: unset;
        bottom: unset;
        transform: none;
        margin-bottom: 20px;
        min-height: 50px;

        &.ghost-btn {
          bottom: unset;
        }
      }
    }
  }
}
</style>
