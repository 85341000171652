<template>
  <div class="add-files">
    <input
      :id="id"
      class="add-files--input"
      :accept="accept"
      :type="type"
      @change="handleFilesChange"
      ref="fileInput"
      multiple
    >
    <button
      class="add-files--button small-btn btn-font-large"
      @click="$refs.fileInput.click()"
    >
      {{ buttonText }}
    </button>

    <div
      :id="`${id}-list`"
      class="add-files--list"
    >
      <div
        v-for="(file, index) in filesPreview"
        :key="file"
        class="add-files--list--item"
      >
        <inline-svg
          class="add-files--list--item--icon"
          :src="require(`@/assets/images/icons/files/${getFileName(file).split('.').pop()}.svg`)"
        />

        <div class="add-files--list--item--file-info">
          <p class="add-files--list--item--file-info--name">
            {{ getFileName(file).length > 25
              ? handleFileNameTrim(getFileName(file))
              : getFileName(file) }}
          </p>
          <p class="add-files--list--item--file-info--size">
            {{ getFileSize(file) > 1000000
              ? ((getFileSize(file)/1000000).toFixed(1) + 'MB')
              : ((getFileSize(file)/1000).toFixed(1) + 'KB') }}
          </p>
        </div>
        <div class="add-files--list--item--status-icon">
          <inline-svg
            class="add-files--list--item--status-icon--cancel-load"
            :class="{'already-loaded': index < loadedFiles && edit}"
            :src="require('@/assets/images/icons/load-file.svg')"
          />
          <inline-svg
            class="add-files--list--item--status-icon--loaded"
            :class="{'already-loaded': index < loadedFiles && edit}"
            :src="require('@/assets/images/icons/loaded.svg')"
          />
          
          <inline-svg
            class="add-files--list--item--status-icon--delete"
            :class="{'already-loaded': index < loadedFiles && edit}"
            :src="require('@/assets/images/icons/trashcan.svg')"
            @click="handleDelete"
          />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {

  components: {
    InlineSvg,
  },

  props: {
    modelValue: {
      type: [String, Number, Object],
      default: ''
    },
    filesPreview: {
      type: [String, Number, Object],
      default: ''
    },
    filesIds: {
      type: [String, Number, Object],
      default: ''
    },
    id: {
      type: String,
      default: 'files-uploader'
    },
    name: {
      type: String,
      default: 'file'
    },
    type: {
      type: String,
      default: 'file'
    },
    accept: {
      type: String,
      default: '.pdf, .doc, .docx, .rtf, .txt, .odt, .xls, .xlsx, .xltx, .ods, .ots, .csv, .dwg, .dxf'
    },
    buttonText: {
      type: String,
      default: 'Upload files'
    },
    deleteFile: {
      type: Function
    },
    edit: {
      type: Boolean,
      default: false
    },
    unregisteredUser: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      inputUpload: '',
      arrayFiles: [],
    }
  },

  computed: {
    loadedFiles() {
      return this.filesIds.length;
    }
  },

  mounted() {
    this.loadedFiles2 = this.filesIds.length;
  },

  methods: {
    getFileName(file) {
      return file.name ? file.name : file.file_name;
    },

    getFileSize(file) {
      return file.size ? file.size : file.file_size;
    },

    handleFileNameTrim(longName) {
      let result = longName.substring(0, 25) + '...' + longName.slice(-7);
      return result;
    },

    handleFilesChange() {
      this.arrayFiles = [];
      this.inputUpload = document.querySelector(`#${this.id}`).files;

      if (this.inputUpload.length) {
        Array.from(this.inputUpload).forEach(file => {
          let reader = new FileReader();
          reader.addEventListener("load", () => {
            this.arrayFiles.push(file)
            this.$emit('update:filesPreview', file);
          });
          reader.readAsText(file);
        })
      }
      this.$emit('update:modelValue', this.arrayFiles);
      document.querySelector(`#${this.id}`).value = '';
    },

    handleDelete(e) {
      const clickedItem = e.target.closest('.add-files--list--item');
      const itemIndex = [...clickedItem.parentElement.children].indexOf(clickedItem);

      this.deleteFile(itemIndex, "file", this.unregisteredUser ? 'unregistered-user' : '');
    },
  },

}
</script>

<style lang="scss">
.add-files {
  display: flex;
  flex-direction: column;
  align-items: center;

  &.hidden {
    height: 0;
    width: 0;
    overflow: hidden;
  }

  &--input {
    display: none;
  }

  &--button {
    padding: 9px 16px;
    display: block;
    margin-right: 16px;
    margin-bottom: 30px;
    max-width: 163px;
    width: 100%;
    box-sizing: border-box;
  }

  &--list {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    min-height: 322px;
    margin-bottom: 60px;

    &--item {
      display: flex;
      align-items: center;
      width: 100%;
      border-bottom: 1px solid rgba(92, 92, 102, 0.16);
      padding: 14px 0;
      line-height: 150%;

      &:first-child {
        border-top: 1px solid rgba(92, 92, 102, 0.16);
      }

      &--icon {
        width: 40px;
        height: 40px;
        margin-right: 8px;
      }

      &--file-info {
        color: #151522;
        font-family: $SFProText;

        &--name {
          font-weight: 400;
          font-size: 12px;
          margin-bottom: 2px;
          line-height: 150%;
        }

        &--size {
          margin: 0;
          font-weight: 300;
          font-size: 10px;
          line-height: 150%;
        }
      }

      &--status-icon {
        margin-left: auto;
        position: relative;
        width: 40px;
        height: 40px;

        &--cancel-load,
        &--loaded,
        &--delete {
          position: absolute;
          top: 50%;
          left: 50%;
          transform-origin: center;
          cursor: pointer;
        }

        &--cancel-load {
          transform: translate(-50%, -50%) rotate(-90deg);
          animation: hide .4s forwards;
          animation-delay: 3s;

          &.already-loaded {
            opacity: 0;
          }
        }

        &--loaded {
          opacity: 0;
          transform: translate(-50%, -50%);
          animation: show-hide 1s forwards;
          animation-delay: 3s;

          &.already-loaded {
            visibility: hidden;
          }
        }

        &--delete {
          opacity: 0;
          transform: translate(-50%, -50%);
          width: 18px;
          height: 20px;
          animation: show .1s forwards;
          animation-delay: 3.8s;

          &.already-loaded {
            opacity: 1;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .add-files {
    &--list {
      height: 290px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .add-files {
    align-items: baseline;

    &--list {
      min-height: 43px;
      box-sizing: content-box;
      margin-bottom: 60px;

      &--item {
        &--file-info {
          &--name {
            font-size: 14px;
            margin-bottom: 1px;
          }

          &--size {
            font-size: 12px;
          }
        }

        &--status-icon {
          &--delete {
            width: 13px;
            height: 15px;
          }
        }
      }
    }
  }
}
</style>
