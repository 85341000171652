<template>
  <div class="add-company-info">

    <custom-input
      :modelValue="companyName"
      @update:modelValue="handleCompanyNameChange"
      label="Company name"
      id="company-name"
      type="text"
      name="company-name"
      :errorMessage="companyNameError"
    />

    <custom-input
      :modelValue="contactName"
      @update:modelValue="handleContactNameChange"
      label="Contact name"
      id="contact-name"
      type="text"
      name="contact-name"
      :errorMessage="contactNameError"
    />

    <custom-input
      :modelValue="companyPhoneNumber"
      @update:modelValue="handleCompanyPhoneNumberChange"
      label="Phone number"
      id="company-phone-number"
      type="tel"
      name="company-phone-number"
      :errorMessage="companyPhoneNumberError"
    />

  </div>
</template>

<script>
import CustomInput from '@/components/shared/CustomInput'

export default {

  components: {
    CustomInput
  },

  props: [
    'companyName',
    'contactName',
    'companyPhoneNumber',
    'handleCompanyNameChange',
    'handleContactNameChange',
    'handleCompanyPhoneNumberChange',
    'companyNameError',
    'contactNameError',
    'companyPhoneNumberError'
  ],

}
</script>

<style lang="scss">
@media screen and (min-width: 1024px) {
  .add-company-info {
    margin-bottom: 84px;
  }
}
</style>
