<template>
  <div class="add-sign-up-info">

    <custom-input
      :modelValue="firstName"
      @update:modelValue="handleFirstNameChange"
      label="First name"
      id="firts-name"
      type="text"
      name="firts-name"
      :errorMessage="firstNameError"
    />

    <custom-input
      :modelValue="lastName"
      @update:modelValue="handleLastNameChange"
      label="Last name"
      id="last-name"
      type="text"
      name="last-name"
      :errorMessage="lastNameError"
    />

    <custom-input
      :modelValue="jobTitle"
      @update:modelValue="handleJobTitleChange"
      label="Job title"
      id="job-title"
      type="text"
      name="job-title"
      :errorMessage="jobTitleError"
    />

    <custom-input
      :modelValue="phoneNumber"
      @update:modelValue="handlePhoneNumberChange"
      label="Phone number"
      id="phone-number"
      type="tel"
      name="phone-number"
      :errorMessage="phoneNumberError"
    />

  </div>
</template>

<script>
import CustomInput from '@/components/shared/CustomInput'

export default {

  components: {
    CustomInput
  },

  props: [
    'firstName',
    'lastName',
    'jobTitle',
    'phoneNumber',
    'handleFirstNameChange',
    'handleLastNameChange',
    'handleJobTitleChange',
    'handlePhoneNumberChange',
    'firstNameError',
    'lastNameError',
    'jobTitleError',
    'phoneNumberError',
  ],

}
</script>

<style lang="scss">
@media screen and (min-width: 1024px) {
  .add-sign-up-info {
    margin-bottom: 84px;
  }
}
</style>
