<template>
  <div class="dashboard-desktop-header">
    <div
      class="dashboard-desktop-header--menu-wrapper"
      :class="[{'without-tabs': currentPage === 'request-quote-form'
              || currentPage === 'project-detail'
              || currentPage === 'notifications'
              || currentPage === 'update-project-details'},
              {'hide': hideHeader}]"
    >
      <div class="dashboard-desktop-header--menu-wrapper--title-wrapper">
        <inline-svg
          v-if="currentPage === 'request-quote-form'
              || this.currentPage === 'project-detail'
              || this.currentPage === 'project-id'
              || this.currentPage === 'project-status'
              || this.currentPage === 'update-project-details'"
          :src="require('@/assets/images/icons/big-arrow-left.svg')"
          class="dashboard-desktop-header--menu-wrapper--title-wrapper--icon"
          @click="this.currentPage === 'update-project-details'
            ? handleNavigation('project-detail')
            : handleNavigation()"
        />
        <h2 class="dashboard-desktop-header--menu-wrapper--title-wrapper--title h1-sb">{{ setTitle }}</h2>
      </div>
      <div
        class="dashboard-desktop-header--menu-wrapper--logo logo-shadow"
        @click="handleToggleMenu"
      >
        <div
          class="dashboard-desktop-header--menu-wrapper--logo--image"
          :style="{'background-image': `url('${setLogoImage()}')`}"
        ></div>
      </div>
      <inline-svg
        :src="require('@/assets/images/icons/arrow-down.svg')"
        class="dashboard-desktop-header--menu-wrapper--icon"
        @click="handleToggleMenu"
      />
    </div>

    <div class="dashboard-desktop-header--btn-wrapper">
      <div class="dashboard-desktop-header--btn-wrapper--tabs-wrapper">
        <Tabs
          :modelValue="currentTab"
          marginBottom="0"
          @update:modelValue="handleTabChange"
          :tabsItems="tabsItems"
        />
      </div>
      <div class="dashboard-desktop-header--btn-wrapper--container">
        <button
          v-if="currentPage === 'projects' && getUserRole === 'contractor' && getCurrentUser && getCurrentUser?.role !== 'viewer'"
          class="dashboard-desktop-header--btn-wrapper--container--button btn-create-project ghost-btn"
          @click="handleOpenInnerPage('request-quote-form')"
        >
          <inline-svg
            :src="require('@/assets/images/icons/plus.svg')"
            class="dashboard-desktop-header--btn-wrapper--container--button--icon"
          />
          Create new project
        </button>
        <button
          v-if="currentPage === 'projects' && getCurrentUser && getCurrentUser?.role !== 'viewer'"
          class="dashboard-desktop-header--btn-wrapper--container--button normal-btn"
          @click="handleRequestModal"
        >
          {{getUserRole === 'contractor' ? 'Invite customer' : 'Request quote'}}
        </button>
        <button
          v-if="getCurrentUser && (getCurrentUser?.role === 'admin' || getCurrentUser?.role === 'owner')
            && (currentPage === 'users' || currentPage === 'roles')"
          class="dashboard-desktop-header--btn-wrapper--container--button normal-btn"
          @click="handleRequestModal"
        >Invite user</button>
      </div>
    </div>

    <Menu
      :showMenu="showMenu"
      :handleToggleMenu="handleToggleMenu"
      :handleLogoutModal="handleLogoutModal"
      :setLogoImage="setLogoImage"
      :handleOpenInnerPage="handleOpenInnerPage"
      :userRole="getUserRole"
    />

    <CustomModal
      v-if="showLogoutModal"
      :message="getLogoutMessage"
      :handleCancel="handleLogoutModal"
      :handleConfirm="logout"
      notice="Are you sure you want to log out?"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import dashboardHelpers from '@/mixins/dashboardHelpers';

import InlineSvg from 'vue-inline-svg';
import Tabs from '@/components/dashboard/Tabs';
import Menu from '@/components/dashboard/Menu';
import CustomModal from '@/components/shared/CustomModal';

export default {
  mixins: [dashboardHelpers],

  components: {
    InlineSvg,
    Tabs,
    Menu,
    CustomModal
  },

  props: [
    'currentPage',
    'handleNavigation',
    'setTitle',
    'handleToggleMenu',
    'currentTab',
    'handleTabChange',
    'tabsItems',
    'handleRequestModal',
    'showMenu',
    'handleOpenInnerPage',
    'isProjectDetailsChanged',
    'showUnsavedChangesModal'
  ],

  emits: [
    'update:showUnsavedChangesModal'
  ],

  data() {
    return {
      showLogoutModal: false,
      scrolledHeight: window.pageYOffset,
      hideHeader: false
    }
  },

  watch: {
    scrolledHeight() {
      this.getScrolledHeight();
      if (this.scrolledHeight > 100 && this.currentPage === 'projects') {
        this.hideHeader = true;
      } else {
        this.hideHeader = false;
      }
    },
  },

  mounted() {
    window.addEventListener('scroll', this.getScrolledHeight);
  },

  beforeUnmount() {
    window.removeEventListener('scroll', this.getScrolledHeight);
  },

  computed: {
    ...mapGetters([
      'getUserCompany',
      'getCurrentUser'
    ])
  },

  methods: {
    ...mapActions([
      'logout'
    ]),

    getScrolledHeight() {
      this.scrolledHeight = window.pageYOffset;
    },
  }
}
</script>

<style lang="scss">
.dashboard-desktop-header {
  display: none;
}

@media screen and (min-width: 1024px) {
  .dashboard-desktop-header {
    display: block;
    position: fixed;
    top: 0;
    left: 0px;
    width: calc(100% - 82px);
    margin-left: 82px;
    background: #F8FBFE;
    padding: 33px 60px 0;
    box-sizing: border-box;
    z-index: 3;

    &--menu-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
      position: relative;
      transition: .2s;

      &.hide {
        display: none;
      }

      &.without-tabs {
        margin-bottom: 1px;
      }

      &--title-wrapper {
        display: flex;
        align-items: center;

        &--icon {
          cursor: pointer;
          margin-right: 20px;
        }

        &--title {
          color: #151522;
        }
      }

      &--logo {
        width: 100%;
        max-width: 60px;
        height: 60px;
        border-radius: 12px;
        margin: 0px 29px 0 0;
        flex: 0 0 auto;
        background-color: #fff;
        position: relative;
        cursor: pointer;

        &--image {
          width: 46px;
          height: 46px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;
          top: 7px;
          left: 7px;
        }
      }

      &--icon {
        cursor: pointer;
        position: absolute;
        right: -6px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &--btn-wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      &--tabs-wrapper {
        padding-top: 32px;
      }

      &--container {
        display: flex;

        &--button {
          padding: 13px 0;
          width: 200px;

          &--icon {
            margin-right: 6px;
            stroke: #151522;
          }

          &.btn-create-project {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 20px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .dashboard-desktop-header--btn-wrapper--container--button {
    width: 240px;
  }
}
</style>
