<template>
  <div class="basic-info">

    <div class="basic-info--body">

      <custom-input
        :modelValue="projectName"
        @update:modelValue="handleProjectNameChange"
        label="Project name"
        id="project-name"
        type="text"
        name="project-name"
        :errorMessage="projectNameError"
      />

      <custom-input
        :modelValue="contactName"
        @update:modelValue="handleContactNameChange"
        label="Contact name"
        id="contact-name"
        type="text"
        name="contact-name"
        :errorMessage="contactNameError"
      />

      <custom-input
        :modelValue="contactEmail"
        @update:modelValue="handleContactEmailChange"
        label="Contact email"
        id="contact-email"
        type="email"
        name="contact-email"
        :errorMessage="contactEmailError"
      />

      <custom-date-picker
        :modelValue="startDate"
        label="Start date"
        placeholder="mm/dd/yyyy"
        @update:modelValue="handleStartDateChange"
      />

      <custom-radio-group
        id="project-type"
        :modelValue="projectType"
        @update:modelValue="handleProjectTypeChange"
        :options="projectTypeOptions"
        label="Project type"
        :errorMessage="projectTypeError"
      />

    </div>

  </div>
</template>

<script>
import CustomInput from '@/components/shared/CustomInput';
import CustomDatePicker from '@/components/shared/CustomDatePicker';
import CustomRadioGroup from '@/components/shared/CustomRadioGroup';

export default {

  components: {
    CustomInput,
    CustomDatePicker,
    CustomRadioGroup,
  },

  props: [
    'projectName',
    'contactName',
    'contactEmail',
    'startDate',
    'projectType',
    'projectTypeOptions',
    'handleProjectNameChange',
    'handleContactNameChange',
    'handleContactEmailChange',
    'handleStartDateChange',
    'handleProjectTypeChange',
    'projectNameError',
    'contactNameError',
    'contactEmailError',
    'startDateError',
    'projectTypeError'
  ],

}
</script>
