<template>
  <div
    class="multy-select"
    :style="{ marginBottom: marginBottom + 'px' }"
  >
    <label
      class="multy-select--label"
      :class="{'hidden': !label}"
      :for="id"
    >
      {{ label }}
    </label>
    <div
      :class="['multy-select--select-wrapper',
        {'err': errorMessage},
        {'disabled': disabled === true},
        {'active': showOptions},
      ]"
    >
      <input
        v-model="contractorSearch"
        :class="['multy-select--select-wrapper--input',
          {'selected': isSearching}
        ]"
        type="text"
        :id="label.toLowerCase()"
        autocomplete="noAutocomplete"
        :placeholder="modelValue.length ? '' : placeholder"
        @focus="handleFocus"
        @blur="handleBlur"
      >
      <div
        class="multy-select--select-wrapper--options"
        :class="{'show': showOptions}"
      >
        <div
          v-for="option in filteredContractors"
          class="multy-select--select-wrapper--options--item"
          :class="{'selected': isIncluded(option)}"
          :key="option.contractor_id"
          :value="option.contractor_id"
          @click="handleSelectChange"
        >
          {{ option.contractor_name }}
        </div>
        <div
          v-if="filteredContractors.length === 0"
          class="multy-select--select-wrapper--options--item no-results"
        >
          No results
        </div>

        <CustomLoader
          v-if="!isLoading
            && getContractors.total_pages !== 0
            && getContractors.total_pages !== loadedPage"
          :totalPages="getContractors.total_pages"
          :currentPage="loadedPage"
          @handleScrollLoad="handleScrollLoad"
        />

      </div>
      <ul
        class="multy-select--select-wrapper--selected"
        :class="{'hidden': showOptions || isSearching}"
        @click="handleShowOptions"
      >
        <li
          v-for="(contractor, index) in modelValue"
          :key="index"
          class="multy-select--select-wrapper--selected--item h6-regular"
          :data-contractor-id="contractor.contractor_id"
        >
          {{ contractor.contractor_name }}
          <inline-svg
            :src="require('@/assets/images/icons/plus-rotated.svg')"
            class="multy-select--select-wrapper--selected--item--delete"
          />
        </li>
      </ul>
      <p v-if="errorMessage" class="multy-select--select-wrapper--error">
        {{ errorMessage }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CustomLoader from '@/components/shared/CustomLoader';
import InlineSvg from 'vue-inline-svg';

export default {

  components: {
    CustomLoader,
    InlineSvg
  },

  props: {
    modelValue: {
      type: Object,
      default: () => {}
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'enter'
    },
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    marginBottom: {
      type: [String, Number],
      default: 18
    },
    selectedWorkTypes: {
      type: Object,
      default: () => {}
    },
    zipCode: {
      type: String,
      default: ''
    },
    errorMessage: {
      type: String,
      default: 'Error Notification'
    },
  },

  data() {
    return {
      showOptions: false,
      isSearching: false,
      loadedPage: 1,
      isLoading: false,
      contractors: [],
      contractorSearch: null,
    }
  },

  created() {
    this.getAllContractors();
  },

  watch: {
    getContractors() {
      this.handleUpdateContractors();
    }
  },

  computed: {
    ...mapGetters([
      'getContractors',
    ]),
    filteredContractors() {
      if (this.contractorSearch) {
        let newArray = this.contractors.filter(el =>
          el.contractor_name.toLowerCase().includes(this.contractorSearch.toLowerCase())
        )
        return newArray
      }
      return this.contractors
    },
  },

  methods: {
    ...mapActions([
      'getAllContractors',
    ]),

    isIncluded(option) {
      let match = this.modelValue.filter(item => item.contractor_id === option.contractor_id);
      return match.length;
    },

    async handleScrollLoad() {
      if  ((this.getContractors.total_pages >= this.loadedPage) && !this.isLoading) {
        this.isLoading = true;
        this.loadedPage++;
        let workTypesIds = '';
        if (this.selectedWorkTypes.length) {
          this.selectedWorkTypes.forEach(type => {
            workTypesIds += `&work_types[]=${type.id}`;
          });
        }

        const data = {
          page: this.loadedPage,
          ...(this.zipCode.length ? { zipCode: `&zip_code=${this.zipCode.trim().replace(/ /g, '+')}` } : {}),
          ...(workTypesIds.length ? { workTypesIds } : {})
        }

        try {
          await this.getAllContractors(data);
          this.isLoading = false;
        } catch (error) {
          console.error('Contractor Select', error);
          this.showError = true;
        }
      }
    },

    handleUpdateContractors() {
      if (!this.modelValue.length && this.getContractors.default_contractor_id) {
        const defaultContractor = {
          contractor_id: this.getContractors.default_contractor_id,
          contractor_name: this.getContractors.default_contractor_name
        };
        this.$emit('update:modelValue', defaultContractor);
      }

      this.contractors.push(...this.getContractors.contractors);
      const uniqueArray = this.contractors.filter((item, index) => {
        const _item = JSON.stringify(item);
        return index === this.contractors.findIndex(obj => {
          return JSON.stringify(obj) === _item;
        });
      });
      this.contractors = uniqueArray;
    },

    handleShowOptions(e) {
      const target = e.target.closest('.multy-select--select-wrapper--selected--item--delete');
      if (target) {
        const contractorId = target.closest('.multy-select--select-wrapper--selected--item').dataset.contractorId;
        this.handleDeleteContractor(contractorId);
      } else {
        const parent = e.target.closest('.multy-select--select-wrapper');
        parent.querySelector('input').focus();
        if (!this.disabled) {
          this.isSearching = false;
        }
        this.showOptions = true;
      }
    },

    handleDeleteContractor(contractorId) {
      const toRemove = this.modelValue.find(item =>
        item.contractor_id === Number(contractorId)
      );
      this.$emit('update:modelValue', toRemove);
    },

    handleSelectChange(e) {
      const selected = this.getContractors.contractors.find(item =>
        item.contractor_id === Number(e.target.getAttribute('value'))
      );
      this.$emit('update:modelValue', selected);
      this.isSearching = false;
      this.handleBlur()
    },

    handleFocus() {
      if (!this.modelValue.length) {
        this.handleResetSelect();
        this.showOptions = true;
      }
    },

    handleResetSelect() {
      this.loadedPage = 0;
      this.contractors.length = 0;
      this.handleScrollLoad();
    },

    handleBlur() {
      setTimeout(() => {
        this.contractorSearch = null;
        this.showOptions = false;
        this.isSearching = false;
      },200)
    },
  },
}
</script>

<style lang="scss">
.multy-select {
  display: flex;
  flex-direction: column;
  max-width: 325px;
  width: 100%;
  margin: 0 auto;
  position: relative;

  &--label {
    font-size: 16px;
    font-weight: 600;
    color: #151522;
    margin: 0 0 13px 0;
    text-align: left;
    font-family: $SFProText;
    line-height: 150%;

    &.hidden {
      display: none;
    }
  }

  &--select-wrapper {
    border: 1px solid #CCCCD7;
    border-radius: 5px;
    transition: box-shadow .3s ease;
    color: #81878C;
    position: relative;

    &--selected {
      position: absolute;
      top: 50%;
      left: 0;
      padding-left: 20px;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      width: 100%;

      &.hidden {
        display: none;
      }

      &--item {
        background: #fff;
        border: 1px solid #E6E6EB;
        border-radius: 5px;
        padding: 4px 28px 4px 10px;
        font-size: 14px;
        color: #151522;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 80%;
        margin-right: 10px;
        box-sizing: border-box;
        position: relative;
        cursor: default;

        &--delete {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
        }

        &.default-contractor {
          background: #E6E6EB;
          padding: 4px 10px;
        }
      }
    }

    &.active {
      border: 1px solid #4B617A;
      box-shadow: 0px 4px 8px rgba(21, 21, 34, 0.06);
    }

    &.err {
      border: 1px solid #E83C56;
      color: #E83C56;
    }

    &:hover, &:focus {
      box-shadow: 0px 4px 8px rgba(21, 21, 34, 0.06);
      cursor: pointer;
    }

    &.disabled {
      border: 1px solid #B3B3BE;
      color: #666670;
    }

    &--input {
      border-radius: 5px;
      border: none;
      box-sizing: border-box;
      font-size: 16px;
      line-height: 150%;
      padding: 14px 32px 13px 20px;
      width: 100%;
      font-family: $SFProText;
      font-weight: 300;
      box-shadow: none;
      color: #33333D;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      background-image: url(~@/assets/images/icons/search-dark.svg);
      background-position: 96% 50%;
      background-repeat: no-repeat;

      &.selected {
        color: #151522;
      }

      .err & {
        color: #E83C56;
      }

      .disabled & {
        color: #666670;
        background-color: #B3B3BE;
        pointer-events: none;
      }

      &::placeholder {
        color: #33333D;;
      }
    }

    &--options {
      position: absolute;
      top: 64px;
      left: 0;
      width: 100%;
      max-height: 240px;
      overflow-y: overlay;
      overflow-x: hidden;
      background: #fff;
      border-radius: 5px;
      z-index: 3;
      box-shadow: 0px 4px 8px rgba(21, 21, 34, 0.06);
      display: none;

      &.show {
        display: block;
      }

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: #5C5C66;
        border-radius: 10px;
        height: 44px;
      }

      &--item {
        color: #151522;
        line-height: 150%;
        padding: 10px 32px 9px 20px;
        font-size: 16px;
        width: 100%;
        font-family: $SFProText;
        font-weight: 400;
        box-sizing: border-box;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        &.no-results {
          color: #666670;
        }

        &:last-child {
          border: none;
        }

        &.selected {
          position: relative;
          font-weight: 600;

          &:after {
            content: '';
            background: url(~@/assets/images/icons/check-select.svg) no-repeat;
            background-position: center;
            background-size: contain;
            width: 20px;
            height: 20px;
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translateY(-50%)
          }
        }
      }
    }

    &--error {
      font-size: 12px;
      color: #E83C56;
      text-align: right;
      font-family: $SFProText;
      font-weight: 500;
      line-height: 150%;
      position: absolute;
      bottom: -22px;
      right: 0;
    }
  }
}

@media screen and (min-width: 1024px) {
  .multy-select {
    max-width: 100%;

    &--select-wrapper {
      &--input,
      &--options--item {
        font-size: 14px;
      }
    }
  }
}
</style>
