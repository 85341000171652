import { createWebHistory, createRouter } from "vue-router";
import store from '../store/index';

import SignIn from "@/views/SignIn.vue";
import SignUp from "@/views/SignUp.vue";
import Subscription from "@/views/Subscription.vue";
import CustomerQuote from "@/views/CustomerQuote.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import PageNotFound from "@/views/PageNotFound.vue";
import Dashboard from "@/views/Dashboard.vue";
import ProjectPage from "@/views/ProjectPage.vue";
import ProjectGallery from "@/views/ProjectGallery.vue";
import Users from "@/views/Users.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: SignIn,
    beforeEnter: (to, from, next) => {
      if (store.getters.isUserLoggedIn) next({ name: 'Dashboard' })
      else next()
    }
  },
  {
    path: "/sign-in",
    name: "SignIn",
    component: SignIn,
  },
  {
    path: "/sign-up",
    name: "SignUp",
    component: SignUp,
    props: true,
  },
  {
    path: "/subscription",
    name: "Subscription",
    component: Subscription,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/customer-quote",
    name: "CustomerQuote",
    component: CustomerQuote,
    props: true,
    beforeEnter: (to, from, next) => {
      if (!to.query.invite_key) next({ name: 'SignIn' })
      else next()
    }
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: ResetPassword,
    props: true,
    beforeEnter: (to, from, next) => {
      if (!to.query.token) next({ name: 'SignIn' })
      else next()
    }
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/dashboard/project/:id',
    name: 'ProjectPage',
    component: ProjectPage,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/dashboard/project/:id/gallery',
    name: 'ProjectGallery',
    component: ProjectGallery,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/dashboard/users',
    name: 'Users',
    component: Users,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/:pathMatch(.*)*",
    name: "PageNotFound",
    component: PageNotFound,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
});

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isUserLoggedIn) {
      next()
      return
    }
    next('/')
  } else {
    next()
  }
})

export default router;
