<template>
  <div>

    <custom-input
      :modelValue="city"
      @update:modelValue="handleCityChange"
      label="City"
      id="city"
      type="text"
      name="city"
      :errorMessage="cityError"
    />

    <custom-input
      :modelValue="state"
      @update:modelValue="handleStateChange"
      label="State"
      id="state"
      type="state"
      name="state"
      :errorMessage="stateError"
    />

    <custom-input
      :modelValue="zip"
      @update:modelValue="handleZipChange"
      label="Zip"
      id="zip"
      type="text"
      name="zip"
      :errorMessage="zipError"
    />

  </div>
</template>

<script>
import CustomInput from '@/components/shared/CustomInput'

export default {

  components: {
    CustomInput,
  },

  props: [
    'city',
    'state',
    'selectOptions',
    'zip',
    'handleCityChange',
    'handleStateChange',
    'handleZipChange',
    'cityError',
    'stateError',
    'zipError',
  ],

}

</script>
