<template>
  <div class="registration-upload-logo">

    <custom-input-uploader
      :modelValue="file"
      :filePreview="filePreview"
      @update:modelValue="handleFileChange"
      @update:filePreview="handleFilePreviewChange"
      @handleFileError="handleFileError"
      :description="description"
      :deleteLogoGC="deleteLogoGC"
    />

  </div>
</template>

<script>
import CustomInputUploader from '@/components/shared/CustomInputUploader'

export default {

  components: {
    CustomInputUploader
  },

  props: [
    'file',
    'filePreview',
    'description',
    'handleUploadFileChange',
    'handleUploadFilePreviewChange',
    'handleUploadFileError',
    'deleteLogoGC'
  ],

  methods: {
    handleFileChange(value) {
      this.handleUploadFileChange(value);
    },

    handleFileError(value) {
      this.handleUploadFileError(value);
    },

    handleFilePreviewChange(value) {
      this.handleUploadFilePreviewChange(value);
   },
  }

}
</script>

<style lang="scss">
.registration-upload-logo {
  margin-bottom: 47px;
}

@media screen and (min-width: 1024px) {
  .registration-upload-logo {
    margin-bottom: 286px;
  }
}
</style>
