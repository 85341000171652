<template>
  <div
    class="user-info"
  >
    <div class="user-info--item">
      <p class="user-info--item--title label">Full name</p>
      <p class="user-info--item--text h6-light">
        {{ userToEdit.first_name }} {{ userToEdit.last_name }}
      </p>
    </div>
    <div class="user-info--item role">
      <p class="user-info--item--title label">Role</p>
      <EditUserRoleList
        v-if="getCurrentUser && (getCurrentUser?.role === 'admin' || getCurrentUser?.role === 'owner')
          && Number(getCurrentUser?.id) !== Number(userToEdit.id)"
        :user="userToEdit"
        :isOneUser="true"
        :roleList="roleList"
        marginBottom="16"
        :alignLeft="true"
      />
      <p
        v-else
        class="user-info--item--text role h6-light"
      >
        {{ userToEdit?.role }}
      </p>
    </div>
    <div class="user-info--item">
      <p class="user-info--item--title label">Email</p>
      <p class="user-info--item--text h6-light">
        {{ userToEdit.email }}
      </p>
    </div>
    <div class="user-info--item">
      <p class="user-info--item--title label">Phone number</p>
      <p class="user-info--item--text h6-light">
        {{ setPhoneNumber(userToEdit.phone) }}
      </p>
    </div>
    <div class="user-info--item">
      <p class="user-info--item--title label">Job title</p>
      <p class="user-info--item--text h6-light">
        {{ userToEdit.job_title }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import EditUserRoleList from '@/components/dashboard/users/EditUserRoleList';

export default {

  components: {
    EditUserRoleList
  },

  props: [
    'roleList',
    'userToEdit'
  ],

  computed: {
    ...mapGetters([
      'getCurrentUser'
    ]),
  },

  methods: {
    setPhoneNumber(phone) {
      return `(${phone.substr(0,3)}) ${phone.substr(3,3)} ${phone.substr(6,4)}`;
    }
  }
}
</script>

<style lang="scss" scoped>
.user-info {
  padding: 5px 25px 40px;

  &--item {
    margin-bottom: 14px;

    &.role {
      max-width: 104px;
    }

    &--title {
      color: #151522;
      margin-bottom: 6px;
    }

    &--text {
      color: #151522;

      &.role {
        text-transform: capitalize;
      }
    }
  }
}
</style>
