<template>
  <div class="dashboard-inner-page">
    <div v-if="isMobile && currentPageSubPage === 'email-settings'">
      <div
        class="dashboard-inner-page--sub-step"
        @click="this.$emit('update:subStep', 'project-activity')"
      >
        <div class="dashboard-inner-page--sub-step--text">
          <p class="dashboard-inner-page--sub-step--text--title text-medium">Project activity</p>
          <p class="dashboard-inner-page--sub-step--text--description h6-light">Notifications that will be sent to you when something happens in the project</p>
        </div>
        <inline-svg
          :src="require('@/assets/images/icons/arrow-left.svg')"
          class="dashboard-inner-page--sub-step--icon"
        />
      </div>
      <div
        class="dashboard-inner-page--sub-step"
        @click="this.$emit('update:subStep', 'account-activity')"
      >
        <div class="dashboard-inner-page--sub-step--text">
          <p class="dashboard-inner-page--sub-step--text--title text-medium">Account activity</p>
          <p class="dashboard-inner-page--sub-step--text--description h6-light">Notifications that will be sent to you when something happens in your account</p>
        </div>
        <inline-svg
          :src="require('@/assets/images/icons/arrow-left.svg')"
          class="dashboard-inner-page--sub-step--icon"
        />
      </div>
    </div>

    <div
      v-if="!isMobile || currentPageSubPage === 'project-activity'"
      class="dashboard-inner-page--sub-step-body project-activity"
      :class="{'open': showSubStep === 'project-activity'}"
    >
      <div
        v-if="!isMobile"
        class="dashboard-inner-page--sub-step-body--desktop-header"
        @click="this.$emit('update:subStep', 'project-activity')"
      >
        <div class="dashboard-inner-page--sub-step-body--desktop-header--text">
          <p class="dashboard-inner-page--sub-step-body--desktop-header--text--title text-medium">Project activity</p>
          <p class="dashboard-inner-page--sub-step-body--desktop-header--text--description h6-light">Notifications that will be sent to you when something happens in the project</p>
        </div>
        <button class="dashboard-inner-page--sub-step-body--desktop-header--button btn-font-large">
          {{showSubStep === 'project-activity' ? 'Close' : 'Edit'}}
        </button>
      </div>

      <div class="dashboard-inner-page--sub-step-body--desktop-body">
        <CustomCheckGroup
          id="project-activity"
          :modelValue="selectedNotifications"
          @update:modelValue="handleNotificationsChange"
          :options="projectActivityOptions"
          :getItemName="getItemName"
          label="Email me about:"
          :errorMessage="errorMessage"
        />
        <button
          class="dashboard-inner-page--sub-step-body--button normal-btn"
          :class="[!isProjectSettingsChanged && 'disabled',
                  isUpdating && 'frozen']"
          @click="handleUpdate('project-activity')"
        >{{ isUpdating ? 'Updated!' : 'Update' }}</button>
      </div>
    </div>

    <div
      v-if="!isMobile || currentPageSubPage === 'account-activity'"
      class="dashboard-inner-page--sub-step-body"
      :class="{'open': showSubStep === 'account-activity'}"
    >
      <div
        v-if="!isMobile"
        class="dashboard-inner-page--sub-step-body--desktop-header"
        @click="this.$emit('update:subStep', 'account-activity')"
      >
        <div class="dashboard-inner-page--sub-step-body--desktop-header--text">
          <p class="dashboard-inner-page--sub-step-body--desktop-header--text--title text-medium">Account activity</p>
          <p class="dashboard-inner-page--sub-step-body--desktop-header--text--description h6-light">Notifications that will be sent to you when something happens in your account</p>
        </div>
        <button class="dashboard-inner-page--sub-step-body--desktop-header--button btn-font-large">
          {{showSubStep === 'account-activity' ? 'Close' : 'Edit'}}
        </button>
      </div>

      <div class="dashboard-inner-page--sub-step-body--desktop-body">
        <CustomCheckGroup
          id="account-activity"
          :modelValue="selectedNotifications"
          @update:modelValue="handleNotificationsChange"
          :options="accountActivityOptions"
          :getItemName="getItemName"
          label="Email me about:"
          :errorMessage="errorMessage"
        />

        <button
          class="dashboard-inner-page--sub-step-body--button normal-btn"
          :class="[!isAccountSettingsChanged && 'disabled',
                  isUpdating && 'frozen']"
          @click="handleUpdate('account-activity')"
        >{{ isUpdating ? 'Updated!' : 'Update' }}</button>
      </div>
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import dashboardHelpers from '@/mixins/dashboardHelpers';

import InlineSvg from 'vue-inline-svg';
import CustomCheckGroup from '@/components/shared/CustomCheckGroup';

export default {
  mixins: [dashboardHelpers],

  components: {
    InlineSvg,
    CustomCheckGroup
  },

  props: [
    'currentPageSubPage',
    'subStep',
    'showSubStep',
    'isMobile',
  ],

  emits: ['update:subStep','update:currentPageSubPage'],

  data: () => ({
    isUpdating: false,
    errorMessage: null,
    selectedNotifications: [],
    projectActivityOptions: [],
    accountActivityOptions: [],
    isProjectSettingsChanged: false,
    isAccountSettingsChanged: false,
  }),

  beforeMount() {
    this.emailSettingsInfo();
  },

  mounted() {
    this.$emit('update:currentPageSubPage', 'email-settings');
  },

  watch: {
    getEmailSettings() {
      if (this.getEmailSettings) {
        if (this.getUserRole === 'contractor') {
          this.accountActivityOptions = this.getEmailSettings.account;
          delete this.getEmailSettings.project['new_quote_customer'];
          this.projectActivityOptions = this.getEmailSettings.project;
        } else {
          delete this.getEmailSettings.account['new_customer'];
          this.accountActivityOptions = this.getEmailSettings.account;
          delete this.getEmailSettings.project['new_quote_contractor'];
          delete this.getEmailSettings.project['payment_progress'];
          this.projectActivityOptions = this.getEmailSettings.project;
        }
      }
    }
  },

  computed: {
    ...mapGetters([
      'getEmailSettings',
      'getUserCompany'
    ])
  },

  methods: {
    ...mapActions([
      'emailSettingsInfo',
      'updateEmailSettings'
    ]),

    handleNotificationsChange(name) {
      if (name === 'personal_info_changed'
        || name === 'company_info_changed'
        || name === 'password_changed'
        || name === 'new_customer') {
        this.accountActivityOptions[name] = !this.accountActivityOptions[name];
        this.isAccountSettingsChanged = true;
      } else {
        this.projectActivityOptions[name] = !this.projectActivityOptions[name];
        this.isProjectSettingsChanged = true;
      }
    },

    getItemName(name) {
      if (name === 'personal_info_changed') {
        return 'Changes in personal information';
      } else if (name === 'company_info_changed') {
        return 'Changes in company information';
      } else if (name === 'password_changed') {
        return 'Password changes';
      } else if (name === 'new_customer') {
        return 'New customer joins';
      } else if (name === 'activity_messages') {
        return 'New activity messages';
      } else if (name === 'project_statuses') {
        return 'Project status changes';
      } else if (name === 'payment_progress') {
        return 'Payment progress updates';
      } else if (name === 'update_project_details') {
        return 'Project details updates';
      } else if (name === 'new_quote_customer') {
        return 'Quote details';
      } else if (name === 'new_quote_contractor') {
        return 'New quote request';
      } else {
        return '';
      }
    },

    async handleUpdate(page) {
      this.isUpdating = true;
      let data;

      if (page === 'account-activity') {
        data = {
          email_notification: {
            personal_info_changed: this.accountActivityOptions.personal_info_changed,
            company_info_changed: this.accountActivityOptions.company_info_changed,
            password_changed: this.accountActivityOptions.password_changed,
            new_customer: this.accountActivityOptions.new_customer,
          }
        }
      } else {
        data = {
          email_notification: {
            activity_messages: this.projectActivityOptions.activity_messages,
            project_statuses: this.projectActivityOptions.project_statuses,
            payment_progress: this.projectActivityOptions.payment_progress,
            update_project_details: this.projectActivityOptions.update_project_details,
            new_quote_customer: this.projectActivityOptions.new_quote_customer,
            new_quote_contractor: this.projectActivityOptions.new_quote_contractor
          }
        }
      }

      try {
        await this.updateEmailSettings(data);
        this.isUpdating = false;
        this.isProjectSettingsChanged = false;
        this.isAccountSettingsChanged = false;
      } catch (error) {
        if (error) {
          console.error('Email Settings', error);
          this.showError = true;
          this.errorMessage = 'Something went wrong. Please reload the page';
        }
      }
    }
  }
}
</script>
