<template>
  <div class="dashboard-footer">
    <div class="dashboard-footer--logo-wrapper">
      <inline-svg
        class="dashboard-footer--logo-wrapper--icon"
        :src="require('@/assets/images/scope-logo-dashboard.svg')"
        @click="handleFooterLinks('projects')"
      />
    </div>
    <div
      class="dashboard-footer--item"
      :class="{'active': currentPage === 'projects'}"
      @click="handleFooterLinks('projects')"
    >
      <inline-svg
        :src="require('@/assets/images/icons/folder.svg')"
        class="dashboard-footer--item--icon"
      />
      <p class="dashboard-footer--item--title p-super-tiny">Projects</p>
      <div class="dashboard-footer--item--tooltip">
        <p class="dashboard-footer--item--tooltip--text caption-3">Projects</p>
      </div>
    </div>
    <div
      class="dashboard-footer--item"
      :class="{'active': currentPage === 'users' || currentPage === 'roles'}"
      @click="handleFooterLinks('users')"
    >
      <inline-svg
        :src="require('@/assets/images/icons/users.svg')"
        class="dashboard-footer--item--icon"
      />
      <p class="dashboard-footer--item--title p-super-tiny">Users</p>
      <div class="dashboard-footer--item--tooltip">
        <p class="dashboard-footer--item--tooltip--text caption-3">Users</p>
      </div>
    </div>
    <div
      class="dashboard-footer--item"
      :class="{'active': currentPage === 'notifications'}"
      @click="handleFooterLinks('notifications')"
    >
      <p
        v-if="getNotificationsNew"
        class="dashboard-footer--item--count"
        :class="getNotificationsNew > 99 && 'small'"
      >{{getNotificationsNew > 99 ? '99+' : getNotificationsNew}}</p>
      <inline-svg
        :src="require('@/assets/images/icons/bell.svg')"
        class="dashboard-footer--item--icon"
      />
      <p class="dashboard-footer--item--title p-super-tiny">Notifications</p>
      <div class="dashboard-footer--item--tooltip">
        <p class="dashboard-footer--item--tooltip--text caption-3">Notifications</p>
      </div>
    </div>
    <p class="dashboard-footer--copyright p-regular">Powered by FCA</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import InlineSvg from 'vue-inline-svg';

export default {
  components: {
    InlineSvg
  },

  props: [
    'currentPage',
    'handleFooterLinks'
  ],

  computed: {
    ...mapGetters([
      'getNotificationsNew',
    ]),
  },
}
</script>

<style lang="scss">
.dashboard-footer {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-top: 1px solid #CCCCD7;
  padding: 10px 0 25px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  background: #F8FBFE;
  z-index: 1;

  &.hidden-footer {
    display: none;
  }

  &--logo-wrapper,
  &--copyright {
    display: none;
  }

  &--item {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    min-width: 75px;
    cursor: pointer;

    &--icon {
      width: 28px;
      height: 28px;
      margin-bottom: 4px;
      opacity: 0.7;
      transition: all .2s ease;

      .active & {
        opacity: 1;
      }

      path {
        transition: all .2s ease;

        .active & {
          stroke: #3D506D;
        }
      }
    }

    &--title {
      color: #4B617A;
      font-family: $SFProText;
      opacity: 0.7;

      .active & {
        color: #3D506D;
        opacity: 1;
      }
    }

    &--count {
      position: absolute;
      z-index: 1;
      top: -3px;
      right: 15px;
      font-family: $SFProText;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      color: #fff;
      background: #E83C56;
      font-weight: 300;
      font-size: 12px;
      line-height: 150%;
      text-align: center;

      &.small {
        font-size: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &--tooltip {
      background: rgba(92, 92, 102, 0.48);
      border-radius: 4px;
      padding: 2px 8px;
      position: absolute;
      top: 34px;
      left: 50%;
      transform: translateX(-50%);
      opacity: 0;
      visibility: hidden;
      transition: all .2s ease;

      &--text {
        color: #fff;
      }
    }
  }
}

@media screen and (max-height: 400px) {
  .dashboard-footer {
    position: absolute;
  }
}

@media screen and (min-width: 1024px) {
  .dashboard-footer {
    top: 0;
    left: 0;
    width: 82px;
    height: 100vh;
    background: #DFEBF9;
    padding: 47px 20px;
    box-sizing: border-box;
    transform: none;
    flex-direction: column;
    justify-content: flex-start;

    &--logo-wrapper {
      display: block;
      margin-bottom: 82px;

      &--icon {
        width: 42px;
        height: 42px;
        cursor: pointer;
      }
    }

    &--copyright {
      display: inline-block;
      position: absolute;
      left: 10px;
      bottom: 47px;
      color: #7A95B4;
      text-align: center;
      max-width: 62px;
    }

    &--item {
      margin-bottom: 47px;
      min-width: inherit;

      &:hover > .dashboard-footer--item--tooltip {
        opacity: 1;
        visibility: visible;
      }

      &:hover > .dashboard-footer--item--icon {
        opacity: 1;
      }

      &:hover > .dashboard-footer--item--icon > path {
        stroke: #151522;
      }

      &--title {
        display: none;
      }

      &--icon {
        opacity: 0.5;

        .active & {
          opacity: 1;
        }

        path {
          .active & {
            stroke: #151522;
          }
        }
      }
    }
  }
}
</style>
