<template>
  <div class="trade-types-modal">
    <div class="trade-types-modal--content">
      <inline-svg
        :src="require('@/assets/images/icons/plus-rotated.svg')"
        class="trade-types-modal--content--close"
        @click="handleHideTradeTypesModal"
      />
      <h6 class="trade-types-modal--content--title h2-sb">Please Add Trade Types</h6>
      <p class="trade-types-modal--content--description h6-regular">Please add trade types in your company settings, so customers will be able to find you by trade types.</p>
      <div class="trade-types-modal--content--controls">
        <p
          class="trade-types-modal--content--controls--button ghost-btn"
          @click="handleHideTradeTypesModal"
        >Will do it later</p>
        <p
          class="trade-types-modal--content--controls--button normal-btn"
          @click="handleAddTradeTypesModal"
        >Add</p>
      </div>
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
  components: {
    InlineSvg
  },

  props: {
    handleHideTradeTypesModal: {
      type: Function,
      default: () => {}
    },
    handleAddTradeTypesModal: {
      type: Function,
      default: () => {}
    }
  },
}
</script>

<style lang="scss" scoped>
.trade-types-modal {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(21,21,34,0.5);
  width: 100%;
  height: 100%;
  z-index: 4;

  &--content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background: #fff;
    border-radius: 26px;
    display: flex;
    flex-direction: column;
    padding: 40px;
    max-width: 477px;
    width: 90%;
    box-sizing: border-box;

    &--close {
      position: absolute;
      top: 25px;
      right: 25px;
      cursor: pointer;
    }

    &--title {
      color: #151522;
      text-align: left;
      margin-bottom: 6px;
    }

    &--description {
      color: #617B97;
      text-align: left;
      margin-bottom: 5px;
      font-size: 14px;

      span {
        font-weight: 600;
      }

      &:last-child {
        margin-bottom: 20px;
      }
    }

    &--controls {
      padding-top: 14px;
      display: flex;
      justify-content: space-between;

      &--button {
        width: calc(50% - 6px);
        padding: 13px 0;
        cursor: pointer;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .trade-types-modal--content--close {
    top: 50px;
    right: 50px;
  }
}
</style>
