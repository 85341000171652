<template>
  <div class="filter">
    <div class="filter--wrapper">
      <div class="filter--wrapper--sort-by">
        <h3 class="filter--title h3-sb">Sort by</h3>
        <CustomSelect
          :modelValue="sortBy"
          @update:modelValue="handleSortingChange"
          label="Sorting"
          id="sort-by"
          name="sort-by"
          :option="sortBy"
          placeholder="Choose sorting"
          :selectOptions="sortOptions"
          :marginBottom="isMobile ? '40' : '30'"
          :errorMessage="null"
        />
      </div>

      <div class="filter--wrapper--filter-by">
        <h3 class="filter--title h3-sb">Filter by</h3>

        <div class="filter--wrapper--filter-by--inputs">
          <div class="filter--wrapper--filter-by--inputs--item">
            <CustomSelect
              :modelValue="customer"
              @update:modelValue="handleCustomerChange"
              label="Customer"
              id="сustomer"
              name="сustomer"
              :option="customer"
              placeholder="Choose сustomer"
              :selectOptions="customerOptions"
              :errorMessage="null"
              disabled
            />
          </div>

          <div class="filter--wrapper--filter-by--inputs--item">
            <CustomSelect
              :modelValue="city"
              @update:modelValue="handleCityChange"
              label="City"
              id="city"
              name="city"
              :option="city"
              placeholder="Choose city"
              :selectOptions="cityOptions"
              :errorMessage="null"
              disabled
            />
          </div>

          <div class="filter--wrapper--filter-by--inputs--item">
            <CustomSelect
              :modelValue="state"
              @update:modelValue="handleStateChange"
              label="State"
              id="sorting"
              name="state"
              :option="state"
              placeholder="Choose sorting"
              :selectOptions="stateOptions"
              :errorMessage="null"
              disabled
            />
          </div>
        </div>
      </div>
    </div>

    <div class="filter--buttons-wrapper">
      <p class="filter--buttons-wrapper--reset btn-font-large">Start over?
        <span @click="handleFilterReset">Reset filters</span>
      </p>

      <button
        class="filter--buttons-wrapper--button normal-btn"
        @click="handleFilter"
      >Show results</button>
    </div>

    <button
      class="filter--button normal-btn"
      @click="handleFilter"
    >Show results</button>
  </div>
</template>

<script>
import CustomSelect from '@/components/shared/CustomSelect'

export default {
  components: {
    CustomSelect,
  },

  props: [
    'sortBy',
    'handleSortingChange',
    'sortOptions',
    'customer',
    'handleCustomerChange',
    'customerOptions',
    'city',
    'handleCityChange',
    'cityOptions',
    'state',
    'handleStateChange',
    'stateOptions',
    'handleFilterReset',
    'isMobile',
    'handleFilter',
  ]
}
</script>

<style lang="scss">
.filter {
  max-width: 325px;
  margin: 0 auto;
  padding: 5px 25px 0;

  &--wrapper {
    margin-bottom: 60px;
  }

  &--title {
    margin-bottom: 28px;
    color: #151522;
  }

  &--button {
    padding: 13px 0;
    margin-bottom: 50px;
    max-width: 325px;
    width: 100%;
  }

  &--buttons-wrapper {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .filter {
    position: absolute;
    z-index: 2;
    top: 78px;
    left: 0px;
    width: calc(100% - 120px);
    padding: 30px 30px 18px;
    margin: 0 60px;
    background: #FFFFFF;
    border: 1px solid #CCCCD7;
    box-sizing: border-box;
    border-radius: 4px;
    max-width: 100%;

    &--button {
      display: none;
    }

    &--title {
      margin-bottom: 18px;
    }

    &--wrapper {
      display: flex;
      border-bottom: 1px solid rgba(92, 92, 102, 0.16);
      margin-bottom: 18px;

      &--sort-by {
        flex: 1;
        margin-right: 30px;
        max-width: 327px;
      }

      &--filter-by {
        flex: 2;

        &--inputs {
          display: flex;
          max-width: 100%;

          &--item {
            flex: 1;
            margin-right: 20px;

            &:last-child {
              margin-right: 0px;
            }
          }
        }
      }
    }

    &--buttons-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &--reset {
        display: block;
        font-family: $SFProText;
        color: #4B617A;

        span {
          color: #10886D;
          cursor: pointer;
        }
      }

      &--button {
        padding: 13px 0;
        max-width: 240px;
        width: 100%;
      }
    }
  }
}
</style>
