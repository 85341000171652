<template>
  <div class="add-comment">
    <div
      id="list-gallery"
      class="add-comment--gallery"
      :class="photosPreview.length && 'border-bottom'"
    >
      <div
        v-for="photo in photosPreview"
        :key="photo"
        class="add-comment--gallery--item"
        :style="{'background-image': `url('${photo}')`}"
      >
        <inline-svg
          class="add-comment--gallery--item--delete"
          :src="require('@/assets/images/icons/cross.svg')"
          @click="handleDelete($event,'photo')"
        />
        <inline-svg
          class="add-comment--gallery--item--cancel-load"
          :src="require('@/assets/images/icons/load-file.svg')"
        />
      </div>
    </div>

    <div
      id="list"
      class="add-comments--list"
    >
      <div
        v-for="file in filesPreview"
        :key="file"
        class="add-comment--list--item"
      >
        <inline-svg
          class="add-comment--list--item--icon"
          :src="require(`@/assets/images/icons/files/${file.name.split('.').pop()}.svg`)"
        />

        <div class="add-comment--list--item--file-info">
          <p class="add-comment--list--item--file-info--name">
            {{ file.name.length > 20
              ? handleFileNameTrim(file.name)
              : file.name }}
          </p>
          <p class="add-comment--list--item--file-info--size">
            {{ file.size > 1000000
              ? ((file.size/1000000).toFixed(1) + 'MB')
              : ((file.size/1000).toFixed(1) + 'KB') }}
          </p>
        </div>

        <div class="add-comment--list--item--status-icon">
          <inline-svg
            class="add-comment--list--item--status-icon--cancel-load"
            :src="require('@/assets/images/icons/load-file.svg')"
          />
          <inline-svg
            class="add-comment--list--item--status-icon--loaded"
            :src="require('@/assets/images/icons/loaded.svg')"
          />
          <inline-svg
            class="add-comment--list--item--status-icon--delete"
            :src="require('@/assets/images/icons/trashcan.svg')"
            @click="handleDelete($event, 'file')"
          />
        </div>
      </div>
    </div>

    <textarea
      @input="handleChange"
      @focus="handleShowButton"
      name="new-comment"
      id="new-comment"
      class="add-comment--textarea"
      :value="modelValue"
      placeholder="Write a comment"
      minlength="minLength"
    >
    </textarea>
    <div v-if="showButton" class="add-comment--wrapper">
      <button
        class="add-comment--wrapper--button normal-btn"
        :class="!modelValue
                && !arrayPhotos.length
                && !arrayFiles.length
                && 'disabled'"
        @click="handleSave"
      >Save</button>
      <label
        class="add-comment--wrapper--label"
        id="file-upload-label"
        for="file-upload"
      >
        <inline-svg
          :src="require('@/assets/images/icons/paperclip.svg')"
          class="add-comment--wrapper--label--icon"
        />
        <input
          id="file-upload"
          class="add-comment--wrapper--label--file"
          :accept="accept"
          type="file"
          @change="handleFilesChange"
          ref="fileInput"
          multiple
        >
      </label>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import InlineSvg from 'vue-inline-svg';

export default {

  components: {
    InlineSvg,
  },

  props: {
    modelValue: {
      type: [String, Number],
      default: ''
    },
    projectId: {
      type: [String, Number],
      default: ''
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: '.png, .jpg, .jpeg, image/jpeg, image/png, image/jpg, .pdf, .doc, .docx, .rtf, .txt, .odt, .xls, .xlsx, .xltx, .ods, .ots, .csv, .dwg, .dxf'
    }
  },

  data: () => ({
    showButton: false,
    arrayFiles: [],
    arrayPhotos: [],
    filesPreview: [],
    photosPreview: [],
  }),

  computed: {
    ...mapGetters([
      'getCompanyProjects',
      'getGcFile',
    ]),
  },

  methods: {
    ...mapActions([
      'addActivity',
      'gcFileUpload',
      'gcFileDelete',
      'projectInfo'
    ]),

    handleFileNameTrim(longName) {
      if (this.isMobile) {
        let result = longName.substring(0, 14) + '...' + longName.slice(-7);
        return result;
      }
      return longName;
    },

    handleShowButton() {
      this.showButton = true;
    },

    handleChange(event) {
      this.$emit('update:modelValue', event.target.value)
      event.target.style.height = "18px";
      event.target.style.height = `${event.target.scrollHeight}px`;
      if (event.target.value === '') {
        this.showButton = false;
      } else {
        this.showButton = true;
      }
    },

    handleFilesChange() {
      this.inputUpload = document.querySelector("#file-upload").files;
      if (this.inputUpload.length) {
        Array.from(this.inputUpload).forEach(file => {
          this.getUploadingFileLink(file)
        })
      }
      document.querySelector("#file-upload").value = '';
    },

    handleFilesPreview(fileType, file) {
      if (fileType === 'photo') {
        let reader = new FileReader();
        reader.addEventListener("load", (e) => {
          this.photosPreview.push(e.target.result)
        });
        reader.readAsDataURL(file);
      } else {
        let reader = new FileReader();
        reader.addEventListener("load", () => {
          this.filesPreview.push(file)
        });
        reader.readAsText(file);
      }
    },

    async getUploadingFileLink(file) {
      const filePrefix = () => Math.random().toString(36).substr(2, 9);
      const fileName = `${filePrefix()}-${file.name}`.replace(/\s/g, '_');
      const data = {
        file_name: fileName,
        file_type: file.type
      }

      try {
        await this.gcFileUpload(data);
        this.uploadingFileGC(this.getGcFile.url, fileName, file);
      } catch (error) {
        console.error('Add Comment', error);
        this.showError = true;
      }
    },

    uploadingFileGC(gcUrl, fileName, file) {
      var xhr = new XMLHttpRequest();
      xhr.open('PUT', gcUrl, true);
      xhr.onload = () => {
        if (xhr.readyState == 4 && xhr.status == "200") {
          if (file.type.includes('image')) {
            this.arrayPhotos.push({
              file: file,
              name: fileName,
              type: file.type,
              size: file.size,
            });
            this.handleFilesPreview('photo',file);
          } else {
            this.arrayFiles.push({
              file: file,
              name: fileName,
              type: file.type,
              size: file.size,
            });
            this.handleFilesPreview('file',file);
          }
        }
      }
      xhr.setRequestHeader('Content-Type', file.type);
      xhr.send(file.slice());
    },

    getActivityType() {
      if (this.modelValue.length
          && !this.arrayFiles.length
          && !this.arrayPhotos.length) {
        return 'message';
      } else if (!this.modelValue.length
          && this.arrayFiles.length
          && !this.arrayPhotos.length) {
        return 'document';
      } else if (!this.modelValue.length
          && !this.arrayFiles.length
          && this.arrayPhotos.length) {
        return 'image';
      } else if (this.modelValue.length
          && !this.arrayFiles.length
          && this.arrayPhotos.length) {
        return 'image_message';
      } else if (this.modelValue.length
          && this.arrayFiles.length
          && !this.arrayPhotos.length) {
        return 'document_message';
      } else if (!this.modelValue.length
          && this.arrayFiles.length
          && this.arrayPhotos.length) {
        return 'image_document';
      } else if (this.modelValue.length
          && this.arrayFiles.length
          && this.arrayPhotos.length) {
        return 'image_document_message';
      }
      return '';
    },

    getFilesInfo() {
      const files = [];
      if (this.arrayFiles.length) {
        this.arrayFiles.map(file => {
          files.push({
            file_name: file.name,
            file_type: file.type,
            file_size: file.size.toString(),
            uploaded: true
          })
        });
      }
      if (this.arrayPhotos.length) {
        this.arrayPhotos.map(file => {
          files.push({
            file_name: file.name,
            file_type: file.type,
            file_size: file.size.toString(),
            uploaded: true
          })
        });
      }
      return files;
    },

    async handleSave() {
      const data = {
        id: this.projectId,
        params: {
          activity_type: this.getActivityType(),
          messages_attributes: [{
            message: this.modelValue,
          }],
          file_storages_attributes: this.getFilesInfo(),
        }
      }

      try {
        await this.addActivity(data);
        this.handleResetData();
      } catch (error) {
        console.log('Add Comment 2: ',error)
      }
    },

    handleResetData() {
      this.arrayPhotos.length = 0;
      this.arrayFiles.length = 0;
      this.filesPreview.length = 0;
      this.photosPreview.length = 0;
      this.$emit('update:modelValue', '');
      this.showButton = false;
      document.querySelector('#new-comment').style.height = "21px";
    },

    async handleDelete(e,fileType) {
      const clickedItem = fileType === 'photo'
        ? e.target.closest('.add-comment--gallery--item')
        : e.target.closest('.add-comment--list--item');

      const itemIndex = [...clickedItem.parentElement.children].indexOf(clickedItem);

      const fileName = fileType === 'photo'
        ? this.arrayPhotos[itemIndex].name
        : this.arrayFiles[itemIndex].name;

      try {
        await this.gcFileDelete({ file_name: fileName });
        if (fileType === 'photo') {
          this.arrayPhotos.splice(itemIndex, 1);
          this.photosPreview.splice(itemIndex, 1);
        } else {
          this.arrayFiles.splice(itemIndex, 1);
          this.filesPreview.splice(itemIndex, 1);
        }
      } catch (error) {
        console.error('Add comment 4', error);
        this.showError = true;
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.add-comment {
  padding: 10px 20px;
  border: 1px solid #CCCCD7;
  box-sizing: border-box;
  border-radius: 5px;
  width: calc(100% - 35px);

  &--gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;

    &.border-bottom {
      margin-bottom: 16px;
      border-bottom: 1px solid rgba(92, 92, 102, 0.16);
    }

    &--item {
      position: relative;
      width: 121px;
      height: 121px;
      margin-bottom: 16px;
      background-size: cover;
      background-position: center;

      &--delete {
        position: absolute;
        top: 8px;
        right: 8px;
        cursor: pointer;
      }

      &--cancel-load {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-90deg);
        cursor: pointer;
        animation: hide .4s forwards;
        animation-delay: 3s;
        transform-origin: center;
      }
    }
  }

  &--list {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;

    &--item {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0 0 14px;
      line-height: 150%;

      &--icon {
        width: 40px;
        height: 40px;
        margin-right: 8px;
        flex: none;
      }

      &--file-info {
        color: #151522;
        font-family: $SFProText;

        &--name {
          font-weight: 400;
          font-size: 12px;
          line-height: 150%;
          margin-bottom: 2px;
          white-space: nowrap;
          max-width: 150px;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &--size {
          margin: 0;
          font-weight: 300;
          font-size: 10px;
          line-height: 150%;
        }
      }

      &--status-icon {
        margin-left: auto;
        position: relative;
        width: 40px;
        height: 40px;

        &--cancel-load,
        &--loaded,
        &--delete {
          position: absolute;
          top: 50%;
          left: 50%;
          transform-origin: center;
          cursor: pointer;
        }

        &--cancel-load {
          transform: translate(-50%, -50%) rotate(-90deg);
          animation: hide .4s forwards;
          animation-delay: 3s;
        }

        &--loaded {
          opacity: 0;
          transform: translate(-50%, -50%);
          animation: show-hide 1s forwards;
          animation-delay: 3s;
        }

        &--delete {
          opacity: 0;
          transform: translate(-50%, -50%);
          width: 18px;
          height: 20px;
          animation: show .1s forwards;
          animation-delay: 3.8s;
        }
      }
    }
  }

  &--textarea {
    color: #151522;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 150%;
    width: 100%;
    height: 21px;
    font-family: $SFProText;
    font-weight: 300;
    box-shadow: none;
    border: none;
    resize: none;
    overflow: auto;
    padding: 0;
  }

  &--wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    &--button {
      padding: 10px 33px;
    }

    &--label {
      &--file {
        display: none;
      }

      &--icon {
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .add-comment {
    &--gallery--item {
      width: 90px;
      height: 90px;
    }

    &--list--item--file-info--name {
      max-width: 100px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .add-comment {
    &--gallery {
      justify-content: flex-start;

      &--item {
        margin-right: 10px;
      }
    }

    &--textarea {
      font-size: 14px;
    }
  }
}
</style>
