<template>
  <div>

    <custom-input
      :modelValue="addressOne"
      @update:modelValue="handleAddressOneChange"
      label="Address 1"
      id="address-one"
      type="text"
      name="address-one"
      :errorMessage="addressOneError"
    />

    <custom-input
      :modelValue="addressTwo"
      @update:modelValue="handleAddressTwoChange"
      label="Address 2"
      id="address-two"
      type="text"
      name="address-two"
      :errorMessage="''"
      optional
    />

  </div>
</template>

<script>
import CustomInput from '@/components/shared/CustomInput'

export default {

  components: {
    CustomInput
  },

  props: [
    'addressOne',
    'addressTwo',
    'handleAddressOneChange',
    'handleAddressTwoChange',
    'addressOneError',
  ],

}
</script>
