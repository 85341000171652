<template>
  <div
    class="search"
    :style="{ marginBottom: marginBottom + 'px' }"
  >
    <div class="search--wrapper">
      <div class="search--wrapper--input-wrapper">
        <input
          class="search--wrapper--input-wrapper--input"
          :class="{'typed': search.length}"
          :value="search"
          :placeholder="placeholder"
          :disabled="disabled"
          @input="handleSearchChange"
        >
        <inline-svg
          :src="require('@/assets/images/icons/cross.svg')"
          class="search--wrapper--input-wrapper--clear-icon"
          :class="{'show':  search.length}"
          @click="handleClearSearch"
        />
      </div>

      <button
        class="search--wrapper--clear btn-font-large"
        :class="{'show': search.length}"
        @click="handleClearSearch"
      >Cancel</button>
      <div
        class="search--wrapper--filter shadow-1"
        :class="[{'active': isFiltered},
                {'hidden':  search.length}]"
      >
        <inline-svg
          :src="require('@/assets/images/icons/filter.svg')"
          class="search--wrapper--filter--icon"
          @click="handleFilter"
        />
      </div>

      <button
        class="search--wrapper--desktop-filter-btn"
        :class="{'active': isFiltered}"
        @click="handleFilterModal"
      >
        <inline-svg
          :src="require('@/assets/images/icons/filter-dark.svg')"
          class="search--wrapper--desktop-filter-btn--icon"
        />
        Filters
      </button>
    </div>
    <p
      v-if="searchError"
      class="select--select-wrapper--error"
    >
      {{ searchError }}
    </p>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {

  components: {
    InlineSvg,
  },

  props: {
    search: {
      type: [String, Number],
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Search'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    marginBottom: {
      type: [String, Number],
      default: 9
    },
    searchError: {
      type: String,
      default: 'Error Search'
    },
    handleFilterModal: {
      type: Function,
    },
    isFiltered: {
      type: Boolean,
      default: false
    }
  },

  beforeUnmount() {
    this.searchTimeout && clearTimeout(this.searchTimeout);
  },

  methods: {
    handleFilter() {
      this.$emit('update:filterOpen', 'filter');
    },

    handleSearchChange(e) {
      this.searchTimeout && clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.$emit('update:search', e.target.value);
      }, 1000);
    },

    handleClearSearch() {
      this.$emit('update:search', '');
    },
  },

}
</script>

<style lang="scss">
.search {
  display: flex;
  flex-direction: column;
  max-width: 325px;
  width: 100%;
  margin: 0 auto;
  position: relative;

  &--wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    &--input-wrapper {
      position: relative;
      margin-right: 14px;
      width: 100%;

      &:before {
        content: "";
        position: absolute;
        top: 9px;
        left: 13px;
        width: 18px;
        height: 18px;
        background: url(~@/assets/images/icons/search.svg) no-repeat;
      }

      &--input {
        max-width: 275px;
        width: 100%;
        padding: 7px 36px 6px 42px;
        box-sizing: border-box;
        background: rgba(122, 149, 180, 0.08);
        border-radius: 8px;
        border: 1px solid rgba(122, 149, 180, 0.08);
        font-size: 16px;
        color: #151522;
        line-height: 150%;
        transition: .4s;
        border: 1px solid rgba(122, 149, 180, 0.08);

        &.typed {
          max-width: 259px;
          border: 1px solid #CCCCD7;
        }

        &:active, &:focus {
          border: 1px solid #CCCCD7;
        }

        &::placeholder {
          color: #9999A0;
        }
      }

      &--clear-icon {
        display: none;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 8px;
        cursor: pointer;

        &.show {
          display: block;

          circle {
            fill: #fff;
          }
        }
      }
    }

    &--clear {
      display: none;
      border: none;
      color: #4B617A;
      background: transparent;

      &.show {
        display: block;
      }
    }

    &--filter {
      position: relative;
      cursor: pointer;
      width: 36px;
      height: 36px;
      border: 1px solid #B3B3BE;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      box-sizing: border-box;
      flex: none;

      &.active {
        &:before {
          content: "";
          position: absolute;
          top: -2px;
          right: -2px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #E9A53B;
        }
      }

      &.hidden {
        display: none;
      }

      &--icon {
        path {
          stroke: #151522;
        }
      }
    }

    &--desktop-filter-btn {
      display: none;
    }
  }

  &--error {
    font-size: 12px;
    color: #E83C56;
    text-align: right;
    font-family: $SFProText;
    font-weight: 500;
    line-height: 150%;
    position: absolute;
    bottom: -22px;
    left: 0;
  }
}

@media screen and (min-width: 1024px) {
  .search {
    max-width: calc(100% - 120px);
    margin: 0 60px;
    box-sizing: border-box;

    &--wrapper {

      &--input-wrapper {
        max-width: 463px;
        width: 100%;

        &:before {
          content: "";
          top: 18px;
          left: 17px;
          background: url(~@/assets/images/icons/search-dark.svg) no-repeat;
        }

        &--input {
          max-width: 463px;
          width: 100%;
          padding: 16px 40px 15px 46px;
          box-sizing: border-box;
          background: #FFFFFF;
          border-radius: 4px;
          border: 1px solid #CCCCD7;
          font-size: 14px;
          color: #151522;
          line-height: 150%;
          margin-right: 14px;
          transition: .4s;

          &.typed {
            max-width: 463px;
          }

          &:active, &:focus {
            border: 1px solid #CCCCD7;
          }

          &::placeholder {
            color: #9999A0;
          }
        }

        &--clear-icon {
          width: 24px;
          height: 24px;
          left: 427px;
          right: unset;

          &.show {
            circle {
              fill: #CCCCD7;
            }
          }
        }
      }

      &--clear,
      &--filter {
        display: none;
      }

      &--clear {
        &.show {
          display: none;
        }
      }

      &--desktop-filter-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 129px;
        height: 42px;
        font-size: 16px;
        background: #fff;
        color: #151522;
        border: 1px solid #B3B3BE;
        text-align: center;
        border-radius: 5px;
        font-family: $SFProText;
        font-weight: 400;
        transition: .3s;
        line-height: 150%;

        &:hover {
          border: 1px solid #9999A0;
        }

        &:focus, &:active {
          border: 1px solid #10886D;
        }

        &.active {
          position: relative;
          border: 1px solid #B3B3BE;

          &:before {
            content: "";
            position: absolute;
            top: 9px;
            left: 38px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #E9A53B;
          }
        }

        &.disabled {
          color: #9999A0;
          border: 1px solid #B3B3BE;

          path {
            stroke: #9999A0;
          }
        }

        &--icon {
          margin-right: 9px;
        }
      }
    }
  }
}
</style>
