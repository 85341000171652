<template>
  <div
    class="radio-item"
    :style="{ marginBottom: marginBottom + 'px' }">
    <label
      class="radio-item--label"
    >
      {{ label }}
      <span
        v-if="label && optional"
        class="h6-light"
      >(opt.)</span>
    </label>

    <div
      class="radio-item--wrapper"
      v-for="(option, index) in options"
      :key="index"
    >
      <input
        class="radio-item--wrapper--input"
        :id="id + index"
        :name="id"
        type="radio"
        :value="option.value"
        :disabled="disabled"
        :checked="option.value === modelValue"
        @change="updateValue(option.value)"
      />

      <label
        class="radio-item--wrapper--label"
        :for="id + index"
      >
        {{ option.text }}
      </label>

    </div>

    <p v-if="errorMessage" class="radio-item--error">
      {{ errorMessage }}
    </p>

  </div>
</template>

<script>
export default {

  props: {
    modelValue: {
      type: [String, Number, Boolean, Object],
      default: false
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number, Boolean, Object],
      default: null,
    },
    options: {
      type: [Array],
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    marginBottom: {
      type: [String, Number],
      default: 0
    },
    errorMessage: {
      type: String,
      default: 'Error Notification'
    },
    optional: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    updateValue(value) {
      this.$emit("update:modelValue", value);
    }
  },

}
</script>

<style lang="scss">
.radio-item {
  display: flex;
  flex-direction: column;
  max-width: 325px;
  width: 100%;
  margin: 0 auto;
  position: relative;

  &--label {
    font-size: 16px;
    font-weight: 600;
    color: #151522;
    margin: 0 0 13px 0;
    text-align: left;
    font-family: $SFProText;
    line-height: 150%;
  }

  &--wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &--label {
      font-family: $SFProText;
      font-weight: 300;
      font-size: 16px;
      color: #000000;
      line-height: 24px;
      margin-bottom: 12px;
    }

    &--input {
      width: 0;
      height: 0;
      opacity: 0;

      & + label,
      &:not(:checked) + label {
        position: relative;
        padding-left: 34px;
        cursor: pointer;
        display: inline-block;
      }

      &:checked + label:before,
      &:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 24px;
        height: 24px;
        border: 2px solid #4B617A;
        box-sizing: border-box;
        border-radius: 100%;
        background: #fff;
      }

      &:checked + label:after,
      &:not(:checked) + label:after {
        content: '';
        width: 14px;
        height: 14px;
        background: #4B617A;
        position: absolute;
        top: 5px;
        left: 5px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
      }

      &:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
      }

      &:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
      }

      &:disabled {
        pointer-events: none;

        & + label:before {
          border: 2px solid #E6E6EB;
        }
      }
    }
  }

  &--error {
    font-size: 12px;
    color: #E83C56;
    text-align: right;
    font-family: $SFProText;
    font-weight: 500;
    line-height: 150%;
    position: absolute;
    bottom: -22px;
    right: 0;
  }
}

@media screen and (min-width: 1024px) {
  .radio-item {
    max-width: 100%;

    &--wrapper {
      &--label {
        font-size: 14px;
      }
    }
  }
}
</style>
