<template>
  <div class="collapse">
    <div class="collapse--header">
      <h4 class="collapse--header--title h4-sb">{{summary.title}}</h4>
      <inline-svg
        :src="require('@/assets/images/icons/edit-icon.svg')"
        class="collapse--header--icon edit"
        :data-edit-step="step"
        @click="handleEdit"
      />
      <inline-svg
        :src="require('@/assets/images/icons/arrow-down.svg')"
        class="collapse--header--icon"
        :style="showDetails && 'transform: rotate(180deg);'"
        @click="toggleDetails"
      />
    </div>

    <div
      v-if="showDetails"
      class="collapse--body"
    >
      <div v-if="summary.details">
        <div
          v-for="(detail, i) in summary.details"
          :key="i"
          class="collapse--body--details-item">
          <p class="collapse--body--details-item--title label text-medium">{{detail.title}}</p>
          <p class="collapse--body--details-item--description h6-light">{{detail.text}}</p>
        </div>
      </div>

      <div
        v-if="summary.photos"
        class="collapse--body--gallery"
      >
        <div
          v-for="photo in summary.photos"
          :key="photo"
          class="collapse--body--gallery--item"
          :style="{'background-image': `url('${photo}')`}"
        ></div>
      </div>

      <div
        v-if="summary.files"
        class="collapse--body--files"
      >
        <div
          v-for="file in summary.files"
          :key="file.name"
          class="collapse--body--files--item"
        >
          <inline-svg
            class="collapse--body--files--item--icon"
            :src="require(`@/assets/images/icons/files/${file.name.split('.').pop()}.svg`)"
          />
          <div class="collapse--body--files--item--file-info">
            <p class="collapse--body--files--item--file-info--name">
              {{ file.name.length > 25
                ? handleFileNameTrim(file.name)
                : file.name }}
            </p>
            <p class="collapse--body--files--item--file-info--size">
              {{ file.size > 1000000
                ? ((file.size/1000000).toFixed(1) + 'MB')
                : ((file.size/1000).toFixed(1) + 'KB') }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
  components: {
    InlineSvg
  },

  props: [
    'summary',
    'photos',
    'files',
    'step',
  ],

  data: () => ({
    showDetails: false
  }),

  methods: {
    handleFileNameTrim(longName) {
      let result = longName.substring(0, 25) + '...' + longName.slice(-7);
      return result;
    },

    toggleDetails() {
      this.showDetails = !this.showDetails;
    },

    handleEdit(e) {
      const editStep = e.target.closest('.edit').dataset.editStep;
      this.$emit('update:currentStep', editStep);
    }
  }
}
</script>

<style lang="scss">
.collapse {
  padding: 30px 0;
  border-bottom: 1px solid rgba(92, 92, 102, 0.32);

  &--header {
    display: flex;
    align-items: center;

    &--title {
      width: 80%;
      color: #151522;
      opacity: 0.9;
    }

    &--icon {
      margin-left: 20px;
      transition: all .3s ease;
      cursor: pointer;
    }
  }

  &--body {
    margin-top: 20px;

    &--details-item {
      margin-bottom: 14px;

      &--title {
        margin-bottom: 6px;
        color: #151522;
      }

      &--description {
        color: #151522;
      }

      &:last-child {
        margin-bottom: 0px;
      }
    }

    &--gallery {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;

      &--item {
        position: relative;
        width: 154px;
        height: 154px;
        margin-bottom: 17px;
        background-size: cover;
        background-position: center;
      }
    }

    &--files {
      display: flex;
      flex-direction: column;
      width: 100%;

      &--item {
        display: flex;
        width: 100%;
        margin-bottom: 17px;

        &:last-child {
          margin-bottom: 0;
        }

        &--icon {
          width: 40px;
          height: 40px;
          margin-right: 8px;
        }

        &--file-info {
          color: #151522;
          font-family: $SFProText;

          &--name {
            font-weight: 400;
            font-size: 12px;
            margin-bottom: 15px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 260px;
          }

          &--size {
            margin: 0;
            font-weight: 300;
            font-size: 10px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .collapse--body--gallery--item {
    width: 130px;
    height: 130px;
  }
}

@media screen and (min-width: 1024px) {
  .collapse--body--gallery--item {
    width: 190px;
    height: 190px;
  }
}
</style>
