<template>
  <div class="invite-modal">
    <div class="invite-modal--content">
      <inline-svg
        :src="require('@/assets/images/icons/plus-rotated.svg')"
        class="invite-modal--content--close"
        @click="handleCancel"
      />
      <h6 class="invite-modal--content--title h2-sb">Invitation</h6>
      <p class="invite-modal--content--description h6-regular">
        You were invited to join the <span>{{ user.inviter.company_name }}</span> as <span>{{ userRole }}</span>. By accepting the invitation your current dashboard will be switched to the <span>{{ user.inviter.company_name }}</span> dashboard.
      </p>
      <p class="invite-modal--content--description h6-regular">
        <span>Note:</span> all data for the existing company will be updated.
      </p>
      <div class="invite-modal--content--controls">
        <p
          class="invite-modal--content--controls--button ghost-btn"
          @click="handleCancel"
        >Decline</p>
        <p
          class="invite-modal--content--controls--button normal-btn"
          @click="handleConfirm"
        >Accept</p>
      </div>
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
  components: {
    InlineSvg
  },

  props: {
    user: {
      type: Object,
      default: () => {}
    },
    handleCancel: {
      type: Function,
      default: () => {}
    },
    handleConfirm: {
      type: Function,
      default: () => {}
    },
  },

  computed: {
    userRole() {
      switch(this.user.inviter?.role) {
        case 0: {
          return 'Admin';
        }
        case 1: {
          return 'Editor';
        }
        case 2: {
          return 'Veiwer';
        }
        default:
          return '';
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.invite-modal {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(21,21,34,0.5);
  width: 100%;
  height: 100%;
  z-index: 4;

  &--content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background: #fff;
    border-radius: 26px;
    display: flex;
    flex-direction: column;
    padding: 40px;
    max-width: 477px;
    width: 90%;
    box-sizing: border-box;

    &--close {
      position: absolute;
      top: 50px;
      right: 50px;
      cursor: pointer;
    }

    &--title {
      color: #151522;
      text-align: left;
      margin-bottom: 6px;
    }

    &--description {
      color: #4B617A;
      text-align: left;
      margin-bottom: 6px;

      span {
        font-weight: 600;
      }

      &:last-child {
        margin-bottom: 20px;
      }
    }

    &--controls {
      padding-top: 14px;
      display: flex;
      justify-content: space-between;

      &--button {
        width: calc(50% - 6px);
        padding: 13px 0;
        cursor: pointer;
        text-align: center;
      }
    }
  }
}
</style>
