<template>
  <div class="activity">
    <div class="activity--header">
      <h4 class="h4-sb">Activity</h4>
    </div>
    <div class="activity--add-block activity-item">
      <div class="activity--add-block--logo-wrapper activity-logo-block">
        <div
          class="activity-logo-block-image"
          :style="{'background-image': `url('${setLogoImage()}')`}">
        </div>
      </div>
      <AddComment
        :modelValue="newComment"
        :projectId="getProjectInfo.id"
        :isMobile="isMobile"
        @update:modelValue="handleUpdateNewComment"
      />
    </div>

    <div
      v-for="(activity, index) in getProjectActivities"
      :key="index"
    >
      <ActivityItem
        :activity="activity"
        :isMobile="isMobile"
      />
    </div>

    <CustomLoader
      v-if="isLoading
            && getProjectActivitiesTotalPages !== 0
            && getProjectActivitiesTotalPages !== 1"
      :totalPages="getProjectActivitiesTotalPages"
      :currentPage="currentPage"
      @handleScrollLoad="handleScrollLoad"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { nextTick } from 'vue';

import _unionBy from 'lodash/unionBy';

import AddComment from '@/components/dashboard/project-details/AddComment';
import ActivityItem from '@/components/dashboard/ActivityItem';
import CustomLoader from '@/components/shared/CustomLoader';

export default {

  props:[
    'setLogoImage',
    'isMobile'
  ],

  components: {
    AddComment,
    ActivityItem,
    CustomLoader
  },

  data: () => ({
    newComment: '',
    activities: [],
    isLoading: true,
    currentPage: 1
  }),

  computed: {
    ...mapGetters([
      'getProjectInfo',
      'getProjectActivities',
      'getProjectActivitiesTotalPages',
      'getProjectActivityNew'
    ])
  },

  watch: {
    getProjectActivityNew() {
      this.handleNewActivity();
    }
  },

  mounted() {
    this.handleActivitiesLoad();
  },

  methods: {
    ...mapActions([
      'projectActivitiesInfo'
    ]),

    handleUpdateNewComment(value) {
      this.newComment = value;
    },

    async handleActivitiesLoad() {
      await this.projectActivitiesInfo({
        id: this.$route.params.id,
        page: this.currentPage.toString()
      });
      this.activities.push(...this.getProjectActivities);
    },

    async handleNewActivity() {
      this.activities.unshift(this.getProjectActivityNew);
      await this.$store.commit('setProjectActivities', this.activities);
    },

    async handleScrollLoad() {
      this.currentPage++;

      const data = {
        id: this.$route.params.id,
        page: this.currentPage.toString()
      }

      await this.projectActivitiesInfo(data);
      this.activities = _unionBy(this.activities,  this.getProjectActivities, 'activity_feed.id');

      this.$store.commit('setProjectActivities', this.activities);

      this.isLoading = false;
      if (this.getProjectActivitiesTotalPages > this.currentPage) {
        // edge case for hight resolution screens
        await nextTick()
        this.isLoading = true;
      }
    },
  }

}
</script>

<style lang="scss">
.activity {
  padding: 30px 0;
  margin-bottom: 50px;
  border-bottom: 1px solid rgba(92, 92, 102, 0.32);

  &--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  &--add-block {
    align-items: flex-start;

    &--logo-wrapper {
      flex: none;
    }
  }

  &--text {
    &--wrapper {
      p {
        color: #151522;
        opacity: 0.9;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .activity {
    margin-bottom: 0;
  }
}
</style>
