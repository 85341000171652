<template>
  <div
    class="sidebar"
    :style="setColor ? 'background: #E6E6EB;' : 'background: #233140;'"
  >
    <div class="sidebar--without-login">
      <div
        class="sidebar--without-login--logo"
        :style="setColor ?
          {'background-image':`url(${require('@/assets/images/scope-logo-dark.svg')})`} :
          {'background-image':`url(${require('@/assets/images/scope-logo-white.svg')})`}"
      ></div>
      <div
        v-if="authPage"
        class="sidebar--without-login--copyright"
      >
        <a
          class="sidebar--without-login--copyright--link"
          style="color: #4eb9ed"
          href="https://finishingcontractors.org/"
          target="_blank"
        >Powered by © {{ currentYear }} FCA International</a>
        <a
          class="sidebar--without-login--copyright--link"
          :style="setColor ? 'color: #666670;' : 'color: #E6E6EB;'"
          href="https://finishingcontractors.org/contact/"
          target="_blank"
        >Contact us</a>
        <a
          class="sidebar--without-login--copyright--link"
          :style="setColor ? 'color: #666670;' : 'color: #E6E6EB;'"
          href="https://finishingcontractors.org/scope-app-terms-and-conditions"
          target="_blank"
        >Terms and Conditions</a>
      </div>

      <p
        v-else
        class="sidebar--without-login--copyright"
        :style="setColor ? 'color: #666670;' : 'color: #E6E6EB;'"
      >Powered by © {{ currentYear }} FCA International</p>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    authPage: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters([
      'getContractorCompanyMainInfo',
      'getInvitedUser'
    ]),

    setColor() {
      return this.getContractorCompanyMainInfo || (this.getInvitedUser && this.getInvitedUser.user_type === 'customer');
    },

    currentYear() {
      return new Date().getFullYear();
    }
  }
}
</script>

<style lang="scss">
.sidebar {
  display: none;
}

@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .sidebar {
    max-width: 39%;
  }
}

@media screen and (min-width: 1024px) {
  .sidebar {
    padding: 100px 60px;
    width: 100%;
    box-sizing: border-box;
    background: #233140;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    &--without-login {
      &--logo {
        width: 237px;
        height: 65px;
        background-repeat: no-repeat;
        margin-bottom: 40px;
      }

      &--copyright {
        font-family: $SFProText;
        position: absolute;
        left: 60px;
        bottom: 40px;
        font-size: 14px;
        line-height: 150%;
        color: #E6E6EB;
        display: flex;
        flex-direction: column;

        &--link {
          text-decoration: none;
        }
      }
    }
  }
}

@media screen and (min-width: 1440px) {
  .sidebar {
    max-width: 570px;
  }
}
</style>
