<template>
  <div class="add-company-contact-info">

    <div>
      <custom-input
        :modelValue="city"
        @update:modelValue="handleCityChange"
        label="City"
        id="city"
        type="text"
        name="city"
        :errorMessage="cityError"
      />

      <div class="add-company-contact-info--row">
        <custom-input
          :modelValue="state"
          @update:modelValue="handleStateChange"
          label="State"
          id="state"
          type="state"
          name="state"
          :errorMessage="stateError"
        />

        <custom-input
          :modelValue="zip"
          @update:modelValue="handleZipChange"
          label="Zip"
          id="zip"
          type="text"
          name="zip"
          :errorMessage="zipError"
        />
      </div>
    </div>

    <div class="add-company-contact-info--address">
      <custom-input
        :modelValue="addressOne"
        @update:modelValue="handleAddressOneChange"
        label="Address 1"
        id="address-one"
        type="text"
        name="address-one"
        :errorMessage="addressOneError"
      />

      <custom-input
        :modelValue="addressTwo"
        @update:modelValue="handleAddressTwoChange"
        label="Address 2"
        id="address-two"
        type="text"
        name="address-two"
        :errorMessage="''"
        optional
      />
    </div>

  </div>
</template>

<script>
import CustomInput from '@/components/shared/CustomInput'

export default {

  components: {
    CustomInput,
  },

  props: [
    'city',
    'state',
    'selectOptions',
    'zip',
    'addressOne',
    'addressTwo',
    'handleCityChange',
    'handleStateChange',
    'handleZipChange',
    'handleAddressOneChange',
    'handleAddressTwoChange',
    'cityError',
    'stateError',
    'zipError',
    'addressOneError',
  ],

}
</script>

<style lang="scss">
@media screen and (min-width: 1024px) {
  .add-company-contact-info {
    margin-bottom: 42px;

    &--row {
      display: flex;
      justify-content: space-between;

      & > div:first-child {
        margin-right: 16px;
      }
    }
  }
}
</style>
