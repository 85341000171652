<template>
  <div class="prompt-apps">

    <div class="prompt-apps--body">
      <inline-svg
        :src="require('@/assets/images/icons/appstore.svg')"
        class="prompt-apps--body--link"
        @click="handleAppstore"
      />

      <inline-svg
        :src="require('@/assets/images/icons/googleplay.svg')"
        class="prompt-apps--body--link"
        @click="handleGoogleplay"
      />
    </div>

  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
  components: {
    InlineSvg
  },

  methods: {
    handleAppstore() {
      window.open('https://apps.apple.com/us/app/scope-by-fca-international/id1569702931',"_self");
    },

    handleGoogleplay() {
      window.open('https://play.google.com/store/apps/details?id=com.fca.fcaapp',"_self");
    },
  }
}
</script>
<style lang="scss">
.prompt-apps {
  &--body {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 200px;
    padding-top: 9px;

    &--link {
      cursor: pointer;

      &:first-child {
        margin-bottom: 20px;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .prompt-apps {
    margin-bottom: 120px;

    &--body {
      flex-direction: row;
      align-items: center;
      margin-bottom: 0;

      &--link {
        max-width: 180px;

        &:first-child {
          margin: 0 21px 0 0;
        }
      }
    }
  }
}
</style>
