<template>
  <div
    class="dashboard-inner-page quote"
    :style="additionalStyles">

    <div
      v-if="isMobile"
      class="dashboard-inner-page--quote-wrapper"
      :class="isUpdateQuoteDetails && 'update-quote'"
    >
      <h2 class="dashboard-inner-page--quote-wrapper--title h2-sb">
        {{ stepSubTitle }}
        <span
          v-if="quoteCurrentStep === 3 || quoteCurrentStep === 4"
          class="h3-light"
        >(opt.)</span>
      </h2>
      <div class="dashboard-inner-page--quote-wrapper--step">
        <BasicInfo
          v-if="quoteCurrentStep === 0"
          :projectName="projectName"
          :contactName="contactName"
          :contactEmail="contactEmail"
          :startDate="startDate"
          :projectType="projectType"
          :projectTypeOptions="projectTypeOptions"
          :handleProjectNameChange="handleProjectNameChange"
          :handleContactNameChange="handleContactNameChange"
          :handleContactEmailChange="handleContactEmailChange"
          :handleStartDateChange="handleStartDateChange"
          :handleProjectTypeChange="handleProjectTypeChange"
          :projectNameError="projectNameError"
          :contactNameError="contactNameError"
          :contactEmailError="contactEmailError"
          :startDateError="startDateError"
          :projectTypeError="projectTypeError"
        />

        <Requirements
          v-if="quoteCurrentStep === 1"
          :timeRequirements="timeRequirements"
          :blackoutDays="blackoutDays"
          :specialRequirements="specialRequirements"
          :handleTimeRequirementsChange="handleTimeRequirementsChange"
          :handleBlackoutDaysChange="handleBlackoutDaysChange"
          :handleSpecialRequirementsChange="handleSpecialRequirementsChange"
        />

        <SiteInfo
          v-if="quoteCurrentStep === 2"
          :location="location"
          :safetyRequirements="safetyRequirements"
          :siteVisit="siteVisit"
          :siteVisitOptions="siteVisitOptions"
          :accessibility="accessibility"
          :locationError="locationError"
          :siteVisitError="siteVisitError"
          :handleLocationChange="handleLocationChange"
          :handleSafetyRequirementsChange="handleSafetyRequirementsChange"
          :handleSiteVisitChange="handleSiteVisitChange"
          :handleAccessibilityChange="handleAccessibilityChange"
        />

        <Summary
          v-if="quoteCurrentStep === 5"
          :projectName="projectName"
          :contactName="contactName"
          :contactEmail="contactEmail"
          :startDate="startDate"
          :projectType="projectType"
          :timeRequirements="timeRequirements"
          :blackoutDays="blackoutDays"
          :specialRequirements="specialRequirements"
          :location="location"
          :safetyRequirements="safetyRequirements"
          :siteVisit="siteVisit"
          :siteVisitOptions="siteVisitOptions"
          :accessibility="accessibility"
          :photos="photos"
          :photosPreview="photosPreview"
          :files="files"
          :filesPreview="filesPreview"
          :handleEditStep="handleEditStep"
          :startFromZero="startFromZero"
        />

      </div>

      <CustomPhotosUploader
        :class="{'hidden': quoteCurrentStep !== 3}"
        :photos="photos"
        @update:photos="handleUploadPhotosChange"
        :photosPreview="photosPreview"
        @update:photosPreview="handleUploadPhotosPreviewChange"
        :photosIds="photosIds"
        :deleteFile="isUpdateQuoteDetails ? handleToggleDeleteModal : deleteFile"
      />

      <CustomFilesUploader
        :class="{'hidden': quoteCurrentStep !== 4}"
        :modelValue="files"
        @update:modelValue="handleUploadFilesChange"
        :filesPreview="filesPreview"
        @update:filesPreview="handleUploadFilesPreviewChange"
        :filesIds="filesIds"
        :deleteFile="isUpdateQuoteDetails ? handleToggleDeleteModal : deleteFile"
        edit
      />

      <CustomCongrats
        v-if="quoteCurrentStep === 6"
        :description="getUserRole === 'contractor' ? 'Project was successfully created.' : 'Your quote was successfully sent'"
        maxWidth="325"
      />

      <div
        v-if="quoteCurrentStep === 6"
        class="dashboard-inner-page--quote-wrapper--buttons-wrapper"
      >
        <p v-if="getUserRole !== 'contractor'" class="dashboard-inner-page--quote-wrapper--buttons-wrapper--text p-medium">Would you like to request new quote?</p>
        <button
          v-if="getUserRole !== 'contractor'"
          class="dashboard-inner-page--quote-wrapper--buttons-wrapper--button-top normal-btn"
          @click="handleCreateQuote"
        >
          Yes, request new quote
        </button>
        <button
          class="dashboard-inner-page--quote-wrapper--buttons-wrapper--button-bottom ghost-btn"
          @click="handleToMainPage"
        >
          {{getUserRole === 'contractor' ? 'Go to Dashboard' : 'No, thank you'}}
        </button>
      </div>

      <button
        v-if="quoteCurrentStep !== 6"
        class="dashboard-inner-page--quote-wrapper--next normal-btn"
        :class="isButtonDisabled && 'disabled'"
        @click="isUpdateQuoteDetails ? updateQuoteInfo(quoteCurrentStep) : handleNextStep()"
      >
        {{ nextButtonTitle }}
      </button>

      <p v-if="getUserRole !== 'contractor'" class="dashboard-inner-page--quote-wrapper--copyright caption-3">{{ companyName }}</p>
    </div>

    <div
      v-if="!isMobile && quoteCurrentStep !== 6"
      class="dashboard-inner-page--quote-desktop"
    >
      <div
        class="dashboard-inner-page--sub-step-body"
        :class="{'open': quoteCurrentStep === 0}"
      >
        <div
          class="dashboard-inner-page--sub-step-body--desktop-header"
          @click="handleSubStep(0)"
        >
          <div class="dashboard-inner-page--sub-step-body--desktop-header--text">
            <p class="dashboard-inner-page--sub-step-body--desktop-header--text--title h2-sb">Basic info</p>
            <p class="dashboard-inner-page--sub-step-body--desktop-header--text--description h3-regular">Add basic info to your quote</p>
          </div>
          <button class="dashboard-inner-page--sub-step-body--desktop-header--button quote-step-button btn-font-large">
            {{quoteCurrentStep === 0 ? 'Close' : 'Edit'}}
          </button>
        </div>
        <div class="dashboard-inner-page--sub-step-body--desktop-body">
          <BasicInfo
            v-if="quoteCurrentStep === 0"
            :projectName="projectName"
            :contactName="contactName"
            :contactEmail="contactEmail"
            :startDate="startDate"
            :projectType="projectType"
            :projectTypeOptions="projectTypeOptions"
            :handleProjectNameChange="handleProjectNameChange"
            :handleContactNameChange="handleContactNameChange"
            :handleContactEmailChange="handleContactEmailChange"
            :handleStartDateChange="handleStartDateChange"
            :handleProjectTypeChange="handleProjectTypeChange"
            :projectNameError="projectNameError"
            :contactNameError="contactNameError"
            :contactEmailError="contactEmailError"
            :startDateError="startDateError"
            :projectTypeError="projectTypeError"
          />
          <button
            class="dashboard-inner-page--sub-step-body--button normal-btn"
            :class="isButtonDisabled && 'disabled'"
            @click="isUpdateQuoteDetails ? updateQuoteInfo(quoteCurrentStep) : handleNextStep()"
          >{{isUpdateQuoteDetails
            ? isUpdating
              ? 'Updated!'
              : 'Update'
            : 'Next'}}</button>
        </div>
      </div>

      <div
        class="dashboard-inner-page--sub-step-body"
        :class="{'open': quoteCurrentStep === 1}"
      >
        <div
          class="dashboard-inner-page--sub-step-body--desktop-header"
          @click="handleSubStep(1)"
        >
          <div class="dashboard-inner-page--sub-step-body--desktop-header--text">
            <p class="dashboard-inner-page--sub-step-body--desktop-header--text--title h2-sb">Requirements</p>
            <p class="dashboard-inner-page--sub-step-body--desktop-header--text--description h3-regular">Add requirements to your quote</p>
          </div>
          <button class="dashboard-inner-page--sub-step-body--desktop-header--button quote-step-button btn-font-large">
            {{quoteCurrentStep === 1 ? 'Close' : 'Edit'}}
          </button>
        </div>
        <div class="dashboard-inner-page--sub-step-body--desktop-body">
          <Requirements
            v-if="quoteCurrentStep === 1"
            :timeRequirements="timeRequirements"
            :blackoutDays="blackoutDays"
            :specialRequirements="specialRequirements"
            :handleTimeRequirementsChange="handleTimeRequirementsChange"
            :handleBlackoutDaysChange="handleBlackoutDaysChange"
            :handleSpecialRequirementsChange="handleSpecialRequirementsChange"
          />
          <button
            class="dashboard-inner-page--sub-step-body--button normal-btn"
            :class="isButtonDisabled && 'disabled'"
            @click="isUpdateQuoteDetails ? updateQuoteInfo(quoteCurrentStep) : handleNextStep()"
          >{{isUpdateQuoteDetails
            ? isUpdating
              ? 'Updated!'
              : 'Update'
            : 'Next'}}</button>
        </div>
      </div>

      <div
        class="dashboard-inner-page--sub-step-body"
        :class="{'open': quoteCurrentStep === 2}"
      >
        <div
          class="dashboard-inner-page--sub-step-body--desktop-header"
          @click="handleSubStep(2)"
        >
          <div class="dashboard-inner-page--sub-step-body--desktop-header--text">
            <p class="dashboard-inner-page--sub-step-body--desktop-header--text--title h2-sb">Site</p>
            <p class="dashboard-inner-page--sub-step-body--desktop-header--text--description h3-regular">Add site information to your quote</p>
          </div>
          <button class="dashboard-inner-page--sub-step-body--desktop-header--button quote-step-button btn-font-large">
            {{quoteCurrentStep === 2 ? 'Close' : 'Edit'}}
          </button>
        </div>
        <div  class="dashboard-inner-page--sub-step-body--desktop-body">
          <SiteInfo
            v-if="quoteCurrentStep === 2"
            :location="location"
            :safetyRequirements="safetyRequirements"
            :siteVisit="siteVisit"
            :siteVisitOptions="siteVisitOptions"
            :accessibility="accessibility"
            :locationError="locationError"
            :siteVisitError="siteVisitError"
            :handleLocationChange="handleLocationChange"
            :handleSafetyRequirementsChange="handleSafetyRequirementsChange"
            :handleSiteVisitChange="handleSiteVisitChange"
            :handleAccessibilityChange="handleAccessibilityChange"
          />
          <button
            class="dashboard-inner-page--sub-step-body--button normal-btn"
            :class="isButtonDisabled && 'disabled'"
            @click="isUpdateQuoteDetails ? updateQuoteInfo(quoteCurrentStep) : handleNextStep()"
          >{{isUpdateQuoteDetails
            ? isUpdating
              ? 'Updated!'
              : 'Update'
            : 'Next'}}</button>
        </div>
      </div>

      <div
        class="dashboard-inner-page--sub-step-body"
        :class="{'open': quoteCurrentStep === 3}"
      >
        <div
          class="dashboard-inner-page--sub-step-body--desktop-header"
          @click="handleSubStep(3)"
        >
          <div class="dashboard-inner-page--sub-step-body--desktop-header--text">
            <p class="dashboard-inner-page--sub-step-body--desktop-header--text--title h2-sb">Photos</p>
            <p class="dashboard-inner-page--sub-step-body--desktop-header--text--description h3-regular">Add photos to your quote</p>
          </div>
          <button class="dashboard-inner-page--sub-step-body--desktop-header--button quote-step-button btn-font-large">
            {{quoteCurrentStep === 3 ? 'Close' : 'Edit'}}
          </button>
        </div>
        <div
          class="dashboard-inner-page--sub-step-body--desktop-body full-width"
          :class="!photosPreview.length && 'no-photos'"
        >
          <CustomPhotosUploader
            :class="{'hidden': quoteCurrentStep !== 3}"
            :photos="photos"
            @update:photos="handleUploadPhotosChange"
            :photosPreview="photosPreview"
            @update:photosPreview="handleUploadPhotosPreviewChange"
            :photosIds="photosIds"
            :deleteFile="isUpdateQuoteDetails ? handleToggleDeleteModal : deleteFile"
          />
          <button
            class="dashboard-inner-page--sub-step-body--button normal-btn"
            :class="isButtonDisabled && 'disabled'"
            @click="isUpdateQuoteDetails ? updateQuoteInfo(quoteCurrentStep) : handleNextStep()"
          >{{isUpdateQuoteDetails
            ? isUpdating
              ? 'Updated!'
              : 'Update'
            : 'Next'}}</button>
        </div>
      </div>

      <div
        class="dashboard-inner-page--sub-step-body"
        :class="{'open': quoteCurrentStep === 4}"
      >
        <div
          class="dashboard-inner-page--sub-step-body--desktop-header"
          @click="handleSubStep(4)"
        >
          <div class="dashboard-inner-page--sub-step-body--desktop-header--text">
            <p class="dashboard-inner-page--sub-step-body--desktop-header--text--title h2-sb">Files</p>
            <p class="dashboard-inner-page--sub-step-body--desktop-header--text--description h3-regular">Add files to your quote</p>
          </div>
          <button class="dashboard-inner-page--sub-step-body--desktop-header--button quote-step-button btn-font-large">
            {{quoteCurrentStep === 4 ? 'Close' : 'Edit'}}
          </button>
        </div>
        <div
          class="dashboard-inner-page--sub-step-body--desktop-body full-width"
          :class="!filesPreview.length && 'no-files'"
        >
          <CustomFilesUploader
            :class="{'hidden': quoteCurrentStep !== 4}"
            :modelValue="files"
            @update:modelValue="handleUploadFilesChange"
            :filesPreview="filesPreview"
            @update:filesPreview="handleUploadFilesPreviewChange"
            :filesIds="filesIds"
            :deleteFile="isUpdateQuoteDetails ? handleToggleDeleteModal : deleteFile"
            edit
          />
          <button
            class="dashboard-inner-page--sub-step-body--button normal-btn"
            :class="isButtonDisabled && 'disabled'"
            @click="isUpdateQuoteDetails ? updateQuoteInfo(quoteCurrentStep) : handleNextStep()"
          >{{isUpdateQuoteDetails
            ? isUpdating
              ? 'Updated!'
              : 'Update'
            : 'Next'}}</button>
        </div>
      </div>

    </div>

    <button
      v-if="!isUpdateQuoteDetails && !isMobile && quoteCurrentStep !== 6"
      class="dashboard-inner-page--submit-quote normal-btn"
      :class="isSubmitButtonDisabled && 'disabled'"
      @click="isUpdateQuoteDetails ? updateQuoteInfo(quoteCurrentStep) : submitForm('customer')"
    >Submit</button>

    <div
      v-if="!isMobile && quoteCurrentStep === 6"
      class="dashboard-inner-page--quote-desktop--congrats"
    >
      <CustomCongrats
        :description="getUserRole === 'contractor' ? 'Project was successfully created.' : 'Your quote was successfully sent'"
        maxWidth="325"
      />

      <div  class="dashboard-inner-page--quote-wrapper--buttons-wrapper">
        <p v-if="getUserRole !== 'contractor'" class="dashboard-inner-page--quote-wrapper--buttons-wrapper--text p-medium">Would you like to request new quote?</p>
        <button
          v-if="getUserRole !== 'contractor'"
          class="dashboard-inner-page--quote-wrapper--buttons-wrapper--button-top normal-btn"
          @click="handleCreateQuote"
        >
          Yes, request new quote
        </button>
        <button
          class="dashboard-inner-page--quote-wrapper--buttons-wrapper--button-bottom ghost-btn"
          @click="handleToMainPage"
        >
          {{getUserRole === 'contractor' ? 'Go to Dashboard' : 'No, thank you'}}
        </button>
      </div>
    </div>

    <custom-error
      v-if="showError"
      :errorMessage="errorMessage"
      :handleErrorClose="handleErrorClose"
      :errorStyles="errorStyles()"
    />

    <CustomModal
      v-if="showDeleteModal"
      :message="deleteModalMessage"
      :handleCancel="handleToggleDeleteModal"
      :handleConfirm="handleDeleteModal"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { validationUtils } from '@/utils/validation';

import quoteHelpers from '@/mixins/quoteHelpers';
import dashboardHelpers from '@/mixins/dashboardHelpers';

import moment from 'moment';

import BasicInfo from '@/components/quote-flow/BasicInfo';
import Requirements from '@/components/quote-flow/Requirements';
import SiteInfo from '@/components/quote-flow/SiteInfo';
import CustomPhotosUploader from '@/components/shared/CustomPhotosUploader';
import CustomFilesUploader from '@/components/shared/CustomFilesUploader';
import Summary from '@/components/quote-flow/Summary';
import CustomCongrats from '@/components/shared/CustomCongrats';
import CustomError from '@/components/shared/CustomError';
import CustomModal from '@/components/shared/CustomModal';

export default {

  mixins: [quoteHelpers, dashboardHelpers],

  components: {
    BasicInfo,
    Requirements,
    SiteInfo,
    CustomPhotosUploader,
    CustomFilesUploader,
    Summary,
    CustomCongrats,
    CustomError,
    CustomModal
  },

  props: [
    'companyName',
    'isMobile',
    'quoteCurrentStep',
    'additionalStyles',
    'isUpdateQuoteDetails', // for logic if user updates project-details (if false - customer creates new quote),
    'updatingQouteDetailsSession', // for detect uploading and deleting files when user updates qoute details
    'currentPage',
    'isProjectDetailsChanged',
    'isDiscardChanges',
    'contractorForQuote'
  ],

  emits: [
    'update:quoteCurrentStep',
    'update:currentPage',
    'update:isProjectDetailsChanged',
    'update:isDiscardChanges',
    'update:updatingQouteDetailsSession'
  ],

  data() {
    return {
      stepEdit: false,
      startFromZero: true,
      showError: false,
      windowWidth: window.innerWidth,
      errorMessage: 'Something went wrong. Please reload the page',
      projectId: null,
      showDeleteModal: false,
      fileNameToDelete: '',

      // for button text to toggle "Update"/"Updated!"
      isUpdating: false,

      // for disabling step buttons if there are no changes
      isBasicInfoChanged: false,
      isRequirementsChanged: false,
      isSiteChanged: false,
      isPhotosChanged: false,
      isFilesChanged: false,

      // first step values
      projectName: '',
      contactName: '',
      contactEmail: '',
      startDate: '',
      projectType: null,
      projectTypeOptions: [
        {
          value: false,
          text: 'New сonstruction',
        },
        {
          value: true,
          text: 'Existing сonstruction',
        },
      ],
      projectNameError: null,
      contactNameError: null,
      contactEmailError: null,
      startDateError: null,
      projectTypeError: null,

      // second step values
      timeRequirements: '',
      blackoutDays: '',
      specialRequirements: '',

      // third step values
      location: '',
      safetyRequirements: '',
      locationError: '',
      siteVisit: null,
      siteVisitOptions: [
        {
          value: true,
          text: 'Yes',
        },
        {
          value: false,
          text: 'No',
        },
      ],
      siteVisitError: null,
      accessibility: '',

      // fourth step values
      photos: [],
      photosPreview: [],
      photosIds: [],

      // fifth step values
      files: [],
      filesPreview: [],
      filesIds: [],
      fieldsChanged: []
    }
  },

  mounted() {
    if (!this.isMobile && this.$router.currentRoute._value.name !== "ProjectPage") {
      this.$emit('update:quoteCurrentStep', 0);
    } else if (this.$router.currentRoute._value.name == "ProjectPage") {
      this.setQouteValues();
      this.$nextTick(function() {
        if (!this.isMobile) {
          this.scrollToElement();
        }
      })
    }
    window.scrollTo(0,0);
    this.$emit('update:isDiscardChanges');
  },

  computed: {
    ...mapGetters([
      'getToken',
      'getCompanyProject',
      'getProjectInfo',
      'getUserCompany',
      'getCurrentUser'
    ]),

    stepSubTitle() {
      switch(this.quoteCurrentStep) {
        case 0:
          return 'Let’s add the basic info';
        case 1:
          return 'Add requirements';
        case 2:
          return 'Add site information';
        case 3:
          return 'Add photos';
        case 4:
          return 'Add files';
        case 5:
          return 'Check all information';
        default:
          return '';
      }
    },

    nextButtonTitle() {
      return this.isUpdateQuoteDetails
        ? this.isUpdating
          ? 'Updated!'
          : 'Update'
        : this.quoteCurrentStep === 5
         ? 'Submit'
         : 'Next';
    },

    isButtonDisabled() {
      switch(this.quoteCurrentStep) {
        case 0:
          if (this.isUpdateQuoteDetails) {
            return (!this.projectName
              || !this.contactName
              || !this.contactEmail
              || !this.startDate
              || !this.isBasicInfoChanged);
          } else {
            return (!this.projectName
              || !this.contactName
              || !this.contactEmail
              || !this.startDate
              || this.projectType === null);
          }
        case 1:
          if (this.isUpdateQuoteDetails) {
            return !this.isRequirementsChanged;
          } else {
            return false;
          }
        case 2:
          if (this.isUpdateQuoteDetails) {
            return !this.isSiteChanged;
          } else {
            return (!this.location
             || this.siteVisit === null);
          }
        case 3:
          if (this.isUpdateQuoteDetails) {
            return !this.isPhotosChanged;
          } else {
            return false;
          }
        case 4:
          if (this.isUpdateQuoteDetails) {
            return !this.isFilesChanged;
          } else {
            return false;
          }
        default:
          return false;
      }
    },

    isSubmitButtonDisabled() {
      return (!this.projectName
              || !this.contactName
              || !this.contactEmail
              || !this.startDate
              || this.projectType === null
              || !this.location
              || this.siteVisit === null);
    },

    getEmailErrorMessage() {
      return validationUtils.isEmailValid(this.contactEmail);
    },

    deleteModalMessage() {
      return `Are you sure you want to delete “${this.fileNameToDelete}”?`;
    }
  },

  methods: {
    ...mapActions([
      'companyProject',
      'updateCompanyProject',
      'projectInfo'
    ]),

    handleToggleDeleteModal(itemIndex = '', fileType = '') {
      this.itemIndex = itemIndex;
      this.fileType = fileType;
      this.showDeleteModal = !this.showDeleteModal;
      if (fileType) {
        if (fileType === 'photo') {
          this.fileNameToDelete = this.photosPreview[itemIndex].file_name
            ? this.photosPreview[itemIndex].file_name
            : this.photosIds[itemIndex].name;
        } else {
          this.fileNameToDelete = this.filesPreview[itemIndex].file_name
            ? this.filesPreview[itemIndex].file_name
            : this.filesPreview[itemIndex].name;
        }
      }
    },

    handleDeleteModal() {
      this.deleteFile(this.itemIndex, this.fileType);
      this.projectInfo(this.$route.params.id);
      this.showDeleteModal = !this.showDeleteModal;
    },

    formValidation() {
      let hasError;
      switch(this.quoteCurrentStep) {
        case 0: {
          const contactEmailErrorMessage = this.getEmailErrorMessage;

          if (contactEmailErrorMessage) {
            this.contactEmailError = contactEmailErrorMessage;
            hasError = true;
          }

          return !hasError;
        }
        default:
          return true;
      }
    },

    handleSubStep(subStepNumber) {
      if (this.quoteCurrentStep === subStepNumber) {
        this.$emit('update:quoteCurrentStep', '');
      } else {
        this.$emit('update:quoteCurrentStep', subStepNumber);
      }
    },

    handleNextStep() {
      const isValid = this.formValidation();
      if (!isValid) return;

      if (this.quoteCurrentStep === 5) {
        if (this.isUpdateQuoteDetails) {
          this.updateQuoteInfo();
        } else {
          this.submitForm('customer');
        }
      } else {
        this.$emit('update:quoteCurrentStep', this.quoteCurrentStep + 1);
      }

      if (this.stepEdit) {
        this.$emit('update:quoteCurrentStep', 5);
        this.stepEdit = false;
      }

      window.scrollTo(0,0);
    },

    setDaysData(days, type) {
      if (days) {
        const daysArray = days.split(',');
        let formattedDays = [];
        daysArray.forEach((item) => {
          const newItem = type === 'to_server' ? moment(item,'MM/DD/YYYY').format('DD-MM-YYYY') : moment(item,'DD-MM-YYYY').format('MM/DD/YYYY');
          formattedDays.push(newItem);
        });
        return formattedDays.join(', ');
      } else {
        return days;
      }
    },

    async updateQuoteInfo(step) {
      if (step === 0 || step === 1 || step === 2) {
        this.isUpdating = true;
        const params = this.fieldsChanged.reduce((acc, curr) => {
          const field = curr.fieldName === 'start_date' || curr.fieldName === 'blackout_days' ? this.setDaysData(this[curr.dataName], 'to_server') : this[curr.dataName];

          return {...acc, [curr.fieldName]: field}
        }, {})

        const data = {
          id: this.isUpdateQuoteDetails.id,
          params: {
            activity: {
              activity_type: 'edit_project',
            },
            project: params
          }
        }

        try {
          await this.updateCompanyProject(data);
          this.handleAfterUpdate();
        } catch (error) {
          if (error) {
            console.error('Request Quote', error);
            this.showError = true;
            this.errorMessage = 'Something went wrong. Please reload the page';
          }
        }
      } else {
        this.isUpdating = true;
        this.$emit('update:updatingQouteDetailsSession');
        this.handleAfterUpdate();
      }
    },

    handleAfterUpdate() {
      this.isUpdating = false;
      this.isSiteChanged = false;
      this.isRequirementsChanged = false;
      this.isBasicInfoChanged = false;
      this.isPhotosChanged = false;
      this.isFilesChanged = false;
      this.fieldsChanged = [];
      if (this.isProjectDetailsChanged) this.$emit('update:isProjectDetailsChanged', true);
    },

    previousStep() {
      if (this.stepEdit) {
        this.$emit('update:quoteCurrentStep', 5);
        this.stepEdit = false;
      } else {
        this.$emit('update:quoteCurrentStep', this.quoteCurrentStep - 1);
      }
    },

    handleUploadPhotosChange(value) {
      this.photos = value;
      if (value) {
        setTimeout(() => {
          this.uploadFiles(this.photos, "photo", "customer");
        }, 500);
      }
    },

    handleUploadFilesChange(value) {
      this.files = value;
      if (value) {
        setTimeout(() => {
          this.uploadFiles(this.files, "file", "customer");
        }, 500);
      }
    },

    handleEditStep(value) {
      this.$emit('update:quoteCurrentStep', Number(value));
      this.stepEdit = true;
    },

    handleCreateQuote() {
      this.$emit('update:quoteCurrentStep', 0);
      this.projectName = '';
      this.contactName = '';
      this.contactEmail = '';
      this.startDate = '';
      this.projectType = '';
      this.timeRequirements = '';
      this.blackoutDays = '';
      this.specialRequirements = '';
      this.location = '';
      this.safetyRequirements = '';
      this.siteVisit = '';
      this.accessibility = '';
      this.photos =  [];
      this.photosPreview = [];
      this.photosIds = [];
      this.files = [];
      this.filesPreview = [];
      this.filesIds = [];
      this.projectId = null;
    },

    handleToMainPage() {
      this.$router.go(0);
    },

    setQouteValues() {
      if (this.isUpdateQuoteDetails) {
        this.projectName = this.isUpdateQuoteDetails.project.name;
        this.contactName = this.isUpdateQuoteDetails.project.contact_name;
        this.contactEmail = this.isUpdateQuoteDetails.project.email;
        this.startDate = moment(this.isUpdateQuoteDetails.project.start_date,'YYYY-MM-DD').format('MM/DD/YYYY');
        this.projectType = this.isUpdateQuoteDetails.project.existing;
        this.timeRequirements = this.isUpdateQuoteDetails.project.time_requirements;
        this.blackoutDays = this.setDaysData(this.isUpdateQuoteDetails.project.blackout_days, 'to_page');
        this.specialRequirements = this.isUpdateQuoteDetails.project.special_requirements;
        this.location = this.isUpdateQuoteDetails.project.location;
        this.safetyRequirements = this.isUpdateQuoteDetails.project.safety_requiremets;
        this.siteVisit = this.isUpdateQuoteDetails.project.site_visit;
        this.accessibility = this.isUpdateQuoteDetails.project.accessibility;
        if (this.isUpdateQuoteDetails.file_storages.length) {
          this.setFilesDetails();
        }
      }
    },

    setFilesDetails() {
      const photoTypes = '.png, .jpg, .jpeg';
      this.isUpdateQuoteDetails.file_storages.filter(file => {
        if (photoTypes.includes(file.file_name.split('.').pop())) {
          this.photosPreview.push(file);
          this.photosIds.push(file.id);
        }
      });

      const fileTypes = '.pdf, .doc, .docx, .rtf, .txt, .odt, .xls, .xlsx, .xltx, .ods, .ots, .csv, .dwg, .dxf';
      this.isUpdateQuoteDetails.file_storages.filter(file => {
        if (fileTypes.includes(file.file_name.split('.').pop())) {
          this.filesPreview.push(file);
          this.filesIds.push(file.id);
        }
      });
    },

    scrollToElement() {
      var element = document.querySelector(".open");
      var headerOffset = -126;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset + headerOffset;

      window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
      });
    }
  },
}
</script>

<style lang="scss">
.dashboard-inner-page {
  .congrats {
    margin-bottom: 136px;
  }

  &.quote {
    padding-bottom: 0;
  }

  &--quote-wrapper {
    padding: 5px 25px;
    max-width: 325px;
    margin: 0 auto;

    &.update-quote {
      padding: 0;
    }

    &--title {
      margin-bottom: 38px;
    }

    &--step {
      margin: 0 auto 48px;
    }

    &--buttons-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 325px;
      margin: 0 auto 20px;

      &--text {
        font-weight: 400;
        color: #151522;
        text-align: center;
        margin-bottom: 20px;
      }

      &--button-top,
      &--button-bottom {
        padding: 13px 0;
        display: inline-block;
        width: 100%;
      }

      &--button-top {
        margin-bottom: 16px;
      }
    }

    &--next {
      padding: 13px 0;
      display: block;
      width: 100%;
      max-width: 325px;
      margin-bottom: 50px;

      .quote & {
        margin-bottom: 60px;
      }
    }

    &--copyright {
      color: #9999A0;
      text-align: center;
      width: 100%;
    }
  }
}

@media screen and (min-width: 1024px) {
  .dashboard-inner-page {
    &--submit-quote {
      max-width: 240px;
      padding: 13px 0;
      width: 100%;
      margin-left: auto;

      .quote & {
        margin-bottom: 60px;
      }
    }

    &--quote-wrapper {
      &--buttons-wrapper {
        &--button-top,
        &--button-bottom {
          max-width: 240px;
        }
      }
    }

    &--quote-desktop {
      margin-bottom: 60px;

      .quote-step-button {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &--check-icon {
        margin-left: 24px;

        .open & {
          display: none;
        }
      }

      &--congrats {
        .congrats {
          margin-bottom: 0;
        }
      }
    }

    &.quote {
      display: flex;
      flex-direction: column;
    }

    .basic-info,
    .requirements {
      margin-bottom: 18px;
    }

    .site-info {
      margin-bottom: 30px;
    }

    .no-photos .add-photos--gallery {
      min-height: 0;
    }

    .add-photos {
      &--gallery {
        justify-content: flex-start;
        margin-bottom: 14px;

        &--item {
          margin-right: 19px;
        }
      }

      &--button {
        margin-bottom: 16px;
      }
    }

    .no-files .add-files--list {
      margin-bottom: 10px;
    }

    .add-files {
      &--button {
        margin-bottom: 20px;
      }

      &--list {
        min-height: 0;
        margin-bottom: 20px;

        &--item {
          &:first-child {
            border-top: none;
            padding-top: 0;
          }
        }
      }
    }
  }
}
</style>
