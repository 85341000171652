<template>
  <div
    class="users"
    :class="[{'menu-open': showMenu},
            {'users-mobile': isMobile}]"
    @click="handleHideMenu"
  >
    <div
      class="users--header"
      :class="[{'inner-page': showUserInfo || showInvitetUser || !canEdit}]"
    >
      <div
        v-if="!showUserInfo && !showInvitetUser"
        class="users--header--logo"
        :style="{'background-image': `url('${setLogoImage()}')`}"
        @click="handleToggleMenu"
      ></div>
      <h2 class="users--header--title h4-sb">{{ setTitle }}</h2>
      <inline-svg
        v-if="getCurrentUser && (getCurrentUser?.role === 'admin' || getCurrentUser?.role === 'owner')
              || showUserInfo"
        :src="require(`@/assets/images/icons/${(currentPage === 'users' || currentPage === 'roles')
          && !showInvitetUser && !showUserInfo
          ? 'plus'
          : 'arrow-left'
          }.svg`)"
        class="dashboard--header--button"
        @click="handleNavigation"
      />
    </div>

    <DesktopMenu
      :currentPage="currentPage"
      :handleNavigation="handleNavigation"
      :setTitle="setTitle"
      :handleToggleMenu="handleToggleMenu"
      :currentTab="currentTab"
      :handleTabChange="handleTabChange"
      :tabsItems="tabsItems"
      :handleRequestModal="handleToggleInviteUser"
      :showMenu="showMenu"
      :handleOpenInnerPage="handleOpenInnerPage"
    />

    <Menu
      v-if="isMobile"
      :showMenu="showMenu"
      :currentPage="currentPage"
      :handleNavigation="handleNavigation"
      :setTitle="setTitle"
      :handleToggleMenu="handleToggleMenu"
      :currentTab="currentTab"
      :handleTabChange="handleTabChange"
      :tabsItems="tabsItems"
      :handleRequestModal="handleToggleInviteUser"
      :setLogoImage="setLogoImage"
      :handleLogoutModal="handleLogoutModal"
      :handleOpenInnerPage="handleOpenInnerPage"
      :userRole="getUserRole"
    />

    <CustomModal
      v-if="showLogoutModal"
      :message="getLogoutMessage"
      :handleCancel="handleLogoutModal"
      :handleConfirm="logout"
      notice="Are you sure you want to log out?"
    />

    <div
      v-if="isMobile"
      class="users--body"
    >
      <InviteUser
        v-if="showInvitetUser"
        :handleToggleInviteUser="handleToggleInviteUser"
      />

      <UserInfo
        v-if="showUserInfo && userToEdit"
        :roleList="roleOptions"
        :userToEdit="userToEdit"
      />

      <div
        v-if="(currentPage === 'users' || currentPage === 'roles')
              && !showInvitetUser && !showUserInfo"
      >
        <Tabs
          :modelValue="currentTab"
          marginBottom="40"
          @update:modelValue="handleTabChange"
          :tabsItems="tabsItems"
        />
        <div
          v-if="currentPage === 'users'"
          class="users--body--mobile-table"
        >
          <div class="users--body--mobile-table--header">
            <p class="users--body--mobile-table--header--item h6-regular">Full name</p>
            <p class="users--body--mobile-table--header--item h6-regular">Role</p>
          </div>
          <div
            v-for="(user, index) in getAllUsers"
            :key="index"
            class="users--body--mobile-table--row"
            :class="index === 0 && 'current-user'"
          >
            <p class="users--body--mobile-table--row--item label">
              {{ user.first_name }} {{ user.last_name }}
            </p>
            <EditUserRoleList
              v-if="index !== 0 || (getCurrentUser
                    && (getCurrentUser?.role !== 'admin' || getCurrentUser?.role !== 'owner'))"
              :user="user"
              :roleList="roleOptions"
              :userToEdit="userToEdit"
              @update:userToEdit="handleUserRoleList"
              padding="20"
            />

            <p
              v-else
              class="users--body--mobile-table--row--item label"
            >
              {{ user?.role }}
            </p>
            <p
              class="users--body--mobile-table--row--info btn-font-large"
              @click="handleOpenUserInfo(user)"
            >View info</p>
          </div>
        </div>

        <RolesInfo
          v-else
          :isMobile="isMobile"
        />
      </div>
    </div>

    <div
      v-else
      class="users--body desktop"
    >
      <InviteUser
        v-if="showInvitetUser"
        :handleToggleInviteUser="handleToggleInviteUser"
      />

      <div
        v-if="currentPage === 'users' || currentPage === 'roles'"
      >
        <div
          v-if="currentPage === 'users'"
          class="users--body--desktop-table"
        >
          <div class="users--body--desktop-table--header">
            <p class="users--body--desktop-table--header--item h6-regular">Full name</p>
            <p class="users--body--desktop-table--header--item h6-regular">Email</p>
            <p class="users--body--desktop-table--header--item h6-regular">Phone number</p>
            <p class="users--body--desktop-table--header--item h6-regular">Job title</p>
            <p class="users--body--desktop-table--header--item h6-regular">Role</p>
          </div>
          <div
            v-for="(user, index) in getAllUsers"
            :key="index"
            class="users--body--desktop-table--row"
            :class="index === 0 && 'current-user'"
          >
            <p class="users--body--desktop-table--row--item label">
              {{ user.first_name }} {{ user.last_name }}
            </p>
            <p class="users--body--desktop-table--row--item label">
              {{ user.email }}
            </p>
            <p class="users--body--desktop-table--row--item label">
              {{ setPhoneNumber(user.phone) }}
            </p>
            <p class="users--body--desktop-table--row--item label">
              {{ user.job_title }}
            </p>
            <div
              v-if="index !== 0 || (getCurrentUser && (getCurrentUser?.role !== 'admin' || getCurrentUser?.role !== 'owner'))"
              class="users--body--desktop-table--row--item"
            >
              <EditUserRoleList
                :user="user"
                :roleList="roleOptions"
                :userToEdit="userToEdit"
                @update:userToEdit="handleUserRoleList"
                padding="20"
              />
            </div>
            <p
              v-else
              class="users--body--desktop-table--row--item role label"
            >
              {{ user.role }}
            </p>
          </div>
        </div>

        <RolesInfo
          v-else
          :isMobile="isMobile"
        />
      </div>
    </div>

    <p
      v-if="isMobile"
      class="users--copyright caption-3"
    >Powered by FCA</p>

    <Footer
      :currentPage="currentPage"
      :handleFooterLinks="handleFooterLinks"
    />

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import commonHelpers from '@/mixins/commonHelpers';
import roleOptions from '@/components/dashboard/users/RoleOptions.js';

import InlineSvg from 'vue-inline-svg';
import Tabs from '@/components/dashboard/Tabs';
import DesktopMenu from '@/components/dashboard/DesktopMenu';
import Menu from '@/components/dashboard/Menu';
import Footer from '@/components/dashboard/Footer';
import CustomModal from '@/components/shared/CustomModal';
import EditUserRoleList from '@/components/dashboard/users/EditUserRoleList';
import UserInfo from '../components/dashboard/users/UserInfo.vue';
import RolesInfo from '../components/dashboard/users/RolesInfo.vue';
import InviteUser from '../components/dashboard/users/InviteUser.vue';

export default {
  name: 'Users',

  mixins: [commonHelpers],

  components: {
    InlineSvg,
    Tabs,
    DesktopMenu,
    Menu,
    Footer,
    CustomModal,
    EditUserRoleList,
    UserInfo,
    RolesInfo,
    InviteUser
  },

  data: () => ({
    currentPage: 'users',
    showMenu: false,
    showLogoutModal: false,
    currentTab: 'users',
    windowWidth: window.innerWidth,
    isMobile: (window.innerWidth < 1024),
    showInvitetUser: false,
    showUserInfo: false,
    tabsItems: {
      users: {
        className: 'users',
        tabName: 'Users'
      },
      roles: {
        className: 'roles',
        tabName: 'Roles'
      }
    },
    roleOptions: roleOptions,
    userToEdit: null
  }),

  beforeMount(){
    this.allUsersInfo();
  },

  mounted() {
    window.addEventListener('resize', this.getWindowWidth);
    if (this.$route.query.page) {
      this.handleOpenInnerPage(this.$route.query.page);
      this.$router.replace({'query': null});
    }
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.getWindowWidth);
  },

  computed: {
    ...mapGetters([
      'getUserCompany',
      'getCurrentUser',
      'getAllUsers',
      'getContractorCompanyMainInfo'
    ]),

    getUserRole() {
      if (this.getCurrentUser && this.getCurrentUser?.type === 'Customer') {
        return 'customer';
      } else {
        return 'contractor';
      }
    },

    customerOptions() {
      const data = this.getCompanyProjects.map(item => {
        return {
          label: item.project.contact_name,
          value: item.project.contact_name
        }
      });
      return data;
    },

    setTitle() {
      if ((this.currentPage === 'users' || this.currentPage === 'roles')
        && !this.showInvitetUser && !this.showUserInfo) {
        return 'Users';
      } else if (this.showInvitetUser) {
        return 'Invite user';
      } else {
        return `${this.userToEdit.first_name} ${this.userToEdit.last_name}`;
      }
    },

    getLogoutMessage() {
      if (this.getCurrentUser) {
        return `${this.getCurrentUser?.first_name} ${this.getCurrentUser?.last_name}`;
      } else {
        return '';
      }
    },

    canEdit() {
      return this.getCurrentUser &&
        (this.getCurrentUser?.role === 'admin' || this.getCurrentUser?.role === 'owner');
    },
  },

  watch: {
    getAllUsers() {
      if (this.userToEdit) {
        this.userToEdit = this.getAllUsers.find(user => user.id === this.userToEdit.id);
      }
    }
  },

  methods: {
    ...mapActions([
      'logout',
      'allUsersInfo'
    ]),

    handleLogoutModal() {
      this.showLogoutModal = !this.showLogoutModal;
    },

    handleToggleInviteUser() {
      this.showInvitetUser = !this.showInvitetUser;
    },

    handleUserRoleList(user) {
      if (this.userToEdit && this.userToEdit.id === user.id) {
        this.userToEdit = null;
      } else if (this.canEdit) {
        this.userToEdit = user;
      }
    },

    setPhoneNumber(phone) {
      return `(${phone.substr(0,3)}) ${phone.substr(3,3)} ${phone.substr(6,4)}`;
    },

    setLogoImage() {
      if (this.getUserCompany && this.getUserCompany?.file_storages) {
        const imageLogo = this.getUserCompany?.file_storages.find(o => o.file_type.includes("image"));
        if (imageLogo) {
          const imageLogoUrl = imageLogo.file_url;

          return imageLogoUrl;
        } else {
          return require('@/assets/images/icons/logo-placeholder.svg');
        }
      } else {
        return require('@/assets/images/icons/logo-placeholder.svg');
      }
    },

    handleNavigation() {
      if (this.showInvitetUser) {
        this.showInvitetUser = false;
        return;
      } else {
        if (!this.showUserInfo) {
          this.showInvitetUser = true;
        }
      }

      if (this.showUserInfo) {
        this.showUserInfo = false;
        this.userToEdit = null;
        return;
      } else {
        if (!this.showInvitetUser) {
          this.showUserInfo = true;
        }
      }
    },

    handleHideMenu(e) {
      if (e.target === document.querySelector('.users')) {
        this.showMenu = false;
      }
    },

    handleToggleMenu() {
      this.showMenu = !this.showMenu;
    },

    handleOpenInnerPage(page) {
      this.$router.push({ name: 'Dashboard', query: { page } });
    },

    handleFooterLinks(page) {
      if (page === 'users') {
        this.currentPage = 'users';
      } else {
        this.$router.push({ name: 'Dashboard', query: { page } });
      }
    },

    handleTabChange(value) {
      this.currentPage = value;
      this.currentTab = value;
    },

    handleOpenUserInfo(user) {
      this.showUserInfo = !this.showUserInfo;
      this.userToEdit = user;
    }
  }
}
</script>

<style lang="scss" scoped>
.users {
  margin: 0 auto;
  position: relative;
  padding-bottom: 100px;
  box-sizing: border-box;
  min-height: 100%;

  &.users-mobile {
    padding-bottom: 115px;
  }

  .error {
    position: fixed;
  }

  &.menu-open {
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      background: rgba(21,21,34,0.5);
    }
  }

  &--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 25px 8px;
    border-bottom: 1px solid #CCCCD7;
    margin-bottom: 20px;
    background: #F8FBFE;
    transition: .2s;
    height: 60px;
    position: fixed;
    top: 0;
    left: 0px;
    box-sizing: border-box;
    z-index: 1;
    width: 100%;

    &.inner-page {
      justify-content: center;
    }

    &--logo {
      width: 28px;
      height: 28px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 50%;
      background-color: #fff;

      .inner-page & {
        position: absolute;
        left: 27px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &--title {
      color: #151522;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 30px;
    }

    &--button {
      width: 16px;
      height: 16px;

      .inner-page & {
        position: absolute;
        left: 27px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &--body {
    padding: 80px 0 100px;
    max-width: 500px;
    margin: 0 auto;

    &--mobile-table {
      &--header {
        border-top: 1px solid rgba(92, 92, 102, 0.4);
        border-bottom: 1px solid rgba(92, 92, 102, 0.4);
        display: flex;

        &--item {
          color: #151522;
          padding: 22px 20px 19px;
          box-sizing: border-box;
          min-width: 120px;

          &:first-child {
            box-shadow: 120px 0px 80px rgba(21, 21, 34, 0.04);
          }
        }
      }

      &--row {
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(92, 92, 102, 0.4);

        &.current-user {
          background: #F8FBFE;
        }

        &--item {
          color: #151522;
          word-break: break-word;
          text-transform: capitalize;
          padding: 20px;
          box-sizing: border-box;
          max-width: 120px;
          width: 100%;
          box-shadow: 120px 0px 80px rgba(21, 21, 34, 0.04);
        }

        &--info {
          color: #4B617A;
          margin-left: auto;
          padding: 20px;
        }
      }
    }
  }

  &--copyright {
    color: #9999A0;
    text-align: center;
    width: 100%;
    position: absolute;
    left: 50%;
    bottom: 100px;
    transform: translateX(-50%);
  }
}

@media screen and (min-width: 1024px) {
  .users {
    max-width: 100%;
    margin-left: 82px;

    &.menu-open {
      &:before {
        content: "";
        width: calc(100% + 82px);
        height: 100vh;
        position: absolute;
        top: 0;
        left: -82px;
        z-index: 3;
        background: rgba(21,21,34,0);
      }
    }

    &--header {
      display: none;
    }

    &--body {
      max-width: 100%;
      margin: 0 60px;

      &.desktop {
        padding: 233px 0 0;
      }

      &--desktop-table {
        box-sizing: border-box;
        border-radius: 8px;
        border: 1px solid rgba(92, 92, 102, 0.4);

        &--header {
          display: flex;
          align-items: center;

          &--item {
            padding: 22px 0px 20px 20px;
            box-sizing: border-box;
            max-width: 300px;
            width: 100%;
            margin-right: 1vw;

            &:last-child {
              margin-left: auto;
              margin-right: 8px;
              max-width: 124px;
            }
          }
        }

        &--row {
          display: flex;
          align-items: center;
          border-top: 1px solid rgba(92, 92, 102, 0.4);

          &.current-user {
            background: #F8FBFE;
          }

          &--item {
            padding: 22px 0px 20px 20px;
            box-sizing: border-box;
            max-width: 300px;
            width: 100%;
            margin-right: 1vw;
            word-break: break-word;

            &:last-child {
              margin-left: auto;
              margin-right: 8px;
              padding: 0;
              max-width: 124px;
            }

            &.role {
              text-transform: capitalize;
              padding: 22px 20px 20px;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1600px) {
  .users--body--desktop-table {
    &--header--item,
    &--row--item {
      margin-right: 3vw;
    }
  }
}
</style>
