<template>
  <div
    class="tabs"
    :style="{ marginBottom: marginBottom + 'px' }"
  >
    <div
      v-for="tab in tabsItems"
      :key="tab.className"
      class="tabs--item"
      :class="{'active': modelValue === tab.className}"
      :data-tab-name="tab.className"
      @click="handleTabChange"
    >
      <p class="tabs--item--title">{{ tab.tabName }}</p>
    </div>
  </div>
</template>

<script>
export default {

  props: {
    modelValue: {
      type: [String, Number],
      default: ''
    },
    marginBottom: {
      type: [String, Number],
      default: 9
    },
    tabsItems: {
      type: Object,
      default: () => {}
    }
  },

  methods: {
    handleTabChange(e) {
      this.$emit('update:modelValue', e.target.closest('.tabs--item').dataset.tabName)
    },
  },

}
</script>

<style lang="scss">
.tabs {
  display: flex;
  max-width: 325px;
  margin: 0 auto;

  &--item {
    opacity: 0.5;
    width: 50%;
    padding: 9px 0 14px;
    transition: .4s;
    cursor: pointer;
    position: relative;
    margin-right: 1px;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 5px;
      background: #4B617A;
    }

    &:last-child {
      margin-right: 0;
    }

    &.active {
      opacity: 1;
    }

    &--title {
      color: #4B617A;
      text-align: center;
      font-size: 14px;
      font-family: $SFProText;
      line-height: 150%;
      font-weight: 400;
    }
  }
}

@media screen and (min-width: 1024px) {
  .tabs {
    margin: 0;
    max-width: 100%;

    &--item {
      margin-right: 50px;
      width: auto;
      padding: 0 0 11px 0;

      &:first-child {
        margin-right: 50px;
      }

      &:after {
        background: transparent;
      }

      &.active {
        opacity: 1;

        &:after {
          background: #4B617A;
          border-radius: 100px;
        }
      }

      &--title {
        font-size: 18px;
      }
    }
  }
}
</style>
