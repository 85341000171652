<template>
  <div class="create-account">

    <custom-input
      :modelValue="email"
      @update:modelValue="handleEmailChange"
      label="Email"
      id="email"
      type="email"
      name="email"
      :errorMessage="emailError"
      :disabled="emailDisabled"
    />

    <custom-input
      :modelValue="password"
      @update:modelValue="handlePasswordChange"
      label="Password"
      id="password"
      type="password"
      name="password"
      :errorMessage="passwordError"
      icon="secured-with-show-option"
    />

    <custom-input
      :modelValue="confirmPassword"
      @update:modelValue="handleConfirmPasswordChange"
      label="Confirm password"
      id="confirm-password"
      type="password"
      name="password"
      confirmPassword
      :errorMessage="confirmPasswordError"
      icon="secured-with-show-option"
    />

    <WorkTypeMultySelect
      v-if="userType === 'Contractor'"
      :modelValue="selectedWorkTypes"
      @update:modelValue="handleSelectWorkTypes"
      id="trade-type"
      name="trade-type"
      label="Trade types"
      placeholder="Choose trade types"
      :errorMessage="null"
      marginBottom='0'
    />

  </div>
</template>

<script>
import CustomInput from '@/components/shared/CustomInput';
import WorkTypeMultySelect from '@/components/sign-up/WorkTypeMultySelect';

export default {

  components: {
    CustomInput,
    WorkTypeMultySelect
  },

  props: [
    'email',
    'password',
    'confirmPassword',
    'handleEmailChange',
    'handlePasswordChange',
    'handleConfirmPasswordChange',
    'emailError',
    'passwordError',
    'confirmPasswordError',
    'emailDisabled',
    'selectedWorkTypes',
    'handleSelectWorkTypes',
    'userType'
  ],

}
</script>

<style lang="scss">
@media screen and (min-width: 1024px) {
  .create-account {
    margin-bottom: 42px;
  }
}
</style>
