<template>
  <div class="dashboard-inner-page">
    <div v-if="isMobile && currentPageSubPage === 'my-account'">
      <div
        class="dashboard-inner-page--sub-step"
        @click="this.$emit('update:subStep', 'personal-information')"
      >
        <div class="dashboard-inner-page--sub-step--text">
          <p class="dashboard-inner-page--sub-step--text--title text-medium">Personal information</p>
          <p class="dashboard-inner-page--sub-step--text--description h6-light">Review the data that you’ve provided, and make changes if you’d like</p>
        </div>
        <inline-svg
          :src="require('@/assets/images/icons/arrow-left.svg')"
          class="dashboard-inner-page--sub-step--icon"
        />
      </div>
      <div
        class="dashboard-inner-page--sub-step"
        @click="this.$emit('update:subStep', 'security')"
      >
        <div class="dashboard-inner-page--sub-step--text">
          <p class="dashboard-inner-page--sub-step--text--title text-medium">Security</p>
          <p class="dashboard-inner-page--sub-step--text--description h6-light">Options and controls for signing in and keeping your account safe</p>
        </div>
        <inline-svg
          :src="require('@/assets/images/icons/arrow-left.svg')"
          class="dashboard-inner-page--sub-step--icon"
        />
      </div>
    </div>

    <div
      v-if="!isMobile || currentPageSubPage === 'personal-information'"
      class="dashboard-inner-page--sub-step-body"
      :class="{'open': showSubStep === 'personal-information'}"
    >
      <div
        v-if="!isMobile"
        class="dashboard-inner-page--sub-step-body--desktop-header"
        @click="this.$emit('update:subStep', 'personal-information')"
      >
        <div class="dashboard-inner-page--sub-step-body--desktop-header--text">
          <p class="dashboard-inner-page--sub-step-body--desktop-header--text--title text-medium">Personal information</p>
          <p class="dashboard-inner-page--sub-step-body--desktop-header--text--description h6-light">Review the data that you’ve provided, and make changes if you’d like</p>
        </div>
        <button class="dashboard-inner-page--sub-step-body--desktop-header--button btn-font-large">
          {{showSubStep === 'personal-information' ? 'Close' : 'Edit'}}
        </button>
      </div>

      <div class="dashboard-inner-page--sub-step-body--desktop-body">
        <CustomInput
          :modelValue="currentUser.email"
          @update:modelValue="handleEmailChange"
          label="Email"
          id="email"
          type="email"
          name="email"
          :errorMessage="emailError"
        />
        <CustomInput
          :modelValue="currentUser.first_name"
          @update:modelValue="handleFirstNameChange"
          label="First name"
          id="first-name"
          type="text"
          name="first-name"
          :errorMessage="firstNameError"
        />
        <CustomInput
          :modelValue="currentUser.last_name"
          @update:modelValue="handleLastNameChange"
          label="Last name"
          id="last-name"
          type="text"
          name="last-name"
          :errorMessage="lastNameError"
        />
        <CustomInput
          :modelValue="currentUser.job_title"
          @update:modelValue="handleJobTitleChange"
          label="Job title"
          id="job-title"
          type="text"
          name="job-title"
          :errorMessage="jobTitleError"
        />
        <custom-input
          :modelValue="currentUser.phone ? currentUser.phone : ''"
          @update:modelValue="handlePhoneNumberChange"
          label="Phone number"
          id="user-phone-number"
          type="tel"
          name="user-phone-number"
          :marginBottom="isMobile ? '60' : '30'"
          :errorMessage="phoneNumberError"
        />

        <button
          class="dashboard-inner-page--sub-step-body--button normal-btn"
          :class="[isUpdateButtonDisabled && 'disabled',
                  isUpdating && 'frozen']"
          @click="handleUpdateContractorInfo"
        >{{ isUpdating ? 'Updated!' : 'Update' }}</button>
      </div>
    </div>

    <div
      v-if="!isMobile || currentPageSubPage === 'security'"
      class="dashboard-inner-page--sub-step-body"
      :class="{'open': showSubStep === 'security'}"
    >
      <div
        v-if="!isMobile"
        class="dashboard-inner-page--sub-step-body--desktop-header"
        @click="this.$emit('update:subStep', 'security')"
      >
        <div class="dashboard-inner-page--sub-step-body--desktop-header--text">
          <p class="dashboard-inner-page--sub-step-body--desktop-header--text--title text-medium">Security</p>
          <p class="dashboard-inner-page--sub-step-body--desktop-header--text--description h6-light">Options and controls for signing in and keeping your account safe</p>
        </div>
        <button class="dashboard-inner-page--sub-step-body--desktop-header--button btn-font-large">
          {{showSubStep === 'security' ? 'Close' : 'Edit'}}
        </button>
      </div>

      <div class="dashboard-inner-page--sub-step-body--desktop-body">
        <custom-input
          :modelValue="oldPassword"
          @update:modelValue="handleOldPasswordChange"
          label="Current password"
          placeholder="Enter current password"
          id="password"
          type="password"
          name="password"
          marginBottom="14"
          :errorMessage="oldPasswordError"
          icon="secured-with-show-option"
        />
        <div class="dashboard-inner-page--sub-step-body--help">
          <router-link
            to="/forgot-password"
            class="dashboard-inner-page--sub-step-body--help--link text-btn"
          >
            Forgot password?
          </router-link>
        </div>
        <custom-input
          :modelValue="password"
          @update:modelValue="handlePasswordChange"
          label="New password"
          placeholder="8+ characters"
          id="new-password"
          type="password"
          name="new-password"
          marginBottom="18"
          :errorMessage="passwordError"
          icon="secured-with-show-option"
        />
        <custom-input
          :modelValue="passwordConfirmation"
          @update:modelValue="handlePasswordConfirmationChange"
          label="Confirm password"
          placeholder="Enter new password again"
          id="confirm-new-password"
          type="password"
          name="confirm-new-password"
          :marginBottom="isMobile ? '60' : '30'"
          :errorMessage="passwordConfirmationError"
          confirmPassword
          icon="secured-with-show-option"
        />
        <button
          class="dashboard-inner-page--sub-step-body--button normal-btn"
          :class="[isUpdateButtonDisabled && 'disabled',
                  isUpdating && 'frozen']"
          @click="handleUpdateContractorInfo"
        >{{ isUpdating ? 'Updated!' : 'Update' }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { validationUtils } from '@/utils/validation';

import InlineSvg from 'vue-inline-svg';
import CustomInput from '@/components/shared/CustomInput';

export default {
  components: {
    InlineSvg,
    CustomInput,
  },

  props: [
    'currentUser',
    'currentPageSubPage',
    'subStep',
    'showSubStep',
    'email',
    'firstName',
    'lastName',
    'jobTitle',
    'phoneNumber',
    'handleEmailChange',
    'handleFirstNameChange',
    'handleLastNameChange',
    'handleJobTitleChange',
    'handlePhoneNumberChange',
    'emailError',
    'firstNameError',
    'lastNameError',
    'jobTitleError',
    'phoneNumberError',
    'handleUpdateContractorInfo',
    'isMobile',
    'oldPassword',
    'password',
    'passwordConfirmation',
    'handleOldPasswordChange',
    'handlePasswordChange',
    'handlePasswordConfirmationChange',
    'oldPasswordError',
    'passwordError',
    'passwordConfirmationError',
    'isUpdating',
  ],

  computed: {
    isUpdateButtonDisabled() {
      if (this.currentPageSubPage === 'personal-information') {
        return (!this.email
          && !this.firstName
          && !this.lastName
          && !this.jobTitle
          && this.currentUser.phone === validationUtils.getFormattedPhoneNumber(this.phoneNumber));
      } else if (this.currentPageSubPage === 'security') {
        return (!this.oldPassword
          || !this.password
          || !this.passwordConfirmation);
      }
      return true;
    },
  },

  mounted() {
    this.$emit('update:currentPageSubPage', 'my-account');
  },
}
</script>