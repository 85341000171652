<template>
  <div
    :id="id"
    :class="['custom-choosing-button',
      {'active': this.modelValue === this.title}
    ]"
    @click="toggleQuote"
    :value="title"
  >
    <div class="custom-choosing-button--wrapper">
      <inline-svg
        :src="require(`@/assets/images/icons/${icon}`)"
        class="custom-choosing-button--wrapper--icon"
      />
      <p class="custom-choosing-button--wrapper--title">
        {{ title }}
      </p>
    </div>
    <p
      v-if="description.length"
      class="custom-choosing-button--description"
    >
      {{ description }}
    </p>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'

export default {

  components: {
    InlineSvg
  },

  props: {
    modelValue: {
      type: [String, Number],
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: 'cloud.svg'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    },
  },

  methods: {
    toggleQuote(e) {
      const clickedButton = e.target.closest('.custom-choosing-button')
      this.$emit('update:modelValue', clickedButton.getAttribute('value'));
      const allButtons = document.querySelectorAll('.custom-choosing-button');

      allButtons.forEach(button => {
        if (button.classList.contains('active')) {
          button.classList.remove('active')
        }    
      })

      clickedButton.classList.add('active')
    }
  },

}
</script>

<style lang="scss">
.custom-choosing-button {
  width: 100%;
  max-width: 325px;
  background: #fff;
  box-shadow: 0px 4px 8px rgba(21, 21, 34, 0.06);
  border-radius: 10px;
  padding: 20px 45px 20px 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
  transition: .4s;
  position: relative;
  cursor: pointer;
  border: 1px solid #fff;

  &.disabled {
    background: #B3B3BE;
    border: 1px solid #B3B3BE;
  }

  &.active {
    border: 1px solid #10886D;

    &:after {
      content: '';
      background-image: url(~@/assets/images/icons/check.svg);
      position: absolute;
      top: 36px;
      right: 20px;
      width: 28px;
      height: 28px;
    }
  }

  &:hover {
    box-shadow: 0px 10px 32px rgba(61, 92, 109, 0.08);
  }

  &--wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    &--icon {
      width: 60px;
      height: 60px;
      margin-right: 18px;

      .disabled & {
        opacity: 0.5;
      }
    }

    &--title {
      color: #151522;
      font-weight: 500;
      font-size: 20px;
      line-height: 150%;
      font-family: $SFProDisplay;

      .disabled & {
        color: #666670;
      }
    } 
  }

  &--description {
    font-family: $SFProText;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 150%;
    color: #4B617A;
  }
}

@media screen and (min-width: 1024px) {
  .custom-choosing-button {
    max-width: 397px;
  }

  .custom-choosing-button:last-child {
    margin-bottom: 0;
  }
}
</style>
