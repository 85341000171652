import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import '@/assets/css/main.scss';

const app = createApp(App);

if (process.env.NODE_ENV === 'production') {
  const oldErrorLog = console.error;
  const oldLog = console.log;
  console.error = (...data) => {
    oldErrorLog(...data);
    axios.post(
      'https://us-central1-fca-app-301416.cloudfunctions.net/log',
      {
        data: data.map(d => {
          if (d instanceof Error) {
            return { string: d.toString(), message: d.message, stack: d.stack }
          }
          return d;
        })
      },
      { headers: { 'Content-Type': 'application/json' } }
    )
      .catch(error => oldErrorLog('Error Sending Logs', { error }));
  };
  console.log = (...data) => {
    oldLog(...data);
    axios.post(
      'https://us-central1-fca-app-301416.cloudfunctions.net/log',
      {
        data: data.map(d => {
          if (d instanceof Error) {
            return { string: d.toString(), message: d.message, stack: d.stack }
          }
          return d;
        })
      },
      { headers: { 'Content-Type': 'application/json' } }
    )
      .catch(error => oldLog('Log Sending Logs', { error }));
  };
  window.onError = function (message, source, lineno, colno, error) {
    axios.post('https://us-central1-fca-app-301416.cloudfunctions.net/log', {
      message,
      source,
      lineno,
      colno,
      error
    }, { headers: { 'Content-Type': 'application/json' } })
      .catch(error => oldErrorLog('OnError Sending Logs', {
        message,
        source,
        lineno,
        colno,
        error
      }));
  };
  console.log((new Error().stack));
}

app.config.globalProperties.axios = axios;
app.use(router);
app.use(store);
app.mount('#app');
