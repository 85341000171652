<template>
  <div class="collapse-project-detail">
    <div @click="toggleDetails" class="collapse-project-detail--header">
      <h4 class="collapse-project-detail--header--title h4-sb">Project details</h4>
      <inline-svg
        :src="require('@/assets/images/icons/arrow-down.svg')"
        class="collapse-project-detail--header--icon"
        :style="showDetails && 'transform: rotate(180deg);'"
      />
    </div>

    <div
      v-if="showDetails"
      class="collapse-project-detail--body"
    >
      <div
        v-if="basicInfoDetails.details"
        class="collapse-project-detail--body--block"
      >
        <div class="collapse-project-detail--body--block--header">
          <h5 class="collapse-project-detail--body--block--header--title h5-sb">{{basicInfoDetails.title}}</h5>
          <div
            v-if="getCurrentUser && getCurrentUser?.role !== 'viewer'"
            @click="handleSubStep('update-project-details'), this.$emit('update:quoteCurrentStep', 0)"
            class="collapse-project-detail--body--block--header--edit"
          >
            <p class="collapse-project-detail--body--block--header--edit--text btn-font-large">Edit</p>
            <inline-svg
              :src="require('@/assets/images/icons/edit-2-icon.svg')"
              class="collapse-project-detail--body--block--header--edit--icon edit"
            />
          </div>
        </div>

        <div
          v-for="(detail, i) in basicInfoDetails.details"
          :key="i"
          class="collapse-project-detail--body--details-item">
          <p class="collapse-project-detail--body--details-item--title label text-medium">{{detail.title}}</p>
          <p class="collapse-project-detail--body--details-item--description h6-light">{{detail.text}}</p>
        </div>
      </div>

      <div class="collapse-project-detail--body--block">
        <div class="collapse-project-detail--body--block--header">
          <h5 class="collapse-project-detail--body--block--header--title h5-sb">{{requirementsDetails.title}}</h5>
          <div
            v-if="getCurrentUser && getCurrentUser?.role !== 'viewer'"
            @click="handleSubStep('update-project-details'), this.$emit('update:quoteCurrentStep', 1)"
            class="collapse-project-detail--body--block--header--edit"
          >
            <p class="collapse-project-detail--body--block--header--edit--text btn-font-large">Edit</p>
            <inline-svg
              :src="require('@/assets/images/icons/edit-2-icon.svg')"
              class="collapse-project-detail--body--block--header--edit--icon edit"
            />
          </div>
        </div>

        <div
          v-if="getProjectInfo.project && getProjectInfo.project.time_requirements
          || getProjectInfo.project && getProjectInfo.project.blackout_days
          || getProjectInfo.project && getProjectInfo.project.special_requirements"
        >
          <div
            v-for="(detail, i) in requirementsDetails.details"
            :key="i"
            class="collapse-project-detail--body--details-item">
            <p class="collapse-project-detail--body--details-item--title label text-medium">{{detail.title}}</p>
            <p class="collapse-project-detail--body--details-item--description h6-light">{{detail.text}}</p>
          </div>
        </div>

        <div
          v-else
          class="collapse-project-detail--body--block--empty"
        >
          <button
            v-if="getCurrentUser && getCurrentUser?.role !== 'viewer'"
            class="collapse-project-detail--body--block--empty--btn text-btn"
            @click="handleSubStep('update-project-details'), this.$emit('update:quoteCurrentStep', 1)"
          >
            <inline-svg
              :src="require('@/assets/images/icons/plus.svg')"
              class="collapse-project-detail--body--block--empty--btn--icon"
            />
            Add requirements
          </button>
        </div>
      </div>

      <div
        v-if="siteDetails"
        class="collapse-project-detail--body--block"
      >
        <div class="collapse-project-detail--body--block--header">
          <h5 class="collapse-project-detail--body--block--header--title h5-sb">{{siteDetails.title}}</h5>
          <div
            v-if="getCurrentUser && getCurrentUser?.role !== 'viewer'"
            @click="handleSubStep('update-project-details'), this.$emit('update:quoteCurrentStep', 2)"
            class="collapse-project-detail--body--block--header--edit"
          >
            <p class="collapse-project-detail--body--block--header--edit--text btn-font-large">Edit</p>
            <inline-svg
              :src="require('@/assets/images/icons/edit-2-icon.svg')"
              class="collapse-project-detail--body--block--header--edit--icon edit"
            />
          </div>
        </div>

        <div
          v-for="(detail, i) in siteDetails.details"
          :key="i"
          class="collapse-project-detail--body--details-item">
          <p class="collapse-project-detail--body--details-item--title label text-medium">{{detail.title}}</p>
          <p class="collapse-project-detail--body--details-item--description h6-light">{{detail.text}}</p>
        </div>
      </div>

      <div
        class="collapse-project-detail--body--gallery collapse-project-detail--body--block"
      >
        <div class="collapse-project-detail--body--block--header">
          <h5 class="collapse-project-detail--body--block--header--title h5-sb">Photos</h5>
          <div
            v-if="Object.keys(photosDetails).length && getCurrentUser && getCurrentUser?.role !== 'viewer'"
            @click="handleSubStep('update-project-details'), this.$emit('update:quoteCurrentStep', 3)"
            class="collapse-project-detail--body--block--header--edit"
          >
            <p class="collapse-project-detail--body--block--header--edit--text btn-font-large">Edit</p>
            <inline-svg
              :src="require('@/assets/images/icons/edit-2-icon.svg')"
              class="collapse-project-detail--body--block--header--edit--icon edit"
            />
          </div>
        </div>
        <button
          v-if="getCurrentUser && getCurrentUser?.role !== 'viewer'"
          class="collapse-project-detail--body--block--add--btn text-btn"
          :class="Object.keys(photosDetails).length && 'above-list'"
          @click="handleSubStep('update-project-details'), this.$emit('update:quoteCurrentStep', 3)"
        >Update photos</button>
        <div
          v-if="Object.keys(photosDetails).length"
          class="collapse-project-detail--body--gallery--wrapper"
        >
          <div
            v-for="(photo, index) in photosDetails.photos"
            :key="photo.id"
            :class="{'count-wrapper': index === getMaxGalleryCount}"
          >
            <div
              v-if="index < getMaxGalleryCount"
              :key="photo.id"
              class="collapse-project-detail--body--gallery--wrapper--item"
              :style="{'background-image': `url('${photo.file_url}')`}"
              @click="handleGalleryClick(index)"
            ></div>
            <div v-if="index === getMaxGalleryCount"
              :key="photo.id"
              class="collapse-project-detail--body--gallery--wrapper--item count-images"
              :class="getRemainingImagesCount === 1 && 'one-photo'"
              :style="{'background-image': `url('${photo.file_url}')`}"
              @click="handleGalleryClick(index)"
            >
              <span
                v-if="getRemainingImagesCount !== 1"
                class="collapse-project-detail--body--gallery--wrapper--item--count-text h2-sb"
              >{{`+${getRemainingImagesCount} photos`}}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="collapse-project-detail--body--files collapse-project-detail--body--block">
        <div class="collapse-project-detail--body--block--header">
          <h5 class="collapse-project-detail--body--block--header--title h5-sb">Files</h5>
          <div
            v-if="Object.keys(filesDetails).length && getCurrentUser && getCurrentUser?.role !== 'viewer'"
            @click="handleSubStep('update-project-details'), this.$emit('update:quoteCurrentStep', 4)"
            class="collapse-project-detail--body--block--header--edit"
          >
            <p class="collapse-project-detail--body--block--header--edit--text btn-font-large">Edit</p>
            <inline-svg
              :src="require('@/assets/images/icons/edit-2-icon.svg')"
              class="collapse-project-detail--body--block--header--edit--icon edit"
            />
          </div>
        </div>
        <button
          v-if="getCurrentUser && getCurrentUser?.role !== 'viewer'"
          class="collapse-project-detail--body--block--add--btn text-btn"
          :class="Object.keys(filesDetails).length && 'above-list'"
          @click="handleSubStep('update-project-details'), this.$emit('update:quoteCurrentStep', 4)"
        >Update files</button>
        <div v-if="Object.keys(filesDetails).length">
          <a
            v-for="file in filesDetails.files"
            :key="file.id"
            class="collapse-project-detail--body--files--item"
            :href="file.file_url"
            target="blank"
          >
            <inline-svg
              class="collapse-project-detail--body--files--item--icon"
              :src="require(`@/assets/images/icons/files/${file.file_name.split('.').pop()}.svg`)"
            />
            <div class="collapse-project-detail--body--files--item--file-info">
              <p class="collapse-project-detail--body--files--item--file-info--name">
                {{ file.file_name.length > 25
                  ? handleFileNameTrim(file.file_name)
                  : file.file_name }}
              </p>
              <p class="collapse-project-detail--body--files--item--file-info--size">
                {{ file.file_size > 1000000
                  ? ((file.file_size/1000000).toFixed(1) + 'MB')
                  : ((file.file_size/1000).toFixed(1) + 'KB') }}
              </p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import InlineSvg from 'vue-inline-svg';

export default {
  components: {
    InlineSvg
  },

  props: [
    'basicInfoDetails',
    'requirementsDetails',
    'siteDetails',
    'photosDetails',
    'filesDetails',
    'handleSubStep',
    'isMobile'
  ],

  emits: ['update:quoteCurrentStep'],

  data: () => ({
    showDetails: false,
    galleryItemWidth: ''
  }),

  computed: {
    ...mapGetters([
      'getProjectInfo',
      'getCurrentUser'
    ]),

    getMaxGalleryCount() {
      if (this.galleryItemWidth && !this.isMobile) {
        let countItemMargin = 19;
        const itemsInRow = Math.floor(((this.$el.offsetWidth + countItemMargin) / this.galleryItemWidth) - 1);
        return itemsInRow;
      }
      return 5;
    },

    getRemainingImagesCount() {
      return this.photosDetails.photos.length - this.getMaxGalleryCount;
    }
  },

  methods: {
    handleFileNameTrim(longName) {
      let result = longName.substring(0, 25) + '...' + longName.slice(-7);
      return result;
    },

    toggleDetails() {
      this.showDetails = !this.showDetails;
      this.setGalleryItemWidth();
    },

    setGalleryItemWidth() {
      this.galleryItemWidth = document.querySelector('.collapse-project-detail--body--gallery--wrapper--item');
      if (this.showDetails && this.galleryItemWidth) {
        this.$nextTick(() => {
          this.galleryItemWidth.parentNode.offsetWidth;
        });
      }
    },

    handleGalleryClick(index) {
      if (index === this.getMaxGalleryCount && this.getRemainingImagesCount !== 1) {
        this.$router.push({ name: 'ProjectGallery', params: { index, showSlider: false } });
      } else {
        this.$router.push({ name: 'ProjectGallery', params: { index, showSlider: true } });
      }
    }
  }
}
</script>

<style lang="scss">
.collapse-project-detail {
  border-bottom: 1px solid rgba(92, 92, 102, 0.32);

  &--header {
    padding: 30px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--title {
      width: 80%;
      color: #151522;
      opacity: 0.9;
    }

    &--icon {
      transition: all .3s ease;
      cursor: pointer;
    }
  }

  &--body {
    margin-bottom: 20px;

    &--block {
      margin-bottom: 30px;
      padding-bottom: 30px;
      border-bottom: 1px solid rgba(92, 92, 102, 0.32);

      &:last-child {
        padding-bottom: 0px;
        border-bottom: 0px;
      }

      &--header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 21px;

        &--edit {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          cursor: pointer;

          &--text {
            color: #10886D;
          }

          &--icon {
            width: 28px;
            height: 28px;
            margin-left: 10px;
          }
        }
      }

      &--empty,
      &--add {
        &--btn {
          display: flex;
          align-items: center;
          border: 0px;
          background: #fff;
          padding: 0;

          &.above-list {
            margin-bottom: 18px;
          }

          &--icon {
            width: 12px;
            height: 12px;
            margin-right: 6px;

            path {
              stroke: #10886D;
            }
          }
        }
      }
    }

    &--details-item {
      margin-bottom: 14px;

      &--title {
        margin-bottom: 6px;
        color: #151522;
      }

      &--description {
        color: #151522;
      }

      &:last-child {
        margin-bottom: 0px;
      }
    }

    &--gallery {
      &--wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;

        .count-wrapper {
          margin-left: auto;
        }

        &--item {
          position: relative;
          width: 154px;
          height: 154px;
          margin-bottom: 20px;
          background-size: cover;
          background-position: center;
          cursor: pointer;

          .count-wrapper & {
            margin-right: auto;
          }

          &--count-text {
            color: #fff;
            text-align: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);

            &.h2-sb {
              line-height: 110%;
            }
          }

          &.count-images {
            &:before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: #1A1A24;
              opacity: 0.5;
            }

            &.one-photo {
              &:before {
                content: none;
              }
            }
          }
        }
      }
    }

    &--files {
      display: flex;
      flex-direction: column;
      width: 100%;

      &--item {
        display: flex;
        width: 100%;
        margin-bottom: 17px;
        text-decoration: none;

        &:last-child {
          margin-bottom: 0;
        }

        &--icon {
          width: 40px;
          height: 40px;
          margin-right: 8px;
        }

        &--file-info {
          color: #151522;
          font-family: $SFProText;

          &--name {
            font-weight: 400;
            font-size: 12px;
            margin-bottom: 2px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 260px;
            line-height: 150%;
          }

          &--size {
            margin: 0;
            font-weight: 300;
            font-size: 10px;
            line-height: 150%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .collapse-project-detail--body--gallery--wrapper {
    &--item {
      width: 130px;
      height: 130px;

      &.count-images {
        margin-left: 10px;
      }
    }

    .count-wrapper {
      margin-left: auto;
    }
  }
}

@media screen and (min-width: 1024px) {
  .collapse-project-detail--header {
    justify-content: space-between;
    cursor: pointer;

    &--title {
      width: auto;
    }
  }

  .collapse-project-detail--body {
    &--gallery--wrapper {
      justify-content: flex-start;

      &--item {
        width: 190px;
        height: 190px;
        margin-right: 19px;

        &.count-images {
          margin-right: 0;
        }
      }

      .count-wrapper {
        margin-left: 0;
      }
    }

    &--files--item--file-info {
      &--name {
        font-size: 14px;
        margin-bottom: 1px;
      }

      &--size {
        font-size: 12px;
      }
    }
  }
}
</style>
