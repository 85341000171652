import api from '../api/api';
import router from '../router/index';

export default {

  state: {
    status: '',
    token: localStorage.getItem('user-token') || null,
    currentUser: null,
    company: null,
    contractorCompany: null,
    role: null,
    error: '',
    gcFile: null,
    invitedUser: {}
  },

  mutations: {
    setToken: function(state, payload) {
      state.token = payload
    },
    setCurrentUser: function(state, payload) {
      state.currentUser = payload
    },
    setUserCompany: function(state, payload) {
      state.company = payload
    },
    setContractorCompanyMainInfo: function(state, payload) {
      state.contractorCompany = payload
    },
    setGcFile: function(state, payload) {
      state.gcFile = payload
    },
    setInvitedUser: function(state, payload) {
      state.invitedUser = payload
    },
    authRequest: function(state) {
      state.status = 'loading'
    },
    resetState: function(state) {
      state.status = '';
      state.token = null;
      state.currentUser = null;
      state.company = null;
      state.contractorCompany = null;
      state.role = null;
      state.error = '';
      state.gcFile = null;
      state.invitedUser = {};
    },
  },

  actions: {
    signIn({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        commit('authRequest')
        api.post('sign_in', {...payload})
          .then(resp => {
            if (resp.status == "201") {
              const token = resp.data.token;
              const user = resp.data;
              const company = resp.data.user.company;

              api.defaults.headers['Authorization'] = 'Bearer ' + token;
              localStorage.setItem('user-token', token);
              commit('setToken', token);
              commit('setCurrentUser', user);
              commit('setUserCompany', company);
              dispatch('loadCompanyStatuses');
              resolve(resp);
            } else {
              commit('authRequest', resp.data.message);
            }
          })
          .catch(error => {
            commit('setCurrentUser', {});
            commit('setToken', null);
            return reject(error);
          });
      })
    },

    signUp({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('authRequest')
        let url = 'sign_up';
        if(router.currentRoute._value.params.invite_key) {
          url = `sign_up?invite_key=${router.currentRoute._value.params.invite_key}`
        }
        api.post(url, {...payload})
          .then(resp => {
            if (resp.status == "201") {
              const token = resp.data.token;
              const user = resp.data;
              const company = resp.data.user.company;

              api.defaults.headers['Authorization'] = 'Bearer ' + token;
              localStorage.setItem('user-token', token);
              commit('setToken', token);
              commit('setCurrentUser', user);
              commit('setUserCompany', company);

              resolve(resp);
            } else {
              commit('authRequest', resp.data.message);
            }
          })
          .catch(error => {
            commit('setCurrentUser', {});
            commit('setToken', null);
            return reject(error);
          });
      })
    },

    updateUserInfo({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        commit('authRequest')
        api.put('/update_users_info', payload)
          .then(resp => {
            if (resp.status == "200") {
              const user = resp.data.user;
              dispatch('setDashboardInfo');
              commit('setCurrentUser', user);
              resolve(resp);
            } else {
              commit('authRequest', resp.data.message);
            }
          })
          .catch(error => {
            commit('setCurrentUser', {});
            return reject(error);
          });
      })
    },

    updateUserPassword({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('authRequest')
        api.put('/update_password', payload)
          .then(resp => {
            if (resp.status == "200") {

              commit('setToken', resp.data.token);

              resolve(resp);
            } else {
              commit('authRequest', resp.data.message);
            }
          })
          .catch(error => {
            commit('setToken', {});
            return reject(error);
          });
      })
    },

    gcFileUpload({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('authRequest')
        api.get('gc_file_upload', {params: {...payload}})
          .then(resp => {
            if (resp.status == "200") {
              commit('setGcFile', resp.data);
              resolve(resp);
            } else {
              commit('authRequest', resp.data.message);
            }
          })
          .catch(error => {
            commit('setGcFile', {});
            return reject(error);
          });
      })
    },

    gcFileDelete({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('authRequest')
        api.get('gc_file_delete', {params: {...payload}})
          .then(resp => {
            if (resp.status == "200") {
              commit('setGcFile', {});
              resolve(resp);
            } else {
              commit('authRequest', resp.data.message);
            }
          })
          .catch(error => {
            commit('setGcFile', {});
            return reject(error);
          });
      })
    },

    logoDelete({ commit }) {
      return new Promise((resolve, reject) => {
        commit('authRequest')
        api.put('gc_logo_update')
          .then(resp => {
            if (resp.status == "200") {

              commit('setGcFile', {});

              resolve(resp);
            } else {
              commit('authRequest', resp.data.message);
            }
          })
          .catch(error => {
            return reject(error);
          });
      })
    },

    gcLogoUpdate({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('authRequest')
        api.put('/gc_logo_update', payload)
          .then(resp => {
            if (resp.status == "200") {
              const company = resp.data.user.company;
              const gcFile = {
                file_name: resp.data.file_name,
                url: resp.data.url
              };

              commit('setUserCompany', company);
              commit('setGcFile', gcFile);

              resolve(resp);
            } else {
              commit('authRequest', resp.data.message);
            }
          })
          .catch(error => {
            commit('setUserCompany', {});
            commit('setGcFile', {});
            return reject(error);
          });
      })
    },

    gcLogoUpdateUploadedStatus({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('authRequest')
        api.put('/update_uploaded_status', payload)
          .then(resp => {
            if (resp.status == "200") {
              resolve(resp);
            } else {
              commit('authRequest', resp.data.message);
            }
          })
          .catch(error => {
            return reject(error);
          });
      })
    },

    forgotPassword({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('authRequest')
        api.post('forgot', {...payload})
          .then(resp => {
            if (resp.status == "200") {
              resolve(resp);
            } else {
              commit('authRequest', resp.data.message);
            }
          })
          .catch(error => {
            commit('setCurrentUser', {});
            commit('setToken', null);
            return reject(error);
          });
      })
    },

    resetPassword({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('authRequest')
        api.post('reset', {...payload})
          .then(resp => {
            if (resp.status == "200") {
              const token = resp.data.token;
              const user = resp.data.user;

              commit('setToken', token);
              commit('setCurrentUser', user);

              resolve(resp);
            } else {
              commit('authRequest', resp.data.message);
            }
          })
          .catch(error => {
            commit('setCurrentUser', {});
            commit('setToken', null);
            return reject(error);
          });
      })
    },

    getContractorCompany({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('authRequest')
        api.get(`check_customer_invite?invite_key=${payload}`)
          .then(resp => {
            if (resp.status == "200") {
              const company = resp.data;
              commit('setContractorCompanyMainInfo', company);
              resolve(resp);
            } else {
              commit('authRequest', resp.data.message);
            }
          })
          .catch(error => {
            commit('setContractorCompanyMainInfo', {});
            return reject(error);
          });
      })
    },

    getContractorInfoForCustomer({ commit }) {
      return new Promise((resolve, reject) => {
        commit('authRequest')
        api.get('/check_contractor')
          .then(resp => {
            if (resp.status == "200") {
              const company = resp.data.company;
              commit('setContractorCompanyMainInfo', company);
              resolve(resp);
            } else {
              commit('authRequest', resp.data.message);
            }
          })
          .catch(error => {
            commit('setContractorCompanyMainInfo', {});
            return reject(error);
          });
      })
    },

    getInvitedUserInfo({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('authRequest')
        api.get(`invite_users/${payload}`)
          .then(resp => {
            if (resp.status == "200") {
              commit('setInvitedUser', resp.data);
              resolve(resp);
            } else {
              commit('authRequest', resp.data.message);
            }
          })
          .catch(error => {
            commit('setInvitedUser', {});
            return reject(error);
          });
      })
    },

    logout({commit, dispatch}){
      return new Promise((resolve) => {
        router.push({ name: "Home" });
        commit('resetState')
        dispatch('resetDashbord')
        delete api.defaults.headers['Authorization']
        localStorage.removeItem('user-token')
        window.location.href = '/';
        resolve()
      })
    }

  },

  getters: {
    getCurrentUser (state) {
      return state.currentUser
    },
    getToken (state) {
      return state.token
    },
    getUserCompany (state) {
      return state.company
    },
    isUserLoggedIn (state) {
      return state.token
    },
    getContractorCompanyMainInfo (state) {
      return state.contractorCompany
    },
    getGcFile (state) {
      return state.gcFile
    },
    getInvitedUser (state) {
      return state.invitedUser
    },
  }

}
