export default {
  watch: {
    windowWidth() {
      this.getWindowWidth();
      if (this.windowWidth < 1024) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
  },

  methods: {
    getWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
  }
}