<template>
  <div class="edit-id">
    <div class="edit-id--wrapper">
      <div class="edit-id--wrapper--header">
        <p class="h2-sb">Project ID</p>
        <inline-svg
          :src="require('@/assets/images/icons/plus-rotated.svg')"
          class="edit-id--wrapper--header--close"
          @click="closeModals"
        />
      </div>
      <CustomInput
        :modelValue="!editingProjectId && !projectIdError && getProjectInfo.project_uid ? getProjectInfo.project_uid : projectId"
        @update:modelValue="handleProjectIdChange"
        label="Project ID"
        id="project-id"
        type="text"
        name="project-id"
        :errorMessage="projectIdError"
        marginBottom="30"
      />

      <button
        class="edit-id--wrapper--button normal-btn"
        :class="{'disabled': getProjectInfo.project_uid && projectId === ''
                || getProjectInfo.project_uid === projectId
                || getProjectInfo.project_uid === null && projectId === ''}"
        @click="handleUpdateId"
      >Update</button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { validationUtils } from '@/utils/validation';

import CustomInput from '@/components/shared/CustomInput';
import InlineSvg from 'vue-inline-svg';

export default {

  components: {
    CustomInput,
    InlineSvg
  },

  props: [
    'closeModals',
  ],

  data: () => ({
    projectId: '',
    projectIdError: null,
    editingProjectId: false
  }),

  computed: {
    ...mapGetters([
      'getProjectInfo'
    ]),
  },

  methods: {
    ...mapActions([
      'setDashboardInfo',
      'updateProjectId',
    ]),

    getProjectIdErrorMessage() {
      return validationUtils.isProjectIdValid(this.projectId);
    },

    formValidation() {
      let hasError;

      const projectIdErrorMessage = this.getProjectIdErrorMessage();

      if (projectIdErrorMessage) {
        this.projectIdError = projectIdErrorMessage;
        hasError = true;
      }

      return !hasError;
    },

    handleProjectIdChange(value) {
      if (this.projectIdError) this.projectIdError = null;
      this.projectId = value;
      this.editingProjectId = true;
    },

    async handleUpdateId() {
      const isValid = this.formValidation();
      if (!isValid) return;

      const data = {
        id: this.getProjectInfo.id,
        params: {
          company_project: {
            project_uid: this.projectId
          }
        }
      }

      try {
        await this.updateProjectId(data);
        this.setDashboardInfo();
        this.closeModals();
        this.projectIdError = null;
        this.editingProjectId = false;
      } catch (error) {
        console.error('Edit Project', error);
        if (error.response && error.response.status === 422) {
          this.projectIdError = 'Project id has already been taken.';
        }
      }
    },
  }

}
</script>

<style lang="scss" scoped>
.edit-id {
  margin: 0 auto;

  &--wrapper {
    padding: 0;
    position: relative;

    &--header {
      display: none;
    }

    &--button {
      padding: 13px 0;
      max-width: 325px;
      width: 100%;
    }
  }
}

@media screen and (min-width: 1024px) {
  .edit-id {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(21,21,34,0.5);
    width: 100%;
    max-width: 100%;
    height: 100%;
    z-index: 3;

    &--wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      background: #fff;
      border-radius: 26px;
      display: flex;
      flex-direction: column;
      width: 477px;
      padding: 40px 40px 8px;
      box-sizing: border-box;

      &--header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 26px;

        p {
          color: #151522;
        }

        &--close {
          cursor: pointer;
        }
      }

      &--button {
        max-width: 240px;
        margin-bottom: 32px;
      }
    }
  }
}
</style>
