<template>
  <div class="contractor-header">
    <div class="contractor-header--wrapper">
      <div
        v-if="companyLogo"
        class="contractor-header--wrapper--logo logo-shadow"
      >
      <div
        class="contractor-header--wrapper--logo--image"
        :style="`background-image: url('${companyLogo}')`"
      ></div>
      </div>
      <h3 class="contractor-header--wrapper--title h3-sb">{{companyName}}</h3>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'companyLogo',
    'companyName'
  ]
}
</script>

<style lang="scss" scoped>
.contractor-header {
  display: none;

  &--wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 50px;

    &--logo {
      border-radius: 12px;
      width: 70px;
      height: 70px;
      margin-right: 12px;
      flex: none;
      position: relative;

      &--image {
        width: 54px;
        height: 54px;
        top: 8px;
        left: 8px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
      }
    }

    &--title {
      color: #151522;
      overflow-wrap: break-word;
      word-break: break-all;
    }
  }
}

@media screen and (min-width: 1024px) {
  .contractor-header {
    display: block;
  }
}
</style>
