<template>
  <div
    class="select"
    :style="{ marginBottom: marginBottom + 'px' }"
  >
    <label
      class="select--label"
      :class="{'hidden': !label}"
      :for="id"
    >
      {{ label }}
    </label>
    <div
      :class="['select--select-wrapper',
        {'err': errorMessage},
        {'disabled': disabled === true},
        {'active': showOptions},
      ]"
      @click="handleShowOptions"
    >
      <input
        :class="['select--select-wrapper--input',
          {'selected': selectedOption || isSearching}
        ]"
        type="text"
        :id="label.toLowerCase()"
        autocomplete="noAutocomplete"
        :placeholder="placeholder"
        :value="isSearching
          ? searchQuery
          : selectedOption
            ? selectedOption
            : ''"
        @input="handleSearch"
        @blur="handleBlur"
      >

      <div
        v-if="showOptions || isSearching"
        class="select--select-wrapper--options"
      >
        <div
          v-for="option in searchedOptions"
          class="select--select-wrapper--options--item"
          :class="{'selected': option.label === selectedOption}"
          :style="(option.label === 'Additional information needed' && option.value === '702') && 'background-color: #F8FBFE;'"
          :key="option"
          :value="option.value"
          @click="handleSelectChange"
        >
          {{ option.label }}
        </div>
        <div
          v-if="searchedOptions.length === 0"
          class="select--select-wrapper--options--item no-results"
        >
          No results
        </div>
      </div>
      <p v-if="errorMessage" class="select--select-wrapper--error">
        {{ errorMessage }}
      </p>
    </div>
  </div>
</template>

<script>

export default {

  props: {
    modelValue: {
      type: [String, Number],
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    selectOptions: {
      type: Array,
      default: () => []
    },
    option: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'enter'
    },
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    marginBottom: {
      type: [String, Number],
      default: 18
    },
    errorMessage: {
      type: String,
      default: 'Error Notification'
    }
  },

  data() {
    return {
      showOptions: false,
      isSearching: false,
      searchQuery: '',
    }
  },

  computed: {
    selectedOption() {
      if (this.option) {
        const selectedOption = this.selectOptions.find(item => item.value === this.option);
        return selectedOption.label;
      }
      return '';
    },

    searchedOptions() {
      if (this.isSearching) {
        return this.selectOptions.filter(item => item.label.toLowerCase().startsWith(this.searchQuery.toLowerCase()));
      }
      return this.selectOptions;
    }
  },

  methods: {
    handleShowOptions() {
      if (!this.disabled) {
        this.showOptions = !this.showOptions;
        this.isSearching = false;
      }
    },

    handleSelectChange(e) {
      this.$emit('update:modelValue', e.target.getAttribute('value'));
      this.isSearching = false;
    },

    handleSearch(e) {
      this.isSearching = true;
      this.searchQuery = e.target.value;
    },

    handleBlur() {
      if (!this.modelValue || this.isSearching === true) {
        this.$emit('update:modelValue', '');
      }
      setTimeout(() => {
        this.showOptions = false;
        this.isSearching = false;
        this.searchQuery = '';
      },200)
    },
  },

}
</script>

<style lang="scss">
.select {
  display: flex;
  flex-direction: column;
  max-width: 325px;
  width: 100%;
  margin: 0 auto;
  position: relative;

  &--label {
    font-size: 16px;
    font-weight: 600;
    color: #151522;
    margin: 0 0 13px 0;
    text-align: left;
    font-family: $SFProText;
    line-height: 150%;

    &.hidden {
      display: none;
    }
  }

  &--select-wrapper {
    border: 1px solid #CCCCD7;
    border-radius: 5px;
    transition: box-shadow .3s ease;
    color: #81878C;
    position: relative;

    &.active {
      border: 1px solid #4B617A;
      box-shadow: 0px 4px 8px rgba(21, 21, 34, 0.06);
    }

    &.err {
      border: 1px solid #E83C56;
      color: #E83C56;
    }

    &:hover, &:focus {
      box-shadow: 0px 4px 8px rgba(21, 21, 34, 0.06);
      cursor: pointer;
    }

    &.disabled {
      border: 1px solid #B3B3BE;
      color: #666670;
    }

    &--input {
      border-radius: 5px;
      border: none;
      box-sizing: border-box;
      font-size: 16px;
      line-height: 150%;
      padding: 14px 32px 13px 20px;
      width: 100%;
      font-family: $SFProText;
      font-weight: 300;
      box-shadow: none;
      color: #B3B3BE;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      background-image: url(~@/assets/images/icons/arrow-down.svg);
      background-position: 98% 50%;
      background-repeat: no-repeat;

      &.selected {
        color: #151522;
      }

      .err & {
        color: #E83C56;
      }

      .disabled & {
        color: #666670;
        background-color: #B3B3BE;
        pointer-events: none;
      }

      &::placeholder {
        color: #B3B3BE;
      }
    }

    &--options {
      position: absolute;
      top: 64px;
      left: 0;
      width: 100%;
      max-height: 245px;
      overflow-y: overlay;
      overflow-x: hidden;
      background: #fff;
      border-radius: 5px;
      z-index: 3;
      box-shadow: 0px 4px 8px rgba(21, 21, 34, 0.06);

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: #5C5C66;
        border-radius: 10px;
        height: 44px;
      }

      &--item {
        color: #151522;
        line-height: 150%;
        padding: 14px 30px 13px 20px;
        font-size: 16px;
        width: 100%;
        font-family: $SFProText;
        font-weight: 300;
        border-bottom: 1px solid rgba(92, 92, 102, 0.08);
        box-sizing: border-box;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        &.no-results {
          color: #666670;
        }

        &:last-child {
          border: none;
        }

        &.selected {
          position: relative;
          font-weight: 600;

          &:after {
            content: '';
            background: url(~@/assets/images/icons/check-select.svg) no-repeat;
            background-position: center;
            background-size: contain;
            width: 20px;
            height: 20px;
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translateY(-50%)
          }
        }
      }
    }

    &--error {
      font-size: 12px;
      color: #E83C56;
      text-align: right;
      font-family: $SFProText;
      font-weight: 500;
      line-height: 150%;
      position: absolute;
      bottom: -22px;
      right: 0;
    }
  }
}

@media screen and (min-width: 1024px) {
  .select {
    max-width: 100%;

    &--select-wrapper {
      &--input,
      &--options--item {
        font-size: 14px;
      }
    }
  }
}
</style>
