<template>
  <div class="invite-user">
    <div class="invite-user--wrapper">
      <div class="invite-user--wrapper--header">
        <p class="invite-user--wrapper--header--title h2-sb">Invite user</p>
        <inline-svg
          :src="require('@/assets/images/icons/plus-rotated.svg')"
          class="invite-user--wrapper--header--close"
          @click="handleToggleInviteUser"
        />
      </div>

      <div class="invite-user--wrapper--item">
        <CustomInput
          :modelValue="userEmail"
          @update:modelValue="handleUserEmailChange"
          label="User email"
          id="user-email"
          name="user-email"
          marginBottom="18"
          inputHeight="50"
          :errorMessage="userEmailError"
        />

        <CustomSelect
          :modelValue="userRole"
          @update:modelValue="handleUserRoleChange"
          label="User role"
          id="user-role"
          name="user-role"
          :option="userRole"
          :selectOptions="roleOptions"
          marginBottom="30"
          placeholder="Choose user role"
          :errorMessage="userRoleError"
        />
        <button
          class="invite-user--wrapper--item--button normal-btn"
          :class="[isButtonDisabled && 'disabled', isSended && 'frozen']"
          @click="handleSendInvitation"
        >{{isSended ? 'Sent!' : 'Send invitation'}}</button>
      </div>
    </div>

    <custom-error
      v-if="showError"
      :errorMessage="errorMessage"
      :handleErrorClose="handleErrorClose"
    />

  </div>
</template>

<script>
import { mapActions } from 'vuex';

import { validationUtils } from '@/utils/validation';
import roleOptions from '@/components/dashboard/users/RoleOptions.js';

import CustomInput from '@/components/shared/CustomInput';
import CustomSelect from '@/components/shared/CustomSelect';
import InlineSvg from 'vue-inline-svg';
import CustomError from '@/components/shared/CustomError';

export default {
  components: {
    CustomInput,
    CustomSelect,
    InlineSvg,
    CustomError
  },

  props: [
    'handleToggleInviteUser'
  ],

  data: () => ({
    isSended: false,
    userEmail: '',
    userEmailError: null,
    userRole: '',
    userRoleError: null,
    roleOptions: roleOptions,
    showError: false,
    errorMessage: 'Something went wrong. Please reload the page'
  }),

  computed: {
    getUserEmailErrorMessage() {
      if (this.userEmail) {
        return validationUtils.isEmailValid(this.userEmail);
      }
      return false;
    },

    isButtonDisabled() {
      return (!this.userEmail || !this.userRole) && !this.isSended;
    }
  },

  methods: {
    ...mapActions([
      'inviteUser'
    ]),

    handleErrorClose() {
      this.showError = false;
    },

    formValidation() {
      let hasError;
      const userEmailErrorMessage = this.getUserEmailErrorMessage;

      if (userEmailErrorMessage) {
        this.userEmailError = userEmailErrorMessage;
        hasError = true;
      }

      return !hasError;
    },

    handleUserEmailChange(value) {
      if (this.userEmailError) this.userEmailError = null;
      this.userEmail = value;
    },

    handleUserRoleChange(value) {
      if (this.userRoleError) this.userRoleError = null;
      this.userRole = value;
    },

    async handleSendInvitation() {
      const isValid = this.formValidation();
      if (!isValid) return;

      const invite_user = {
        email: this.userEmail,
        role: Number(this.userRole)
      }

      try {
        this.isSended = true;
        await this.inviteUser({ invite_user });
        setTimeout(() => {
          this.isSended = false;
        }, 2000)
      } catch (error) {
        console.error('INvite user', error);
        this.errorMessage = error.response.data.message;
        this.showError = true;
      }

      this.handleAfterSend();
    },

    handleAfterSend() {
      this.userEmail = '';
      this.userRole = '';
    }
  }
}
</script>

<style lang="scss">
.invite-user {
  max-width: 375px;
  margin: 0 auto;

  &--wrapper {
    padding: 5px 25px 0;

    &--header {
      display: none;
    }

    &--item {
      margin-bottom: 50px;

      &--title {
        margin-bottom: 13px;
        color: #151522;
      }

      &--button {
        padding: 13px 0;
        max-width: 325px;
        width: 100%;

        &.frozen {
          pointer-events: none;
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .invite-user {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(21,21,34,0.5);
    width: 100%;
    max-width: 100%;
    height: 100%;
    z-index: 3;

    &--wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      background: #fff;
      border-radius: 26px;
      display: flex;
      flex-direction: column;
      width: 477px;
      padding: 40px 40px 8px;
      box-sizing: border-box;

      &--header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &--title {
          margin-bottom: 24px;
        }

        p {
          color: #151522;
        }

        &--close {
          cursor: pointer;
          position: absolute;
          top: 50px;
          right: 50px;
        }
      }

      &--item {
        margin-bottom: 32px;

        &--button {
          max-width: 240px;
        }
      }
    }
  }
}
</style>
