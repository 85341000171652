<template>
  <div class="requirements">
    <custom-text-area
      :modelValue="timeRequirements"
      @update:modelValue="handleTimeRequirementsChange"
      label="Shift/time requirements"
      placeholder="Are there any special shift or time  of day requirements for this job?"
      id="time-requirements"
      name="time-requirements"
      errorMessage=""
      :margin-bottom="20"
      optional
    />

    <custom-date-picker
      :modelValue="blackoutDays"
      @update:modelValue="handleBlackoutDaysChange"
      modePicker="multiple"
      label="Blackout days"
      placeholder="Days when work cannot be done"
      optional
      isMultiple
    />

    <custom-text-area
      :modelValue="specialRequirements"
      @update:modelValue="handleSpecialRequirementsChange"
      label="Special requirements"
      placeholder="Are there any certifications, or other requirements that would impede starting the job?"
      id="special-requirements"
      name="special-requirements"
      errorMessage=""
      :margin-bottom="20"
      optional
    />
  </div>
</template>

<script>
import CustomTextArea from '@/components/shared/CustomTextArea';
import CustomDatePicker from '@/components/shared/CustomDatePicker';

export default {

  components: {
    CustomTextArea,
    CustomDatePicker,
  },

  props: [
    'timeRequirements',
    'blackoutDays',
    'specialRequirements',
    'handleTimeRequirementsChange',
    'handleBlackoutDaysChange',
    'handleSpecialRequirementsChange'
  ]
}
</script>
