<template>
  <div class="dashboard-inner-page single-step">
    <div
      v-if="currentPageSubPage === 'help'"
      class="dashboard-inner-page--sub-step-body open"
    >
      <div class="dashboard-inner-page--sub-step-body--desktop-header">
        <div class="dashboard-inner-page--sub-step-body--desktop-header--text">
          <p class="dashboard-inner-page--sub-step-body--desktop-header--text--title text-medium">Support</p>
          <p class="dashboard-inner-page--sub-step-body--desktop-header--text--description h6-light">Need assistance? We’ll get you the help you need.</p>
        </div>
      </div>
      <div class="dashboard-inner-page--sub-step-body--desktop-body help-page">
        <a
          class="dashboard-inner-page--sub-step-body--desktop-body--link h4-sb"
          href="mailto:support@scopeneeds.com"
          target="_blank"
        >support@scopeneeds.com</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'currentPageSubPage',
  ],

  mounted() {
    this.$emit('update:currentPageSubPage', 'help');
  }
}
</script>