<template>
  <div class="forgot-password">

    <custom-sidebar v-if="currentStep === 0"/>

    <div
      class="forgot-password--body"
      :class="{'email-sent': currentStep === 1}"
    >

      <div v-if="currentStep === 0">

        <div class="forgot-password--body--header">
          <router-link
            class="forgot-password--body--header--back"
            to="/sign-in"
          >
            <inline-svg
              :src="require('@/assets/images/icons/arrow-left.svg')"
              class="forgot-password--body--header--link"
            />
          </router-link>
          <h3 class="forgot-password--body--header--title">Forgot password?</h3>
        </div>

        <h2 class="forgot-password--body--title h2-sb">Let’s restore your account</h2>

        <p class="forgot-password--body--description p-medium">Enter the email address you used when you joined and we’ll send you instructions to reset your password</p>

        <div class="forgot-password--body--input-wrapper">
          <custom-input
            :modelValue="email"
            @update:modelValue="handleEmailChange"
            label="Email"
            id="email"
            type="email"
            name="email"
            :errorMessage="emailError"
          />
        </div>

        <button
          class="forgot-password--body--button normal-btn"
          :class="isButtonDisabled && 'disabled'"
          @click="handleForgot"
        >
          Send reset instructions
        </button>

        <router-link
          to="/sign-in"
          class="forgot-password--body--button-back ghost-btn"
        >
          Back
        </router-link>

        <custom-error
          v-if="showError"
          :errorMessage="errorMessage"
          :handleErrorClose="handleErrorClose"
        />

      </div>

      <div
        v-if="currentStep === 1"
        class="forgot-password--body--first-step"
      >

        <div class="forgot-password--body--email-sent">
          <inline-svg
            class="forgot-password--body--email-sent--icon"
            :src="require('@/assets/images/icons/check-circle.svg')"
          />
          <h1 class="forgot-password--body--email-sent--title h1-regular">Email sent</h1>
          <p class="forgot-password--body--email-sent--description p-medium">Check your email and open the link we sent to continue</p>
        </div>

        <router-link
          to="/sign-in"
          class="forgot-password--body--button normal-btn"
        >
          Got it
        </router-link>

      </div>

      <p class="forgot-password--body--copyright caption-3">Powered by FCA</p>

    </div>

    <p
      v-if="currentStep === 0"
      class="forgot-password--register btn-font-large"
    >
      No account?
      <router-link
        to="/sign-up"
        class="forgot-password--register--link text-btn"
      >
        Create one
      </router-link>
    </p>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import { validationUtils } from '@/utils/validation';

import InlineSvg from 'vue-inline-svg';
import CustomInput from '@/components/shared/CustomInput';
import CustomSidebar from '@/components/shared/CustomSidebar';
import CustomError from '@/components/shared/CustomError';

export default {

  name: 'Reset password',

  components: {
    InlineSvg,
    CustomInput,
    CustomSidebar,
    CustomError,
  },

  data: () => ({
    currentStep: 0,
    email: '',
    emailError: null,
    errorMessage: 'Something went wrong. Please reload the page',
    showError: false
  }),

  computed: {
    getEmailErrorMessage() {
      return validationUtils.isEmailValid(this.email);
    },

    isButtonDisabled() {
      return !this.email;
    },
  },

  methods: {
    ...mapActions([
      'forgotPassword'
    ]),

    handleErrorClose() {
      this.showError = false;
    },

    handleEmailChange(value) {
      if (this.emailError) this.emailError = null;
      this.email = value;
    },

    formValidation() {
      let hasError;
      const emailErrorMessage = this.getEmailErrorMessage;

      if (emailErrorMessage) {
        this.emailError = emailErrorMessage;
        hasError = true;
      }

      return !hasError
    },

    async handleForgot() {
      const isValid = this.formValidation();
      if (!isValid) return;

      const data = {
        email: this.email.toLowerCase(),
      };

      try {
        await this.forgotPassword(data);
        this.currentStep++;
      } catch (error) {
        if (error) {
          this.showError = true;
          if (error.response && error.response.status === 404) {
            this.errorMessage = 'We couldn’t find an account matching the email you entered. Please check your email and try again.';
          }
        }
      }
    },
  }
}
</script>

<style lang="scss">
.forgot-password {
  height: 100%;

  &--body {
    padding: 40px 25px 7px;
    margin: 0 auto;
    max-width: 375px;
    height: 100%;
    box-sizing: border-box;
    position: relative;

    &.email-sent {
      padding: 0 25px 50px;
    }

    &--header {
      margin-bottom: 31px;
      position: relative;

      &--link {
        width: 100%;
        max-width: 8px;
        height: 14px;
        padding: 7px 20px 7px 0;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
      }

      &--title {
        text-align: center;
        width: 100%;
        color: #151522;
        font-size: 20px;
        font-weight: 600;
        font-family: $SFProDisplay;
        line-height: 150%;
      }
    }

    &--title {
      color: #151522;
      text-align: left;
      margin-bottom: 12px;
      white-space: pre-wrap;
    }

    &--description {
      color: #4B617A;
      margin-bottom: 38px;
      max-width: 325px;
      white-space: pre-wrap;
    }

    &--button {
      padding: 13px 0;
      display: inline-block;
      width: calc(100% - 50px);
      position: absolute;
      bottom: 100px;
      left: 50%;
      max-width: 325px;
      transform: translateX(-50%);
      text-align: center;
      text-decoration: none;
    }

    &--button-back {
      display: none;
      text-decoration: none;
    }

    &--copyright {
      color: #9999A0;
      text-align: center;
      position: absolute;
      bottom: 36px;
      left: 0;
      width: 100%;
    }

    &--email-sent {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;

      &--icon {
        width: 160px;
        height: 160px;
        margin-bottom: 14px;
      }

      &--title {
        color: #151522;
        text-align: center;
        margin-bottom: 10px;
      }

      &--description {
        color: #617B97;
        max-width: 280px;
        text-align: center;
      }
    }

    &--first-step {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }

  &--register {
    color: #4B617A;
    text-align: center;
    position: absolute;
    bottom: 58px;
    left: 0;
    width: 100%;

    &--link {
      color: #10886D;
      text-decoration: none;
    }
  }
}

@media screen and (max-width: 375px) and (max-height: 811px),
(max-height: 811px) {
  .forgot-password {
    &--body {
      &--button {
        bottom: 70px
      }

      &--copyright {
        bottom: 10px;
      }
    }

    &--register {
      bottom: 30px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .forgot-password {
    display: flex;
    max-width: 100%;
    padding: 0;
    position: relative;

    &--body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 100px 0;
      max-width: 397px;
      width: 100%;
      position: relative;

      &.email-sent {
        padding: 0 0 50px;
      }

      &--header {
        display: none;
      }

      &--title {
        margin-bottom: 12px;
      }

      &--description {
        margin-bottom: 38px;
      }

      &--input-wrapper {
        margin-bottom: 60px;
      }

      &--button {
        width: 240px;
        position: relative;
        left: unset;
        bottom: unset;
        transform: none;
        margin-bottom: 20px;

        .email-sent & {
          margin: 0 auto;
        }
      }

      &--button-back {
        width: 240px;
        padding: 12px 0;
        display: inline-block;
      }

      &--email-sent {
        height: auto;

        &--description {
          margin-bottom: 40px;
        }
      }

      &--copyright {
        display: none;
      }
    }

    &--register {
      position: absolute;
      top: 100px;
      right: 60px;
      bottom: unset;
      left: unset;
      width: 182px;
    }
  }
}
</style>
