<template>
  <div class="range-wrapper" :class="getUserRole !== 'contractor' && 'disable'">
    <div class="range-wrapper--container">
      <input
        type="range"
        min="0"
        max="100"
        step="5"
        :value="modelValue"
        @input="handleChange"
        @mouseup="updateRangeValue"
        @touchend="updateRangeValue"
        :style="{backgroundSize: `${backgroundSize}% 100%`}"
      >
    </div>

    <div class="range-wrapper--labels">
      <div
        class="range-wrapper--labels--item"
        v-for="(label, i) in 21"
        :key="i"
      >
        <span
          class="range-wrapper--labels--item--icon"
          :class="setClassIcon(i)"
        ></span>
        <span
          v-if="showRangeNumber(i)"
          class="range-wrapper--labels--item--text p-regular"
        >{{5 * i}}%</span>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import commonHelpers from '@/mixins/commonHelpers';

export default {

  mixins: [commonHelpers],

  props: {
    modelValue: {
      type: [String, Number],
      default: ''
    },
    updateRangeValue: {
      type: Function
    },
    getUserRole: {
      type: [String, Number],
    }
  },

  emits: ['update:modelValue'],

  data: () => ({
    backgroundSize: 0,
    windowWidth: window.innerWidth,
    isMobile: (window.innerWidth < 1024)
  }),

  watch: {
    getProjectInfo() {
      this.backgroundSize = this.getProjectInfo.project_checklists[0].progress_percentage;
    }
  },

  computed: {
    ...mapGetters([
      'getProjectInfo',
    ]),
  },

  methods: {
    handleChange(e) {
      this.backgroundSize = e.target.value;
      this.$emit('update:modelValue', e.target.value);
    },

    setClassIcon(i) {
      if (this.isMobile) {
        return i%5===0 ? 'big' : 'small';
      } else {
        return i%2===0 ? 'big' : 'small';
      }
    },

    showRangeNumber(i) {
      if (this.isMobile) {
        return i%5===0;
      } else {
        return i%2===0;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.range-wrapper {
  position: relative;
  margin: 0 10px 57px;

  &.disable {
    pointer-events: none;
  }

  &--labels {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 16px;
    left: 50%;
    transform: translateX(-50%);
    width: 93%;
    z-index: -1;

    &--item {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      &--icon {
        background: #9999A0;
        width: 1px;
        display: block;

        &.big {
          height: 6px;
        }

        &.small {
          height: 4px;
        }
      }

      &--text {
        color: #617B97;
        opacity: 0.9;
        width: 31px;
        text-align: center;
        position: absolute;
        top: 10px;
      }
    }
  }
}

input[type=range] {
  -webkit-appearance: none;
  display: block;
  width: 100%;
  margin: 16px 0;
  background: rgba(92, 92, 102, 0.16);
  border-radius: 100px;
  height: 12px;
  background-image: -webkit-gradient(linear, 20% 0%, 20% 100%, color-stop(0%, #10886D), color-stop(100%, #10886D));
  background-image: -webkit-linear-gradient(left, #10886D 0%,#10886D 100%);
  background-image: -moz-linear-gradient(left, #10886D 0%, #10886D 100%);
  background-image: -o-linear-gradient(to right, #10886D 0%,#10886D 100%);
  background-image: linear-gradient(to right, #10886D 0%,#10886D 100%);
  background-repeat: no-repeat;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  box-shadow: none;
  background: transparent;
  border-radius: 0px;
  border: none;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: none;
  background: #10886D;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -13px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: transparent;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 12px;
  cursor: pointer;
  box-shadow: none;
  background: transparent;
  border-radius: 0px;
  border: none;
}
input[type=range]::-moz-range-thumb {
  box-shadow: none;
  background: #10886D;
  border: none;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 12px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: transparent;
  border: none;
  border-radius: 0px;
  box-shadow: none;
}
input[type=range]::-ms-fill-upper {
  background: transparent;
  border: none;
  border-radius: 0px;
  box-shadow: none;
}
input[type=range]::-ms-thumb {
  box-shadow: none;
  background: #10886D;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  background: transparent;
}
input[type=range]:focus::-ms-fill-upper {
  background: transparent;
}

@media screen and (min-width: 1024px) {
  .range-wrapper--labels {
    width: 100%;
  }

  .range-wrapper--labels--item--icon {
    width: 2px;
  }

  .range-wrapper--labels--item--text {
    position: relative;
    top: 0;
  }
}
</style>
