<template>
  <div class="project-detail">
    <div
      v-if="isMobile"
      class="project-detail--header"
    >
      <h2 class="project-detail--header--title h4-sb">{{ setTitle }}</h2>
      <inline-svg
        :src="require('@/assets/images/icons/arrow-left.svg')"
        class="project-detail--header--button"
        @click="this.currentPage === 'update-project-details'
          ? handleNavigation('project-detail')
          : handleNavigation()"
      />
    </div>

    <DesktopMenu
      :currentPage="currentPage"
      :handleNavigation="handleNavigation"
      :setTitle="setTitle"
      :handleToggleMenu="handleToggleMenu"
      :handleRequestModal="handleRequestModal"
      :showMenu="showMenu"
      :handleOpenInnerPage="handleOpenInnerPage"
      :showUnsavedChangesModal="showUnsavedChangesModal"
      @update:showUnsavedChangesModal="handleToggleUnsavedChangesModal"
      :isProjectDetailsChanged="isProjectDetailsChanged"
    />

    <div v-if="currentPage === 'project-detail'">
      <div class="project-detail--id">
        <div class="project-detail--id--header">
          <h4 class="h4-sb">Project ID</h4>
          <div
            v-if="setProjectUid !== '' && getUserRole === 'contractor' && getCurrentUser?.role !== 'viewer'"
            @click="handleSubStep('project-id')"
            class="project-detail--id--header--edit"
          >
            <p class="project-detail--id--header--edit--text btn-font-large">Edit</p>
            <inline-svg
              :src="require('@/assets/images/icons/edit-2-icon.svg')"
              class="project-detail--id--header--edit--icon edit"
            />
          </div>
        </div>

        <h6
          v-if="setProjectUid !== ''"
          class="h6-sb"
        >{{getProjectInfo.project_uid}}</h6>

        <button
          v-if="setProjectUid === '' && getUserRole === 'contractor'
            && getCurrentUser && getCurrentUser?.role !== 'viewer'"
          class="project-detail--id--btn text-btn change-sub-step-btn"
          data-sub-step="project-id"
          @click="handleSubStep('project-id')"
        >
          <inline-svg
            :src="require('@/assets/images/icons/plus.svg')"
            class="project-detail--id--btn--icon"
          />
          Add ID
        </button>
      </div>

      <div class="project-detail--status">
        <div class="project-detail--status--header">
          <h4 class="h4-sb">Project status</h4>
          <div
            v-if="getCurrentUser && getCurrentUser?.role !== 'viewer'"
            @click="handleSubStep('project-status')"
            class="project-detail--id--header--edit"
          >
            <p class="project-detail--id--header--edit--text btn-font-large">Edit</p>
            <inline-svg
              :src="require('@/assets/images/icons/edit-2-icon.svg')"
              class="project-detail--id--header--edit--icon edit"
            />
          </div>
        </div>
        <p
          class="project-detail--status--status-block h6-sb"
          :class="this.getCurrentUser && this.getCurrentUser?.role === 'viewer' && 'disabled'"
          @click="handleSubStep('project-status')"
        >
          <inline-svg
            :src="require('@/assets/images/icons/tag.svg')"
            class="project-detail--status--status-block--icon"
          />
          {{ getProjectInfo.project ? setProjectStatus(getProjectInfo) : '' }}
        </p>
      </div>

      <div class="project-detail--details">
        <ProjectDetailCollapse
          :basicInfoDetails="basicInfoDetails"
          :requirementsDetails="requirementsDetails"
          :siteDetails="siteDetails"
          :photosDetails="photosDetails"
          :filesDetails="filesDetails"
          :handleOpenInnerPage="handleOpenInnerPage"
          :handleSubStep="handleSubStep"
          :quoteCurrentStep="quoteCurrentStep"
          @update:quoteCurrentStep="handleQuoteStep"
          :isMobile="isMobile"
        />
      </div>

      <PaymentCheckList
        v-if="getUserRole === 'contractor'
          && getCurrentUser && getCurrentUser?.role !== 'viewer'"
        :isLoading="isLoading"
        @update:isLoading="isLoading=false"
      />

      <Activity
        :setLogoImage="setLogoImage"
        :isMobile="isMobile"
      />
    </div>

    <RequestQuoteForm
      v-if="currentPage === 'update-project-details'"
      :isMobile="isMobile"
      :quoteCurrentStep="quoteCurrentStep"
      @update:quoteCurrentStep="handleQuoteStep"
      :currentPage="currentPage"
      @update:currentPage="handleNavigation"
      :additionalStyles="'margin: 0px;'"
      :isUpdateQuoteDetails="getProjectInfo"
      :updatingQouteDetailsSession="updatingQouteDetailsSession"
      @update:updatingQouteDetailsSession="handleUpdateQouteDetailsSession"
      :isProjectDetailsChanged="isProjectDetailsChanged"
      @update:isProjectDetailsChanged="handleisProjectDetailsChanged"
      :isDiscardChanges="isDiscardChanges"
      @update:isDiscardChanges="handleDiscardChanges"
    />

    <EditProjectId
      v-if="currentPage === 'project-id'"
      :closeModals="closeModals"
    />

    <EditProjectStatus
      v-if="currentPage === 'project-status'"
      :closeModals="closeModals"
      :handleAddNewStatus="handleNavigation"
    />

    <p
      v-if="isMobile"
      class="project-detail--copyright caption-3"
    >
      Powered by FCA
    </p>

    <Footer
      v-if="!isMobile"
      :currentPage="currentPage"
      :handleFooterLinks="handleNavigation"
    />

    <CustomBlunkLoader
      v-if="isLoading"
    />

    <custom-error
      v-if="showError"
      :errorMessage="errorMessage"
      :handleErrorClose="handleErrorClose"
      :errorStyles="errorStyles()"
    />

    <CustomModal
      v-if="getProjectInfo && showUnsavedChangesModal"
      message="You have unsaved changes."
      :handleCancel="handleToggleUnsavedChangesModal"
      :handleConfirm="handleDiscardClick"
      notice="Do you want to discard them?"
      confirmText="Discard"
      cancelText="Cancel"
    />

    <CustomModal
      v-if="showLogoutModal"
      :message="getLogoutMessage"
      :handleCancel="handleLogoutModal"
      :handleConfirm="logout"
      notice="Are you sure you want to log out?"
    />

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import dashboardHelpers from '@/mixins/dashboardHelpers';
import commonHelpers from '@/mixins/commonHelpers';

import moment from 'moment';

import InlineSvg from 'vue-inline-svg';
import ProjectDetailCollapse from '@/components/dashboard/project-details/ProjectDetailCollapse';
import RequestQuoteForm from '@/components/dashboard/RequestQuoteForm';
import PaymentCheckList from '@/components/dashboard/project-details/PaymentCheckList';
import Activity from '@/components/dashboard/project-details/Activity';
import EditProjectId from '@/components/dashboard/project-details/EditProjectId';
import EditProjectStatus from '@/components/dashboard/project-details/EditProjectStatus';
import DesktopMenu from '@/components/dashboard/DesktopMenu';
import Footer from '@/components/dashboard/Footer';
import CustomBlunkLoader from '@/components/shared/CustomBlunkLoader';
import CustomError from '@/components/shared/CustomError';
import CustomModal from '@/components/shared/CustomModal';

export default {
  name: 'ProjectPage',

  mixins: [dashboardHelpers, commonHelpers],

  components: {
    InlineSvg,
    ProjectDetailCollapse,
    RequestQuoteForm,
    PaymentCheckList,
    Activity,
    EditProjectId,
    EditProjectStatus,
    DesktopMenu,
    Footer,
    CustomBlunkLoader,
    CustomError,
    CustomModal
  },

  data: () => ({
    currentPage: 'project-detail',
    quoteCurrentStep: 0,
    windowWidth: window.innerWidth,
    isMobile: (window.innerWidth < 1024),
    basicInfoDetails: {},
    requirementsDetails: {},
    siteDetails: {},
    photosPreview: [],
    filesPreview: [],
    photosDetails: [],
    filesDetails: [],
    showMenu: false,
    showRequestModal: false,
    isLoading: true,
    showError: false,
    errorMessage: 'Something went wrong. Please reload the page',
    showLogoutModal: false,

    // for unsaved-changes-modal
    // for showing unsaved-changes-modal if user changed project-details and try to leave without saving
    isProjectDetailsChanged: false,
    showUnsavedChangesModal: false,
    isDiscardChanges: false,
    navigationLink: '',
    isLogoutClicked: false,
    updatingQouteDetailsSession: Math.random().toString(36).substr(2, 9)
  }),

  mounted() {
    window.addEventListener('resize', this.getWindowWidth);
    window.addEventListener('beforeunload', this.handleReloadPage);
    this.handleLoadInfo();
    window.scrollTo(0,0);
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.getWindowWidth);
    window.removeEventListener('beforeunload', this.handleReloadPage);
  },

  beforeRouteLeave(to, from , next) {
    if (this.isProjectDetailsChanged) {
      this.showUnsavedChangesModal = true;
      if (this.isDiscardChanges) {
        next();
      } else {
        this.isLogoutClicked = false;
        next(false);
      }
    } else {
      next();
    }
  },

  computed: {
    ...mapGetters([
      'getProjectInfo',
      'getUserCompany',
      'getCurrentUser',
    ]),

    setProjectUid() {
      return this.getProjectInfo.project_uid ? this.getProjectInfo.project_uid : '';
    },

    setTitle() {
      if (this.isMobile) {
        if (this.currentPage === 'project-detail') {
          return this.getProjectInfo.project
            ? this.getProjectInfo.project.name
            : '';
        } else if (this.currentPage === 'project-id') {
          return 'Project ID';
        } else if (this.currentPage === 'project-status') {
          return 'Project status';
        } else if (this.currentPage === 'update-project-details') {
          return 'Update qoute';
        }
      } else {
        if (this.currentPage === 'update-project-details') {
          return 'Update qoute';
        } else {
          return this.getProjectInfo.project
            ? this.getProjectInfo.project.name
            : '';
        }
      }
      return '';
    },
  },

  watch: {
    getProjectInfo() {
      this.setProjectDetails();
    }
  },

  methods: {
    ...mapActions([
      'projectInfo',
      'logout'
    ]),

    handleReloadPage(e) {
      if (this.isProjectDetailsChanged) {
        e.preventDefault();
        e.returnValue = "";
      }
    },

    handleErrorClose() {
      this.showError = false;
    },

    async handleLoadInfo() {
      try {
        window.scrollTo(0,0);
        await this.projectInfo(this.$route.params.id);
        this.isLoading = false;
      } catch (error) {
        console.error('Project 1', error);
        this.showError = true;
      }
      this.setProjectDetails();
    },

    setProjectDetails() {
      this.setBasicInfoDetails();
      this.setRequirementsDetails();
      this.setSiteDetails();
      this.setPhotosDetails();
      this.setFilesDetails();
    },

    setBlackoutDays(days) {
      if (days) {
        const daysArray = days.split(',');
        let formattedDays = [];
        daysArray.forEach((item) => {
          const newItem = moment(item,'DD-MM-YYYY').format('MM/DD/YYYY');
          formattedDays.push(newItem);
        });
        return formattedDays.join(', ');
      } else {
        return days;
      }
    },

    setBasicInfoDetails() {
      if (this.getProjectInfo.project) {
        this.basicInfoDetails = {
          title: 'Basic info',
          details: [
            {
              title: 'Project name',
              text: this.getProjectInfo.project.name
            },
            {
              title: 'Contact name',
              text: this.getProjectInfo.project.contact_name
            },
            {
              title: 'Contact email',
              text: this.getProjectInfo.project.email
            },
            {
              title: 'Start date',
              text: moment(this.getProjectInfo.project.start_date,'YYYY-MM-DD').format('MM/DD/YYYY')
            },
            {
              title: 'Project type',
              text: this.getProjectInfo.project.existing ? 'New' : 'Existing'
            }
          ]
        }
      }
    },

    setRequirementsDetails() {
      if (this.getProjectInfo.project) {
        const details = [
          {
            title: 'Shift/time requirements',
            text: this.getProjectInfo.project.time_requirements
          },
          {
            title: 'Blackout days',
            text: this.setBlackoutDays(this.getProjectInfo.project.blackout_days)
          },
          {
            title: 'Special requirements',
            text: this.getProjectInfo.project.special_requirements
          }
        ].filter(d => d.text !== '')

        this.requirementsDetails = {
          title: 'Requirements',
          details
        }
      }
    },

    setSiteDetails () {
      if (this.getProjectInfo.project) {
        const details = [
          {
            title: 'Location',
            text: this.getProjectInfo.project.location
          },
          {
            title: 'Safety requirements',
            text: this.getProjectInfo.project.safety_requiremets
          },
          {
            title: 'Site visit',
            text: this.getProjectInfo.project.site_visit ? 'Yes' : 'No'
          },
          {
            title: 'Accessibility',
            text: this.getProjectInfo.project.accessibility
          }
        ].filter(d => d.text !== '')

        this.siteDetails = {
          title: 'Site',
          details
        }
      }
    },

    setPhotosDetails() {
      const photoTypes = '.png, .jpg, .jpeg';
      this.photosPreview.length = 0;
      this.photosDetails.length = 0;

      if (this.getProjectInfo.file_storages && this.getProjectInfo.file_storages.length) {
        this.photosPreview = this.getProjectInfo.file_storages.filter(file => {
          if (photoTypes.includes(file.file_name.split('.').pop())) {
            return file;
          }
        })

        if (this.photosPreview.length) {
          this.photosDetails = {
            title: 'Photos',
            photos: this.photosPreview
          }
        }
      }
    },

    setFilesDetails() {
      const fileTypes = '.pdf, .doc, .docx, .rtf, .txt, .odt, .xls, .xlsx, .xltx, .ods, .ots, .csv, .dwg, .dxf';
      this.filesPreview.length = 0;
      this.filesDetails.length = 0;

      if (this.getProjectInfo.file_storages && this.getProjectInfo.file_storages.length) {
        this.filesPreview = this.getProjectInfo.file_storages.filter(file => {
          if (fileTypes.includes(file.file_name.split('.').pop())) {
            return file;
          }
        })

        if (this.filesPreview.length) {
          this.filesDetails = {
            title: 'Files',
            files: this.filesPreview
          }
        }
      }
    },

    handleToggleUnsavedChangesModal(logout = false) {
      if (this.showMenu) this.showMenu = false;
      this.showUnsavedChangesModal = !this.showUnsavedChangesModal;
      if (logout) {
        this.isLogoutClicked = true;
      } else {
        this.isLogoutClicked = false;
      }
    },

    handleDiscardClick() {
      if (this.showMenu) this.showMenu = false;
      this.showUnsavedChangesModal = false;
      this.isProjectDetailsChanged = false;
      if (this.isLogoutClicked) {
        this.handleLogoutModal();
        return;
      }
      this.isDiscardChanges = true;
      if (this.navigationLink === 'project-detail') {
        this.handleNavigation();
        return;
      } else {
        this.handleOpenInnerPage(this.navigationLink);
      }
      this.handleNavigation();
    },

    handleDiscardChanges() {
      this.isDiscardChanges = false;
    },

    handleNavigation(linkName, update) {
      if (linkName) {
        this.isLogoutClicked = false;
      }
      if (update === 'update-details') {
        this.currentPage = 'project-detail';
        this.isProjectDetailsChanged = false;
        this.handleLoadInfo();
        return;
      }
      if (this.isProjectDetailsChanged && !this.isDiscardChanges) {
        this.navigationLink = linkName;
        this.showUnsavedChangesModal = true;
        return;
      }
      if (linkName === 'notifications') {
        this.handleOpenInnerPage('notifications');
        return;
      }
      if (linkName === 'users') {
        this.$router.push({ name: 'Users' });
        return;
      }
      if (linkName === 'project-statuses') {
        this.handleOpenInnerPage('project-statuses');
        return;
      }
      if (this.currentPage === 'update-project-details') {
        this.isLoading = true;
        this.handleLoadInfo();
      }
      if (this.currentPage === 'project-id'
        || this.currentPage === 'project-status'
        || this.currentPage === 'update-project-details') {
        this.currentPage = 'project-detail';
      } else {
        this.$router.push({ name: 'Dashboard' });
      }
    },

    handleSubStep(subStepName) {
      this.currentPage = subStepName;
    },

    handleOpenInnerPage(page) {
      if (page) {
        this.isLogoutClicked = false;
      }
      if (this.isProjectDetailsChanged && !this.isDiscardChanges) {
        this.navigationLink = page;
        this.showUnsavedChangesModal = true;
        return;
      }
      if (page === 'projects') {
        this.$router.push({ name: 'Dashboard' });
      } else if (page === 'users') {
        this.$router.push({ name: 'Users'});
      } else {
        this.$router.push({ name: 'Dashboard', query: { page } });
      }
    },

    closeModals() {
      this.currentPage = 'project-detail';
      try {
        this.projectInfo(this.$route.params.id);
      } catch (error) {
        console.error('PRoject 2', error);
        this.showError = true;
      }
    },

    handleToggleMenu() {
      this.showMenu = !this.showMenu;
    },

    handleRequestModal() {
      this.showRequestModal = !this.showRequestModal;
    },

    handleQuoteStep(value) {
      this.quoteCurrentStep = value;
    },

    handleisProjectDetailsChanged(updated = false) {
      if (updated) {
        this.isProjectDetailsChanged = false;
      } else {
        this.isProjectDetailsChanged = true;
      }
    },

    handleUpdateQouteDetailsSession() {
      this.updatingQouteDetailsSession = Math.random().toString(36).substr(2, 9);
    }
  }
}
</script>

<style lang="scss">
.project-detail {
  position: relative;
  padding: 80px 25px 60px;
  max-width: 325px;
  margin: 0 auto;
  min-height: calc(100% - 150px);

  &--header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 25px 8px;
    border-bottom: 1px solid #CCCCD7;
    margin-bottom: 20px;
    background: #F8FBFE;
    transition: .2s;
    height: 60px;
    position: fixed;
    top: 0;
    left: 0px;
    box-sizing: border-box;
    z-index: 1;
    width: 100%;

    &--title {
      color: #151522;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 30px;
    }

    &--button {
      width: 16px;
      height: 16px;
      position: absolute;
      left: 27px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }

  &--id {
    padding: 5px 0 30px;

    &--header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      &--edit {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;

        &--text {
          color: #10886D;
        }

        &--icon {
          width: 28px;
          height: 28px;
          margin-left: 10px;
        }
      }
    }

    &--btn {
      display: flex;
      align-items: center;
      border: 0px;
      background: #fff;
      padding: 0;
      margin-left: 4px;

      &--icon {
        width: 12px;
        height: 12px;
        margin-right: 10px;

        path {
          stroke: #10886D;
        }
      }
    }
  }

  &--status {
    padding: 30px 0;
    border-top: 1px solid rgba(92, 92, 102, 0.32);

    &--header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    &--status-block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: fit-content;
      box-sizing: border-box;
      padding: 9px 20px;
      color: #666670;
      border: 1px solid #B3B3BE;
      border-radius: 40px;
      cursor: pointer;

      &.disabled {
        cursor: default;
        pointer-events: none;
      }

      &--icon {
        margin-right: 8px;
        flex: none;
      }
    }
  }

  &--details {
    border-top: 1px solid rgba(92, 92, 102, 0.32);
  }

  &--button {
    padding: 13px 0;
    max-width: 325px;
    width: 100%;
    margin: 0 auto;
    display: block;
  }

  &--copyright {
    color: #9999A0;
    text-align: center;
    width: 100%;
    position: absolute;
    left: 50%;
    bottom: 36px;
    transform: translateX(-50%);
  }
}

@media screen and (min-width: 1024px) {
  .project-detail {
    margin: 0 60px;
    max-width: 100%;
    padding: 167px 0 60px 82px;
  }
}
</style>
