<template>
  <div class="site-info">

    <custom-input
      :modelValue="location"
      @update:modelValue="handleLocationChange"
      label="Location"
      id="location"
      type="text"
      name="location"
      :errorMessage="locationError"
    />

    <custom-text-area
      :modelValue="safetyRequirements"
      @update:modelValue="handleSafetyRequirementsChange"
      label="Safety requirements"
      id="safety-requirements"
      name="safety-requirements"
      errorMessage=""
      :marginBottom="20"
      optional
    />

    <custom-radio-group
      id="site-visit"
      :modelValue="siteVisit"
      @update:modelValue="handleSiteVisitChange"
      :options="siteVisitOptions"
      label="Is a site visit required prior to job starting?"
      :errorMessage="siteVisitError"
      :marginBottom="8"
    />

    <custom-text-area
      :modelValue="accessibility"
      @update:modelValue="handleAccessibilityChange"
      label="Accessibility"
      id="accessibility"
      placeholder="Is there anything that would prevent access to the job"
      name="accessibility"
      errorMessage=""
      :margin-bottom="0"
      optional
    />

  </div>
</template>

<script>
import CustomInput from '@/components/shared/CustomInput';
import CustomTextArea from '@/components/shared/CustomTextArea';
import CustomRadioGroup from '@/components/shared/CustomRadioGroup';

export default {

  components: {
    CustomInput,
    CustomTextArea,
    CustomRadioGroup,
  },

  props: [
    'location',
    'locationError',
    'handleLocationChange',
    'safetyRequirements',
    'handleSafetyRequirementsChange',
    'siteVisit',
    'siteVisitOptions',
    'siteVisitError',
    'handleSiteVisitChange',
    'accessibility',
    'handleAccessibilityChange'
  ]

}
</script>
