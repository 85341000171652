<template>
  <div
    class="dashboard-menu"
    :class="{'open': showMenu}"
  >
    <div class="dashboard-menu--header">
      <div
        class="dashboard-menu--header--logo"
        :style="{'background-image': `url('${setLogoImage()}')`}"
      ></div>
      <h6 class="dashboard-menu--header--title h6-sb">{{ getUserCompany ? getUserCompany?.name : '' }}</h6>
      <inline-svg
        :src="require('@/assets/images/icons/plus-rotated.svg')"
        class="dashboard-menu--header--close"
        @click="handleToggleMenu"
      />
    </div>
    <div class="dashboard-menu--body">
      <div
        class="dashboard-menu--body--item"
        @click="handleOpenInnerPage('my-account')"
      >
        <inline-svg
          :src="require('@/assets/images/icons/person-menu.svg')"
          class="dashboard-menu--body--item--icon"
        />
        <div class="dashboard-menu--body--item--text">
          <p class="dashboard-menu--body--item--text--title text-medium">My account</p>
          <p class="dashboard-menu--body--item--text--description h6-light">All the necessary settings about your account</p>
        </div>
      </div>

      <div
        class="dashboard-menu--body--item"
        @click="handleOpenInnerPage('company')"
      >
        <inline-svg
          :src="require('@/assets/images/icons/suitcase.svg')"
          class="dashboard-menu--body--item--icon"
        />
        <div class="dashboard-menu--body--item--text">
          <p class="dashboard-menu--body--item--text--title text-medium">Company</p>
          <p class="dashboard-menu--body--item--text--description h6-light">All the necessary settings about your company</p>
        </div>
      </div>

      <div
        class="dashboard-menu--body--item"
        @click="handleOpenInnerPage('email-settings')"
      >
        <inline-svg
          :src="require('@/assets/images/icons/mail-icon.svg')"
          class="dashboard-menu--body--item--icon"
        />
        <div class="dashboard-menu--body--item--text">
          <p class="dashboard-menu--body--item--text--title text-medium">Email notifications</p>
          <p class="dashboard-menu--body--item--text--description h6-light">All the necessary settings about email notifications</p>
        </div>
      </div>

      <div
        v-if="userRole === 'contractor' && getCurrentUser?.role === 'owner'"
        class="dashboard-menu--body--item"
        @click="handleOpenInnerPage('subscription')"
      >
        <inline-svg
          :src="require('@/assets/images/icons/dollar-sign.svg')"
          class="dashboard-menu--body--item--icon"
        />
        <div class="dashboard-menu--body--item--text">
          <p class="dashboard-menu--body--item--text--title text-medium">Subscription</p>
          <p class="dashboard-menu--body--item--text--description h6-light">All the necessary settings about subscription</p>
        </div>
      </div>

      <div
        class="dashboard-menu--body--item"
        @click="handleOpenInnerPage('help')"
      >
        <inline-svg
          :src="require('@/assets/images/icons/help-circle.svg')"
          class="dashboard-menu--body--item--icon"
        />
        <div class="dashboard-menu--body--item--text">
          <p class="dashboard-menu--body--item--text--title text-medium">Help</p>
          <p class="dashboard-menu--body--item--text--description h6-light">Need assistance? We’ll get you the help you need.</p>
        </div>
      </div>

      <!-- TODO hide page with adding new project statuses -->
      <!-- <div
        v-if="userRole === 'contractor' && canEdit"
        class="dashboard-menu--body--item"
        @click="handleOpenInnerPage('project-settings')"
      >
        <inline-svg
          :src="require('@/assets/images/icons/folder.svg')"
          class="dashboard-menu--body--item--icon project"
        />
        <div class="dashboard-menu--body--item--text">
          <p class="dashboard-menu--body--item--text--title text-medium">Project</p>
          <p class="dashboard-menu--body--item--text--description h6-light">All the necessary settings about projects</p>
        </div>
      </div> -->

      <div
        class="dashboard-menu--body--item"
        @click="handleLogoutModal"
      >
         <inline-svg
          :src="require('@/assets/images/icons/log-out.svg')"
          class="dashboard-menu--body--item--icon"
        />
        <div class="dashboard-menu--body--item--text">
          <p class="dashboard-menu--body--item--text--title text-medium logout">Log out</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

import { mapGetters } from 'vuex';

export default {
  components: {
    InlineSvg
  },

  props: [
    'showMenu',
    'handleToggleMenu',
    'handleLogoutModal',
    'setLogoImage',
    'handleOpenInnerPage',
    'userRole'
  ],

  computed: {
    ...mapGetters([
      'getUserCompany',
      'getCurrentUser'
    ]),

    canEdit() {
      return this.getCurrentUser && this.getCurrentUser?.role !== 'viewer';
    },
  },
}
</script>

<style lang="scss">
.dashboard-menu {
  max-width: 315px;
  width: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: -100%;
  z-index: 2;
  height: 100vh;
  background: #fff;
  transition: 0s;

  &.open {
    left: 0;
  }

  &--header {
    padding: 16px 25px;
    border-bottom: 1px solid #BFC3C8;
    display: flex;
    align-items: center;

    &--logo {
      width: 40px;
      height: 40px;
      margin-right: 8px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 50%;
      background-color: #fff;
    }

    &--title {
      color: #000000;
    }

    &--close {
      margin-left: auto;
    }
  }

  &--body {
    display: flex;
    flex-direction: column;

    &--item {
      padding: 12px 22px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #F0F3FF;
      cursor: pointer;

      &--icon {
        width: 28px;
        height: 28px;
        margin-right: 14px;

        &.project {
          width: 31px;
          height: 31px;
        }
      }

      &--text {
        &--title {
          color: #000;
          margin-bottom: 6px;

          &.logout {
            color: #E83C56;
            margin-bottom: 0;
          }
        }

        &--description {
          color: #81878C;
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .dashboard-menu {
    top: 107px;
    left: unset;
    right: -100%;
    height: auto;
    box-shadow: 0px 4px 8px rgba(21, 21, 34, 0.06);
    border: 1px solid #F0F3FF;
    box-sizing: border-box;
    z-index: 4;

    &.open {
      right: 52px;
      left: unset;
    }

    &--header {
      display: none;
    }
  }
}
</style>
