<template>
  <div class="invite-key-already-used">
    <inline-svg
      class="invite-key-already-used--icon"
      :src="require('@/assets/images/icons/alert-triangle-big.svg')"
    />
    <h1 class="invite-key-already-used--title h1-regular">The link has already been used!</h1>
    <p class="invite-key-already-used--description p-medium">Please contact the contractor for a new link or request 
a quote from your customer admin panel</p>
    <router-link
      to="/"
      class="invite-key-already-used--button normal-btn"
    >
      Go to Homepage
    </router-link>
    <p class="invite-key-already-used--copyright caption-3">Powered by FCA</p>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
  components: {
    InlineSvg
  }
}
</script>

<style lang="scss">
.invite-key-already-used {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  &--icon {
    width: 160px;
    height: 160px;
    min-height: 160px;
    margin-bottom: 14px;
  }

  &--title {
    color: #151522;
    text-align: center;
    margin-bottom: 10px;
    max-width: 325px;

    &.h1-regular {
      line-height: 130%;
    }
  }

  &--description {
    font-weight: 400;
    color: #617B97;
    max-width: 325px;
    text-align: center;
  }

  &--button {
    text-align: center;
    text-decoration: none;
    padding: 13px 0;
    display: inline-block;
    width: calc(100% - 50px);
    position: absolute;
    bottom: 100px;
    left: 50%;
    max-width: 325px;
    transform: translateX(-50%);
  }

  &--copyright {
    color: #9999A0;
    text-align: center;
    position: absolute;
    bottom: 36px;
    left: 0;
    width: 100%;
  }
}

@media screen and (max-width: 375px) and (max-height: 811px),
(max-height: 811px) {
  .invite-key-already-used {
    &--description {
      margin-bottom: 88px;
    }

    &--button {
      position: relative;
      left: unset;
      bottom: unset;
      transform: none;
      margin-bottom: 49px;
    }

    &--copyright {
      position: relative;
      left: unset;
      bottom: unset;
      transform: none;
      width: 100%;
      display: inline-block;
      margin-bottom: 36px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .invite-key-already-used {
    &--title {
      line-height: 150%;
    }

    &--description {
      max-width: 461px;
      margin-bottom: 40px;
    }

    &--button {
      position: relative;
      left: unset;
      bottom: unset;
      transform: none;
      max-width: 240px;
    }

    &--copyright {
      display: none;
    }
  }
}
</style>
