<template>
  <div
    class="multy-select-type"
    :style="{ marginBottom: marginBottom + 'px' }"
  >
    <label
      class="multy-select-type--label"
      :class="{'hidden': !label}"
      :for="id"
    >
      {{ label }}
    </label>
    <div
      :class="['multy-select-type--select-wrapper',
        {'err': errorMessage},
        {'disabled': disabled === true},
        {'active': showOptions}
      ]"
    >
      <div
        :class="['multy-select-type--select-wrapper--tags',
                {'active': !showOptions}]"
        @click="handleShowOptions"
      >
        <p
          v-if="!showOptions && !modelValue.length"
          class="multy-select-type--select-wrapper--tags--placeholder"
        >Choose trade types</p>
        <ul
          class="multy-select-type--select-wrapper--tags--selected"
          :class="{'hidden': showOptions || isSearching}"
        >
          <li
            v-for="(work, index) in modelValue"
            :key="index"
            class="multy-select-type--select-wrapper--tags--selected--item h6-regular"
            :data-work-id="work.id"
          >
            {{ work.name }}
            <inline-svg
              :src="require('@/assets/images/icons/plus-rotated.svg')"
              class="multy-select-type--select-wrapper--tags--selected--item--delete"
            />
          </li>
        </ul>
      </div>
      <input
        :class="['multy-select-type--select-wrapper--input',
          {'selected': isSearching || showOptions}
        ]"
        type="text"
        :id="label.toLowerCase()"
        autocomplete="noAutocomplete"
        :placeholder="placeholder"
        :value="isSearching || showOptions
          ? searchQuery
          : ''"
        @input="handleSearch"
        @focus="handleFocus"
        @blur="handleBlur"
      >
      <div
        class="multy-select-type--select-wrapper--options"
        :class="{'show': searchQuery.length || showOptions}"
      >
        <div
          v-for="option in getWorkTypes"
          class="multy-select-type--select-wrapper--options--item"
          :class="{'selected': isIncluded(option)}"
          :key="option.id"
          :value="option.id"
          @click="handleSelectChange"
        >
          {{ option.name }}
        </div>
      </div>
      <p v-if="errorMessage" class="multy-select-type--select-wrapper--error">
        {{ errorMessage }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import InlineSvg from 'vue-inline-svg';

export default {

  components: {
    InlineSvg
  },

  props: {
    modelValue: {
      type: Object,
      default: () => {}
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'enter'
    },
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    marginBottom: {
      type: [String, Number],
      default: 18
    },
    errorMessage: {
      type: String,
      default: 'Error Notification'
    },
  },

  data() {
    return {
      showOptions: false,
      isSearching: false,
      searchQuery: '',
      isLoading: false,
    }
  },

  computed: {
    ...mapGetters([
      'getWorkTypes',
    ]),
  },

  methods: {
    isIncluded(option) {
      let match = this.modelValue.filter(item => item.id === option.id);
      return match.length;
    },

    handleFocus() {
      this.showOptions = true;
    },

    handleShowOptions(e) {
      const target = e.target.closest('.multy-select-type--select-wrapper--tags--selected--item--delete');
      if (target) {
        const workId = target.closest('.multy-select-type--select-wrapper--tags--selected--item').dataset.workId;
        this.handleDeleteWorkType(workId);
      } else {
        const parent = e.target.closest('.multy-select-type--select-wrapper');
        parent.querySelector('input').focus();
        if (!this.disabled) {
          this.isSearching = false;
        }
        this.showOptions = true;
      }
    },

    handleDeleteWorkType(workId) {
      const toRemove = this.modelValue.find(item => 
        item.id === Number(workId)
      );
      this.$emit('update:modelValue', toRemove);
    },

    handleSelectChange(e) {
      const selected = this.getWorkTypes.find(item => 
        item.id === Number(e.target.getAttribute('value'))
      );
      this.$emit('update:modelValue', selected);
      this.isSearching = false;
      this.handleBlur()
    },

    handleSearch(e) {
      this.isSearching = true;
      this.searchQuery = e.target.value;
    },

    handleBlur() {
      setTimeout(() => {
        this.showOptions = false;
        this.isSearching = false;
        this.searchQuery = '';
      },200)
    },
  },
}
</script>

<style lang="scss">
.multy-select-type {
  display: flex;
  flex-direction: column;
  max-width: 325px;
  width: 100%;
  margin: 0 auto;
  position: relative;

  &--label {
    font-size: 16px;
    font-weight: 600;
    color: #151522;
    margin: 0 0 13px 0;
    text-align: left;
    font-family: $SFProText;
    line-height: 150%;

    &.hidden {
      display: none;
    }
  }

  &--select-wrapper {
    border: 1px solid #CCCCD7;
    border-radius: 5px;
    transition: box-shadow .3s ease;
    color: #81878C;
    position: relative;

    &--tags {
      padding: 10px 10px 0;
      background-image: url(~@/assets/images/icons/arrow-down.svg);
      background-position: 96% 12px;
      background-repeat: no-repeat;
      display: none;
      min-height: 52px;
      box-sizing: border-box;

      &.active {
        display: flex;
      }

      &--placeholder {
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
        width: 100%;
        font-family: $SFProText;
        font-weight: 300;
        box-shadow: none;
        color: #B3B3BE;
        font-size: 14px;
      }

      &--selected {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 90%;

        &.hidden {
          display: none;
        }

        &--item {
          background: #fff;
          border: 1px solid #E6E6EB;
          border-radius: 5px;
          padding: 4px 28px 4px 10px;
          font-size: 14px;
          color: #151522;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 80%;
          margin: 0 10px 10px 0;
          box-sizing: border-box;
          position: relative;
          cursor: default;

          &--delete {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
          }
        }
      }
    }

    &.active {
      border: 1px solid #4B617A;
      box-shadow: 0px 4px 8px rgba(21, 21, 34, 0.06);
    }

    &.err {
      border: 1px solid #E83C56;
      color: #E83C56;
    }

    &:hover, &:focus {
      box-shadow: 0px 4px 8px rgba(21, 21, 34, 0.06);
      cursor: pointer;
    }

    &.disabled {
      border: 1px solid #B3B3BE;
      color: #666670;
    }

    &--input {
      border-radius: 5px;
      border: none;
      box-sizing: border-box;
      font-size: 16px;
      line-height: 150%;
      padding: 14px 32px 13px 20px;
      width: 100%;
      font-family: $SFProText;
      font-weight: 300;
      box-shadow: none;
      color: #33333D;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      background-image: url(~@/assets/images/icons/arrow-down.svg);
      background-position: 96% 50%;
      background-repeat: no-repeat;
      display: none;

      &.selected {
        color: #151522;
        display: inline-block;
      }

      .err & {
        color: #E83C56;
      }

      .active & {
        background-image: url(~@/assets/images/icons/arrow-up.svg);
      }

      .disabled & {
        color: #666670;
        background-color: #B3B3BE;
        pointer-events: none;
      }

      &::placeholder {
        color: #33333D;;
      }
    }

    &--options {
      position: absolute;
      top: 64px;
      left: 0;
      width: 100%;
      max-height: 160px;
      overflow-y: overlay;
      overflow-x: hidden;
      background: #fff;
      border-radius: 5px;
      z-index: 3;
      box-shadow: 0px 4px 8px rgba(21, 21, 34, 0.06);
      display: none;

      &.show {
        display: block;
      }

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: #5C5C66;
        border-radius: 10px;
        height: 44px;
      }

      &--item {
        color: #151522;
        line-height: 150%;
        padding: 10px 32px 9px 20px;
        font-size: 16px;
        width: 100%;
        font-family: $SFProText;
        font-weight: 400;
        box-sizing: border-box;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        &.no-results {
          color: #666670;
        }

        &:last-child {
          border: none;
        }

        &.selected {
          position: relative;
          font-weight: 600;

          &:after {
            content: '';
            background: url(~@/assets/images/icons/check-select.svg) no-repeat;
            background-position: center;
            background-size: contain;
            width: 20px;
            height: 20px;
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translateY(-50%)
          }
        }
      }
    }

    &--error {
      font-size: 12px;
      color: #E83C56;
      text-align: right;
      font-family: $SFProText;
      font-weight: 500;
      line-height: 150%;
      position: absolute;
      bottom: -22px;
      right: 0;
    }
  }
}

@media screen and (min-width: 1024px) {
  .multy-select-type {
    max-width: 100%;

    &--select-wrapper {
      &--input,
      &--options--item {
        font-size: 14px;
      }
    }
  }
}
</style>
