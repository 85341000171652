export default {
  computed: {
    getUserRole() {
      if (this.getCurrentUser && this.getCurrentUser?.type === 'Customer') {
        return 'customer';
      } else {
        return 'contractor';
      }
    },

    getLogoutMessage() {
      if (this.getCurrentUser) {
        return `${this.getCurrentUser?.first_name} ${this.getCurrentUser?.last_name}`;
      } else {
        return '';
      }
    }
  },

  methods: {
    handleErrorClose() {
      this.showError = false;
    },

    handleLogoutModal() {
      if (this.isProjectDetailsChanged) {
        if (this.isLogoutClicked) {
          this.showLogoutModal = false;
          this.isLogoutClicked = false;
        } else {
          this.$emit('update:showUnsavedChangesModal', true);
        }
      } else {
        this.showLogoutModal = !this.showLogoutModal;
        if (this.isLogoutClicked) {
          this.isProjectDetailsChanged = true;
        }
      }
    },

    errorStyles() {
      let sidebarElement = document.querySelector(".dashboard-footer");
      if (!this.isMobile && sidebarElement) {
        let sidebarWidth = sidebarElement.offsetWidth;
        let mainPartWidth = this.windowWidth - sidebarWidth;
        let percentPosition = mainPartWidth*100/this.windowWidth/2;
        return `position: fixed; left: calc(${sidebarWidth}px + ${percentPosition}%);`;
      } else {
        return "position: fixed; left: 50%;";
      }
    },

    setLogoImage() {
      if (this.getUserCompany && this.getUserCompany?.file_storages) {
        const imageLogo = this.getUserCompany?.file_storages.find(o => o.file_type.includes("image"));
        if (imageLogo) {
          const imageLogoUrl = imageLogo.file_url;

          return imageLogoUrl;
        } else {
          return require('@/assets/images/icons/logo-placeholder.svg');
        }
      } else {
        return require('@/assets/images/icons/logo-placeholder.svg');
      }
    },

    setProjectStatus(project) {
      if (Object.keys(project).length !== 0 && project.project_statuses) {
      const selectedStatus = project.project_statuses.find(item => item.id === project.project.status);
        return selectedStatus ? selectedStatus.name : '';
      }
    },
  }
}
