<template>
  <div
    id="calendar-wrapper"
    class="input-calendar"
    :style="{ marginBottom: marginBottom + 'px' }">
    <label
      class="input-calendar--label"
      :for="modelValue"
    >
      {{label}}
      <span
        v-if="optional"
        class="h6-light"
      >(opt.)</span>
    </label>

    <div
      class="input-calendar--wrapper"
      @click="isShow = true"
    >
      <input
        type="text"
        :value="modelValue"
        class="flatpickr-input-calendar"
        @input="changeModel"
        :placeholder="placeholder"
      />
      <inline-svg
        :src="require(modelValue ? '@/assets/images/icons/done-icon.svg' : '@/assets/images/icons/calendar-icon.svg')"
        class="input-calendar--wrapper--icon"
      />
    </div>

    <div class="input-calendar--buttons">
      <button
        class="input-calendar--buttons--button ghost-btn"
        @click="handleCancel"
      >Cancel</button>
      <button
        class="input-calendar--buttons--button normal-btn"
        @click="handleApply"
      >Apply</button>
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.css';

export default {
  components: {
    InlineSvg
  },

  props: {
    modelValue: {
      type: [String, Number],
      default: ''
    },
    modePicker: {
      type: String
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    optional: {
      type: Boolean,
      default: false
    },
    marginBottom: {
      type: [String, Number],
      default: 18
    },
    isMultiple: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    currentDate: '',
    calendar: '',
    isShow: false,
  }),

  beforeUnmount() {
    window.removeEventListener('click', this.handleClick);
  },

  mounted() {
    window.addEventListener('click', this.handleClick);

    this.calendar = flatpickr(document.querySelector('.flatpickr-input-calendar'), {
      mode: this.isMultiple ? 'multiple' : 'single',
      allowInput: true,
      altInput: true,
      altFormat: "m/d/Y",
      dateFormat: "m/d/Y",
      monthSelectorType: 'static',
      disableMobile: true,
      appendTo: document.querySelector('.input-calendar--wrapper'),
      locale: {
        firstDayOfWeek: 1
      },
      onChange: (selectedDates, dateStr) => {
        this.currentDate = dateStr;
        this.$emit('update:modelValue', this.currentDate);
      },
      onClose: (selectedDates,dateStr,instance) => {
        const calendar = document.querySelector('.flatpickr-calendar');
        if (!instance.isOpen) {
          if (!calendar.classList.contains('open')) {
            calendar.classList.add('open');
          }
        }
        if (!this.isShow && calendar.classList.contains('open')) {
          calendar.classList.remove('open');
        }
      }
    });
    const calendar = document.querySelector('.flatpickr-calendar');
    const buttons = document.querySelector('.input-calendar--buttons');
    calendar.append(buttons);
  },

  methods: {
    changeModel(e) {
      this.currentDate = e.target.value;
    },

    handleApply() {
      this.$emit('update:modelValue', this.currentDate);
      this.handleClose();
    },

    handleClose() {
      this.isShow = false;
      this.calendar.close();
    },

    handleCancel() {
      this.isShow = false;
      const calendar = document.querySelectorAll('.flatpickr-input-calendar');
      calendar.forEach(item => {item.value = ''});
      this.calendar.clear();
      this.calendar.close();
    },

    handleClick(e) {
      console.log('click');
      const calendar = document.querySelectorAll('.flatpickr-input-calendar');
      if (this.isShow && !e.target.closest('.input-calendar--wrapper')) {
        this.handleClose();
      }
      if (this.modelValue !== calendar.value) {
        calendar.forEach(item => {item.value = this.modelValue});
        this.currentDate = this.modelValue;
      }
      this.$emit('update:modelValue', this.currentDate);
    },
  }
}
</script>

<style lang="scss">
.input-calendar {
  margin-bottom: 20px;

  &--wrapper {
    position: relative;
    display: flex;

    .flatpickr-calendar {
      left: auto !important;
      top: 50px !important;
    }

    &--icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 13px;
      pointer-events: none;
    }
  }

  &--label {
    font-size: 16px;
    font-weight: 600;
    color: #151522;
    margin: 0 0 13px 0;
    text-align: left;
    font-family: $SFProText;
    line-height: 150%;
    display: inline-block;
  }

  &--buttons {
    padding: 14px 12px;
    display: flex;

    &--button {
      padding: 10px 0;
      width: 143px;

      &:first-child {
        margin-right: 14px;
      }
    }
  }
}

.flatpickr-wrapper {
  width: 100%;
}

.flatpickr-input-calendar.input {
  width: 325px;
  color: #151522;
  border: 1px solid #CCCCD7;
  box-sizing: border-box;
  font-size: 16px;
  border-radius: 5px;
  line-height: 150%;
  padding: 14px 40px 13px 20px;
  width: 100%;
  font-family: $SFProText;
  font-weight: 300;
  box-shadow: none;
  transition: box-shadow .3s ease;
}

.flatpickr-input-calendar::placeholder {
  color: #B3B3BE;
}

.flatpickr-calendar {
  border: 1px solid rgba(102, 102, 102, 0.24);
  border-radius: 4px;
  box-shadow: none;
  width: 325px;
  top: 20px;
}

.flatpickr-innerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(92, 92, 102, 0.32);
  padding: 25px 0;
}

.flatpickr-calendar:before,
.flatpickr-calendar:after {
  display: none;
}

.flatpickr-months {
  height: 60px;
  border-bottom: 1px solid rgba(92, 92, 102, 0.32);
}

.flatpickr-months .flatpickr-month {
  height: 60px;
  font-weight: 600;
  font-size: 16px;
}

.flatpickr-current-month {
  top: 12px;
}

.flatpickr-current-month span.cur-month {
  font-family: $SFProText;
  font-weight: 600;
  font-size: 16px;
  color: #151522;
}

.flatpickr-current-month span.cur-month:hover {
  background: transparent;
}

.flatpickr-current-month input.cur-year {
  pointer-events: none;
  font-family: $SFProText;
  font-weight: 600;
  font-size: 16px;
  color: #151522;
}

.numInputWrapper:hover {
  background: transparent;
}

.numInputWrapper span.arrowUp,
.numInputWrapper span.arrowDown {
  display: none;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  color: #9999A0;
  fill: #9999A0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}

.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: #4B617A;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #4B617A;
}

span.flatpickr-weekday {
  font-family: $SFProText;
  font-weight: normal;
  font-size: 12px;
  color: #B3B3BE;
  opacity: 0.5;
  text-transform: uppercase;
}

.flatpickr-day {
  font-family: $SFProText;
  font-weight: 300;
  font-size: 18px;
  color: #4B617A;
  margin-bottom: 4px;
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color: #E6E6EB;
}

.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
  border-radius: 14px 0px 0px 14px;
}

.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0px 14px 14px 0px;
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  background: #DFEBF9;
  border-color: #DFEBF9;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #4B617A;
  border-color: #4B617A;
}

.flatpickr-day.inRange {
  -webkit-box-shadow: -5px 0 0 #DFEBF9, 5px 0 0 #DFEBF9;
  box-shadow: -5px 0 0 #DFEBF9, 5px 0 0 #DFEBF9;
}

.rangeMode .flatpickr-day {
  margin-top: 4px;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  -webkit-box-shadow: -10px 0 0 #4B617A;
  box-shadow: -10px 0 0 #4B617A;
}

@media screen and (max-width: 360px) {
  .flatpickr-rContainer,
  .flatpickr-calendar,
  .dayContainer,
  .flatpickr-days {
    width: 100%;
  }

  .dayContainer {
    min-width: 100%;
  }
}

@media screen and (min-width: 1024px) {
  .flatpickr-calendar {
    width: 397px;
  }

  .flatpickr-input-calendar.input,
  .flatpickr-months .flatpickr-month,
  .flatpickr-current-month span.cur-month,
  .flatpickr-current-month input.cur-year {
    font-size: 14px;
  }

  .input-calendar--buttons {
    justify-content: space-evenly;
  }
}
</style>
