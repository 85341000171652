<template>
  <div class="add-photos">
    <input
      :id="id"
      class="add-photos--input"
      :accept="accept"
      :type="type"
      @change="handleFilesChange"
      ref="fileInput"
      multiple
    >

    <button
      class="add-photos--button small-btn btn-font-large"
      @click="$refs.fileInput.click()"
    >
      <inline-svg
        class="add-photos--button--icon"
        :src="require('@/assets/images/icons/camera.svg')"
      />
      <span class="add-photos--button--text btn-font-large mobile">Add photos</span>
      <span class="add-photos--button--text btn-font-large desktop">Upload photos</span>
    </button>

    <div
      :id="`${id}-gallery`"
      class="add-photos--gallery"
    >
      <div
        v-for="(photo, index) in photosPreview"
        :key="photo"
        class="add-photos--gallery--item"
        :style="{'background-image': `url('${photo.file_url ? photo.file_url : photo}')`}"
      >
        <inline-svg
          class="add-photos--gallery--item--delete"
          :src="require('@/assets/images/icons/cross.svg')"
          @click="handleDelete"
        />
        <inline-svg
          class="add-photos--gallery--item--cancel-load"
          :class="{'already-loaded': index < loadedPhotos }"
          :src="require('@/assets/images/icons/load-file.svg')"
        />
      </div>
    </div>

  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {

  components: {
    InlineSvg,
  },

  props: {
    photos: {
      type: [String, Number, Object],
      default: ''
    },
    photosPreview: {
      type: [String, Number, Object],
      default: ''
    },
    photosIds: {
      type: [String, Number, Object],
      default: ''
    },
    id: {
      type: String,
      default: 'photos-uploader'
    },
    name: {
      type: String,
      default: 'file'
    },
    type: {
      type: String,
      default: 'file'
    },
    accept: {
      type: String,
      default: '.png, .jpg, .jpeg, image/jpeg, image/png, image/jpg'
    },
    buttonText: {
      type: String,
      default: 'Upload photos'
    },
    deleteFile: {
      type: Function
    },
    unregisteredUser: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      inputUpload: '',
      arrayPhotos: []
    }
  },

  computed: {
    loadedPhotos() {
      return this.photosIds.length;
    }
  },

  methods: {
    handleFilesChange() {
      this.arrayPhotos = [];
      this.inputUpload = document.querySelector(`#${this.id}`).files;

      if (this.inputUpload.length) {
        Array.from(this.inputUpload).forEach(photo => {
          let reader = new FileReader();
          reader.addEventListener("load", (e) => {
            this.arrayPhotos.push(photo)
            this.$emit('update:photosPreview', e.target.result);
          });
          reader.readAsDataURL(photo);
        })
      }

      this.$emit('update:photos', this.arrayPhotos);
      document.querySelector(`#${this.id}`).value = '';
    },

    handleDelete(e) {
      const clickedItem = e.target.closest('.add-photos--gallery--item');
      const itemIndex = [...clickedItem.parentElement.children].indexOf(clickedItem);

      this.deleteFile(itemIndex, "photo", this.unregisteredUser ? 'unregistered-user' : '');
    },
  },
}
</script>

<style lang="scss">
.add-photos {
  display: flex;
  flex-direction: column;
  align-items: center;

  &.hidden {
    height: 0;
    width: 0;
    overflow: hidden;
  }

  &--input {
    display: none;
  }

  &--button {
    margin-right: 16px;
    margin-bottom: 30px;
    width: 163px;
    height: 42px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;

    &--text {
      &.mobile {
        display: block;
      }

      &.desktop {
        display: none;
      }
    }

    &--icon {
      display: block;
      margin-right: 6px;
    }
  }

  &--gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 322px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 43px;

    &--item {
      position: relative;
      width: 154px;
      height: 154px;
      margin-bottom: 17px;
      background-size: cover;
      background-position: center;

      &--delete {
        position: absolute;
        top: 8px;
        right: 8px;
        cursor: pointer;
      }

      &--cancel-load {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-90deg);
        cursor: pointer;
        animation: hide .4s forwards;
        animation-delay: 3s;
        transform-origin: center;

        &.already-loaded {
          opacity: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .add-photos {
    &--gallery {
      height: 290px;

      &--item {
        width: 130px;
        height: 130px;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .add-photos {
    align-items: baseline;

    &--button {
      &--icon {
        display: none;
      }

      &--text {
        &.mobile {
          display: none;
        }

        &.desktop {
          display: block;
        }
      }
    }

    &--gallery {
      box-sizing: border-box;
      margin-bottom: 47px;
      min-height: 43px;

      &--item {
        width: 190px;
        height: 190px;
      }
    }
  }
}
</style>
