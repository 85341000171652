<template>
  <div
    class="check-item"
    :style="{ marginBottom: marginBottom + 'px' }">
    <label
      v-if="label"
      class="check-item--label"
    >
      {{ label }}
      <span
        v-if="label && optional"
        class="h6-light"
      >(opt.)</span>
    </label>

    <div
      class="check-item--wrapper"
      v-for="(option, name, index) in options"
      :key="index"
    >
      <input
        class="check-item--wrapper--input"
        :id="getOptionId(option, index)"
        :name="id"
        type="checkbox"
        :value="option"
        :disabled="disabled"
        :checked="isChecked(option)"
        @change="updateValue(option, name)"
      />

      <label
        class="check-item--wrapper--label"
        :for="getOptionId(option, index)"
      >
        {{ getItemName(name) ? getItemName(name) : option.name }}
      </label>

    </div>

    <p v-if="errorMessage" class="check-item--error">
      {{ errorMessage }}
    </p>

  </div>
</template>

<script>
export default {

  props: {
    modelValue: {
      type: [String, Number, Boolean, Object],
      default: false
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number, Boolean, Object],
      default: null,
    },
    options: {
      type: [Array, Object],
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    marginBottom: {
      type: [String, Number],
      default: 0
    },
    errorMessage: {
      type: String,
      default: 'Error Notification'
    },
    optional: {
      type: Boolean,
      default: false
    },
    getItemName: {
      type: Function,
      default: () => {}
    }
  },

  methods: {
    isChecked(option) {
      if (option.id) {
        const isIncluded = this.modelValue.find(item => item.id === option.id);
        return isIncluded ? true : false;
      } else {
        return option
      }
    },

    updateValue(option, name) {
      if (option.name) {
        this.$emit("update:modelValue", option);
      } else {
        this.$emit("update:modelValue", name);
      }
    },

    getOptionId(option, index) {
      return `${this.id}-${option.id ? option.id : index}`;
    }
  },
}
</script>

<style lang="scss" scoped>
.check-item {
  display: flex;
  flex-direction: column;
  max-width: 325px;
  width: 100%;
  margin: 0 auto;
  position: relative;

  &--label {
    font-size: 16px;
    font-weight: 600;
    color: #151522;
    margin: 0 0 18px 0;
    text-align: left;
    font-family: $SFProText;
    line-height: 150%;
  }

  &--wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 24px;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 60px;
    }

    &--label {
      font-family: $SFProText;
      font-weight: 300;
      font-size: 14px;
      color: #151522;
      line-height: 150%;
    }

    &--input {
      width: 0;
      height: 0;
      opacity: 0;

      & + label,
      &:not(:checked) + label {
        position: relative;
        padding-left: 34px;
        cursor: pointer;
        display: inline-block;
      }

      &:checked + label:before,
      &:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        box-sizing: border-box;
        border-radius: 4px;
        background: #fff;
      }

      &:not(:checked) + label:before {
        border: 1px solid #CCCCD7;
      }

      &:checked + label:before {
        border: 1px solid #4B617A;
        background: #4B617A;
      }

      &:checked + label:after,
      &:not(:checked) + label:after {
        content: '';
        width: 20px;
        height: 20px;
        background: #4B617A;
        position: absolute;
        top: 50%;
        left: 2px;
        transform: translateY(-50%);
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
      }

      &:not(:checked) + label:after {
        opacity: 0;
      }

      &:checked + label:after {
        background: url(~@/assets/images/icons/check-icon.svg) no-repeat;
        background-size: contain;
      }

      &:disabled {
        pointer-events: none;

        & + label:before {
          border: 1px solid #B3B3BE;
          background: #B3B3BE;
        }
      }
    }
  }

  &--error {
    font-size: 12px;
    color: #E83C56;
    text-align: right;
    font-family: $SFProText;
    font-weight: 500;
    line-height: 150%;
    position: absolute;
    bottom: -22px;
    right: 0;
  }
}

@media screen and (min-width: 1024px) {
  .check-item {
    max-width: 100%;

    &--wrapper {
      &--label {
        font-size: 14px;
      }
    }
  }
}
</style>
