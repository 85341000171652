<template>
  <div class="notifications">
    <div
      v-if="getNotifications && getNotifications.length"
    >
      <div
        v-for="(activity, index) in notifications"
        :key="index"
      >
        <ActivityItem
          :activity="activity"
          :isMobile="isMobile"
          :highlight="newNotifications >= index +1"
          notificationPage="true"
        />
      </div>

      <CustomLoader
        v-if="isLoading
              && getNotificationsTotalPages !== 0"
        :totalPages="getNotificationsTotalPages"
        :currentPage="currentPage"
        @handleScrollLoad="handleScrollLoad"
        :positionCenter="currentPage === 0 || currentPage === 1"
      />
    </div>

    <div
      v-else
      class="notifications--empty"
    >
      <p class="notifications--empty--text h2-regular">No notifications</p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { nextTick } from 'vue';

import ActivityItem from '@/components/dashboard/ActivityItem';
import CustomLoader from '@/components/shared/CustomLoader';

export default {
  components: {
    ActivityItem,
    CustomLoader
  },

  props: [
    'setLogoImage',
    'isMobile'
  ],

  data: () => ({
    notifications: [],
    isLoading: true,
    currentPage: 0,
    newNotifications: 0
  }),

  computed: {
    ...mapGetters([
      'getNotifications',
      'getNotificationsTotalPages',
      'getNotificationsNew'
    ]),
  },

  watch: {
    getNotifications() {
      this.handleUpdateNotifications();
    }
  },

  mounted() {
    this.handleLoadInfo();
  },

  unmounted() {
    this.newNotifications = 0;
  },

  methods: {
    ...mapActions([
      'notificationsUpdate',
      'notificationsInfo'
    ]),

    async handleLoadInfo() {
      this.newNotifications = this.getNotificationsNew;
      await this.notificationsUpdate();
    },

    async handleScrollLoad() {
      this.currentPage++;
      await this.notificationsInfo(this.currentPage);
      this.isLoading = false;
      if (this.getNotificationsTotalPages > this.currentPage) {
        // edge case for hight resolution screens
        await nextTick();
        this.isLoading = true;
      }
    },

    handleUpdateNotifications() {
      if (this.notifications.length && this.currentPage === 1) {
        return;
      }
      this.notifications.push(...this.getNotifications);
    },
  }
}
</script>

<style lang="scss" scoped>
.notifications {
  margin-bottom: 50px;

  &--empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 225px);

    &--text {
      color: #9999A0;
    }
  }
}

@media screen and (min-width: 1024px) {
  .notifications {
    margin-bottom: 0;

    &--empty {
      height: calc(100vh - 250px);
    }
  }
}
</style>
