<template>
  <div class="chose-user-type">

    <custom-choosing-button
      :modelValue="userType"
      @update:modelValue="handleUserTypeChange"
      id="contractor"
      icon="contractor.svg"
      title="Contractor"
      description="Receive quotes from potential customers, visualize projects, assign work requests to team members."
      @click="handleUserTypeChange('Contractor')"
    />

    <custom-choosing-button
      :modelValue="userType"
      @update:modelValue="handleUserTypeChange"
      id="customer"
      icon="customer.svg"
      title="Customer"
      description="Request a quote from a contractor, manage multiple work requests, and receive updates on projects."
      @click="handleUserTypeChange('Customer')"
    />

  </div>
</template>

<script>
import CustomChoosingButton from '@/components/shared/CustomChoosingButton';

export default {

  components: {
    CustomChoosingButton,
  },

  props: [
    'userType',
    'handleUserTypeChange',
  ],

}
</script>

<style lang="scss">
@media screen and (min-width: 1024px) {
  .chose-user-type {
    margin-bottom: 60px;
  }
}
</style>
