<template>
  <div class="before-started">

    <div class="before-started--wrapper">
      <CustomCheckBox
        id="terms"
        :modelValue="termsAgree"
        @update:modelValue="handleAgreeChange($event, 'termsAgree')"
      />
      <label for="terms" class="before-started--wrapper--label">Check here to indicate that you have read and agree to the <a href="https://finishingcontractors.org/scope-app-terms-and-conditions" target="_blank" style="color: #10886D;">Terms, Conditions and End User License Agreement.</a></label>
    </div>

    <div class="before-started--wrapper">
      <CustomCheckBox
        id="notifications"
        :modelValue="notificationsAgree"
        @update:modelValue="handleAgreeChange($event, 'notificationsAgree')"
      />
      <label for="notifications" class="before-started--wrapper--label">
        <span>Check here to indicate that you agree to receive electronic notifications reflecting activity within the app; including email and in app notifications. These notifications are crucial to proper app functionality.</span>
        <span>(Your privacy is important to us, we will never use or share your email without your permission.)</span>
      </label>
    </div>

    <div class="before-started--wrapper">
      <CustomCheckBox
        id="marketing"
        :modelValue="marketingAgree"
        @update:modelValue="handleAgreeChange($event, 'marketingAgree')"
      />
      <label for="marketing" class="before-started--wrapper--label">
        Check here to receive periodic marketing communications from SCOPE and its partners. (opt.)
      </label>
    </div>
  </div>
</template>

<script>
import CustomCheckBox from '@/components/shared/CustomCheckBox';

export default {
  components: {
    CustomCheckBox
  },

  props: [
    'termsAgree',
    'notificationsAgree',
    'marketingAgree',
    'handleAgreeChange'
  ]
}
</script>

<style lang="scss" scoped>
.before-started {
  &--wrapper {
    display: flex;
    margin-bottom: 20px;

    &--label {
      font-family: $SFProText;
      font-weight: 300;
      font-size: 14px;
      color: #151522;
      line-height: 150%;

      a {
        text-decoration: none;
      }

      span {
        margin-bottom: 10px;
        display: inline-block;

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }

    &:last-child {
      margin-bottom: 60px;
    }
  }
}
</style>
