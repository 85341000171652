<template>
  <div class="check-list">
    <div class="check-list--header">
      <h4 class="h4-sb">Likelihood of getting the job {{rangeValue}}%</h4>
    </div>

    <RangeSlider
      :modelValue="rangeValue"
      @update:modelValue="handleRangeChange"
      :updateRangeValue="handleUpdatePaymentsProgress"
      :getUserRole="getUserRole"
    />

    <div class="check-list--notes">
      <h4 class="check-list--notes--title h4-sb">Internal Job Notes</h4>
      <p class="check-list--notes--description p-super-tiny">Notes are only visible to your organization</p>

      <div
        v-for="(task, index) in tasks"
        :key="index"
        class="check-list--tasks"
        :class="getUserRole !== 'contractor' && 'disable'"
      >
        <div
          class="check-list--tasks--wrapper"
          :class="updatedTaskId === task.id && 'wrapper-update'"
        >

          <p
            @click="handleOpenUpdateTask(task.id, task.name)"
            class="h6-light check-list--tasks--wrapper--list-number"
          >
            {{index + 1}}.
          </p>

          <div
            v-if="updatedTaskId === task.id"
            class="check-list--tasks--wrapper--edit-task"
          >
            <div class="check-list--add-item--wrapper">
              <CustomCommentTextArea
                :modelValue="task.name"
                @update:modelValue="handleTaskUpdateChange(task, $event)"
                id="edit-note"
                name="edit-note"
                placeholder="Add note"
              />

              <div class="check-list--add-item--wrapper--btn-block">
                <button
                  class="check-list--add-item--wrapper--btn-block--button normal-btn update"
                  :class="updatedTaskEmpty
                          && 'disabled'"
                  @click="updateTask(task)"
                >Update</button>

                <inline-svg
                  :src="require('@/assets/images/icons/plus-rotated.svg')"
                  class="check-list--add-item--wrapper--btn-block--icon"
                  @click="handleCancelEdit(task)"
                />

                <inline-svg
                  class="check-list--add-item--wrapper--btn-block--delete"
                  :src="require('@/assets/images/icons/trashcan.svg')"
                  @click="handleDeleteTask"
                />
              </div>
            </div>
          </div>

          <p
            v-else
            class="h6-light check-list--tasks--wrapper--text"
            @click="handleOpenUpdateTask(task.id, task.name)"
          >{{task.name}}</p>
        </div>
      </div>

      <button
        v-if="!showAddNewTask"
        class="project-detail--id--btn text-btn change-sub-step-btn"
        @click="handleOpenAddNewTask"
      >
        <inline-svg
          :src="require('@/assets/images/icons/plus.svg')"
          class="project-detail--id--btn--icon"
        />
        Add new note
      </button>
    </div>

    <div
      v-if="showAddNewTask"
      class="check-list--add-item"
    >
      <div class="check-list--add-item--wrapper">
        <CustomCommentTextArea
          :modelValue="newTask"
          @update:modelValue="handleNewTaskChange"
          id="add-note"
          name="add-note"
          placeholder="Add note"
        />

        <div class="check-list--add-item--wrapper--btn-block">
          <button
            class="check-list--add-item--wrapper--btn-block--button normal-btn"
            :class="!newTask
                    && 'disabled'"
            @click="handleAddTask"
          >Add</button>

          <inline-svg
            :src="require('@/assets/images/icons/plus-rotated.svg')"
            class="check-list--header--icon"
            @click="handleOpenAddNewTask"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { nextTick } from 'vue';

import dashboardHelpers from '@/mixins/dashboardHelpers';

import InlineSvg from 'vue-inline-svg';
import CustomCommentTextArea from '@/components/shared/CustomCommentTextArea';
import RangeSlider from '@/components/dashboard/project-details/RangeSlider';

export default {
  mixins: [dashboardHelpers],

  props: [
    'isLoading'
  ],

  emits: [
    'update:isLoading',
    'update:modelValue'
  ],

  components: {
    InlineSvg,
    RangeSlider,
    CustomCommentTextArea
  },

  data: () => ({
    newTask: '',
    editableTask: '',
    showAddNewTask: false,
    showUpdateTask: false,
    updatedTaskId: null,
    updatedTaskEmpty: false,
    tasks: [],
    rangeValue: 0,
    modelValue: ''
  }),

  watch: {
    getProjectInfo() {
      this.sortCheckListItems();
      this.rangeValue = this.getProjectInfo.project_checklists[0].progress_percentage;
    }
  },

  computed: {
    ...mapGetters([
      'getProjectInfo',
      'getUserCompany',
      'getCurrentUser'
    ]),
  },

  methods: {
    ...mapActions([
      'projectInfo',
      'addCheckListItem',
      'completeOrUpdateCheckListItem',
      'deleteCheckListItem',
      'updatePaymentsProgress'
    ]),

    sortCheckListItems() {
      let newTaskList;
      if (Object.keys(this.getProjectInfo).length !== 0
        && this.getProjectInfo.project_checklists.length
        && this.getProjectInfo.project_checklists[0].project_checklist_items.length) {
        newTaskList = this.getProjectInfo.project_checklists[0].project_checklist_items;
        this.tasks = newTaskList.sort(function(a, b) {
            return (a.id - b.id);
        });
      } else {
        this.tasks.length = 0;
      }
      this.$emit('update:isLoading');
    },

    handleOpenAddNewTask() {
      this.showAddNewTask = !this.showAddNewTask;
      this.newTask = '';
    },

    handleOpenUpdateTask(id, name) {
      this.updatedTaskId = id;
      this.editableTask = name;
      nextTick(() => {
        document.querySelector('#edit-note').focus();
      })
    },

    handleNewTaskChange(value) {
      this.newTask = value;
    },

    handleTaskUpdateChange(task, value) {
      task.name = value;
      if (value == '') {
        this.updatedTaskEmpty = true;
      } else {
        this.updatedTaskEmpty = false;
      }
    },

    handleRangeChange(value) {
      this.rangeValue = value;
    },

    handleCancelEdit(task) {
      this.updatedTaskId = null;
      task.name = this.editableTask;
      this.editableTask = '';
    },

    updateTask(task) {
      if (task.name === this.editableTask) {
        this.handleCancelEdit(task);
      } else {
        this.handleUpdateTask();
      }
    },

    handleDeleteTask() {
      this.updatedTaskEmpty = true;
      this.handleUpdateTask();
    },

    async handleAddTask() {
      if (this.newTask) {
        const data = {
          id: this.getProjectInfo.id,
          params: {
            id: this.getProjectInfo.project_checklists[0].id,
            project_checklist_item: {
              name: this.newTask
            },
            activity: {
              activity_type: 'checklist_item'
            },
          }
        }

        try {
          await this.addCheckListItem(data);
          this.newTask = '';
          this.showAddNewTask = false;
        } catch (error) {
          console.log('Payment check', error)
        }
      }
    },

    async handleUpdateTask() {
      const currentTask = this.getProjectInfo.project_checklists[0].project_checklist_items.find(t => t.id === this.updatedTaskId)
      const data = {
        id: this.getProjectInfo.id,
        item_id: currentTask.id,
        params: {
          ...(!this.updatedTaskEmpty ? {
            project_checklist_item: {
              name: currentTask.name
            },
          } : {}),
          activity: {
            activity_type: this.updatedTaskEmpty ? 'checklist_item_delete_name' : 'checklist_item_update_name'
          },
        }
      }

      try {
        await this.updatedTaskEmpty ? this.deleteCheckListItem(data) : this.completeOrUpdateCheckListItem(data);
        this.updatedTaskId = null;
        this.updatedTaskEmpty = false;
      } catch (error) {
        console.error('Paycment check 2', error);
        console.log(error)
      }
    },

    async handleUpdatePaymentsProgress() {
      const data = {
        id: this.getProjectInfo.id,
        checklist_id: this.getProjectInfo.project_checklists[0].id,
        params: {
          project_checklist: {
            progress_percentage: +this.rangeValue
          },
          activity: {
            activity_type: 'progress_bar'
          },
        }
      }

      try {
        await this.updatePaymentsProgress(data);
      } catch (error) {
        console.error('Payment check 3', error)
      }
    }
  }
}
</script>

<style lang="scss">
.check-list {
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(92, 92, 102, 0.32);

  &--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0 13px;

    &--icon {
      width: 16px;
      height: 16px;
      margin-right: 6px;
      cursor: pointer;

      path {
        stroke: #151522;
      }
    }
  }

  &--notes {
    border-top: 1px solid rgba(92, 92, 102, 0.32);
    padding-top: 30px;

    &--title {
      margin-bottom: 2px;
    }

    &--description {
      font-family: $SFProText;
      color: #617B97;
      opacity: 0.9;
      margin-bottom: 14px;
    }
  }

  &--progress {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;

    p {
      font-family: $SFProText;
      color: #617B97;
      opacity: 0.9;
      margin-right: 8px;
    }

    &--bar {
      position: relative;
      width: calc(100% - 35px);
      height: 12px;
      background: rgba(92, 92, 102, 0.16);
      border-radius: 100px;

      &--color-width {
        position: absolute;
        top: 0;
        left: 0;
        background: #7A95B4;
        height: 100%;

        &.complete {
          background: #10886D;
        }
      }
    }
  }

  &--tasks {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
    margin-left: -6px;

    &.disable {
      pointer-events: none;
    }

    &--wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 6px;

      &:hover {
        background: #F8FBFE;
        border-radius: 4px;
        cursor: pointer;
      }

      &.wrapper-update {
        align-items: flex-start;

        &:hover {
          background: transparent;
          cursor: auto;
        }
      }

      &--text {
        width: 100%;
      }

      &--list-number {
        padding-right: 4px;
      }

      &--icon-wrapper {
        width: 24px;
        height: 24px;
        background-color: #FFFFFF;
        border: 1px solid #CCCCD7;
        box-sizing: border-box;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .3s;
        margin-right: 10px;

        &.task-complete {
          background-color: #4B617A;
        }
      }

      &--edit-task {
        width: 100%;
      }
    }
  }

  &--add-item {
    &--wrapper {
      border: 1px solid #CCCCD7;
      box-sizing: border-box;
      border-radius: 5px;
      background: #FFFFFF;
      padding: 10px 20px;
      width: 100%;

      &--textarea {
        color: #151522;
        box-sizing: border-box;
        font-size: 16px;
        line-height: 150%;
        width: 100%;
        height: 21px;
        font-family: $SFProText;
        font-weight: 300;
        box-shadow: none;
        border: none;
        resize: none;
        overflow: auto;
        padding: 0;
        margin-bottom: 16px;
      }

      &--btn-block {
        display: flex;
        align-items: center;
        position: relative;

        &--button {
          width: 103px;
          height: 44px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 20px;
        }

        &--icon {
          cursor: pointer;
        }

        &--delete {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 2px;
          width: 15px;
          height: 17px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
