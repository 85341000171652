<template>
  <div class="reset-password">

    <custom-sidebar v-if="currentStep === 0"/>

    <div
      class="reset-password--body"
      :class="{'success': currentStep === 1}"
    >

      <div v-if="currentStep === 0">

        <div class="reset-password--body--header">
          <h3 class="reset-password--body--header--title">New password</h3>
        </div>

        <h2 class="reset-password--body--title h2-sb">Create new password</h2>

        <div class="reset-password--body--input-wrapper">
          <custom-input
            :modelValue="password"
            @update:modelValue="handlePasswordChange"
            label="New password"
            id="password"
            type="password"
            name="password"
            :errorMessage="passwordError"
            icon="secured-with-show-option"
          />

          <custom-input
            :modelValue="confirmPassword"
            @update:modelValue="handleConfirmPasswordChange"
            label="Confirm password"
            id="confirm-password"
            type="password"
            name="password"
            confirmPassword
            :errorMessage="confirmPasswordError"
            icon="secured-with-show-option"
          />
        </div>

        <button
          class="reset-password--body--button normal-btn"
          :class="isButtonDisabled && 'disabled'"
          @click="handleReset"
        >
          Reset password
        </button>

      </div>

      <div
        v-if="currentStep === 1"
        class="reset-password--body--success"
      >
        <inline-svg
          class="reset-password--body--success--icon"
          :src="require('@/assets/images/icons/lock.svg')"
        />
        <p class="reset-password--body--success--description p-medium">Your password was successfully changed</p>
      </div>

      <p class="reset-password--body--copyright caption-3">Powered by FCA</p>

      <custom-error
        v-if="showError"
        :errorMessage="errorMessage"
        :handleErrorClose="handleErrorClose"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import { validationUtils } from '@/utils/validation';

import InlineSvg from 'vue-inline-svg';
import CustomInput from '@/components/shared/CustomInput';
import CustomSidebar from '@/components/shared/CustomSidebar';
import CustomError from '@/components/shared/CustomError';

export default {

  name: 'Reset password',

  components: {
    InlineSvg,
    CustomInput,
    CustomSidebar,
    CustomError
  },

  data: () => ({
    currentStep: 0,
    password: '',
    confirmPassword: '',
    passwordError: null,
    confirmPasswordError: null,
    token: '',
    errorMessage: 'Something went wrong. Please reload the page',
    showError: false
  }),

  mounted() {
    this.token = this.$route.query.token;
  },

  computed: {
    getPasswordErrorMessage() {
      return validationUtils.getPasswordErrorMessage(this.password);
    },

    getConfirmPasswordErrorMessage() {
      return validationUtils.getConfirmPasswordErrorMessage(this.password,this.confirmPassword);
    },

    isButtonDisabled() {
      return (!this.password || !this.confirmPassword);
    },
  },

  methods: {
    ...mapActions([
      'resetPassword'
    ]),

    handleErrorClose() {
      this.showError = false;
    },

    handlePasswordChange(value) {
      if (this.passwordError) this.passwordError = null;
      this.password = value;
    },

    handleConfirmPasswordChange(value) {
      if (this.confirmPasswordError) this.confirmPasswordError = null;
      this.confirmPassword = value;
    },

    formValidation() {
      let hasError;
      const passwordErrorMessage = this.getPasswordErrorMessage;
      const confirmPasswordErrorMessage = this.getConfirmPasswordErrorMessage;

      if (passwordErrorMessage) {
        this.passwordError = passwordErrorMessage;
        hasError = true;
      }

      if (confirmPasswordErrorMessage) {
        this.confirmPasswordError = confirmPasswordErrorMessage;
        hasError = true;
      }

      return !hasError
    },

    async handleReset() {
      const isValid = this.formValidation();
      if (!isValid) return;

      const data = {
        password: this.password,
        password_confirmation: this.confirmPassword,
        token: this.token,
      };

      try {
        await this.resetPassword(data);
        this.currentStep++;

        setTimeout(() => {
          //We will change it, when we have user account page
          this.$router.push({ name: "SignIn" });
        }, 5000)
      } catch (error) {
        console.error('Dashboard 10', error);
        if (error) {
          this.showError = true;
          if (error.response && error.response.status === 404) {
            this.errorMessage = 'Token is wrong or expired. Please check your email and try again.';
          }
        }
      }
    },
  }
}
</script>

<style lang="scss">
.reset-password {
  height: 100%;

  &--body {
    padding: 40px 25px 7px;
    margin: 0 auto;
    max-width: 375px;
    height: 100%;
    box-sizing: border-box;
    position: relative;

    &.success {
      padding: 0 25px 50px;
    }

    &--header {
      margin-bottom: 31px;
      position: relative;

      &--link {
        width: 100%;
        max-width: 8px;
        height: 14px;
        padding: 7px 20px 7px 0;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
      }

      &--title {
        text-align: center;
        width: 100%;
        color: #151522;
        font-size: 20px;
        font-weight: 600;
        font-family: $SFProDisplay;
        line-height: 150%;
      }
    }

    &--title {
      color: #151522;
      text-align: left;
      margin-bottom: 38px;
      white-space: pre-wrap;
    }

    &--button {
      padding: 13px 0;
      display: inline-block;
      width: calc(100% - 50px);
      position: absolute;
      bottom: 100px;
      left: 50%;
      max-width: 325px;
      transform: translateX(-50%);
    }

    &--copyright {
      color: #9999A0;
      text-align: center;
      position: absolute;
      bottom: 36px;
      left: 0;
      width: 100%;
    }

    &--success {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;

      &--icon {
        width: 160px;
        height: 160px;
        margin-bottom: 14px;
      }

      &--description {
        color: #617B97;
        max-width: 200px;
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 375px) and (max-height: 811px),
(max-height: 811px) {
  .reset-password {
    &--body {
      &--button,
      &--copyright {
        position: relative;
        left: unset;
        bottom: unset;
        transform: none;
        width: 100%;
      }

      &--button {
        margin-bottom: 18px;
      }

      &--copyright {
        margin-bottom: 36px;
        display: inline-block;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .reset-password {
    display: flex;
    max-width: 100%;
    padding: 0;
    position: relative;

    &--body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 100px 0;
      max-width: 397px;
      width: 100%;
      position: relative;

      &.success {
        padding: 0;
      }

      &--header {
        display: none;
      }

      &--title {
        margin-bottom: 38px;
      }

      &--input-wrapper {
        margin-bottom: 42px;
      }

      &--button {
        width: 240px;
        position: relative;
        left: unset;
        bottom: unset;
        transform: none;
      }

      &--copyright {
        display: none;
      }
    }
  }
}
</style>
