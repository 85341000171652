<template>
  <div class="page-not-found">

    <inline-svg
      :src="require('@/assets/images/icons/404.svg')"
      class="page-not-found--icon"
    />
    <h1 class="page-not-found--title h1-regular">Looks like you’re lost!</h1>
    <p class="page-not-found--description p-medium">The page you’re looking for not available</p>
    <router-link
      to="/"
      class="page-not-found--button normal-btn"
    >
      Go to home
    </router-link>

  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {

  name: 'PageNotFound',

  components: {
    InlineSvg,
  },

}
</script>

<style lang="scss">
.page-not-found {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  &--icon {
    margin-bottom: 20px;
  }

  &--title {
    margin-bottom: 10px;
    color: #151522;
  }

  &--description {
    margin-bottom: 40px;
    color: #617B97;
    max-width: 250px;
    text-align: center;
  }

  &--button {
    text-align: center;
    text-decoration: none;
    padding: 13px 0;
    display: inline-block;
    width: calc(100% - 50px);
    position: absolute;
    bottom: 100px;
    left: 50%;
    max-width: 325px;
    transform: translateX(-50%);
  }
}

@media screen and (max-width: 375px) and (max-height: 811px),
(max-height: 811px) {
  .page-not-found {
    &--button {
      position: relative;
      left: unset;
      bottom: unset;
      transform: none;
    }
  }
}

@media screen and (min-width: 1024px) {
  .page-not-found {
    &--description {
      max-width: 100%;
    }

    &--button {
      position: relative;
      left: unset;
      bottom: unset;
      transform: none;
      max-width: 240px;
    }
  }
}
</style>
