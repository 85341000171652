import axios from 'axios'

const token = localStorage.getItem('user-token');
export default axios.create({
  baseURL: process.env.VUE_APP_STAGING_API_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': token ? `Bearer ${token}` : ''
  }
})
