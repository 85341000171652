<template>
  <div class="blunk-loader">
    <inline-svg
      class="blunk-loader--svg"
      :src="require('@/assets/images/icons/loader.svg')"
    />
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
  components: {
    InlineSvg
  },
}
</script>

<style lang="scss" scoped>
.blunk-loader {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: #fff;
  z-index: 5;

  &--svg {
    width: 36px;
    height: 36px;
    animation: spin 1s linear infinite;
  }
}

@media screen and (min-width: 1024px) {
  .blunk-loader {
    width: calc(100vw - 82px);
  }
}
</style>
